import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import useModal from "../../../hooks/useModal";
import RegisterInstructorModal from "../modals/RegisterInstructorModal";

const RegisterInstructorButton = () => {
  const { t } = useTranslation();
  const { open, handleOpen, handleClose } = useModal();

  return (
    <>
      <Button
        variant="contained"
        color="instructor"
        sx={{
          width: "125px",
        }}
        onClick={handleOpen}
      >
        {t("register-now")}
      </Button>

      <RegisterInstructorModal handleClose={handleClose} open={open} />
    </>
  );
};

export default RegisterInstructorButton;
