import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useTranslation } from "react-i18next";
import Page from "../../common/layout/Page";
import ClassList from "../../components/class/ClassList";
import { GetClasses } from "../../schema/GetClasses";

export const GET_CLASSES = gql`
  query GetClasses {
    classes {
      classId
      number
      startDate
      endDate
      students {
        id
        user {
          email
          firstName
          lastName
        }
      }
    }
  }
`;

function Classes() {
  const { t } = useTranslation();

  const { loading, data } = useQuery<GetClasses>(GET_CLASSES, {
    fetchPolicy: "cache-and-network",
  });

  return (
    <Page
      title={t("manage-classes")}
      pageHeading={t("manage-classes")}
      subHeading={t("overview-of-all-schools-classes")}
    >
      {data ? (
        <ClassList loading={loading} classes={data.classes} canModify />
      ) : (
        <></>
      )}
    </Page>
  );
}

export default Classes;
