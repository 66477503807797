import { useState } from "react";

interface ModalState {
  open: boolean;
  handleOpen: () => void;
  handleClose: () => void;
}

const useModal: () => ModalState = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return {
    open,
    handleClose,
    handleOpen,
  };
};

export default useModal;
