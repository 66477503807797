import { IconButton } from "@mui/material";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import { useTranslation } from "react-i18next";
import EditButton from "../../../common/buttons/EditButton";
import TextBlock from "../../../common/display/TextBlock";
import TextLine from "../../../common/display/TextLine";
import useModal from "../../../hooks/useModal";
import { Practical } from "../../../schema/Practical";
import FormSection from "../../FormSection";
import EditPracticalDetailsModal from "../modals/EditPracticalDetailsModal";

interface PracticalDetailsProps {
  data?: Practical;
}

const FormSectionPracticalDetails = ({ data }: PracticalDetailsProps) => {
  const { t } = useTranslation();
  const { open, handleOpen, handleClose } = useModal();

  return (
    <>
      <FormSection
        sectionTitle={t("edit-editors")}
        sectionAction={
          <IconButton onClick={handleOpen} sx={{ color: "white" }}>
            <GroupAddOutlinedIcon />
          </IconButton>
        }
        headerBackgroundColor="primary.main"
        headerForegroundColor="white"
        canEdit={!!data?.access.canEdit}
      >
        <TextBlock label={t("practical")}>
          <TextLine value={t("practicalType." + data?.practicalType)} />
        </TextBlock>
        <TextBlock label={t("practical-tutor")}>
          <TextLine value={data?.assignedTeacher?.fullName} />
        </TextBlock>
        <TextBlock label={t("organization")}>
          <TextLine value={data?.assignedOrganization?.fullName} />
        </TextBlock>
        <TextBlock label={t("practical-instructor")}>
          <TextLine value={data?.assignedInstructor?.fullName} />
        </TextBlock>
      </FormSection>
      {data && (
        <EditPracticalDetailsModal
          practicalId={data.id}
          open={open}
          handleClose={handleClose}
          data={{
            practicalId: data.id,
            assignedTeacherId: data.assignedTeacher?.id || null,
            assignedInstructorId: data.assignedInstructor?.id || null,
            assignedOrganizationId: data.assignedOrganization?.id || null,
            practicalType: data.practicalType,
          }}
        />
      )}
    </>
  );
};
export default FormSectionPracticalDetails;
