import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import EditButton from "../../../common/buttons/EditButton";
import DisplayDate from "../../../common/display/DisplayDate";
import TextBlock from "../../../common/display/TextBlock";
import useModal from "../../../hooks/useModal";
import { EducationDuration } from "../../../schema/EducationDuration";
import FormSection from "../../FormSection";
import EditEducationDurationModal from "../modals/EditEducationDurationModal";

interface EducationDurationProps {
  data?: EducationDuration | null;
  studentId: string;
  locked?: boolean;
  loading?: boolean;
}

const FormSectionEducationDuration = ({
  data,
  studentId,
  locked,
  loading,
}: EducationDurationProps) => {
  const { open, handleOpen, handleClose } = useModal();
  const { t } = useTranslation();

  return (
    <>
      <FormSection
        sectionTitle={t("education-duration")}
        meta={data?.response?.meta}
        sectionAction={<EditButton handleOpen={handleOpen} />}
        headerBackgroundColor={"student.100"}
        locked={locked}
        loading={loading}
        canEdit={!!data?.access.canEdit}
      >
        {data ? (
          <>
            <TextBlock label={t("education-start")}>
              <DisplayDate>
                {data?.response?.educationDuration?.educationStart}
              </DisplayDate>
            </TextBlock>
            <TextBlock label={t("education-end")}>
              <DisplayDate>
                {data?.response?.educationDuration?.educationEnd}
              </DisplayDate>
            </TextBlock>
          </>
        ) : (
          <Typography color="#c8cbcf" textAlign="center" justifyItems="center">
            {t("no-data")}
          </Typography>
        )}
      </FormSection>
      <EditEducationDurationModal
        data={data?.response?.educationDuration}
        studentId={studentId}
        open={open}
        handleClose={handleClose}
      />
    </>
  );
};

export default FormSectionEducationDuration;
