import { Route, Switch } from "react-router-dom";
import Guarded from "../common/auth/Guarded";
import useCurrentUser from "../hooks/useCurrentUser";
import PracticalPage from "../pages/student/PracticalPage";
import Practicals from "../pages/student/Practicals";
import StudentProfilePage from "../pages/student/StudentProfilePage";
import TrainingCertificate from "../pages/student/TrainingCertificate";
import { Role } from "../schema/globalTypes";

const StudentRoutes = () => {
  const { user } = useCurrentUser();

  return (
    <Guarded requiredRole={Role.STUDENT}>
      <Switch>
        <Route path="/ausbildungsnachweis">
          <TrainingCertificate />
        </Route>
        <Route path="/ausbildungsprofil">
          <StudentProfilePage />
        </Route>
        <Route path="/praxiseinsaetze" exact>
          <Practicals />
        </Route>
        <Route
          path="/praxiseinsaetze/:einsatzId"
          render={(props) => (
            <>
              {user && (
                <PracticalPage
                  studentId={user.id}
                  practicalId={props.match.params.einsatzId}
                />
              )}
            </>
          )}
        />
      </Switch>
    </Guarded>
  );
};

export default StudentRoutes;
