import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import Page from "../../common/layout/Page";
import NavigationSection from "../../components/NavigationSection";
import useCurrentUser from "../../hooks/useCurrentUser";
import { Role } from "../../schema/globalTypes";

export default function StudentHomePage() {
  const { t } = useTranslation();
  const { user } = useCurrentUser();
  const history = useHistory();
  const message = user ? `${t("home")}, ${user.fullName}` : t("home");
  return (
    <Page title={t("home")} pageHeading={message}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography>
            Dies ist Ihr digitaler Ausbildungsnachweis Praxis für die Ausbildung
            zur Pflegefachfrau/zum Pflegefachmann (gemäß § 60 Abs. 5
            Pflegeberufe Ausbildungs- und Prüfungsverordnung – PflAPrV). Dem
            digitalen Nachweis liegen die verbindlichen Unterlagen für die
            Ausbildung im Land Schleswig -Holstein zugrunde.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subHeader">
            Aufbau des Ausbildungsnachweis Praxis
          </Typography>
          <Typography>
            Der Ausbildungsnachweis besteht aus Dokumenten für den Nachweis der
            Praxiseinsätze, die so zusammengestellt sind, dass der
            Ausbildungsverlauf chronologisch abgebildet wird.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container height="100%" columnSpacing={4}>
            <Grid item xs={12} md={6} height="100%">
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                height="100%"
                marginBottom={3}
              >
                <Box>
                  <Typography variant="subHeader">
                    Ausbildungsprofil (Deckblatt)
                  </Typography>
                  <Typography>
                    Im Ausbildungsprofil/auf dem Deckblatt werden Angaben
                    zur/zum Auszubildenden, zum Träger der praktischen
                    Ausbildung und zur Pflegeschule gemacht. Auf einem separaten
                    Blatt werden alle an der Ausbildung beteiligten
                    Ansprechpartner mit ihren Kontaktdaten aufgeführt. Ebenso
                    wird die Vertiefung angegeben, die im Ausbildungsvertrag
                    festgelegt wurde und von der abhängt, ob die/der
                    Auszubildende vom Wahlrecht nach § 59 Pflegeberufegesetz
                    Gebrauch machen kann. Der Vertiefungseinsatz soll beim
                    Träger der praktischen Ausbildung in einem der Bereiche, in
                    dem bereits ein Pflichteinsatz stattgefunden hat,
                    durchgeführt werden.
                  </Typography>
                </Box>
                <NavigationSection
                  title="Ausbildungsprofil (Deckblatt)"
                  role={Role.STUDENT}
                >
                  <Button
                    component={Link}
                    to="/ausbildungsprofil"
                    variant="contained"
                    color="student"
                  >
                    zum Profil
                  </Button>
                </NavigationSection>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} height="100%">
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                height="100%"
                marginBottom={3}
              >
                <Box>
                  <Typography variant="subHeader">
                    Übersicht der Praxiseinsätze
                  </Typography>
                  <Typography>
                    Die tabellarische Übersicht gibt den Ausbildungsverlauf als
                    Auszug aus dem Ausbildungsplan nach § 6 Abs. 3
                    Pflegeberufegesetz und § 3 Abs. 3 Ausbildungs- und
                    Prüfungsverordnung für die Pflegeberufe wieder und wird vom
                    Auszubildenden im Verlauf der Ausbildung ausgefüllt. Die
                    ausgefüllte Übersicht ist vor der Zwischenprüfung sowie vor
                    der Zulassung zur staatlichen Prüfung zu unterschreiben.
                  </Typography>
                </Box>
                <NavigationSection
                  title="Praxiseinsätze (Übersicht)"
                  role={Role.STUDENT}
                >
                  <Button
                    component={Link}
                    to="/praxiseinsaetze"
                    variant="contained"
                    color="student"
                  >
                    zur Übersicht
                  </Button>
                </NavigationSection>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
}
