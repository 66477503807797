import { gql, useQuery } from "@apollo/client";
import {
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import Badge from "@mui/material/Badge";
import { NavLink } from "react-router-dom";
import { GetApproved } from "../../schema/GetApproved";
import { Role } from "../../schema/globalTypes";

interface SideBarNavigationItemProps {
  path?: string;
  title: string;
  exact: boolean;
  backgroundColor?: string;
  children?: MenuItem[];
  icon?: React.ReactNode;
  handleDrawerToggle?: () => void;
  teachersNotApprovedCount: number;
  instructorsNotApprovedCount: number;
  organizationsNotApprovedCount: number;
  studentsNotApprovedCount: number;
}

const SideBarNavigationItem = ({
  path,
  title,
  icon,
  backgroundColor,
  handleDrawerToggle,
  teachersNotApprovedCount,
  instructorsNotApprovedCount,
  organizationsNotApprovedCount,
  studentsNotApprovedCount,
  exact,
}: SideBarNavigationItemProps) => {
  return (
    <ListItemButton
      component={NavLink}
      to={path || ""}
      activeClassName="Mui-selected"
      sx={{
        "&:hover": { backgroundColor: { backgroundColor } },

        "&.Mui-selected": {
          backgroundColor: { backgroundColor },
        },
        "&.Mui-selected&:hover": {
          backgroundColor: { backgroundColor },
        },
      }}
      exact={exact}
      onClick={handleDrawerToggle}
    >
      {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
      <ListItemText primary={title} />

      {title === "Lehrer*innen" && teachersNotApprovedCount > 0 ? (
        <Badge badgeContent={teachersNotApprovedCount} color="primary" />
      ) : null}

      {title === "Praxisanleiter*innen" && instructorsNotApprovedCount > 0 ? (
        <Badge badgeContent={instructorsNotApprovedCount} color="primary" />
      ) : null}

      {title === "Betriebe" && organizationsNotApprovedCount > 0 ? (
        <Badge badgeContent={organizationsNotApprovedCount} color="primary" />
      ) : null}
      {title === "Auszubildende" && studentsNotApprovedCount > 0 ? (
        <Badge badgeContent={studentsNotApprovedCount} color="primary" />
      ) : null}
    </ListItemButton>
  );
};

interface SideBarNavigationProps {
  menu: Menu;
  currentRoles?: Role[] | null;
  handleDrawerToggle?: () => void;
}

const SideBarNavigation = ({
  currentRoles,
  menu,
  handleDrawerToggle,
}: SideBarNavigationProps) => {
  const GET_APPROVED = gql`
    query GetApproved {
      students {
        id
        user {
          isApproved
        }
      }
      instructors {
        userId
        user {
          isApproved
        }
      }
      organizations {
        userId
        user {
          isApproved
        }
      }
      teachers {
        userId
        user {
          isApproved
        }
      }
    }
  `;

  const { data } = useQuery<GetApproved>(GET_APPROVED, {
    pollInterval: 6000,
    skip: !currentRoles?.includes(Role.SCHOOL_ADMIN),
  });

  const unapprovedInstructors = (data?.instructors || []).filter(
    (x) => x.user.isApproved === false
  ).length;

  const unapprovedOrganizations = (data?.organizations || []).filter(
    (x) => x.user.isApproved === false
  ).length;

  const unapprovedStudents = (data?.students || []).filter(
    (x) => x.user.isApproved === false
  ).length;

  const unapprovedTeachers = (data?.teachers || []).filter(
    (x) => x.user.isApproved === false
  ).length;

  return (
    <>
      <Stack>
        {menu
          .filter(({ role }) => !role || currentRoles?.includes(role))
          .map(({ title, children }) => (
            <div key={title}>
              <Grid container alignContent="center">
                <Grid item p={2} paddingTop={4}>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: 13,
                      textTransform: "uppercase",
                    }}
                  >
                    {title}
                  </Typography>
                </Grid>
              </Grid>
              <List key={title} sx={{ padding: 0, marginBottom: 2 }}>
                {children &&
                  children.map(
                    ({
                      exact,
                      title,
                      path,
                      children,
                      icon,
                      backgroundColor,
                    }) => (
                      <SideBarNavigationItem
                        title={title}
                        path={path}
                        children={children}
                        key={title}
                        icon={icon}
                        exact={!!exact}
                        backgroundColor={backgroundColor}
                        handleDrawerToggle={handleDrawerToggle}
                        teachersNotApprovedCount={unapprovedTeachers}
                        instructorsNotApprovedCount={unapprovedInstructors}
                        organizationsNotApprovedCount={unapprovedOrganizations}
                        studentsNotApprovedCount={unapprovedStudents}
                      />
                    )
                  )}
              </List>
              <Divider flexItem />
            </div>
          ))}
      </Stack>
    </>
  );
};

export default SideBarNavigation;
