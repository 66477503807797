import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { UseControllerProps } from "react-hook-form";
import InputDropdown from "../../common/input/InputDropdown";
import { GetInstructorsByOrganizationId } from "../../schema/GetInstructorsByOrganizationId";
import { GetInstructorsList } from "../../schema/GetInstructorsList";

type InputDropdownProps<T> = {
  filterByOrganizationId?: boolean;
  organizationId?: string;
  label?: string;
  required?: boolean;
  autoFocus?: boolean;
  options?: Options[];
  exceptClassId?: string;
} & UseControllerProps<T>;

interface Options {
  label: string;
  id: string;
}

function InstructorDropdown<T>({
  filterByOrganizationId,
  organizationId,
  exceptClassId,
  options,
  ...props
}: InputDropdownProps<T>) {
  const GET_INSTRUCTORS_LIST = gql`
    query GetInstructorsList {
      instructors {
        userId
        user {
          id
          firstName
          lastName
        }
      }
    }
  `;

  const { data: instructorsList } = useQuery<GetInstructorsList>(
    GET_INSTRUCTORS_LIST,
    { fetchPolicy: "cache-and-network" }
  );

  const GET_INSTRUCTORS_BY_ORGANIZATION_ID = gql`
    query GetInstructorsByOrganizationId($organizationId: ID!) {
      instructorsByOrganizationId(organizationId: $organizationId) {
        userId
        user {
          id
          firstName
          lastName
        }
      }
    }
  `;

  const { data: instructorsListById } =
    useQuery<GetInstructorsByOrganizationId>(
      GET_INSTRUCTORS_BY_ORGANIZATION_ID,
      {
        variables: {
          organizationId: organizationId,
        },
        fetchPolicy: "cache-and-network",
      }
    );

  if (!instructorsList) {
    return <></>;
  }

  let finalList: GetInstructorsList = instructorsList;

  if (filterByOrganizationId && instructorsListById) {
    finalList = {
      instructors: instructorsListById?.instructorsByOrganizationId,
    };
  }

  const dropdownInstructors = finalList.instructors.map((instructor) => {
    const userId = instructor.userId;
    const label = instructor.user.firstName + " " + instructor.user.lastName;
    return {
      id: userId,
      label,
    };
  });

  return <InputDropdown options={dropdownInstructors} {...props} />;
}

export default InstructorDropdown;
