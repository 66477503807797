import { UseControllerProps } from "react-hook-form";
import { useTranslation } from "react-i18next";
import InputDropdown from "../../../common/input/InputDropdown";
import { CompetenceAppendix } from "../../../schema/globalTypes";

type InputCompetenceAppendixProps<T> = {
  label: string;
} & UseControllerProps<T>;

function InputCompetenceAppendix<T>(props: InputCompetenceAppendixProps<T>) {
  const { t } = useTranslation();

  const optionsAppendix = [
    {
      label: `${t("competenceAppendix.APPENDIX_1")}`,
      id: CompetenceAppendix.APPENDIX_1,
    },
    {
      label: `${t("competenceAppendix.APPENDIX_2")}`,
      id: CompetenceAppendix.APPENDIX_2,
    },
    {
      label: `${t("competenceAppendix.APPENDIX_3")}`,
      id: CompetenceAppendix.APPENDIX_3,
    },
    {
      label: `${t("competenceAppendix.APPENDIX_4")}`,
      id: CompetenceAppendix.APPENDIX_4,
    },
  ];
  return <InputDropdown options={optionsAppendix} {...props} required={true} />;
}

export default InputCompetenceAppendix;
