import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import deLocale from "date-fns/locale/de";
import { SnackbarProvider } from "notistack";
import React from "react";
import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import theme from "../src/assets/theme/theme";
import App from "./App";
import AuthenticatedApolloProvider from "./common/auth/AuthenticatedApolloProvider";
import { AuthenticationProvider } from "./context/AuthenticationContext";
import "./i18n";

ReactDOM.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
          <SnackbarProvider maxSnack={3}>
            <AuthenticationProvider>
              <AuthenticatedApolloProvider>
                <HelmetProvider>
                  <App />
                </HelmetProvider>
              </AuthenticatedApolloProvider>
            </AuthenticationProvider>
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
