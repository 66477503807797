/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum CompetenceAppendix {
  APPENDIX_1 = "APPENDIX_1",
  APPENDIX_2 = "APPENDIX_2",
  APPENDIX_3 = "APPENDIX_3",
  APPENDIX_4 = "APPENDIX_4",
}

export enum CompetenceType {
  I = "I",
  II = "II",
  III = "III",
  IV = "IV",
  V = "V",
}

export enum InterviewProtocolType {
  FIRST = "FIRST",
  INTERMEDIATE = "INTERMEDIATE",
  LAST = "LAST",
}

export enum InterviewReason {
  COACHING = "COACHING",
  EXCERSIZE = "EXCERSIZE",
  OTHER = "OTHER",
}

export enum PracticalLocationType {
  LIVING_AREA = "LIVING_AREA",
  WARD = "WARD",
}

export enum PracticalType {
  ADDITIONAL1 = "ADDITIONAL1",
  ADDITIONAL2 = "ADDITIONAL2",
  ADDITIONAL3 = "ADDITIONAL3",
  OBLIGATORY1 = "OBLIGATORY1",
  OBLIGATORY2 = "OBLIGATORY2",
  OBLIGATORY3 = "OBLIGATORY3",
  OBLIGATORY4 = "OBLIGATORY4",
  OBLIGATORY5 = "OBLIGATORY5",
  ORIENTATION = "ORIENTATION",
}

export enum Role {
  ADMIN = "ADMIN",
  INSTRUCTOR = "INSTRUCTOR",
  ORGANIZATION = "ORGANIZATION",
  SCHOOL_ADMIN = "SCHOOL_ADMIN",
  STUDENT = "STUDENT",
  TEACHER = "TEACHER",
}

export enum SpecializationType {
  CHILDREN = "CHILDREN",
  ELDERLY = "ELDERLY",
  NONE = "NONE",
}

export interface AddStudentToClassInput {
  classId: string;
  userId: string;
}

export interface ClassUserAssignmentInput {
  classId: string;
  userId: string;
}

export interface CreateClassInput {
  classNumber: string;
  startDate: ScalarDate;
  endDate: ScalarDate;
}

export interface CreateFileInput {
  name: string;
  upload: ScalarUpload;
}

export interface CreateInstructorInput {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  schoolId: string;
  organizationID?: string | null;
}

export interface CreateOrganizationInput {
  email: string;
  password: string;
  organizationName: string;
  street?: string | null;
  streetNumber?: number | null;
  postCode?: number | null;
  city: string;
}

export interface CreateStudentInput {
  email: string;
  firstName: string;
  lastName: string;
  birthdate: string;
  password: string;
  schoolId: string;
  classId?: string | null;
}

export interface CreateTeacherInput {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  schoolId: string;
  isSchoolAdmin: boolean;
}

export interface DeleteClassInput {
  classId: string;
}

export interface DeleteStudent {
  userId: string;
}

export interface EducationDurationInput {
  educationStart: ScalarDate;
  educationEnd: ScalarDate;
}

export interface EducationProviderInput {
  provider: string;
  institutionName?: string | null;
  street: string;
  houseNumber: string;
  postcode: string;
  city: string;
  mentor: string;
}

export interface InstructionProtocolInput {
  practicalId: string;
  date: ScalarDate;
  duration: number;
  description: string;
  signature?: string | null;
}

export interface InterviewProtocolTeacherInput {
  date: ScalarDate;
  attendedByStudent: boolean;
  attendedByInstructor: boolean;
  instructorName?: string | null;
  attendedByTeacher: boolean;
  teacherName?: string | null;
  attendedByOther: PersonInput[];
  interviewReason: InterviewReason[];
  interviewReasonOtherDescription?: string | null;
  descriptionSituation?: ScalarHtml | null;
  descriptionOther?: ScalarHtml | null;
  descriptionResults?: ScalarHtml | null;
}

export interface MasterDataInput {
  firstName: string;
  lastName: string;
  classId?: string | null;
  supplyArea?: string | null;
}

export interface NightShiftProtocolInput {
  practicalId: string;
  studentId: string;
  startDate: ScalarDate;
  stopDate: ScalarDate;
  plannedWorkingHours: number;
  achievedHours: number;
  description?: string | null;
  signatureName: string;
  signature: string;
}

export interface NursingSchoolInput {
  schoolName: string;
  street: string;
  houseNumber: string;
  postCode: string;
  city: string;
  mentor: string;
}

export interface PersonInput {
  name: string;
  email?: string | null;
  role?: string | null;
}

export interface PracticalDurationInput {
  id: string;
  startDate?: ScalarDate | null;
  endDate?: ScalarDate | null;
  plannedHours?: number | null;
  recordedHours?: number | null;
}

export interface PracticalInput {
  studentId: string;
  practicalType: PracticalType;
}

export interface PracticalLocationInput {
  id: string;
  isProvider: boolean;
  name: string;
  street: string;
  housenumber: string;
  postcode: string;
  city: string;
  locationType: PracticalLocationType;
  locationTypeDescription: string;
  specialization: string;
}

export interface ReferenceInput {
  type: string;
  id: string;
}

export interface RegisterInstructorInput {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export interface RegisterOrganizationInput {
  email: string;
  password: string;
  organizationName: string;
  street?: string | null;
  streetNumber?: number | null;
  postCode?: number | null;
  city: string;
}

export interface RegisterStudentInput {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  birthdate: string;
  schoolId: string;
}

export interface RegisterTeacherInput {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  schoolId: string;
}

export interface RemarksInput {
  remarks: string;
}

export interface RemoveStudentToClassInput {
  classId: string;
  userId: string;
}

export interface SpecializationInput {
  specialization: SpecializationType;
  choiceMade?: ScalarDate | null;
  contractUpdated?: ScalarDate | null;
}

export interface TaskInput {
  content: ScalarHtml;
  competenceTypes: CompetenceType[];
}

export interface UpdateClassInput {
  classId: string;
  classNumber: string;
  startDate: ScalarDate;
  endDate: ScalarDate;
}

export interface UpdateInstructorInput {
  userId: string;
  email: string;
  firstName: string;
  lastName: string;
}

export interface UpdateInterviewProtocolInput {
  date: ScalarDate;
  attendedByStudent: boolean;
  attendedByInstructor: boolean;
  attendedByOther?: PersonInput[] | null;
  descriptionSituation?: ScalarHtml | null;
  descriptionGoals?: ScalarHtml | null;
  descriptionExercises?: ScalarHtml | null;
  descriptionResults?: ScalarHtml | null;
}

export interface UpdateOrganizationInput {
  userId: string;
  email: string;
  organizationName: string;
  street?: string | null;
  streetNumber?: number | null;
  postCode?: number | null;
  city: string;
}

export interface UpdatePracticalInput {
  practicalId: string;
  practicalType: PracticalType;
  assignedTeacherId?: string | null;
  assignedInstructorId?: string | null;
  assignedOrganizationId?: string | null;
}

export interface UpdateSchoolInput {
  id: string;
  name: string;
  street: string;
  postcode: string;
  city: string;
}

export interface UpdateTaskInput {
  competenceAppendix: CompetenceAppendix;
  tasks: TaskInput[];
}

export interface UpdateTeacherInput {
  userId: string;
  email: string;
  firstName: string;
  lastName: string;
  isSchoolAdmin: boolean;
}

export interface UpdateUserInput {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
