import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircle";
import {
  Alert,
  Grid,
  IconButton,
  Stack,
  Table,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import gql from "graphql-tag";
import { useEffect } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import AddButton from "../../../common/buttons/AddButton";
import FormModal from "../../../common/form/FormModal";
import InputRichText from "../../../common/input/InputRichText";
import EmptyStateSubtle from "../../../common/layout/EmptyStateSubtle";
import { STUDENT_PRACTICAL } from "../../../fragments/student";
import { CompetenceType, UpdateTaskInput } from "../../../schema/globalTypes";
import {
  UpdatePracticalTasks,
  UpdatePracticalTasksVariables,
} from "../../../schema/UpdatePracticalTasks";
import InputCompetenceAppendix from "../input/InputCompetenceAppendix";
import InputCompetenceArea from "../input/InputCompetenceArea";

interface EditPracticalTasksModalProps {
  data?: UpdateTaskInput | null;
  practicalId: string;
  open: boolean;
  handleClose: () => void;
}

export default function EditPracticalTasksModal({
  data,
  practicalId,
  open,
  handleClose,
}: EditPracticalTasksModalProps) {
  const UPDATE_PRACTICAL_TASKS = gql`
    ${STUDENT_PRACTICAL}
    mutation UpdatePracticalTasks(
      $practicalTasks: UpdateTaskInput!
      $practicalId: ID!
    ) {
      updatePracticalTasks(
        command: $practicalTasks
        practicalId: $practicalId
      ) {
        ...Practical
      }
    }
  `;

  const form = useForm<UpdateTaskInput>();
  const { control, reset } = form;
  const { t } = useTranslation();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "tasks",
  });

  const addTask = () =>
    append({
      content: "",
      competenceTypes: [],
    });

  useEffect(() => {
    reset(
      data || {
        tasks: [],
      }
    );
  }, [data, practicalId, reset, open]);

  return (
    <FormProvider {...form}>
      <FormModal<
        UpdateTaskInput,
        UpdatePracticalTasks,
        UpdatePracticalTasksVariables
      >
        title={t("add-tasks")}
        description=""
        maxWidth="lg"
        mutation={UPDATE_PRACTICAL_TASKS}
        open={open}
        handleClose={handleClose}
        makeVars={(practicalTasks) => ({
          practicalTasks: { ...practicalTasks },
          practicalId,
        })}
        useContext
      >
        {(control) => (
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8} display="flex" flexDirection="column">
                  <Box
                    sx={{
                      backgroundColor: "info.main",
                      padding: 2,
                      borderRadius: 1,
                      marginBottom: 1,
                      fontWeight: "bold",
                    }}
                  >
                    {t("tasks-agreed-on-in-first-or-intermediate-interview")}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flex: 1,
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "2rem",
                    }}
                  >
                    <AddButton
                      onClick={addTask}
                      buttonLabel={t("add-task")}
                      variant="outlined"
                      color="success"
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Typography
                    variant="subtitle1"
                    color="#96C11E"
                    sx={{ marginBottom: 2 }}
                  >
                    {t("appendix-number-competence-area")}
                  </Typography>
                  <InputCompetenceAppendix
                    name={`competenceAppendix` as const}
                    control={control}
                    label={t("appendix-number")}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {fields && fields.length >= 1 ? (
                <>
                  <Grid container spacing={4}>
                    {fields.map((field, index) => {
                      return (
                        <Grid item xs={12}>
                          <Grid container spacing={{ xs: 0, sm: 2 }}>
                            <Grid item xs={12} sm={8} sx={{ marginBottom: 2 }}>
                              <Stack direction="row" alignItems="center">
                                <Typography
                                  color="#96C11E"
                                  marginRight={2}
                                  variant="subtitle1"
                                >{`Aufgabe ${index + 1}`}</Typography>
                                <IconButton
                                  size="small"
                                  sx={{
                                    "&:hover": {
                                      "& .removeIcon": { color: "error.main" },
                                    },
                                  }}
                                  onClick={() => remove(index)}
                                >
                                  <RemoveCircleOutlineIcon className="removeIcon" />
                                </IconButton>
                              </Stack>
                              <InputRichText
                                name={`tasks.${index}.content` as const}
                                key={field.id}
                                multiline
                                control={control}
                                label={t("task")}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={4}
                              display="flex"
                              flexDirection="column"
                              flex="1"
                              justifyContent="flex-end"
                              paddingBottom="0.4rem"
                            >
                              <Typography color="#96C11E">
                                {t("expertise-in-competence-areas-I-V")}
                              </Typography>
                              <InputCompetenceArea
                                name={`tasks.${index}.competenceTypes` as const}
                                label={t("practical-tasks")}
                                control={control}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                </>
              ) : (
                <Grid item xs={12}>
                  <EmptyStateSubtle
                    title={t("no-tasks")}
                    description={t("add-tasks-to-start")}
                    buttonLabel={t("add-tasks")}
                  />
                </Grid>
              )}
            </Grid>
            {fields && fields.length >= 1 && (
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={8}></Grid>
                  <Grid item xs={12} sm={4}>
                    <Box>
                      <Alert severity="info" color="success">
                        <Table>
                          {Object.keys(CompetenceType).map((competenceType) => (
                            <TableRow key={competenceType}>
                              <TableCell
                                sx={{
                                  border: "none",
                                  fontWeight: "800",
                                  padding: "4px",
                                }}
                              >
                                {competenceType}
                              </TableCell>
                              <TableCell
                                sx={{ border: "none", padding: "4px" }}
                              >
                                {t(`competenceTypes.${competenceType}`)}
                              </TableCell>
                            </TableRow>
                          ))}
                        </Table>
                      </Alert>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
      </FormModal>
    </FormProvider>
  );
}
