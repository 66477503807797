import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import EditButton from "../../../common/buttons/EditButton";
import DisplayRichText from "../../../common/display/DisplayRichText";
import TextBlockValue from "../../../common/display/TextBlockValue";
import useModal from "../../../hooks/useModal";
import { Remarks } from "../../../schema/Remarks";
import FormSection from "../../FormSection";
import EditRemarksModal from "../modals/EditRemarksModal";

interface RemarksProps {
  data?: Remarks | null;
  studentId: string;
  locked?: boolean;
  loading?: boolean;
}

const FormSectionRemarks = ({
  data,
  studentId,
  locked,
  loading,
}: RemarksProps) => {
  const { open, handleOpen, handleClose } = useModal();
  const { t } = useTranslation();

  return (
    <>
      <FormSection
        sectionTitle={t("remarks")}
        meta={data?.response?.meta}
        sectionAction={<EditButton handleOpen={handleOpen} />}
        locked={locked}
        loading={loading}
        headerBackgroundColor={"student.100"}
        canEdit={!!data?.access.canEdit}
      >
        {data?.response?.remarks ? (
          <TextBlockValue>
            <DisplayRichText text={data.response?.remarks.remarks} />
          </TextBlockValue>
        ) : (
          <Typography color="#c8cbcf" textAlign="center" justifyItems="center">
            {t("no-data")}
          </Typography>
        )}
      </FormSection>
      <EditRemarksModal
        data={data?.response?.remarks}
        studentId={studentId}
        open={open}
        handleClose={handleClose}
      />
    </>
  );
};

export default FormSectionRemarks;
