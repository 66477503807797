import gql from "graphql-tag";
import React from "react";
import { useTranslation } from "react-i18next";
import FormModal from "../../../common/form/FormModal";
import InputText from "../../../common/input/InputText";
import { EducationProviderInput } from "../../../schema/globalTypes";
import {
  UpdateEducationProvider,
  UpdateEducationProviderVariables,
} from "../../../schema/UpdateEducationProvider";

interface EditEducationProviderProps {
  data?: EducationProviderInput | null;
  studentId: string;
  open: boolean;
  handleClose: () => void;
}

export default function EditEducationProviderModal({
  data,
  studentId,
  open,
  handleClose,
}: EditEducationProviderProps) {
  const { t } = useTranslation();

  const UPDATE_EDUCATIONPROVIDER = gql`
    mutation UpdateEducationProvider(
      $educationProvider: EducationProviderInput!
      $studentId: ID!
    ) {
      updateEducationProvider(
        command: $educationProvider
        studentId: $studentId
      ) {
        id
        educationProvider {
          response {
            educationProvider {
              provider
              institutionName
              street
              houseNumber
              postcode
              city
              mentor
            }
          }
          access {
            canEdit
            canView
          }
        }
      }
    }
  `;

  return (
    <FormModal<
      EducationProviderInput,
      UpdateEducationProvider,
      UpdateEducationProviderVariables
    >
      title={t("provider-of-practical-training")}
      description={t("change-the-data-of-the-training-provider-here")}
      defaultValues={data || undefined}
      makeVars={(educationProvider) => ({
        educationProvider: { ...educationProvider },
        studentId,
      })}
      mutation={UPDATE_EDUCATIONPROVIDER}
      open={open}
      handleClose={handleClose}
    >
      {(control) => (
        <>
          <InputText
            control={control}
            name="provider"
            label={t("education-provider")}
            required
            autoFocus
          />
          <InputText
            control={control}
            name="institutionName"
            label={t("institution-name")}
            // helperText="sofern abweichend vom Träger"
            // required
          />
          <InputText
            control={control}
            name="street"
            label={t("street")}
            required
          />
          <InputText
            control={control}
            name="houseNumber"
            label={t("house-number")}
            required
          />
          <InputText
            control={control}
            name="postcode"
            label={t("postcode")}
            required
          />
          <InputText control={control} name="city" label={t("city")} required />
          <InputText
            control={control}
            name="mentor"
            label={t("mentor")}
            required
          />
        </>
      )}
    </FormModal>
  );
}
