import { ReactNode } from "react";
import useCurrentUser from "../../hooks/useCurrentUser";
import { Role } from "../../schema/globalTypes";

interface GuardedProps {
  children: ReactNode;
  requiredRole: Role;
}

const Guarded = ({ children, requiredRole }: GuardedProps) => {
  const { user } = useCurrentUser();
  return <>{user?.roles.includes(requiredRole) ? <>{children}</> : <></>}</>;
};

export default Guarded;
