import { useQuery } from "@apollo/client";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import gql from "graphql-tag";
import { useTranslation } from "react-i18next";
import UploadButton from "../common/buttons/UploadButton";
import DisplayDate from "../common/display/DisplayDate";
import useModal from "../hooks/useModal";
import { GetFiles, GetFilesVariables } from "../schema/GetFiles";
import DeleteFileButton from "./DeleteFileButton";
import FormSection from "./FormSection";
import UploadFileModal from "./UploadFileModal";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

interface UploadFileProps {
  locked?: boolean;
  loading?: boolean;
  headerBackgroundColor?: string;
  referenceType: string;
  referenceId: string;
}

export const GET_FILES = gql`
  query GetFiles($reference: ReferenceInput!) {
    files(reference: $reference) {
      file {
        id
        name
      }
      meta {
        createdAt
        createdBy {
          fullName
        }
      }
      access {
        canEdit
        canView
      }
    }
  }
`;

const UploadFileSection = ({
  locked,
  loading,
  headerBackgroundColor,
  referenceId,
  referenceType,
}: UploadFileProps) => {
  const { open, handleOpen, handleClose } = useModal();
  const { t } = useTranslation();

  const { data } = useQuery<GetFiles, GetFilesVariables>(GET_FILES, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    variables: { reference: { id: referenceId, type: referenceType } },
  });

  return (
    <>
      <FormSection
        sectionTitle={t("file-upload")}
        locked={locked}
        loading={loading}
        headerBackgroundColor={"instructor.50"}
        sectionAction={<UploadButton handleOpen={handleOpen} />}
      >
        <>
          <TableContainer>
            <Table>
              <TableHead sx={{ backgroundColor: "#ededed" }}>
                <TableRow>
                  <TableCell component="th" scope="row">
                    {t("filename")}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {t("uploaded-at")}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {t("by")}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    align="right"
                  ></TableCell>
                </TableRow>
              </TableHead>
              {data && data.files.length >= 1 ? (
                <TableBody>
                  {data.files.map((file) => (
                    <TableRow key={file.file.id}>
                      <TableCell>{file.file.name}</TableCell>
                      <TableCell>
                        <DisplayDate>{file.meta.createdAt}</DisplayDate>
                      </TableCell>
                      <TableCell>{file.meta.createdBy?.fullName}</TableCell>
                      <TableCell
                        align="right"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <a href={`/file/${file.file.id}`} download>
                          <IconButton>
                            <FileDownloadIcon />
                          </IconButton>
                        </a>

                        {file.access.canEdit && (
                          <DeleteFileButton
                            referenceId={referenceId}
                            referenceType={referenceType}
                            fileId={file.file.id}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Typography
                        color="#c8cbcf"
                        textAlign="center"
                        justifyItems="center"
                      >
                        {t("no-files")}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </>
      </FormSection>

      <UploadFileModal
        title={t("upload-file")}
        description={t("upload-a-file-that-you-want-to-add")}
        referenceId={referenceId}
        referenceType={referenceType}
        open={open}
        handleClose={handleClose}
      />
    </>
  );
};
export default UploadFileSection;
