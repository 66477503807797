import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import Page from "../../common/layout/Page";
import NavigationSection from "../../components/NavigationSection";
import useCurrentUser from "../../hooks/useCurrentUser";
import { Role } from "../../schema/globalTypes";

export default function TeacherHomePage() {
  const { t } = useTranslation();
  const { user } = useCurrentUser();
  const history = useHistory();
  const message = user ? `${t("home")}, ${user.fullName}` : t("home");
  return (
    <Page title={t("home")} pageHeading={message}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography>
            Dies ist der digitaler Ausbildungsnachweis Praxis für die Ausbildung
            zur Pflegefachfrau/zum Pflegefachmann (gemäß § 60 Abs. 5
            Pflegeberufe Ausbildungs- und Prüfungsverordnung – PflAPrV). Dem
            digitalen Nachweis liegen die verbindlichen Unterlagen für die
            Ausbildung im Land Schleswig-Holstein zugrunde.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container columnSpacing={4}>
            <Grid item xs={12} md={4} height="100%">
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                height="100%"
                marginBottom={3}
              >
                <Box marginBottom={3}>
                  <Typography variant="subHeader">
                    Meine Auszubildenden
                  </Typography>
                  <Typography>
                    Hier sehen Sie eine Liste aller Auszubildenden, für die Sie
                    als Kursleitung zugeordnet sind.
                  </Typography>
                </Box>
                <NavigationSection
                  title="Meine Auszubildenden"
                  role={Role.TEACHER}
                >
                  <Button
                    component={Link}
                    to="/ausbildung/auszubildende"
                    variant="contained"
                    color="teacher"
                  >
                    zu den Auszubildenden
                  </Button>
                </NavigationSection>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} height="100%">
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                height="100%"
                marginBottom={3}
              >
                <Box marginBottom={3}>
                  <Typography variant="subHeader">Meine Kurse</Typography>
                  <Typography>
                    Hier sehen Sie eine Liste aller Kurse, für die Sie als
                    Kursleitung zugeordnet sind.
                  </Typography>
                </Box>
                <NavigationSection
                  title="Meine Auszubildenden"
                  role={Role.TEACHER}
                >
                  <Button
                    component={Link}
                    to="/ausbildung/kurse"
                    variant="contained"
                    color="teacher"
                  >
                    zu meinen Kursen
                  </Button>
                </NavigationSection>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} height="100%">
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                height="100%"
                marginBottom={3}
              >
                <Box marginBottom={3}>
                  <Typography variant="subHeader">
                    Meine Praxisbegleitungen
                  </Typography>
                  <Typography>
                    Hier sehen Sie eine Liste aller Praxiseinsätze, für die Sie
                    als Praxisbegleitung zugeordnet sind.
                  </Typography>
                </Box>
                <NavigationSection
                  title="Meine Auszubildenden"
                  role={Role.TEACHER}
                >
                  <Button
                    component={Link}
                    to="/ausbildung/praxisbegleitungen"
                    variant="contained"
                    color="teacher"
                  >
                    zu den Praxisbegleitungen
                  </Button>
                </NavigationSection>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
}
