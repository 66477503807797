import {
  Alert,
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import UploadButton from "../../../common/buttons/UploadButton";
import DisplayDate from "../../../common/display/DisplayDate";
import DisplayTimespan from "../../../common/display/DisplayTimespan";
import useModal from "../../../hooks/useModal";
import { Role } from "../../../schema/globalTypes";
import { InstructionProtocol } from "../../../schema/InstructionProtocol";
import ApproveButtonInstructionProtocols from "../../approval/ApproveButtonInstructionProtocols";
import ApprovedBy from "../../approval/ApprovedBy";
import FormSection from "../../FormSection";
import AddInstructionProtocolModal from "../modals/AddInstructionProtocolModal";

interface InstructionProtocolProps {
  data?: InstructionProtocol | null;
  practicalId: string;
  loading?: boolean;
}

const FormSectionInstructionProtocol = ({
  data,
  practicalId,
  loading,
}: InstructionProtocolProps) => {
  const { open, handleOpen, handleClose } = useModal();
  const { t } = useTranslation();

  return (
    <>
      <FormSection
        sectionTitle={t("instruction-protocol")}
        loading={loading}
        headerBackgroundColor={"student.100"}
        sectionAction={<UploadButton handleOpen={handleOpen} />}
        canEdit={!!data?.access.canEdit}
      >
        <Stack spacing={5}>
          <Alert severity="info" color="success">
            Ziel der Praxisanleitung ist die schrittweise Heranführung von
            Auszubildenden an zunehmend komplexer werdende Pflegesituationen.
            Die Auszubildenden werden zunehmend in die Lage versetzt,
            Verantwortung zu übernehmen sowie selbstständig und mit weiteren
            Mitgliedern eines qualifikationsheterogenen Teams
            zusammenzuarbeiten. Darüber hinaus werden die Auszubildenden dazu
            angeleitet, Sicherheit bei den eigenständigen Aufgaben, insbesondere
            den vorbehaltenen Tätigkeiten nach § 4 Pflegeberufegesetz, im Rahmen
            des Pflegeprozesses zu gewinnen. Somit lässt sich auch aus dem
            Nachweis der Praxisanleitung ablesen, wie die Kompetenzentwicklung
            der Auszubildenden erfolgt.
            <br />
            <br />
            Der zeitliche Umfang und die Inhalte der geplanten und
            strukturierten Praxisanleitung nach § 4 Pflegeberufe-Ausbildungs-
            und -Prüfungsverordnung werden im Ausbildungsnachweis Praxis
            dokumentiert. Das Formblatt Praxisanleitung stellt ein Beispiel zur
            Dokumentation der Praxisanleitung dar. Es dürfen aber auch andere
            Formen der Dokumentation genutzt werden, sofern diese die
            vorgegebenen Informationen enthalten.
            <br />
            <br />
            Die Auszubildenden werden dazu angehalten, ihre Praxisanleitung
            selbst zu dokumentieren. Mit der Dokumentation der geplanten und
            strukturierten Praxisanleitung ist nachzuweisen, dass sie einen
            Umfang von mindestens zehn Prozent der während eines Einsatz zu
            leistenden praktischen Ausbildungszeit hat.13 Die Inhalte der
            Anleitungssequenzen werden jeweils mit Handzeichen der
            Praxisanleitenden versehen und der gesamte Nachweis der
            Praxisanleitung am Ende des Einsatzes von den Auszubildenden und den
            verantwortlichen Praxisanleitenden unterschrieben.
            <br />
            <br />
            Inhaltlich richtet sich die Praxisanleitung nach den mit den
            Auszubildenden abgestimmten Zielen der Ausbildung auf der Grundlage
            der vereinbarten Arbeits- und Lernaufgaben des Lernortes Praxis
            sowie der Pflegeschule. Methodisch werden als Praxisanleitung im
            Sinne des § 4 Abs. 1 PflAPrV folgende Settings verstanden, sofern
            der Lernfortschritt jedes Einzelnen reflektiert wird:
            <ul>
              <li>Einzelanleitung</li>
              <li>Gruppenanleitung</li>
              <li>Praxisanleiter gestützte Projekte</li>
              <li>Praktische Zwischenprüfung</li>
              <li>Lernen</li>
              <ul>
                <li>Durch Anleitung am Modell</li>
                <li>Durch Reflexion des Erlebten</li>
                <li>Anhand bearbeiteter Aufgaben</li>
              </ul>
            </ul>
          </Alert>
          <TableContainer>
            <Table>
              <TableHead sx={{ backgroundColor: "#ededed" }}>
                <TableRow>
                  <TableCell component="th" scope="row">
                    {t("date")}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {t("hours")}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {t("training-topic")}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {t("inserted-by")}
                  </TableCell>
                  {/* <TableCell component="th" scope="row">
                    {t("signature")}
                  </TableCell> */}
                  <TableCell
                    component="th"
                    scope="row"
                    align="right"
                  ></TableCell>
                </TableRow>
              </TableHead>
              {data && data.response.length >= 1 ? (
                <TableBody>
                  {data.response.map((protocol) => (
                    <TableRow key={protocol.instructionProtocol.practicalId}>
                      <TableCell>
                        <DisplayDate>
                          {protocol.instructionProtocol.date}
                        </DisplayDate>
                      </TableCell>
                      <TableCell>
                        <DisplayTimespan>
                          {protocol.instructionProtocol.duration}
                        </DisplayTimespan>
                      </TableCell>
                      <TableCell>
                        {protocol.instructionProtocol.description}
                      </TableCell>
                      <TableCell>
                        {protocol.meta.createdBy?.fullName || ""}
                      </TableCell>
                      {/* <TableCell>
                        {protocol.signature && protocol.signature != "" && (
                          <img
                            width={100}
                            height="auto"
                            src={protocol.signature}
                            alt={t("signature")}
                          />
                        )}
                      </TableCell> */}
                      <TableCell
                        align="right"
                        onClick={(e) => e.stopPropagation()}
                      ></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Typography
                        color="#c8cbcf"
                        textAlign="center"
                        justifyItems="center"
                      >
                        {t("no-data")}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <Stack spacing={2}>
            <Typography variant="sectionHeader">{t("signatures")}</Typography>
            <Alert severity="info" color="success">
              {t("signatures-info")}
            </Alert>
            <Box display="flex" flexDirection="row" justifyContent="flex-end">
              {!!data?.response?.length && (
                <Stack spacing={2}>
                  <ApprovedBy
                    role={Role.INSTRUCTOR}
                    approvedAt={data.signatures.signedByInstructorAt}
                    approvedBy={data.signatures.signedByInstructor?.fullName}
                  >
                    <ApproveButtonInstructionProtocols
                      canApprove={true}
                      practicalId={practicalId}
                      role={Role.INSTRUCTOR}
                    />
                  </ApprovedBy>
                  <ApprovedBy
                    role={Role.STUDENT}
                    approvedAt={data.signatures.signedByStudentAt}
                    approvedBy={data.signatures.signedByStudent?.fullName}
                  >
                    <ApproveButtonInstructionProtocols
                      canApprove={!!data.signatures.signedByInstructorAt}
                      practicalId={practicalId}
                      role={Role.STUDENT}
                    />
                  </ApprovedBy>
                </Stack>
              )}
            </Box>
          </Stack>
        </Stack>
      </FormSection>

      <AddInstructionProtocolModal
        title={t("add-instruction-protocol")}
        description={t("add-instruction-protocol-details")}
        open={open}
        practicalId={practicalId}
        handleClose={handleClose}
      />
    </>
  );
};
export default FormSectionInstructionProtocol;
