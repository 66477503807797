import { Alert, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import EditButton from "../../../common/buttons/EditButton";
import TextBlock from "../../../common/display/TextBlock";
import TextLine from "../../../common/display/TextLine";
import useModal from "../../../hooks/useModal";
import { PracticalLocation } from "../../../schema/PracticalLocation";
import FormSection from "../../FormSection";
import EditPracticalLocationModal from "../modals/EditPracticalLocationModal";

interface PracticalLocationProps {
  data: PracticalLocation | null;
  practicalId: string;
}

const FormSectionPracticalLocation = ({
  data,
  practicalId,
}: PracticalLocationProps) => {
  const { t } = useTranslation();
  const { open, handleOpen, handleClose } = useModal();

  return (
    <>
      <FormSection
        sectionTitle={t("practical-proof-institution")}
        meta={data?.response?.meta}
        sectionAction={<EditButton handleOpen={handleOpen} />}
        headerBackgroundColor={"student.100"}
        canEdit={!!data?.access.canEdit}
      >
        <Stack spacing={2}>
          <Alert severity="info" color="success">
            Hier werden von der Praxiseinrichtung Angaben zum Einsatzort
            gemacht.
          </Alert>
          {data?.response?.practicalLocation ? (
            <>
              <TextBlock label={t("provider-of-practical-training")}>
                {data?.response?.practicalLocation?.isProvider === true && (
                  <TextLine value={t("yes")} />
                )}
                {data?.response?.practicalLocation?.isProvider === false && (
                  <TextLine value={t("no")} />
                )}
              </TextBlock>
              {data.response?.practicalLocation.isProvider === false && (
                <>
                  <TextBlock label={t("other-provider")}>
                    <TextLine
                      value={data ? data.response?.practicalLocation.name : ""}
                    />
                  </TextBlock>
                  <TextBlock label={t("address")}>
                    <TextLine
                      value={
                        data?.response?.practicalLocation
                          ? data.response?.practicalLocation.street +
                            " " +
                            data.response?.practicalLocation.housenumber
                          : ""
                      }
                    />
                    <TextLine
                      value={
                        data?.response?.practicalLocation
                          ? data?.response?.practicalLocation.postcode +
                            " " +
                            data?.response?.practicalLocation.city
                          : ""
                      }
                    />
                  </TextBlock>
                </>
              )}
              {data?.response?.practicalLocation.locationType ===
                "LIVING_AREA" && (
                <TextBlock label={t("locationType.LIVING_AREA")}>
                  <TextLine
                    value={
                      data
                        ? data?.response?.practicalLocation
                            .locationTypeDescription
                        : ""
                    }
                  />
                </TextBlock>
              )}
              {data?.response?.practicalLocation.locationType === "WARD" && (
                <TextBlock label={t("locationType.WARD")}>
                  <TextLine
                    value={
                      data
                        ? data?.response?.practicalLocation
                            .locationTypeDescription
                        : ""
                    }
                  />
                </TextBlock>
              )}

              <TextBlock label={t("specialization")}>
                <TextLine
                  value={data?.response?.practicalLocation.specialization}
                />
              </TextBlock>
            </>
          ) : (
            <Typography
              color="#c8cbcf"
              textAlign="center"
              justifyItems="center"
            >
              {t("no-data")}
            </Typography>
          )}
        </Stack>
      </FormSection>
      <EditPracticalLocationModal
        data={data?.response?.practicalLocation}
        id={practicalId}
        open={open}
        handleClose={handleClose}
      />
    </>
  );
};

export default FormSectionPracticalLocation;
