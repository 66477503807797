import { useQuery } from "@apollo/client";
import React from "react";
import { useTranslation } from "react-i18next";
import Page from "../../common/layout/Page";
import Practical from "../../components/student/Practical";
import {
  GetCurrentPracticalVisit,
  GetCurrentPracticalVisitVariables,
} from "../../schema/GetCurrentPracticalVisit";
import { GET_CURRENT_PRACTICAL_VISIT } from "../teacher/PracticalVisit";

interface PracticalVisitProps {
  practicalId: string;
}

const PracticalVisit = ({ practicalId }: PracticalVisitProps) => {
  const { data } = useQuery<
    GetCurrentPracticalVisit,
    GetCurrentPracticalVisitVariables
  >(GET_CURRENT_PRACTICAL_VISIT, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    variables: { practicalId },
  });
  const { t } = useTranslation();

  return (
    <Page
      breadcrumb={[
        {
          name: `${t("my-practical-instructions")}`,
          to: "/praxis/praxisanleitungen",
        },
        {
          name: `${
            `${t("practical-instruction")} ` +
            data?.practical.student.user.fullName
          }`,
          to: "/praxisanleitungen/:einsatzId",
        },
      ]}
      title={
        data
          ? `${
              `${t("practical-instruction")} ` +
              data?.practical.student.user.fullName
            }`
          : `${t("loading")}`
      }
      pageHeading={
        data
          ? `${
              `${t("practical-instruction-of")} ` +
              data.practical.student.user.fullName
            }`
          : `${t("loading")}`
      }
      subHeading={
        data
          ? `${t("practicalType." + data.practical.practicalType)}`
          : `${t("loading")}`
      }
    >
      {data && (
        <Practical
          practicalId={practicalId}
          studentId={data.practical.student.id}
        />
      )}
    </Page>
  );
};
export default PracticalVisit;
