import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Page from "../common/layout/Page";

function NotFound() {
  const { t } = useTranslation();
  return (
    <Page title="404" pageHeading={t("something-went-wrong")}>
      <Grid item xs={8} xl={5} alignItems="flex-start">
        <Typography variant="body1">{t("page-not-found")}</Typography>
      </Grid>
    </Page>
  );
}

export default NotFound;
