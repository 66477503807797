import { gql, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import Page from "../../common/layout/Page";
import PracticalList from "../../components/student/PracticalList";
import { STUDENT_DETAILS } from "../../fragments/student";
import { GetCurrentPracticals } from "../../schema/GetCurrentPracticals";

export const GET_STUDENT = gql`
  ${STUDENT_DETAILS}

  query GetCurrentPracticals {
    currentStudent {
      ...StudentDetails
    }
  }
`;

function Practicals() {
  const { t } = useTranslation();

  const { loading, data } = useQuery<GetCurrentPracticals>(GET_STUDENT, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
  });

  return (
    <Page
      title={t("practicals")}
      pageHeading={t("practicals")}
      subHeading={t("here-you-find-an-overview-of-all-practicals")}
    >
      <PracticalList data={data?.currentStudent} loading={loading} />
    </Page>
  );
}

export default Practicals;
