import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import Page from "../common/layout/Page";
import FormSectionUserProfile from "../components/FormSectionUserProfile";

function ProfilePage() {
  const { t } = useTranslation();
  return (
    <Page title={t("profile")} pageHeading={t("your-profile-page")}>
      <Grid container>
        <Grid item xs={12} md={8}>
          <FormSectionUserProfile />
        </Grid>
      </Grid>
    </Page>
  );
}

export default ProfilePage;
