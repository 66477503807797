import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import React from "react";
import { useTranslation } from "react-i18next";
import Page from "../../common/layout/Page";
import StudentProfile from "../../components/student/StudentProfile";
import { STUDENT_DETAILS } from "../../fragments/student";
import { GetCurrentStudent } from "../../schema/GetCurrentStudent";

export default function CoverPage() {
  const { t } = useTranslation();

  const GET_STUDENT = gql`
    ${STUDENT_DETAILS}

    query GetCurrentStudent {
      currentStudent {
        ...StudentDetails
      }
    }
  `;

  const { loading, data } = useQuery<GetCurrentStudent>(GET_STUDENT, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
  });

  return (
    <Page
      title={t("training-profile")}
      pageHeading={t("training-profile")}
      subHeading={t("training-profile-info-text")}
    >
      {data?.currentStudent && (
        <StudentProfile
          studentId={data.currentStudent.id}
          data={data?.currentStudent}
          loading={loading}
        />
      )}
    </Page>
  );
}
