import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { UseControllerProps } from "react-hook-form";
import InputDropdown from "../../common/input/InputDropdown";
import { GetStudentsList } from "../../schema/GetStudentsList";

type InputDropdownProps<T> = {
  label?: string;
  required?: boolean;
  autoFocus?: boolean;
  options?: Options[];
  exceptClassId?: string;
} & UseControllerProps<T>;

interface Options {
  label: string;
  id: string;
}

function StudentDropdown<T>({
  exceptClassId,
  options,
  ...props
}: InputDropdownProps<T>) {
  const GET_STUDENTS_LIST = gql`
    query GetStudentsList {
      students {
        id
        class {
          classId
          number
        }
        user {
          id
          firstName
          lastName
          email
          isApproved
        }
      }
    }
  `;

  const { data: studentsList } = useQuery<GetStudentsList>(GET_STUDENTS_LIST, {
    fetchPolicy: "no-cache",
  });
  const dropdownStudents = studentsList?.students
    .filter((student) =>
      exceptClassId ? student.class?.classId !== exceptClassId : true
    )
    .map((student) => {
      const userId = student.id;
      const label = student.user.firstName + " " + student.user.lastName;
      return {
        id: userId,
        label,
      };
    });

  return <InputDropdown options={dropdownStudents} {...props} />;
}

export default StudentDropdown;
