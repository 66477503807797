import { useTranslation } from "react-i18next";
import Page from "../../common/layout/Page";
import StudentDetails from "../../components/student/StudentDetails";
import useUsername from "../../hooks/useUsername";

interface StudentProps {
  studentId: string;
}

const Student = ({ studentId }: StudentProps) => {
  const { t } = useTranslation();
  const username = useUsername(studentId);

  return (
    <Page
      breadcrumb={[
        { name: `${t("student")}`, to: ".." },
        { name: username, to: "" },
      ]}
      title={t("student")}
      pageHeading={username}
    >
      <StudentDetails studentId={studentId} />
    </Page>
  );
};

export default Student;
