import gql from "graphql-tag";
import { useTranslation } from "react-i18next";
import FormModal from "../../../common/form/FormModal";
import InputText from "../../../common/input/InputText";
import { UpdateInstructorInput } from "../../../schema/globalTypes";
import {
  UpdateInstructor,
  UpdateInstructorVariables,
} from "../../../schema/UpdateInstructor";

interface EditInstructorModalProps {
  data?: UpdateInstructorInput | null;
  open: boolean;
  userId: string;
  handleClose: () => void;
}

const UPDATE_INSTRUCTOR = gql`
  mutation UpdateInstructor($instructorData: UpdateInstructorInput!) {
    updateInstructor(command: $instructorData) {
      userId
      user {
        id
        email
        firstName
        lastName
      }
    }
  }
`;

export default function EditInstructorModal({
  data,
  userId,
  open,
  handleClose,
}: EditInstructorModalProps) {
  const { t } = useTranslation();

  return (
    <FormModal<
      UpdateInstructorInput,
      UpdateInstructor,
      UpdateInstructorVariables
    >
      title={t("edit-instructor-data")}
      description={t("change-instructor-details")}
      maxWidth="md"
      defaultValues={data || undefined}
      makeVars={(instructorData) => ({
        instructorData: { ...instructorData, userId },
      })}
      mutation={UPDATE_INSTRUCTOR}
      open={open}
      handleClose={handleClose}
    >
      {(control) => (
        <>
          <InputText
            control={control}
            name="firstName"
            label={t("name")}
            required
          />
          <InputText
            control={control}
            name="lastName"
            label={t("lastname")}
            required
          />
          <InputText
            control={control}
            name="email"
            label={t("email")}
            required
          />
        </>
      )}
    </FormModal>
  );
}
