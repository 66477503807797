import { gql, useMutation } from "@apollo/client";
import { STUDENT_PRACTICAL } from "../../fragments/student";
import { Role } from "../../schema/globalTypes";
import {
  RejectInterviewProtocol,
  RejectInterviewProtocolVariables,
} from "../../schema/RejectInterviewProtocol";
import ApproveButton from "./ApproveButton";

interface RejectButtonInterviewProtocolProps {
  canApprove: boolean;
  role: Role;
  protocolId: string;
}

const REJECT_INTERVIEW_PROTOCOL = gql`
  ${STUDENT_PRACTICAL}
  mutation RejectInterviewProtocol($interviewProtocolId: ID!) {
    rejectInterviewProtocol(interviewProtocolId: $interviewProtocolId) {
      ...Practical
    }
  }
`;

const RejectButtonInterviewProtocol = ({
  canApprove,
  role,
  protocolId,
}: RejectButtonInterviewProtocolProps) => {
  const [mutate] = useMutation<
    RejectInterviewProtocol,
    RejectInterviewProtocolVariables
  >(REJECT_INTERVIEW_PROTOCOL);

  const handleApprove = async () => {
    return mutate({ variables: { interviewProtocolId: protocolId } }).then(
      () => {
        return;
      }
    );
  };

  return (
    <ApproveButton
      canApprove={canApprove}
      handleApprove={handleApprove}
      role={role}
      cancel
    />
  );
};

export default RejectButtonInterviewProtocol;
