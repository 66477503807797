import { useQuery } from "@apollo/client";
import { Box, Tab, Tabs } from "@mui/material";
import gql from "graphql-tag";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  Link as RouterLink,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from "react-router-dom";
import StudentProfile from "../../components/student/StudentProfile";
import { STUDENT_DETAILS } from "../../fragments/student";
import { GetStudent, GetStudentVariables } from "../../schema/GetStudent";
import NightShiftsList from "./NightShiftsList";
import PracticalList from "./PracticalList";

interface StudentDetailsProps {
  studentId: string;
}

const StudentDetails = ({ studentId }: StudentDetailsProps) => {
  const GET_STUDENT = gql`
    ${STUDENT_DETAILS}

    query GetStudent($userId: ID!) {
      student(userId: $userId) {
        ...StudentDetails
      }
    }
  `;

  const { loading, data, error } = useQuery<GetStudent, GetStudentVariables>(
    GET_STUDENT,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      variables: { userId: studentId },
      errorPolicy: "all",
    }
  );
  const { url, path } = useRouteMatch();
  const match = useRouteMatch(`${path}/:tabname`);
  const { t } = useTranslation();
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider", marginBottom: 2 }}>
          <Tabs
            value={match?.url || `${url}/ausbildungsprofil`}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="student tabs"
          >
            <Tab
              label={t("training-profile")}
              value={`${url}/ausbildungsprofil`}
              component={RouterLink}
              to={`${url}/ausbildungsprofil`}
            />

            <Tab
              label={t("practicals")}
              value={`${url}/praxiseinsaetze`}
              component={RouterLink}
              to={`${url}/praxiseinsaetze`}
            />
            <Tab
              label={t("night-shifts")}
              value={`${url}/nachtdienste`}
              component={RouterLink}
              to={`${url}/nachtdienste`}
            />
          </Tabs>
        </Box>
      </Box>

      <Switch>
        <Route
          path={path}
          exact
          render={(props) => <Redirect to={`${url}/ausbildungsprofil`} />}
        />
        <Route path={`${path}/ausbildungsprofil`} exact>
          <StudentProfile
            studentId={studentId}
            data={data?.student}
            loading={loading}
            error={error}
          />
        </Route>
        <Route path={`${path}/praxiseinsaetze`} exact>
          <PracticalList data={data?.student} loading={loading} />
        </Route>
        <Route path={`${path}/nachtdienste`} exact>
          <NightShiftsList
            data={data?.student.nightShiftProtocols}
            loading={loading}
            primary
          />
        </Route>
      </Switch>
    </>
  );
};

export default StudentDetails;
