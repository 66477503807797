import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import gql from "graphql-tag";
import React, { useEffect } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import AddButton from "../../../common/buttons/AddButton";
import FormField from "../../../common/form/FormField";
import FormModal from "../../../common/form/FormModal";
import InputCheckbox from "../../../common/input/InputCheckbox";
import InputDate from "../../../common/input/InputDate";
import InputRichText from "../../../common/input/InputRichText";
import InputText from "../../../common/input/InputText";
import { STUDENT_PRACTICAL } from "../../../fragments/student";
import {
  InterviewProtocolType,
  UpdateInterviewProtocolInput,
} from "../../../schema/globalTypes";
import {
  UpdateInterviewProtocol,
  UpdateInterviewProtocolVariables,
} from "../../../schema/UpdateInterviewProtocol";

interface EditInterviewProtocolModalProps {
  data?: UpdateInterviewProtocolInput | null;
  practicalId: string;
  protocolType: InterviewProtocolType;
  open: boolean;
  handleClose: () => void;
}

export default function EditInterviewProtocolModal({
  data,
  practicalId,
  protocolType,
  open,
  handleClose,
}: EditInterviewProtocolModalProps) {
  const UPDATE_INTERVIEW_PROTOCOL = gql`
    ${STUDENT_PRACTICAL}
    mutation UpdateInterviewProtocol(
      $interviewProtocol: UpdateInterviewProtocolInput!
      $practicalId: ID!
      $protocolType: InterviewProtocolType!
    ) {
      updateInterviewProtocol(
        command: $interviewProtocol
        practicalId: $practicalId
        protocolType: $protocolType
      ) {
        ...Practical
      }
    }
  `;

  const form = useForm<UpdateInterviewProtocolInput>();
  const { control, reset } = form;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "attendedByOther",
  });

  const { t } = useTranslation();

  useEffect(() => {
    reset(
      data || {
        attendedByInstructor: false,
        attendedByStudent: false,
        attendedByOther: [],
        date: new Date().toISOString(),
        descriptionExercises: "",
        descriptionGoals: "",
        descriptionResults: "",
        descriptionSituation: "",
      }
    );
  }, [data, practicalId, protocolType, reset, open]);

  return (
    <FormProvider {...form}>
      <FormModal<
        UpdateInterviewProtocolInput,
        UpdateInterviewProtocol,
        UpdateInterviewProtocolVariables
      >
        title={t("edit-protocol")}
        description=""
        maxWidth="lg"
        defaultValues={data || undefined}
        makeVars={(interviewProtocol) => {
          return {
            interviewProtocol,
            practicalId,
            protocolType,
          };
        }}
        mutation={UPDATE_INTERVIEW_PROTOCOL}
        open={open}
        handleClose={handleClose}
        useContext
      >
        {(control) => (
          <>
            <Grid container spacing={2} justifyContent="space-around">
              <Grid item xs={12}>
                <Stack direction="row" sx={{ alignItems: "center" }}>
                  <Typography
                    color="#96C11E"
                    marginRight={4}
                    variant="subtitle1"
                  >
                    {t("interview-held-on")}
                  </Typography>

                  <Box width="14em">
                    <InputDate
                      name="date"
                      control={control}
                      label={t("date")}
                      required
                      autoFocus
                    />
                  </Box>
                </Stack>
              </Grid>
              <Grid item xs={12} alignSelf="center" marginBottom={2}>
                <Grid item xs={12} md={3}>
                  <Typography
                    color="#95C11F"
                    marginRight={2}
                    variant="subtitle1"
                  >
                    {t("attendees")}
                  </Typography>
                </Grid>
                <Stack direction="column">
                  <Grid item xs={12}>
                    <InputCheckbox
                      name="attendedByStudent"
                      label={t("student")}
                      control={control}
                      defaultValue={data?.attendedByStudent}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      width="100%"
                      justifyContent="space-between"
                    >
                      <InputCheckbox
                        name="attendedByInstructor"
                        label={t("mentor")}
                        control={control}
                        defaultValue={data?.attendedByInstructor}
                        required
                      />
                      <AddButton
                        onClick={() =>
                          append({
                            name: "",
                            email: "",
                            role: "",
                          })
                        }
                        buttonLabel={t("add-person")}
                        variant="outlined"
                        color="success"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}></Grid>
                </Stack>
              </Grid>
            </Grid>
            {fields.length > 0 && (
              <Grid item xs={12}>
                <Typography variant="subtitle1" color="#95C11F">
                  {t("additional-persons-label")}
                </Typography>
                {fields.map((field, index) => {
                  return (
                    <Grid
                      container
                      key={field.id}
                      direction="row"
                      rowSpacing={{ md: 1 }}
                      columnSpacing={{ xs: 1, md: 2 }}
                    >
                      <Grid item xs={12} sm={3}>
                        <InputText
                          name={`attendedByOther.${index}.name` as const}
                          key={field.id}
                          control={control}
                          label={t("fullname")}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <InputText
                          key={field.id}
                          name={`attendedByOther.${index}.email` as const}
                          control={control}
                          label={t("email")}
                        />
                      </Grid>
                      <Grid item xs={10} sm={3}>
                        <InputText
                          key={field.id}
                          name={`attendedByOther.${index}.role` as const}
                          control={control}
                          label={t("role")}
                        />
                      </Grid>

                      <Grid item xs={2} sm={3} alignSelf="center">
                        <IconButton
                          size="large"
                          sx={{
                            "&:hover": {
                              "& .removeIcon": { color: "error.main" },
                            },
                          }}
                          onClick={() => remove(index)}
                        >
                          <DeleteIcon className="removeIcon" />
                        </IconButton>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            )}

            <Grid item xs={12} mt={4}>
              <Divider sx={{ display: { md: "none" } }} />
              <Typography color="#95C11F" variant="subtitle1">
                {t("reflection-on-education")}
              </Typography>
              <FormField
                info={t(
                  `interviewProtocol.${protocolType}.descriptionSituation`
                )}
              >
                <InputRichText
                  name="descriptionSituation"
                  control={control}
                  label={t("reflection-on-education")}
                  multiline
                />
              </FormField>
            </Grid>

            <Grid item xs={12} mt={4}>
              <Divider sx={{ display: { md: "none" } }} />
              <Typography color="#95C11F" variant="subtitle1">
                {t("practical-goal")}
              </Typography>
              <FormField
                info={t(`interviewProtocol.${protocolType}.descriptionGoals`)}
              >
                <InputRichText
                  name="descriptionGoals"
                  control={control}
                  label={t("practical-goal")}
                  multiline
                />
              </FormField>
            </Grid>

            <Grid item xs={12} mt={4}>
              <Divider sx={{ display: { md: "none" } }} />
              <Typography color="#95C11F" variant="subtitle1">
                {t("practical-tasks-nursing-school")}
              </Typography>
              <FormField
                info={t(
                  `interviewProtocol.${protocolType}.descriptionExercises`
                )}
              >
                <InputRichText
                  name="descriptionExercises"
                  control={control}
                  label={t("practical-tasks-nursing-school")}
                  multiline
                />
              </FormField>
            </Grid>

            <Grid item xs={12} mt={4}>
              <Divider sx={{ display: { md: "none" } }} />
              <Typography color="#95C11F" variant="subtitle1">
                {t("result-and-agreement")}
              </Typography>
              <FormField
                info={t(`interviewProtocol.${protocolType}.descriptionResults`)}
              >
                <InputRichText
                  name="descriptionResults"
                  control={control}
                  label={t("result-and-agreement")}
                  multiline
                />
              </FormField>
            </Grid>
          </>
        )}
      </FormModal>
    </FormProvider>
  );
}
