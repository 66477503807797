import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import AddButton from "../../common/buttons/AddButton";
import MoreInfoButton from "../../common/buttons/MoreInfoButton";
import DisplayDate from "../../common/display/DisplayDate";
import EmptyStateSubtle from "../../common/layout/EmptyStateSubtle";
import PaginatedSeachBar from "../../common/PaginatedSearchBar";
import DeleteClassButton from "../../components/class/DeleteClassButton";
import AddClassModal from "../../components/class/modals/AddClassModal";
import useFilter from "../../hooks/useFilter";
import useModal from "../../hooks/useModal";
import usePagination from "../../hooks/usePagination";
import { ClassDetails } from "../../schema/ClassDetails";

interface ClassListProps {
  classes?: ClassDetails[];
  canOpen?: boolean;
  canModify?: boolean;
  loading: boolean;
}

const searchFields = ["number"];

const ClassList = ({ classes, loading, canModify }: ClassListProps) => {
  const { t } = useTranslation();
  const { open, handleOpen, handleClose } = useModal();
  const history = useHistory();
  const { url } = useRouteMatch();

  const handleClick = (classId: string) => {
    history.push(`${url}/${classId}`);
  };

  const filter = useFilter(classes, searchFields);
  const pagination = usePagination(filter.data);

  return (
    <>
      {canModify && (
        <Box
          sx={{
            visibility: { xs: "visible", sm: "hidden" },
            marginBottom: { xs: "1rem", sm: "-1rem" },
          }}
        >
          <AddButton
            variant="contained"
            color="success"
            buttonLabel={t("add")}
            onClick={handleOpen}
          />
        </Box>
      )}
      <PaginatedSeachBar
        loading={loading}
        setSearchValue={filter.setSearchValue}
        paginationProps={pagination.paginationProps}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead
            sx={
              canModify
                ? { backgroundColor: "#EDEDED" }
                : { backgroundColor: "#e5f1f9" }
            }
          >
            <TableRow>
              <TableCell component="th" scope="col">
                {t("class-number")}
              </TableCell>
              <TableCell component="th" scope="col">
                {t("start-date")}
              </TableCell>
              <TableCell component="th" scope="col">
                {t("end-date")}
              </TableCell>
              <TableCell
                component="th"
                scope="col"
                align="right"
                sx={{
                  visibility: { xs: "hidden", sm: "visible" },
                  marginBottom: { xs: "1rem", sm: "-1rem" },
                }}
              >
                {canModify && (
                  <AddButton
                    variant="contained"
                    color="warning"
                    onClick={handleOpen}
                    buttonLabel={t("add")}
                  />
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          {pagination && pagination.currentData.length >= 1 ? (
            <TableBody>
              {pagination.currentData.map((classData) => (
                <TableRow
                  hover
                  onClick={() => handleClick(classData.classId)}
                  sx={{ cursor: "pointer" }}
                  key={classData.classId}
                >
                  <TableCell component="td">{classData.number}</TableCell>
                  <TableCell component="td">
                    <DisplayDate>{classData.startDate}</DisplayDate>
                  </TableCell>
                  <TableCell component="td">
                    <DisplayDate>{classData.endDate}</DisplayDate>
                  </TableCell>
                  <TableCell
                    component="td"
                    align="right"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <MoreInfoButton
                      onClick={() => handleClick(classData.classId)}
                    />
                    {canModify && (
                      <DeleteClassButton classId={classData.classId} />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : canModify ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={4}>
                  <EmptyStateSubtle
                    title={t("no-data")}
                    description={t("add-a-class")}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={4}>
                  <EmptyStateSubtle
                    title={t("you-are-not-added-to-a-class")}
                    description={t(
                      "if-you-want-to-be-added-to-a-class-please-contact-administration"
                    )}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>

      <AddClassModal open={open} handleClose={handleClose} />
    </>
  );
};

export default ClassList;
