import EditButton from "../../../common/buttons/EditButton";
import useModal from "../../../hooks/useModal";
import { UpdateTeacherInput } from "../../../schema/globalTypes";
import EditTeacherModal from "../modals/EditTeacherModal";

interface EditTeacherButtonProps {
  userId: string;
  data?: UpdateTeacherInput | null;
}

const EditTeacherButton = ({ userId, data }: EditTeacherButtonProps) => {
  const { open, handleOpen, handleClose } = useModal();

  return (
    <>
      <EditButton handleOpen={handleOpen} />

      <EditTeacherModal
        data={data}
        userId={userId}
        handleClose={handleClose}
        open={open}
      />
    </>
  );
};

export default EditTeacherButton;
