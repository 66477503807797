import CreateIcon from "@mui/icons-material/Create";
import { IconButton } from "@mui/material";

interface EditButtonProps {
  handleOpen: () => void;
  color?: string;
}

const EditButton = ({ handleOpen, color }: EditButtonProps) => (
  <IconButton
    aria-label="bearbeiten"
    onClick={handleOpen}
    sx={{
      "&:hover": { "& .createIcon": { color: color || "primary.main" } },
      color: color,
    }}
  >
    <CreateIcon className="createIcon" />
  </IconButton>
);

export default EditButton;
