import { Alert, Button } from "@mui/material";
import gql from "graphql-tag";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FormModal from "../../../common/form/FormModal";
import InputPassword from "../../../common/input/InputPassword";
import InputText from "../../../common/input/InputText";
import { generatePassword } from "../../../helpers/helpers";
import { GET_ORGANIZATIONS } from "../../../pages/school/Organizations";
import {
  CreateOrganization,
  CreateOrganizationVariables,
} from "../../../schema/CreateOrganization";
import { GetOrganizations } from "../../../schema/GetOrganizations";
import { CreateOrganizationInput } from "../../../schema/globalTypes";

interface AddOrganizationModalProps {
  data?: CreateOrganizationInput | null;
  open: boolean;
  handleClose: () => void;
}

const CREATE_ORGANIZATION = gql`
  mutation CreateOrganization($organizationData: CreateOrganizationInput!) {
    createOrganization(command: $organizationData) {
      organizationName
      city
      street
      streetNumber
      postCode
      userId
      user {
        id
        email
        firstName
        lastName
        isApproved
      }
    }
  }
`;

export default function AddOrganizationModal({
  open,
  handleClose,
}: AddOrganizationModalProps) {
  const [isModalInReadOnly, setIsModalInReadOnly] = useState(false);
  const form = useForm<CreateOrganizationInput>();
  const { getValues } = form;
  const { t } = useTranslation();

  useEffect(() => {
    if (open) {
      form.reset({
        password: generatePassword(),
      });
      setIsModalInReadOnly(false);
    }
  }, [open, form]);

  const setReadOnly = (success: boolean) => {
    if (success) {
      setIsModalInReadOnly(true);
    } else {
      handleClose();
    }
  };

  return (
    <FormProvider {...form}>
      <FormModal<
        CreateOrganizationInput,
        CreateOrganization,
        CreateOrganizationVariables
      >
        title={
          isModalInReadOnly
            ? `${t("new-organization-added")}`
            : `${t("add-organization")}`
        }
        description={
          isModalInReadOnly
            ? `${t("new-organization-created-successfully")}`
            : `${t("add-organization")}`
        }
        maxWidth="md"
        makeVars={(organizationData) => ({
          organizationData: { ...organizationData },
        })}
        mutation={CREATE_ORGANIZATION}
        mutationOptions={{
          update(cache, { data }) {
            const getOrganizationsData = cache.readQuery<GetOrganizations>({
              query: GET_ORGANIZATIONS,
            });

            let organizations = getOrganizationsData?.organizations || [];
            if (data?.createOrganization) {
              organizations = [data.createOrganization, ...organizations];
            }

            cache.writeQuery<GetOrganizations>({
              query: GET_ORGANIZATIONS,
              data: { organizations },
            });
          },
        }}
        actions={
          isModalInReadOnly ? (
            <>
              <Button onClick={handleClose}>
                {isModalInReadOnly ? `${t("close")}` : `${t("cancel")}`}
              </Button>
              <Button variant="contained">
                <a
                  style={{ color: "white", textDecoration: "none" }}
                  href={
                    `mailto:${getValues().email}` +
                    "?" +
                    "subject=" +
                    `${t("invite-mail-subject")}` +
                    "&" +
                    "body=" +
                    `${t("invite-mail-greeting")} ${
                      getValues().organizationName
                    } ${getValues().city},%0D%0A%0D%0A` +
                    `${t("we-would-like-to-invite-you")} ${
                      getValues().email
                    } ${t("and-password")} ${getValues().password} ${t(
                      "change-password-on-first-login"
                    )}%0D%0A%0D%0A` +
                    `${t("greetings")}%0D%0A${t("your-nursing-school")}`
                  }
                >
                  {t("send-mail")}
                </a>
              </Button>
            </>
          ) : undefined
        }
        open={open}
        handleClose={setReadOnly}
        useContext
      >
        {(control) => (
          <>
            <InputText
              control={control}
              name="organizationName"
              label={t("organization")}
              required
              autoFocus
              readOnly={isModalInReadOnly}
            />
            <InputText
              control={control}
              name="street"
              label={t("street")}
              readOnly={isModalInReadOnly}
            />
            <InputText
              control={control}
              name="streetNumber"
              label={t("house-number")}
              readOnly={isModalInReadOnly}
            />
            <InputText
              control={control}
              name="postCode"
              label={t("postcode")}
              readOnly={isModalInReadOnly}
            />
            <InputText
              control={control}
              name="city"
              label={t("city")}
              required
              readOnly={isModalInReadOnly}
            />
            <InputText
              control={control}
              name="email"
              label={t("email")}
              required
              readOnly={isModalInReadOnly}
            />
            <InputPassword
              control={control}
              name="password"
              label={t("password")}
              required
              readOnly={isModalInReadOnly}
            />
            {isModalInReadOnly && (
              <Alert severity="success" sx={{ mt: 1 }}>
                {t("send-password-by-mail-organization")}
              </Alert>
            )}
          </>
        )}
      </FormModal>
    </FormProvider>
  );
}
