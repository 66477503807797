import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import AddButton from "../../common/buttons/AddButton";
import MoreInfoButton from "../../common/buttons/MoreInfoButton";
import DisplayDate from "../../common/display/DisplayDate";
import EmptyStateSubtle from "../../common/layout/EmptyStateSubtle";
import PaginatedSeachBar from "../../common/PaginatedSearchBar";
import DeleteStudentButton from "../../components/student/DeleteStudentButton";
import AddStudentModal from "../../components/student/modals/AddStudentModal";
import useFilter from "../../hooks/useFilter";
import useModal from "../../hooks/useModal";
import usePagination from "../../hooks/usePagination";
import { GetStudents_students } from "../../schema/GetStudents";
import CheckOrActivate from "../user/CheckOrActivate";
import EditStudentButton from "./buttons/EditStudentButton";

interface StudentListProps {
  students?: GetStudents_students[];
  canOpen?: boolean;
  canModify?: boolean;
  loading: boolean;
}

const searchFields = ["user.firstName", "user.lastName", "user.email"];

const StudentList = ({
  students,
  loading,
  canOpen = false,
  canModify = false,
}: StudentListProps) => {
  const filter = useFilter(students, searchFields);
  const pagination = usePagination(filter.data);

  const { open, handleOpen, handleClose } = useModal();
  const { t } = useTranslation();
  const history = useHistory();
  const { url } = useRouteMatch();

  const handleClick = (studentId: string) => {
    if (canOpen) {
      history.push(`${url}/${studentId}`);
    }
  };

  return (
    <>
      {canModify && (
        <Box
          sx={{
            visibility: { xs: "visible", sm: "hidden" },
            marginBottom: { xs: "1rem", sm: "-1rem" },
          }}
        >
          <AddButton
            variant="contained"
            color="success"
            buttonLabel={t("add")}
            onClick={handleOpen}
          />
        </Box>
      )}
      <PaginatedSeachBar
        loading={loading}
        setSearchValue={filter.setSearchValue}
        paginationProps={pagination.paginationProps}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead
            sx={
              canModify
                ? { backgroundColor: "#EDEDED" }
                : { backgroundColor: "#e5f1f9" }
            }
          >
            <TableRow>
              <TableCell component="th" scope="col">
                {t("lastname")}
              </TableCell>
              <TableCell component="th" scope="col">
                {t("name")}
              </TableCell>
              <TableCell component="th" scope="col">
                {t("birthdate")}
              </TableCell>
              <TableCell component="th" scope="col">
                {t("email")}
              </TableCell>
              <TableCell component="th" scope="col">
                {t("class")}
              </TableCell>
              <TableCell
                component="th"
                scope="col"
                style={{ textAlign: "center" }}
              >
                {t("is-approved")}
              </TableCell>
              <TableCell
                component="th"
                scope="col"
                align="right"
                sx={{
                  visibility: { xs: "hidden", sm: "visible" },
                  marginBottom: { xs: "1rem", sm: "-1rem" },
                }}
              >
                {canModify && (
                  <AddButton
                    variant="contained"
                    color="warning"
                    onClick={handleOpen}
                    buttonLabel={t("add")}
                  />
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pagination.currentData && pagination.currentData.length >= 1 ? (
              <>
                {pagination.currentData.map((student) => (
                  <TableRow
                    hover={canOpen}
                    onClick={() => handleClick(student.id)}
                    sx={canOpen ? { cursor: "pointer" } : {}}
                    key={student.id}
                  >
                    <TableCell component="th" scope="row">
                      {student.user.lastName}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {student.user.firstName}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <DisplayDate>{student.birthdate}</DisplayDate>
                    </TableCell>
                    <TableCell>{student.user.email}</TableCell>
                    <TableCell>
                      {student ? student.class?.number : ""}
                    </TableCell>
                    <TableCell component="td" style={{ textAlign: "center" }}>
                      <CheckOrActivate
                        isApproved={student.user.isApproved}
                        userId={student.user.id}
                      />
                    </TableCell>
                    <TableCell
                      align="right"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {canModify && (
                        <EditStudentButton
                          data={{
                            userId: student.user.id,
                            firstName: student.user.firstName,
                            lastName: student.user.lastName,
                            email: student.user.email,
                          }}
                          userId={student.id}
                        />
                      )}
                      {canOpen && (
                        <MoreInfoButton
                          onClick={() => handleClick(student.id)}
                        />
                      )}
                      {canModify && <DeleteStudentButton userId={student.id} />}
                    </TableCell>
                  </TableRow>
                ))}
              </>
            ) : canModify ? (
              <TableRow>
                <TableCell colSpan={7}>
                  <EmptyStateSubtle
                    title={t("no-data")}
                    description={t("add-students")}
                  />
                </TableCell>
              </TableRow>
            ) : (
              <TableRow>
                <TableCell colSpan={7}>
                  <EmptyStateSubtle
                    title={t("no-data")}
                    description={t(
                      "you-can-see-students-by-becoming-class-admin-or-becoming-a-visitor"
                    )}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <AddStudentModal open={open} handleClose={handleClose} />
    </>
  );
};

export default StudentList;
