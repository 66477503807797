import gql from "graphql-tag";
import React from "react";
import { useTranslation } from "react-i18next";
import FormModal from "../../../common/form/FormModal";
import InputDate from "../../../common/input/InputDate";
import InputText from "../../../common/input/InputText";
import { GET_CLASSES } from "../../../pages/school/Classes";
import {
  CreateNewClass,
  CreateNewClassVariables,
} from "../../../schema/CreateNewClass";
import { GetClasses } from "../../../schema/GetClasses";
import { CreateClassInput } from "../../../schema/globalTypes";

interface AddClassModalProps {
  open: boolean;
  handleClose: () => void;
}

const CREATE_CLASS = gql`
  mutation CreateNewClass($classData: CreateClassInput!) {
    createClass(command: $classData) {
      classId
      number
      startDate
      endDate
      students {
        id
        user {
          id
          email
          firstName
          lastName
        }
      }
    }
  }
`;

const formToBackend: (formData: CreateClassInput) => CreateNewClassVariables = (
  formData
) => {
  return {
    classData: {
      classNumber: formData.classNumber,
      startDate: formData.startDate,
      endDate: formData.endDate,
    },
  };
};

export default function AddClassModal({
  open,
  handleClose,
}: AddClassModalProps) {
  const { t } = useTranslation();
  return (
    <FormModal<CreateClassInput, CreateNewClass, CreateNewClassVariables>
      title={t("add-class")}
      description=""
      defaultValues={undefined}
      makeVars={formToBackend}
      mutation={CREATE_CLASS}
      mutationOptions={{
        update(cache, { data }) {
          const getClassesData = cache.readQuery<GetClasses>({
            query: GET_CLASSES,
          });

          let classes = getClassesData?.classes || [];
          if (data?.createClass) {
            classes = [data.createClass, ...classes];
          }

          cache.writeQuery<GetClasses>({
            query: GET_CLASSES,
            data: { classes },
          });
        },
      }}
      open={open}
      handleClose={handleClose}
    >
      {(control) => (
        <>
          <InputText
            control={control}
            name="classNumber"
            label={t("class-number")}
            multiline
            required
            autoFocus
          />

          <InputDate
            control={control}
            name="startDate"
            label={t("start-date")}
          />

          <InputDate control={control} name="endDate" label={t("end-date")} />
        </>
      )}
    </FormModal>
  );
}
