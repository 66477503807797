import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import EditButton from "../../../common/buttons/EditButton";
import TextBlock from "../../../common/display/TextBlock";
import TextLine from "../../../common/display/TextLine";
import useModal from "../../../hooks/useModal";
import { GetSchool } from "../../../schema/GetSchool";
import FormSection from "../../FormSection";
import EditSchoolModal from "../modals/EditSchoolModal";

interface SchoolDataProps {
  data?: GetSchool;
  canModify?: boolean;
}

const FormSectionSchoolData = ({ data }: SchoolDataProps) => {
  const { open, handleOpen, handleClose } = useModal();
  const { t } = useTranslation();

  return (
    <>
      <FormSection
        sectionTitle={t("school")}
        sectionAction={<EditButton handleOpen={handleOpen} />}
        headerBackgroundColor="#ededed"
      >
        {data ? (
          <>
            <TextBlock label={t("name")}>
              <TextLine value={data.school.name} />
            </TextBlock>
            <TextBlock label={t("street")}>
              <TextLine value={data.school.street} />
            </TextBlock>
            <TextBlock label={t("postcode")}>
              <TextLine value={data.school.postcode} />
            </TextBlock>
            <TextBlock label={t("city")}>
              <TextLine value={data.school.city} />
            </TextBlock>
          </>
        ) : (
          <Typography color="#c8cbcf" textAlign="center" justifyItems="center">
            {t("no-data")}
          </Typography>
        )}
      </FormSection>
      <EditSchoolModal
        data={{
          id: "1",
          name: data?.school.name || "",
          street: data?.school.street || "",
          postcode: data?.school.postcode || "",
          city: data?.school.city || "",
        }}
        open={open}
        handleClose={handleClose}
        schoolId={data?.school.id || ""}
      />
    </>
  );
};
export default FormSectionSchoolData;
