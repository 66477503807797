import gql from "graphql-tag";
import React from "react";
import { useTranslation } from "react-i18next";
import FormModal from "../../../common/form/FormModal";
import InputDate from "../../../common/input/InputDate";
import { EducationDurationInput } from "../../../schema/globalTypes";
import {
  UpdateEducationDuration,
  UpdateEducationDurationVariables,
} from "../../../schema/UpdateEducationDuration";

interface EditEducationDurationModalProps {
  data?: EducationDurationInput | null;
  studentId: string;
  open: boolean;
  handleClose: () => void;
}

export default function EditEducationDurationModal({
  data,
  studentId,
  open,
  handleClose,
}: EditEducationDurationModalProps) {
  const { t } = useTranslation();

  const UPDATE_EDUCATIONDURATION = gql`
    mutation UpdateEducationDuration(
      $educationDuration: EducationDurationInput!
      $studentId: ID!
    ) {
      updateEducationDuration(
        command: $educationDuration
        studentId: $studentId
      ) {
        id
        educationDuration {
          response {
            educationDuration {
              educationStart
              educationEnd
            }
          }
          access {
            canEdit
            canView
          }
        }
      }
    }
  `;

  return (
    <FormModal<
      EducationDurationInput,
      UpdateEducationDuration,
      UpdateEducationDurationVariables
    >
      title={t("edit-duration-of-training")}
      description={t(
        "change-the-information-about-the-duration-of-your-training-here"
      )}
      defaultValues={data || undefined}
      makeVars={(educationDuration) => ({
        educationDuration: { ...educationDuration },
        studentId,
      })}
      mutation={UPDATE_EDUCATIONDURATION}
      open={open}
      handleClose={handleClose}
    >
      {(control) => (
        <>
          <InputDate
            control={control}
            name="educationStart"
            label={t("education-start")}
            required
            autoFocus
          />
          <InputDate
            control={control}
            name="educationEnd"
            label={t("education-end")}
            required
          />
        </>
      )}
    </FormModal>
  );
}
