import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import React from "react";
import { useController, UseControllerProps } from "react-hook-form";

type InputCheckboxProps<T> = {
  label: string;
  disabled?: boolean;
  required?: boolean;
  defaultValue?: boolean;
} & UseControllerProps<T>;

function InputCheckbox<T>({
  label,
  disabled,
  required,
  defaultValue,
  ...props
}: InputCheckboxProps<T>) {
  const rules = {
    required: required ? `${label} ist ein Pflichtfeld.` : undefined,
    ...props.rules,
  };

  const {
    field,
    fieldState: { error },
  } = useController({ ...props, rules });

  return (
    <div>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              {...field}
              color="success"
              disabled={disabled}
              required={required}
              defaultChecked={defaultValue}
            />
          }
          label={label}
        />
      </FormGroup>
      {error && (
        <Typography sx={{ color: "error.main", fontSize: "small" }}>
          {error.message}
        </Typography>
      )}
    </div>
  );
}

export default InputCheckbox;
