import { useQuery } from "@apollo/client";
import { Grid } from "@mui/material";
import gql from "graphql-tag";
import { useTranslation } from "react-i18next";
import Page from "../../common/layout/Page";
import Practical from "../../components/student/Practical";
import { GetStudentPracticalType } from "../../schema/GetStudentPracticalType";

interface PracticalPageProps {
  practicalId: string;
  studentId: string;
}

function PracticalPage({ practicalId, studentId }: PracticalPageProps) {
  const { t } = useTranslation();

  const GET_PRACTICALTYPE = gql`
    query GetStudentPracticalType($practicalId: ID!) {
      practical(practicalId: $practicalId) {
        id
        practicalType
      }
    }
  `;

  const { data } = useQuery<GetStudentPracticalType>(GET_PRACTICALTYPE, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    variables: { practicalId },
  });

  return (
    <Page
      breadcrumb={[
        {
          name: `${t("practicals")}`,
          to: ".",
        },
        {
          name: data
            ? t("practicalType." + data?.practical.practicalType)
            : `${t("loading")}`,
          to: "/praxiseinsaetze/:einsatzId",
        },
      ]}
      title={
        data
          ? t("practicalType." + data.practical.practicalType)
          : `${t("loading")}`
      }
      pageHeading={
        data
          ? t("practicalType." + data.practical.practicalType)
          : `${t("loading")}`
      }
    >
      <Grid item xs={12} xl={12} alignItems="flex-start">
        <Practical practicalId={practicalId} studentId={studentId} />
      </Grid>
    </Page>
  );
}

export default PracticalPage;
