import { gql, useMutation } from "@apollo/client";
import { STUDENT_PRACTICAL } from "../../fragments/student";
import { Role } from "../../schema/globalTypes";
import {
  SignPracticalDuration,
  SignPracticalDurationVariables,
} from "../../schema/SignPracticalDuration";
import ApproveButton from "./ApproveButton";

interface ApproveButtonPracticalDurationProps {
  canApprove: boolean;
  role: Role;
  durationId: string;
}

const SIGN_PRACTICAL_DURATION = gql`
  ${STUDENT_PRACTICAL}
  mutation SignPracticalDuration($practicalDurationId: ID!) {
    signPracticalDuration(practicalDurationId: $practicalDurationId) {
      ...Practical
    }
  }
`;

const ApproveButtonPracticalDuration = ({
  canApprove,
  role,
  durationId,
}: ApproveButtonPracticalDurationProps) => {
  const [mutate] = useMutation<
    SignPracticalDuration,
    SignPracticalDurationVariables
  >(SIGN_PRACTICAL_DURATION);

  const handleApprove = async () => {
    return mutate({ variables: { practicalDurationId: durationId } }).then(
      () => {
        return;
      }
    );
  };

  return (
    <ApproveButton
      canApprove={canApprove}
      handleApprove={handleApprove}
      role={role}
    />
  );
};

export default ApproveButtonPracticalDuration;
