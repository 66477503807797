import { useSnackbar } from "notistack";
import gql from "graphql-tag";
import DeleteButton from "../common/buttons/DeleteButton";
import ConfirmDialog from "../common/form/ConfirmDialog";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { DeleteFile, DeleteFileVariables } from "../schema/DeleteFile";
import { GetFiles, GetFilesVariables } from "../schema/GetFiles";
import { GET_FILES } from "../components/UploadFileSection";

interface DeleteFileButtonProps {
  fileId: string;
  referenceId: string;
  referenceType: string;
}

const DELETE_FILE = gql`
  mutation DeleteFile($fileId: ID!) {
    deleteFile(fileId: $fileId)
  }
`;

const DeleteFileButton = ({
  fileId,
  referenceId,
  referenceType,
}: DeleteFileButtonProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const [deleteFile] = useMutation<DeleteFile, DeleteFileVariables>(
    DELETE_FILE,
    {
      update(cache) {
        const getFilesData = cache.readQuery<GetFiles, GetFilesVariables>({
          query: GET_FILES,
          variables: {
            reference: {
              id: referenceId,
              type: referenceType,
            },
          },
        });
        const files = (getFilesData?.files || []).filter(
          (x) => x.file.id !== fileId
        );

        cache.writeQuery<GetFiles>({
          query: GET_FILES,
          variables: {
            reference: {
              id: referenceId,
              type: referenceType,
            },
          },
          data: { files },
        });
      },
    }
  );

  const [showConfirmation, setShowConfirmation] = useState(false);

  const deleteBtnHandler = () => {
    setShowConfirmation(true);
  };

  const { t } = useTranslation();

  const handleConfirmHandler = async () => {
    try {
      await deleteFile({
        variables: {
          fileId: fileId,
        },
      });

      enqueueSnackbar(`${t("file-successfully-deleted")}`, {
        variant: "success",
      });
    } catch (e) {
      const error = e as Error;
      enqueueSnackbar(error.message, { variant: "error" });
    }
    setShowConfirmation(false);
  };

  const handleCancelHandler = () => {
    setShowConfirmation(false);
  };

  return (
    <>
      <DeleteButton onClick={deleteBtnHandler} />
      <ConfirmDialog
        title={t("delete-file")}
        open={showConfirmation}
        message={t("really-delete-file")}
        handleConfirm={handleConfirmHandler}
        handleCancel={handleCancelHandler}
        confirmBtnText={t("delete")}
      />
    </>
  );
};
export default DeleteFileButton;
