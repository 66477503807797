import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useTranslation } from "react-i18next";
import Page from "../../common/layout/Page";
import ClassDetails from "../../components/class/ClassDetails";
import { GetClass, GetClassVariables } from "../../schema/GetClass";
interface ClassProps {
  classId: string;
}

export const GET_CLASS = gql`
  query GetClass($id: ID!) {
    class(id: $id) {
      classId
      number
      startDate
      endDate
    }
  }
`;

const Class = ({ classId }: ClassProps) => {
  const { t } = useTranslation();
  const { data } = useQuery<GetClass, GetClassVariables>(GET_CLASS, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    variables: { id: classId },
  });

  return (
    <Page
      breadcrumb={[
        { name: `${t("my-classes")}`, to: ".." },
        {
          name: `${`${t("class")} ` + data?.class.number}`,
          to: "/kurse/:kursId",
        },
      ]}
      title={
        data ? `${`${t("class")} ` + data.class.number}` : `${t("loading")}`
      }
      pageHeading={
        data ? `${`${t("class")} ` + data.class.number}` : `${t("loading")}`
      }
    >
      <ClassDetails classId={classId} />
    </Page>
  );
};

export default Class;
