import { useMutation } from "@apollo/client";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { IconButton } from "@mui/material";
import gql from "graphql-tag";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ConfirmDialog from "../../common/form/ConfirmDialog";
import { GET_STUDENTS } from "../../pages/school/SchoolStudentsPage";
import { GetStudents } from "../../schema/GetStudents";
import {
  RemoveStudentFromClass,
  RemoveStudentFromClassVariables,
} from "../../schema/RemoveStudentFromClass";

interface RemoveStudentFromClassButtonProps {
  classId: string;
  userId: string;
}

const REMOVE_STUDENT_FROM_CLASS = gql`
  mutation RemoveStudentFromClass($classData: RemoveStudentToClassInput!) {
    removeStudentFromClass(command: $classData) {
      classId
      students {
        id
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

const RemoveStudentFromClassButton = ({
  classId,
  userId,
}: RemoveStudentFromClassButtonProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [removeStudentFromClass] = useMutation<
    RemoveStudentFromClass,
    RemoveStudentFromClassVariables
  >(REMOVE_STUDENT_FROM_CLASS, {
    update(cache, { data }) {
      const getStudentsData = cache.readQuery<GetStudents>({
        query: GET_STUDENTS,
      });

      const students = (getStudentsData?.students || []).filter(
        (x) => x.id !== userId
      );

      cache.writeQuery<GetStudents>({
        query: GET_STUDENTS,
        data: { students },
      });
    },
  });

  const [showConfirmation, setShowConfirmation] = useState(false);

  const deleteBtnHandler = () => {
    setShowConfirmation(true);
  };

  const handleConfirmHandler = async () => {
    try {
      await removeStudentFromClass({
        variables: {
          classData: {
            userId,
            classId,
          },
        },
      });

      enqueueSnackbar(`${t("student-successfully-removed-from-class")}`, {
        variant: "success",
      });
    } catch (e) {
      const error = e as Error;
      enqueueSnackbar(error.message, { variant: "error" });
    }
    setShowConfirmation(false);
  };

  const handleCancelHandler = () => {
    setShowConfirmation(false);
  };
  const { t } = useTranslation();

  return (
    <>
      <IconButton
        onClick={deleteBtnHandler}
        sx={{ "&:hover": { "& .removeIcon": { color: "error.main" } } }}
      >
        <RemoveCircleOutlineIcon className="removeIcon" fontSize="small" />
      </IconButton>
      <ConfirmDialog
        title={`${t("remove-student")}`}
        open={showConfirmation}
        message={t("remove-student-from-class")}
        handleConfirm={handleConfirmHandler}
        handleCancel={handleCancelHandler}
        confirmBtnText={t("remove")}
      />
    </>
  );
};

export default RemoveStudentFromClassButton;
