import { useQuery } from "@apollo/client";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Box } from "@mui/system";
import gql from "graphql-tag";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import AddButton from "../../common/buttons/AddButton";
import MoreInfoButton from "../../common/buttons/MoreInfoButton";
import EmptyStateSubtle from "../../common/layout/EmptyStateSubtle";
import Page from "../../common/layout/Page";
import PaginatedSeachBar from "../../common/PaginatedSearchBar";
import DeleteInstructorButton from "../../components/instructor/buttons/DeleteInstructorButton";
import EditInstructorButton from "../../components/instructor/buttons/EditInstructorButton";
import AddInstructorModal from "../../components/instructor/modals/AddInstructorModal";
import CheckOrActivate from "../../components/user/CheckOrActivate";
import useFilter from "../../hooks/useFilter";
import useModal from "../../hooks/useModal";
import usePagination from "../../hooks/usePagination";
import { GetInstructors } from "../../schema/GetInstructors";

export const GET_INSTRUCTORS = gql`
  query GetInstructors {
    instructors {
      userId
      user {
        id
        firstName
        lastName
        email
        isApproved
      }
    }
  }
`;

const searchFields = ["user.firstName", "user.lastName", "user.email"];

export default function Intstructors() {
  const { t } = useTranslation();
  const history = useHistory();
  const { url } = useRouteMatch();

  const { loading, data } = useQuery<GetInstructors>(GET_INSTRUCTORS, {
    fetchPolicy: "cache-and-network",
  });
  const filter = useFilter(data?.instructors, searchFields);
  const pagination = usePagination(filter.data);
  const { open, handleOpen, handleClose } = useModal();

  const handleClick = (instructorId: string) => {
    history.push(`${url}/${instructorId}`);
  };

  return (
    <Page
      title={t("instructors")}
      pageHeading={t("manage-instructors")}
      subHeading={t("here-you-find-an-overview-of-all-instructors")}
    >
      <Box
        sx={{
          visibility: { xs: "visible", sm: "hidden" },
          marginBottom: { xs: "1rem", sm: "-1rem" },
        }}
      >
        <AddButton
          variant="contained"
          color="success"
          buttonLabel={t("add")}
          onClick={handleOpen}
        />
      </Box>
      <PaginatedSeachBar
        loading={loading}
        setSearchValue={filter.setSearchValue}
        paginationProps={pagination.paginationProps}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead sx={{ backgroundColor: "#EDEDED" }}>
            <TableRow>
              <TableCell component="th" scope="col">
                {t("lastname")}
              </TableCell>
              <TableCell component="th" scope="col">
                {t("name")}
              </TableCell>
              <TableCell component="th" scope="col">
                {t("email")}
              </TableCell>
              <TableCell
                component="th"
                scope="col"
                style={{ textAlign: "center" }}
              >
                {t("is-approved")}
              </TableCell>
              <TableCell
                component="th"
                scope="col"
                align="right"
                sx={{
                  visibility: { xs: "hidden", sm: "visible" },
                  marginBottom: { xs: "1rem", sm: "-1rem" },
                }}
              >
                <AddButton
                  variant="contained"
                  color="warning"
                  buttonLabel={t("add")}
                  onClick={handleOpen}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          {pagination && pagination.currentData.length >= 1 ? (
            <TableBody>
              {pagination.currentData.map((instructor) => (
                <TableRow
                  hover
                  onClick={() => handleClick(instructor.userId)}
                  sx={{ cursor: "pointer" }}
                  key={instructor.userId}
                >
                  <TableCell component="td">
                    {instructor.user.lastName}
                  </TableCell>
                  <TableCell component="td">
                    {instructor.user.firstName}
                  </TableCell>
                  <TableCell component="td">{instructor.user.email}</TableCell>
                  <TableCell
                    component="td"
                    style={{ textAlign: "center" }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <CheckOrActivate
                      isApproved={instructor.user.isApproved}
                      userId={instructor.userId}
                    />
                  </TableCell>
                  <TableCell align="right" onClick={(e) => e.stopPropagation()}>
                    <MoreInfoButton
                      onClick={() => handleClick(instructor.userId)}
                    />
                    <EditInstructorButton
                      data={{
                        userId: instructor.userId,
                        firstName: instructor.user.firstName,
                        lastName: instructor.user.lastName,
                        email: instructor.user.email,
                      }}
                      userId={instructor.userId}
                    />
                    <DeleteInstructorButton userId={instructor.userId} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={4}>
                  <EmptyStateSubtle
                    title={t("no-data")}
                    description={t("add-instructors")}
                    buttonLabel={t("add-instructors")}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <AddInstructorModal open={open} handleClose={handleClose} />
    </Page>
  );
}
