import { useQuery } from "@apollo/client";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import gql from "graphql-tag";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import AddButton from "../../common/buttons/AddButton";
import EmptyStateSubtle from "../../common/layout/EmptyStateSubtle";
import { STUDENT_USER } from "../../fragments/student";
import useModal from "../../hooks/useModal";
import {
  GetClassStudents,
  GetClassStudentsVariables,
} from "../../schema/GetClassStudents";
import AddStudentToClassModal from "./modals/AddStudentToClassModal";
import RemoveStudentFromClassButton from "./RemoveStudentFromClassButton";

interface ClassStudentsProps {
  canOpen?: boolean;
  classId: string;
}

const ClassStudents = ({ canOpen, classId }: ClassStudentsProps) => {
  const GET_CLASS_STUDENTS = gql`
    ${STUDENT_USER}

    query GetClassStudents($id: ID!) {
      class(id: $id) {
        classId
        students {
          ...StudentUser
        }
      }
    }
  `;

  const { loading, data } = useQuery<
    GetClassStudents,
    GetClassStudentsVariables
  >(GET_CLASS_STUDENTS, {
    variables: { id: classId },
    fetchPolicy: "cache-and-network",
  });
  const { t } = useTranslation();
  const { open, handleOpen, handleClose } = useModal();
  const history = useHistory();
  const { url } = useRouteMatch();

  const handleClick = (studentId: string) => {
    if (canOpen) {
      history.push(
        `${url}/../../../auszubildende/${studentId}/ausbildungsprofil`
      );
    }
  };

  return (
    <>
      <Box
        sx={{
          visibility: { xs: "visible", sm: "hidden" },
          marginBottom: { xs: "1rem", sm: "-1rem" },
        }}
      >
        <AddButton
          variant="contained"
          color="warning"
          buttonLabel={t("add")}
          onClick={handleOpen}
        />
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead sx={{ backgroundColor: "#EDEDED" }}>
            <TableRow>
              <TableCell component="th" scope="col">
                {t("lastname")}
              </TableCell>
              <TableCell component="th" scope="col">
                {t("name")}
              </TableCell>
              <TableCell
                component="th"
                scope="col"
                align="right"
                sx={{
                  visibility: { xs: "hidden", sm: "visible" },
                  marginBottom: { xs: "1rem", sm: "-1rem" },
                }}
              >
                <AddButton
                  variant="contained"
                  color="warning"
                  buttonLabel={t("add")}
                  onClick={handleOpen}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          {data && data.class.students.length >= 1 ? (
            <TableBody>
              {data?.class.students.map((student) => (
                <TableRow
                  hover={canOpen}
                  sx={canOpen ? { cursor: "pointer" } : {}}
                  onClick={() => handleClick(student.id)}
                  key={student.id}
                >
                  <TableCell component="td">{student.user.lastName}</TableCell>
                  <TableCell component="td">{student.user.firstName}</TableCell>
                  <TableCell
                    component="td"
                    align="right"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <RemoveStudentFromClassButton
                      userId={student.id}
                      classId={classId}
                    />

                    {/* <MoreInfoButton onClick={() => handleClick(student.id)} /> */}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={3}>
                  <EmptyStateSubtle
                    title={t("no-data")}
                    description={t("add-student-to-class")}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <AddStudentToClassModal
        open={open}
        handleClose={handleClose}
        classId={classId}
      />
    </>
  );
};

export default ClassStudents;
