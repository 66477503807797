import EditButton from "../../../common/buttons/EditButton";
import useModal from "../../../hooks/useModal";
import EditInstructorModal from "../modals/EditInstructorModal";
import { UpdateInstructorInput } from "../../../schema/globalTypes";

interface EditInstructorButtonProps {
  userId: string;
  data?: UpdateInstructorInput | null;
}

const EditInstructorButton = ({ userId, data }: EditInstructorButtonProps) => {
  const { open, handleOpen, handleClose } = useModal();

  return (
    <>
      <EditButton handleOpen={handleOpen} />

      <EditInstructorModal
        data={data}
        userId={userId}
        handleClose={handleClose}
        open={open}
      />
    </>
  );
};

export default EditInstructorButton;
