import { useQuery } from "@apollo/client";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Box } from "@mui/system";
import gql from "graphql-tag";
import { useTranslation } from "react-i18next";
import AddButton from "../../common/buttons/AddButton";
import EmptyStateSubtle from "../../common/layout/EmptyStateSubtle";
import Page from "../../common/layout/Page";
import PaginatedSeachBar from "../../common/PaginatedSearchBar";
import DeleteOrganizationButton from "../../components/organization/buttons/DeleteOrganizationButton";
import EditOrganizationButton from "../../components/organization/buttons/EditOrganizationButton";
import AddOrganizationModal from "../../components/organization/modals/AddOrganizationModal";
import CheckOrActivate from "../../components/user/CheckOrActivate";
import useFilter from "../../hooks/useFilter";
import useModal from "../../hooks/useModal";
import usePagination from "../../hooks/usePagination";
import { GetOrganizations } from "../../schema/GetOrganizations";

export const GET_ORGANIZATIONS = gql`
  query GetOrganizations {
    organizations {
      organizationName
      city
      street
      streetNumber
      postCode
      userId
      user {
        id
        firstName
        lastName
        email
        isApproved
      }
    }
  }
`;

const searchFields = ["user.firstName", "user.lastName", "user.email"];

export default function Organizations() {
  const { t } = useTranslation();
  const { loading, data } = useQuery<GetOrganizations>(GET_ORGANIZATIONS, {
    fetchPolicy: "cache-and-network",
  });
  const { open, handleOpen, handleClose } = useModal();
  const filter = useFilter(data?.organizations, searchFields);
  const pagination = usePagination(filter.data);

  return (
    <Page
      title={t("organizations")}
      pageHeading={t("manage-organizations")}
      subHeading={t("here-you-find-an-overview-of-all-organizations")}
    >
      <Box
        sx={{
          visibility: { xs: "visible", sm: "hidden" },
          marginBottom: { xs: "1rem", sm: "-1rem" },
        }}
      >
        <AddButton
          variant="contained"
          color="success"
          buttonLabel={t("add")}
          onClick={handleOpen}
        />
      </Box>
      <PaginatedSeachBar
        loading={loading}
        setSearchValue={filter.setSearchValue}
        paginationProps={pagination.paginationProps}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead sx={{ backgroundColor: "#EDEDED" }}>
            <TableRow>
              <TableCell component="th" scope="col">
                {t("organization")}
              </TableCell>
              <TableCell component="th" scope="col">
                {t("city")}
              </TableCell>
              <TableCell component="th" scope="col">
                {t("email")}
              </TableCell>
              <TableCell
                component="th"
                scope="col"
                style={{ textAlign: "center" }}
              >
                {t("is-approved")}
              </TableCell>
              <TableCell
                component="th"
                scope="col"
                align="right"
                sx={{
                  visibility: { xs: "hidden", sm: "visible" },
                  marginBottom: { xs: "1rem", sm: "-1rem" },
                }}
              >
                <AddButton
                  variant="contained"
                  color="warning"
                  buttonLabel={t("add")}
                  onClick={handleOpen}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          {pagination && pagination.currentData.length >= 1 ? (
            <TableBody>
              {pagination.currentData.map((organization) => (
                <TableRow key={organization.userId}>
                  <TableCell component="td">
                    {organization.user.firstName}
                  </TableCell>
                  <TableCell component="td">
                    {organization.user.lastName}
                  </TableCell>
                  <TableCell component="td">
                    {organization.user.email}
                  </TableCell>
                  <TableCell
                    component="td"
                    style={{ textAlign: "center" }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <CheckOrActivate
                      isApproved={organization.user.isApproved}
                      userId={organization.userId}
                    />
                  </TableCell>
                  <TableCell align="right" onClick={(e) => e.stopPropagation()}>
                    <EditOrganizationButton
                      data={{
                        userId: organization.userId,
                        organizationName: organization.organizationName,
                        postCode: organization.postCode,
                        street: organization.street,
                        streetNumber: organization.streetNumber,
                        city: organization.city,
                        email: organization.user.email,
                      }}
                      userId={organization.userId}
                    />
                    <DeleteOrganizationButton userId={organization.userId} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={4}>
                  <EmptyStateSubtle
                    title={t("no-data")}
                    description={t("add-organizations")}
                    buttonLabel={t("add-organizations")}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <AddOrganizationModal open={open} handleClose={handleClose} />
    </Page>
  );
}
