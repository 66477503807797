import { Alert, Button } from "@mui/material";
import gql from "graphql-tag";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FormModal from "../../../common/form/FormModal";
import InputDate from "../../../common/input/InputDate";
import InputPassword from "../../../common/input/InputPassword";
import InputText from "../../../common/input/InputText";
import { generatePassword } from "../../../helpers/helpers";
import { GET_STUDENTS } from "../../../pages/school/SchoolStudentsPage";

import {
  CreateNewStudent,
  CreateNewStudentVariables,
} from "../../../schema/CreateNewStudent";
import { CreateStudentInput } from "../../../schema/globalTypes";
import ClassDropdown from "../../class/ClassDropdown";

const CREATE_STUDENT = gql`
  mutation CreateNewStudent($studentData: CreateStudentInput!) {
    createStudent(command: $studentData) {
      id
      class {
        classId
        number
      }
      user {
        firstName
        lastName
        email
      }
      birthdate
    }
  }
`;

interface AddStudentModalProps {
  open: boolean;
  handleClose: () => void;
}

export default function AddStudentModal({
  open,
  handleClose,
}: AddStudentModalProps) {
  const [isModalInReadOnly, setIsModalInReadOnly] = useState(false);

  const form = useForm<CreateStudentInput>();
  const { getValues } = form;

  useEffect(() => {
    if (open) {
      form.reset({
        password: generatePassword(),
      });
      setIsModalInReadOnly(false);
    }
  }, [open, form]);

  const setReadOnly = (success: boolean) => {
    if (success) {
      setIsModalInReadOnly(true);
    } else {
      handleClose();
    }
  };

  const { t } = useTranslation();

  return (
    <FormProvider {...form}>
      <FormModal<
        CreateStudentInput,
        CreateNewStudent,
        CreateNewStudentVariables
      >
        title={
          isModalInReadOnly
            ? `${t("new-student-added")}`
            : `${t("add-student")}`
        }
        description={
          isModalInReadOnly
            ? `${t("new-student-created-successfully")}`
            : `${t("add-new-student-with-full-name")}`
        }
        mutation={CREATE_STUDENT}
        open={open}
        handleClose={setReadOnly}
        makeVars={(studentData) => ({
          studentData: { ...studentData },
        })}
        mutationOptions={{
          refetchQueries: [GET_STUDENTS],
        }}
        actions={
          isModalInReadOnly ? (
            <>
              <Button onClick={handleClose}>
                {isModalInReadOnly ? `${t("close")}` : `${t("cancel")}`}
              </Button>
              <Button variant="contained">
                <a
                  style={{ color: "white", textDecoration: "none" }}
                  href={
                    `mailto:${getValues().email}` +
                    "?" +
                    "subject=" +
                    `${t("invite-mail-subject")}` +
                    "&" +
                    "body=" +
                    `${t("invite-mail-greeting")} ${getValues().firstName} ${
                      getValues().lastName
                    },%0D%0A%0D%0A` +
                    `${t("we-would-like-to-invite-you")} ${
                      getValues().email
                    } ${t("and-password")} ${getValues().password} ${t(
                      "change-password-on-first-login"
                    )}%0D%0A%0D%0A` +
                    `${t("greetings")}%0D%0A${t("your-nursing-school")}`
                  }
                >
                  {t("send-mail")}
                </a>
              </Button>
            </>
          ) : undefined
        }
        useContext
      >
        {(control) => (
          <>
            <InputText
              control={control}
              name="firstName"
              label={t("name")}
              required
              autoFocus
              readOnly={isModalInReadOnly}
            />
            <InputText
              control={control}
              name="lastName"
              label={t("lastname")}
              required
              readOnly={isModalInReadOnly}
            />
            <InputDate
              control={control}
              name="birthdate"
              label={t("birthdate")}
              required
            />
            <InputText
              control={control}
              name="email"
              label={t("email")}
              required
              readOnly={isModalInReadOnly}
            />
            <ClassDropdown
              name="classId"
              control={control}
              label={t("choose-class")}
            />
            <InputPassword
              control={control}
              name="password"
              label={t("password")}
              required
              readOnly={isModalInReadOnly}
            />
            {isModalInReadOnly && (
              <Alert severity="success" sx={{ mt: 1 }}>
                {t("send-password-by-mail")}
              </Alert>
            )}
          </>
        )}
      </FormModal>
    </FormProvider>
  );
}
