import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import Page from "../../common/layout/Page";
import NavigationSection from "../../components/NavigationSection";
import useCurrentUser from "../../hooks/useCurrentUser";
import { Role } from "../../schema/globalTypes";

export default function OrganizationHomePage() {
  const { t } = useTranslation();
  const { user } = useCurrentUser();
  const history = useHistory();
  const message = user ? `${t("home")}, ${user.fullName}` : t("home");
  return (
    <Page title={t("home")} pageHeading={message}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography>
            Dies ist der digitaler Ausbildungsnachweis Praxis für die Ausbildung
            zur Pflegefachfrau/zum Pflegefachmann (gemäß § 60 Abs. 5
            Pflegeberufe Ausbildungs- und Prüfungsverordnung – PflAPrV). Dem
            digitalen Nachweis liegen die verbindlichen Unterlagen für die
            Ausbildung im Land Schleswig-Holstein zugrunde.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subHeader">Praxisnachweise</Typography>
          <Typography>
            Unter "Meine Praxisnachweise" werden Ihnen die Nachweisdokumente der
            Ihnen zugeordneten Auszubildenden angezeigt.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <NavigationSection
            title="Meine Praxisnachweise"
            role={Role.ORGANIZATION}
          >
            <Button
              component={Link}
              to="/betriebe/praxisnachweise"
              variant="contained"
              color="organization"
            >
              Zur Übersicht
            </Button>
          </NavigationSection>
        </Grid>
      </Grid>
    </Page>
  );
}
