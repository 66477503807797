import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Fade,
  Grid,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { de } from "date-fns/locale";
import { useSnackbar } from "notistack";
import React from "react";
import { useTranslation } from "react-i18next";
import { relativeDate } from "../common/utils/date";
import { Meta } from "../schema/Meta";

type FormSectionProps = {
  sectionTitle: string;
  sectionAction?: React.ReactNode;
  children: React.ReactNode;
  locked?: boolean;
  canEdit?: boolean;
  loading?: boolean;
  meta?: Meta | null;
  headerBackgroundColor?: string;
  headerForegroundColor?: string;
};

function FormSection({
  sectionTitle,
  sectionAction,
  locked,
  meta,
  loading,
  children,
  headerBackgroundColor,
  headerForegroundColor,
  canEdit,
}: FormSectionProps) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Card sx={{ width: "100%" }} variant="outlined">
      <CardHeader
        titleTypographyProps={{
          fontSize: { xs: "1.2rem" },
          fontWeight: 300,
          color: headerForegroundColor,
        }}
        title={sectionTitle}
        sx={{
          backgroundColor: headerBackgroundColor,
          py: "12px",
          color: headerForegroundColor,
        }}
        action={
          locked || canEdit === false ? (
            <LockOutlinedIcon
              sx={{ color: headerForegroundColor || "#c8cbcf", margin: "8px" }}
            />
          ) : (
            sectionAction
          )
        }
      ></CardHeader>
      <Divider />
      <CardContent>
        {loading ? (
          <Skeleton variant="rectangular" height={200} />
        ) : locked ? (
          enqueueSnackbar(t("no-access-rights"), {
            variant: "error",
            preventDuplicate: true,
          }) && (
            <Box
              height={150}
              display="flex"
              textAlign="center"
              alignItems="center"
              justifyContent="center"
            >
              <Stack direction="row" spacing={2}>
                <LockOutlinedIcon sx={{ color: "#c8cbcf" }} fontSize="medium" />
                <Typography color="#c8cbcf">{t("no-access-rights")}</Typography>
              </Stack>
            </Box>
          )
        ) : (
          <Stack spacing={1}>{children}</Stack>
        )}
      </CardContent>
      <CardActions>
        <Grid container justifyContent="flex-end">
          {meta && (meta.createdAt || meta.createdBy) ? (
            <Tooltip
              placement="bottom-start"
              title={format(new Date(meta.createdAt || ""), "PPPPpppp", {
                locale: de,
              })}
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              arrow
            >
              <Typography variant="sectionFooter" noWrap>
                {t("last-edited")}
                {meta.createdAt ? relativeDate(meta.createdAt) : ""}
                {meta.createdBy && (
                  <>
                    {" "}
                    {t("by")}{" "}
                    {meta.createdBy?.firstName + " " + meta.createdBy?.lastName}
                  </>
                )}
              </Typography>
            </Tooltip>
          ) : (
            <></>
          )}
        </Grid>
      </CardActions>
    </Card>
  );
}
export default FormSection;
