import CheckIcon from "@mui/icons-material/Check";
import UserApprovalButton from "../UserApprovalButton";

interface CheckOrActivateProps {
  isApproved: boolean;
  userId: string;
}

const CheckOrActivate = ({ isApproved, userId }: CheckOrActivateProps) =>
  isApproved ? <CheckIcon /> : <UserApprovalButton userId={userId} />;

export default CheckOrActivate;
