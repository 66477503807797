import gql from "graphql-tag";

export const CLASS_DETAILS = gql`
  fragment ClassDetails on Class {
    classId
    number
    startDate
    endDate
  }
`;
