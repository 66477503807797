import { UseControllerProps } from "react-hook-form";
import { useTranslation } from "react-i18next";
import InputCheckboxGroup from "../../../common/input/InputCheckboxGroup";
import { InterviewReason } from "../../../schema/globalTypes";

type InputInterviewReasonProps<T> = {
  label: string;
  disabled?: boolean;
  required?: boolean;
} & UseControllerProps<T>;

function InputInterviewReason<T>(props: InputInterviewReasonProps<T>) {
  const { t } = useTranslation();
  return (
    <InputCheckboxGroup
      {...props}
      options={[
        {
          label: `${t("interviewReason.COACHING")}`,
          value: InterviewReason.COACHING,
        },
        {
          label: `${t("interviewReason.EXCERSIZE")}`,
          value: InterviewReason.EXCERSIZE,
        },
        {
          label: `${t("interviewReason.OTHER")}`,
          value: InterviewReason.OTHER,
        },
      ]}
    />
  );
}

export default InputInterviewReason;
