import gql from "graphql-tag";
import React from "react";
import { useTranslation } from "react-i18next";
import FormModal from "../../../common/form/FormModal";
import InputDate from "../../../common/input/InputDate";
import InputText from "../../../common/input/InputText";
import { STUDENT_PRACTICAL } from "../../../fragments/student";
import { PracticalDurationInput } from "../../../schema/globalTypes";
import {
  UpdatePracticalDuration,
  UpdatePracticalDurationVariables,
} from "../../../schema/UpdatePracticalDuration";

interface EditPracticalDurationModalProps {
  data?: PracticalDurationInput | null;
  practicalId: string;
  open: boolean;
  handleClose: () => void;
}

export default function EditPracticalDurationModal({
  data,
  practicalId,
  open,
  handleClose,
}: EditPracticalDurationModalProps) {
  const { t } = useTranslation();
  const UPDATE_PRACTICAL_DURATION = gql`
    ${STUDENT_PRACTICAL}
    mutation UpdatePracticalDuration(
      $practicalDuration: PracticalDurationInput!
    ) {
      updatePracticalDuration(command: $practicalDuration) {
        ...Practical
      }
    }
  `;

  return (
    <FormModal<
      PracticalDurationInput,
      UpdatePracticalDuration,
      UpdatePracticalDurationVariables
    >
      title={t("edit-practical-duration")}
      description={t("change-practical-duration-here")}
      defaultValues={data || undefined}
      makeVars={(practicalDuration) => ({
        practicalDuration: { ...practicalDuration, id: practicalId },
      })}
      mutation={UPDATE_PRACTICAL_DURATION}
      open={open}
      handleClose={handleClose}
    >
      {(control) => (
        <>
          <InputDate
            control={control}
            name="startDate"
            label={t("practical-duration-start")}
            required
          />
          <InputDate
            control={control}
            name="endDate"
            label={t("practical-duration-end")}
            required
          />
          <InputText
            control={control}
            name="plannedHours"
            label={t("practical-duration-planned")}
            required
          />
          <InputText
            control={control}
            name="recordedHours"
            label={t("practical-duration-recorded")}
            required
          />
        </>
      )}
    </FormModal>
  );
}
