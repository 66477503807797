import { useQuery } from "@apollo/client";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import gql from "graphql-tag";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import MoreInfoButton from "../../common/buttons/MoreInfoButton";
import EmptyStateSubtle from "../../common/layout/EmptyStateSubtle";
import Page from "../../common/layout/Page";
import PaginatedSeachBar from "../../common/PaginatedSearchBar";
import useFilter from "../../hooks/useFilter";
import usePagination from "../../hooks/usePagination";
import { CurrentPracticals } from "../../schema/CurrentPracticals";

export const CURRENT_PRACTICALS = gql`
  query CurrentPracticals {
    currentPracticals {
      practicalType
      id
      isApprovedByStudent
      isApprovedByInstructor
      isApprovedByOrganization
      isApprovedByTeacher
      isWaitingForStudent
      isWaitingForInstructor
      isWaitingForOrganization
      isWaitingForTeacher
      student {
        id
        user {
          fullName
          email
        }
      }
    }
  }
`;

const searchFields = [
  "practical.student.user.fullName",
  "practical.student.user.email",
];

const PracticalVisits = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { url } = useRouteMatch();
  const { loading, data } = useQuery<CurrentPracticals>(CURRENT_PRACTICALS, {
    fetchPolicy: "cache-and-network",
  });

  const handleClick = (practicalId: string) => {
    if (data) {
      history.push(`${url}/${practicalId}`);
    }
  };

  const filter = useFilter(data?.currentPracticals || [], searchFields);
  const pagination = usePagination(filter.data);

  return (
    <Page
      title={t("my-practical-visits")}
      pageHeading={t("my-practical-visits")}
      subHeading={t("my-practical-visits-description")}
    >
      <PaginatedSeachBar
        loading={loading}
        setSearchValue={filter.setSearchValue}
        paginationProps={pagination.paginationProps}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead sx={{ backgroundColor: "teacher.50" }}>
            <TableRow>
              <TableCell component="th" scope="col">
                {t("practical")}
              </TableCell>
              <TableCell component="th" scope="col">
                {t("student")}
              </TableCell>
              <TableCell component="th" scope="col">
                Aktion erforderlich
              </TableCell>
              <TableCell component="th" scope="col">
                {t("approved")}
              </TableCell>
              <TableCell component="th" scope="col"></TableCell>
            </TableRow>
          </TableHead>
          {pagination.currentData && pagination.currentData.length >= 1 ? (
            <TableBody>
              {pagination.currentData.map((practical) => (
                <TableRow
                  key={practical.id}
                  hover
                  onClick={() => handleClick(practical.id)}
                  sx={{ cursor: "pointer" }}
                >
                  <TableCell
                    component="td"
                    sx={{
                      fontWeight: practical.isWaitingForTeacher
                        ? "bold"
                        : undefined,
                    }}
                  >
                    {t("practicalType." + practical.practicalType)}
                  </TableCell>
                  <TableCell
                    component="td"
                    sx={{
                      fontWeight: practical.isWaitingForTeacher
                        ? "bold"
                        : undefined,
                    }}
                  >
                    {practical.student.user.fullName}
                  </TableCell>
                  <TableCell
                    component="td"
                    sx={{
                      fontWeight: practical.isWaitingForTeacher
                        ? "bold"
                        : undefined,
                    }}
                  >
                    {practical.isWaitingForTeacher ? "ja" : "nein"}
                  </TableCell>
                  <TableCell
                    component="td"
                    sx={{
                      fontWeight: practical.isWaitingForTeacher
                        ? "bold"
                        : undefined,
                    }}
                  >
                    {practical.isApprovedByTeacher ? "ja" : "nein"}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: practical.isWaitingForTeacher
                        ? "bold"
                        : undefined,
                    }}
                  >
                    <MoreInfoButton onClick={() => handleClick(practical.id)} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={5}>
                  <EmptyStateSubtle
                    title={t("no-data")}
                    description={t("no-practical-visits-yet")}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Page>
  );
};

export default PracticalVisits;
