import { Alert, Button } from "@mui/material";
import gql from "graphql-tag";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FormModal from "../../../common/form/FormModal";
import Label from "../../../common/form/Label";
import InputCheckbox from "../../../common/input/InputCheckbox";
import InputPassword from "../../../common/input/InputPassword";
import InputText from "../../../common/input/InputText";
import { generatePassword } from "../../../helpers/helpers";
import { GET_TEACHERS } from "../../../pages/school/Teachers";
import {
  CreateTeacher,
  CreateTeacherVariables,
} from "../../../schema/CreateTeacher";
import { GetTeachers } from "../../../schema/GetTeachers";
import { CreateTeacherInput } from "../../../schema/globalTypes";

interface AddTeacherModalProps {
  data?: CreateTeacherInput | null;
  open: boolean;
  handleClose: () => void;
}

const CREATE_TEACHER = gql`
  mutation CreateTeacher($teacherData: CreateTeacherInput!) {
    createTeacher(command: $teacherData) {
      userId
      user {
        id
        email
        firstName
        lastName
        fullName
        isApproved
      }
      isSchoolAdmin
      classes {
        classId
        number
      }
    }
  }
`;

export default function AddTeacherModal({
  data,
  open,
  handleClose,
}: AddTeacherModalProps) {
  const [isModalInReadOnly, setIsModalInReadOnly] = useState(false);
  const form = useForm<CreateTeacherInput>();
  const { getValues } = form;
  const { t } = useTranslation();

  useEffect(() => {
    if (open) {
      form.reset({
        password: generatePassword(),
        isSchoolAdmin: false,
      });
      setIsModalInReadOnly(false);
    }
  }, [open, form]);

  const setReadOnly = (success: boolean) => {
    if (success) {
      setIsModalInReadOnly(true);
    } else {
      handleClose();
    }
  };

  return (
    <FormProvider {...form}>
      <FormModal<CreateTeacherInput, CreateTeacher, CreateTeacherVariables>
        title={
          isModalInReadOnly
            ? `${t("new-teacher-added")}`
            : `${t("add-teacher")}`
        }
        description={
          isModalInReadOnly
            ? `${t("new-teacher-created-successfully")}`
            : `${t("add-a-teacher")}`
        }
        maxWidth="md"
        makeVars={(teacherData) => ({
          teacherData: { ...teacherData },
        })}
        mutation={CREATE_TEACHER}
        mutationOptions={{
          update(cache, { data }) {
            const getTeachersData = cache.readQuery<GetTeachers>({
              query: GET_TEACHERS,
            });

            let teachers = getTeachersData?.teachers || [];
            if (data?.createTeacher) {
              teachers = [data.createTeacher, ...teachers];
            }

            cache.writeQuery<GetTeachers>({
              query: GET_TEACHERS,
              data: { teachers },
            });
          },
        }}
        actions={
          isModalInReadOnly ? (
            <>
              <Button onClick={handleClose}>
                {isModalInReadOnly ? `${t("close")}` : `${t("cancel")}`}
              </Button>
              <Button variant="contained">
                <a
                  style={{ color: "white", textDecoration: "none" }}
                  href={
                    `mailto:${getValues().email}` +
                    "?" +
                    "subject=" +
                    `${t("invite-mail-subject")}` +
                    "&" +
                    "body=" +
                    `${t("invite-mail-greeting")} ${getValues().firstName} ${
                      getValues().lastName
                    },%0D%0A%0D%0A` +
                    `${t("we-would-like-to-invite-you")} ${
                      getValues().email
                    } ${t("and-password")} ${getValues().password} ${t(
                      "change-password-on-first-login"
                    )}%0D%0A%0D%0A` +
                    `${t("greetings")}%0D%0A${t("your-nursing-school")}`
                  }
                >
                  {t("send-mail")}
                </a>
              </Button>
            </>
          ) : undefined
        }
        open={open}
        handleClose={setReadOnly}
        useContext
      >
        {(control) => (
          <>
            <InputText
              control={control}
              name="firstName"
              label={t("name")}
              required
              autoFocus
              readOnly={isModalInReadOnly}
            />
            <InputText
              control={control}
              name="lastName"
              label={t("lastname")}
              required
              readOnly={isModalInReadOnly}
            />
            <InputText
              control={control}
              name="email"
              label={t("email")}
              required
              readOnly={isModalInReadOnly}
            />
            <InputPassword
              control={control}
              name="password"
              label={t("password")}
              required
              readOnly={isModalInReadOnly}
            />
            <Label label={t("is-teacher-school-admin")} marginTop={2} />
            <InputCheckbox
              name="isSchoolAdmin"
              label={t("yes")}
              control={control}
              disabled={isModalInReadOnly}
            />
            {isModalInReadOnly && (
              <Alert severity="success" sx={{ mt: 1 }}>
                {t("send-password-by-mail-teacher")}
              </Alert>
            )}
          </>
        )}
      </FormModal>
    </FormProvider>
  );
}
