import SearchIcon from "@mui/icons-material/Search";
import { Box, debounce, InputAdornment, TextField } from "@mui/material";
import { useCallback, useMemo } from "react";

interface SearchInputProps {
  onChange: (value: string) => void;
  debounce?: number;
  placeholder?: string;
}

const SearchInput = (props: SearchInputProps) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      props.onChange(e.target.value);
    },
    [props]
  );

  // const debounced = useMemo(
  //   () => debounce(handleChange, props.debounce || 300),
  //   [handleChange, props.debounce]
  // );
  return (
    <Box display="flex" alignItems="center">
      <TextField
        placeholder={props.placeholder || "Suche"}
        variant="standard"
        type="search"
        onChange={handleChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default SearchInput;
