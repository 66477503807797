import { Stack } from "@mui/material";
import gql from "graphql-tag";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import FormModal from "../../../common/form/FormModal";
import InputCheckbox from "../../../common/input/InputCheckbox";
import InputPasswordHidden from "../../../common/input/InputPasswordHidden";
import InputText from "../../../common/input/InputText";
import useAuthentication from "../../../hooks/useAuthentication";
import { RegisterInstructorInput } from "../../../schema/globalTypes";
import {
  RegisterInstructor,
  RegisterInstructorVariables,
} from "../../../schema/RegisterInstructor";

interface RegisterInstructorModalProps {
  open: boolean;
  handleClose: () => void;
}

const REGISTER_INSTRUCTOR = gql`
  mutation RegisterInstructor($command: RegisterInstructorInput!) {
    registerInstructor(command: $command) {
      accessToken
      refreshToken
    }
  }
`;

function RegisterInstructorModal({
  open,
  handleClose,
}: RegisterInstructorModalProps) {
  const { t } = useTranslation();

  const { setToken, login } = useAuthentication();
  const history = useHistory();

  const onClose = (success: boolean, data?: RegisterInstructor | null) => {
    if (data && success) {
      console.log("setting token", data);
      setToken(
        data.registerInstructor.accessToken,
        data.registerInstructor.refreshToken
      ).then(() => {
        handleClose();
        history.push("/");
      });
    } else {
      handleClose();
    }
  };

  const form = useForm<
    RegisterInstructorInput & {
      confirmation: boolean;
      password_repeat: string;
    }
  >();

  const password = form.watch("password");

  return (
    <FormProvider {...form}>
      <FormModal<
        RegisterInstructorInput & {
          confirmation: boolean;
          password_repeat: string;
        },
        RegisterInstructor,
        RegisterInstructorVariables
      >
        title={t("register-instructor")}
        description={t("register-instructor-description")}
        maxWidth="sm"
        defaultValues={undefined}
        makeVars={(command) => {
          const { confirmation, password_repeat, ...rest } = command;
          return {
            command: rest,
          };
        }}
        mutation={REGISTER_INSTRUCTOR}
        open={open}
        handleClose={onClose}
        useContext
      >
        {(control) => (
          <Stack spacing={2}>
            <InputText
              control={control}
              name="firstName"
              label={t("name")}
              required
            />
            <InputText
              control={control}
              name="lastName"
              label={t("lastname")}
              required
            />
            <InputText
              control={control}
              name="email"
              label={t("email")}
              required
            />

            <InputPasswordHidden
              control={control}
              name="password"
              label={t("password")}
              required
            />
            <InputPasswordHidden
              control={control}
              name="password_repeat"
              label="Passwort wiederholen"
              required
              rules={{
                validate: (value) =>
                  value === password || "Die Passwörter stimmen nicht überein.",
              }}
            />
            <InputCheckbox
              control={control}
              name="confirmation"
              label={t("Befugnis zur Praxisanleitung vorhanden")}
              required
            />
          </Stack>
        )}
      </FormModal>
    </FormProvider>
  );
}

export default RegisterInstructorModal;
