import { AppBar, Box, Divider, Drawer, Toolbar } from "@mui/material";
import { ReactNode, useState } from "react";
import MenuTop from "../../components/MenuTop";
import useCurrentUser from "../../hooks/useCurrentUser";
import { ApplicationMenu } from "../../navigation";
import SideBarNavigation from "../navigation/SideBarNavigation";

const drawerWidth = 280;

interface AppLayoutProps {
  children: ReactNode;
}

const LayoutAuthorized = ({ children }: AppLayoutProps) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const { user } = useCurrentUser();

  return (
    <Box
      sx={{
        display: {
          sm: "flex",
        },
      }}
    >
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <MenuTop handleDrawerOpen={handleDrawerToggle} />
      </AppBar>

      {user && user.roles.length > 0 && user?.isApproved && (
        <Box
          component="nav"
          sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", md: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            <>
              <Toolbar />
              <Divider />

              <SideBarNavigation
                currentRoles={user?.roles}
                menu={ApplicationMenu}
                handleDrawerToggle={handleDrawerToggle}
              />
            </>
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", md: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            open
          >
            <>
              <Toolbar />
              <Divider />

              <SideBarNavigation
                currentRoles={user?.roles}
                menu={ApplicationMenu}
              />
            </>
          </Drawer>
        </Box>
      )}

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { md: `calc(100% - ${drawerWidth}px)` },
        }}
        id="#pageTop"
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
};

export default LayoutAuthorized;
