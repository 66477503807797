import ReceiptLongRoundedIcon from "@mui/icons-material/ReceiptLongRounded";
import { Box, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import AddButton from "../buttons/AddButton";

interface EmptyStateProps {
  title?: string;
  description?: string;
  buttonLabel?: string;
  buttonAction?: () => void;
}

export default function EmptyStateSubtle({
  title,
  description,
  buttonLabel,
  buttonAction,
}: EmptyStateProps) {
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        //backgroundColor: "#E5E5E5",
        flexGrow: 1,
        padding: "16px",
      }}
      justifyContent="center"
      alignContent="center"
      textAlign="center"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ReceiptLongRoundedIcon
            sx={{ fontSize: { xs: 40, md: 80 }, color: "lightgray" }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ color: "#c8cbcf" }}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" sx={{ color: "#989da3" }}>
            {description}
          </Typography>
        </Grid>
        {buttonAction && buttonLabel && (
          <Grid item xs={12}>
            <AddButton
              variant="outlined"
              color="success"
              buttonLabel={buttonLabel}
              onClick={buttonAction}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
