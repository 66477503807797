import { Stack, Typography } from "@mui/material";
import gql from "graphql-tag";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import FormModal from "../../../common/form/FormModal";
import InputDate from "../../../common/input/InputDate";
import InputPasswordHidden from "../../../common/input/InputPasswordHidden";
import InputText from "../../../common/input/InputText";
import useAuthentication from "../../../hooks/useAuthentication";
import { RegisterStudentInput } from "../../../schema/globalTypes";
import {
  RegisterStudent,
  RegisterStudentVariables,
} from "../../../schema/RegisterStudent";
import SchoolDropdown from "../../school/SchoolDropdown";

interface RegisterStudentModalProps {
  open: boolean;
  handleClose: () => void;
}

const REGISTER_STUDENT = gql`
  mutation RegisterStudent($command: RegisterStudentInput!) {
    registerStudent(command: $command) {
      accessToken
      refreshToken
    }
  }
`;

function RegisterStudentModal({
  open,
  handleClose,
}: RegisterStudentModalProps) {
  const { t } = useTranslation();

  const { setToken, login } = useAuthentication();
  const history = useHistory();

  const onClose = (success: boolean, data?: RegisterStudent | null) => {
    if (data && success) {
      console.log("setting token", data);
      setToken(
        data.registerStudent.accessToken,
        data.registerStudent.refreshToken
      ).then(() => {
        handleClose();
        history.push("/");
      });
    } else {
      handleClose();
    }
  };

  const form = useForm<
    RegisterStudentInput & {
      password_repeat: string;
    }
  >();

  const password = form.watch("password");

  return (
    <FormProvider {...form}>
      <FormModal<
        RegisterStudentInput & {
          password_repeat: string;
        },
        RegisterStudent,
        RegisterStudentVariables
      >
        title={t("register-student")}
        description={t("register-student-description")}
        maxWidth="sm"
        defaultValues={undefined}
        makeVars={(command) => {
          const { password_repeat, ...rest } = command;
          return {
            command: rest,
          };
        }}
        mutation={REGISTER_STUDENT}
        open={open}
        handleClose={onClose}
        useContext
      >
        {(control) => (
          <Stack spacing={2}>
            <InputText
              control={control}
              name="firstName"
              label={t("name")}
              required
            />
            <InputText
              control={control}
              name="lastName"
              label={t("lastname")}
              required
            />
            <InputDate
              control={control}
              name="birthdate"
              label={t("birthdate")}
              required
            />
            <SchoolDropdown
              name="schoolId"
              control={control}
              label={t("choose-school")}
              required
            />
            <InputText
              control={control}
              name="email"
              label={t("email")}
              required
            />
            <InputPasswordHidden
              control={control}
              name="password"
              label={t("password")}
              required
            />
            <InputPasswordHidden
              control={control}
              name="password_repeat"
              label="Passwort wiederholen"
              required
              rules={{
                validate: (value) =>
                  value === password || "Die Passwörter stimmen nicht überein.",
              }}
            />
          </Stack>
        )}
      </FormModal>
    </FormProvider>
  );
}

export default RegisterStudentModal;
