import gql from "graphql-tag";
import { useTranslation } from "react-i18next";
import FormModal from "../../../common/form/FormModal";
import InputText from "../../../common/input/InputText";
import {
  UpdateSchool,
  UpdateSchoolVariables,
} from "../../../schema/UpdateSchool";
import { UpdateSchoolInput } from "../../../schema/globalTypes";
interface EditSchoolModalProps {
  data?: UpdateSchoolInput | null;
  open: boolean;
  schoolId: string;
  handleClose: () => void;
}

const UPDATE_SCHOOL = gql`
  mutation UpdateSchool($schoolData: UpdateSchoolInput!) {
    updateSchool(command: $schoolData) {
      id
      name
      street
      postcode
      city
    }
  }
`;

export default function EditSchoolModal({
  data,
  schoolId,
  open,
  handleClose,
}: EditSchoolModalProps) {
  const { t } = useTranslation();

  return (
    <FormModal<UpdateSchoolInput, UpdateSchool, UpdateSchoolVariables>
      title={t("edit-school-data")}
      description={t("change-school-details")}
      maxWidth="md"
      defaultValues={data || undefined}
      makeVars={(schoolData) => ({
        schoolData: { ...schoolData },
      })}
      mutation={UPDATE_SCHOOL}
      open={open}
      handleClose={handleClose}
    >
      {(control) => (
        <>
          <InputText control={control} name="name" label={t("name")} required />
          <InputText
            control={control}
            name="street"
            label={t("street")}
            required
          />
          <InputText
            control={control}
            name="postcode"
            label={t("postcode")}
            required
          />
          <InputText control={control} name="city" label={t("city")} required />
        </>
      )}
    </FormModal>
  );
}
