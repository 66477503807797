import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import React from "react";
import { useController, UseControllerProps } from "react-hook-form";

type InputCheckboxGroupProps<T> = {
  label: string;
  disabled?: boolean;
  required?: boolean;
  options?: Option[];
} & UseControllerProps<T>;

interface Option {
  label: string;
  value: string;
}

function InputCheckboxGroup<T>({
  label,
  disabled,
  required,
  defaultValue,
  options,
  ...props
}: InputCheckboxGroupProps<T>) {
  const rules = {
    required: required ? `${label} ist ein Pflichtfeld.` : undefined,
    ...props.rules,
  };

  const { field } = useController({ ...props, rules });

  let currentOptions: string[] = [];
  if (Array.isArray(field.value)) {
    currentOptions = field.value;
  }

  const setValue = (value: string, checked: boolean) => {
    if (Array.isArray(field.value)) {
      if (checked && !field.value.find((v) => v === value)) {
        field.onChange([...field.value, value]);
      }
      if (!checked && field.value.find((v) => v === value)) {
        field.onChange(field.value.filter((v) => v !== value));
      }
    }
  };

  return (
    <FormGroup row={true}>
      {options &&
        options.map((option, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                {...field}
                color="success"
                disabled={disabled}
                required={required}
                defaultChecked={
                  currentOptions.find((o) => o === option.value) !== undefined
                }
                onChange={(e) => {
                  setValue(option.value, e.target.checked);
                }}
              />
            }
            label={option.label}
          />
        ))}
    </FormGroup>
  );
}

export default InputCheckboxGroup;
