import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import useAuthentication from "../../hooks/useAuthentication";

interface SessionTimeoutNotificationProps {
  minRemainingSeconds: number;
}

const SessionTimeoutNotification = ({
  minRemainingSeconds,
}: SessionTimeoutNotificationProps) => {
  const { getToken, getRefreshToken, login, logout } = useAuthentication();
  const [isTokenLoading, setIsTokenLoading] = useState(false);

  const calcRemaing = (sessionExpiry: number) =>
    sessionExpiry + 2 * 60 - Math.round(new Date().getTime() / 1000);

  const formatRemaining = (remaining: number) =>
    format(remaining * 1000, "mm:ss");

  const remainingSeconds = calcRemaing(getRefreshToken()?.exp || 0);
  const remainingMessage = formatRemaining(remainingSeconds);

  const [remaingSeconds, setRemainingSeconds] = useState(
    2 * minRemainingSeconds
  );
  const [remainingTime, setRemainingTime] = useState(remainingMessage);

  useEffect(() => {
    const interval = setInterval(() => {
      const sessionExpiry = getRefreshToken()?.exp || 0;
      const newRemaining = calcRemaing(sessionExpiry);

      if (newRemaining > 0) {
        setRemainingSeconds(newRemaining);
        setRemainingTime(formatRemaining(newRemaining));
      } else if (remaingSeconds) {
        setRemainingSeconds(0);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [remaingSeconds, getRefreshToken]);

  const showDialog = remaingSeconds < minRemainingSeconds;
  const showRemaining = remaingSeconds > 0;

  return (
    <Dialog open={showDialog} disableEscapeKeyDown maxWidth="xs">
      <DialogTitle color="error">Sitzungsablauf</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Stack spacing={2}>
            {showRemaining ? (
              <Stack spacing={2}>
                Ihr Sitzung läuft ab in <br />
                <Typography sx={{ fontSize: 38, textAlign: "center" }}>
                  {remainingTime}
                </Typography>
                <br />
                Bitte klicken Sie auf "Weiter" um angemeldet zu bleiben oder
                melden Sie sich ab mit "Abmelden".
              </Stack>
            ) : (
              <>
                <p>Ihre Sitzung ist abgelaufen.</p>
                <p>Sie werden zur Login-Seite weitergeleitet.</p>
              </>
            )}
          </Stack>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {showRemaining ? (
          <Grid container justifyContent="flex-end" spacing={2}>
            <Grid item>
              <Button variant="outlined" color="error" onClick={logout}>
                Abmelden
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                loading={isTokenLoading}
                variant="contained"
                color="error"
                onClick={() => {
                  setIsTokenLoading(true);
                  getToken().finally(() => {
                    setIsTokenLoading(false);
                    setRemainingSeconds(minRemainingSeconds * 2);
                  });
                }}
              >
                Weiter
              </LoadingButton>
            </Grid>
          </Grid>
        ) : (
          <Button variant="outlined" color="error" onClick={login}>
            Anmelden
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default SessionTimeoutNotification;
