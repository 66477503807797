import { Button, Container, Grid, Stack, styled } from "@mui/material";
import { useHistory } from "react-router-dom";
import LoginImage from "../assets/images/logo_Schriftzug_rgb_4C_klein@2x.png";
import theme from "../assets/theme/theme";
import useAuthentication from "../hooks/useAuthentication";

const StartPage = () => {
  const { login } = useAuthentication();
  const history = useHistory();

  const register = () => {
    history.push("/registrierung");
  };

  const Img = styled("img")({
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  });

  return (
    <Container sx={{ paddingTop: theme.spacing(20) }}>
      <Stack spacing={5} sx={{ maxWidth: "1000px" }}>
        <Img alt="pflash-login" src={LoginImage}></Img>

        <Stack justifyContent="flex-end" direction="row" spacing={2}>
          <Button variant="outlined" color="secondary" onClick={register}>
            Neu Registrieren
          </Button>
          <Button variant="contained" color="secondary" onClick={login}>
            Zum Login
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
};

export default StartPage;
