import { useTranslation } from "react-i18next";
import EditButton from "../../../common/buttons/EditButton";
import useModal from "../../../hooks/useModal";
import { UpdateUserInput } from "../../../schema/globalTypes";
import EditUserModal from "../../EditUserModal";

interface EditStudentButtonProps {
  userId: string;
  data?: UpdateUserInput | null;
}

const EditStudentButton = ({ userId, data }: EditStudentButtonProps) => {
  const { t } = useTranslation();
  const { open, handleOpen, handleClose } = useModal();

  return (
    <>
      <EditButton handleOpen={handleOpen} />

      <EditUserModal
        data={data}
        userId={userId}
        title={t("edit-student-data")}
        description={t("change-student-details")}
        handleClose={handleClose}
        open={open}
      />
    </>
  );
};

export default EditStudentButton;
