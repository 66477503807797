import FeedbackIcon from "@mui/icons-material/Feedback";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
} from "@mui/material";
import { useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export default function FeedbackModal() {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div>
      <Fab
        variant="extended"
        size="medium"
        color="primary"
        aria-label="feedback"
        onClick={handleClickOpen}
      >
        <FeedbackIcon sx={{ mr: 1 }} />
        Feedback
      </Fab>
      <Dialog open={open} onClose={handleClose} fullScreen={fullScreen}>
        <DialogTitle>Feedback</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Diese Anwendung befindet sich zur Zeit noch in der Entwicklung. Um
            diese stetig zu verbessern, würden wir uns sehr über Ihre
            Rückmeldung freuen. Schicken Sie uns dazu eine E-Mail an{" "}
            <a href="mailto:feedback@pflash.de">feedback@pflash.de</a>
            <br />
            Vielen Dank!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Schließen</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
