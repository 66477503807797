import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useRef } from "react";
import { useController, UseControllerProps } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ReactSignatureCanvas from "react-signature-canvas";

type InputSignatureProps<T> = {
  label?: string;
  required?: boolean;
} & UseControllerProps<T>;

function InputSignature<T>({
  label,
  required,
  ...props
}: InputSignatureProps<T>) {
  const rules = {
    required: required ? `${label} ist ein Pflichtfeld.` : undefined,
    ...props.rules,
  };

  const { field } = useController({ ...props, rules });

  const signature = useRef<ReactSignatureCanvas | null>(null);

  const clearSignature = () => {
    signature.current?.clear();
  };

  const { t } = useTranslation();

  return (
    <>
      <Typography sx={{ paddingTop: 2, color: "rgb(0 0 0 / 60%)" }}>
        {t("signature")}
      </Typography>
      <Box sx={{ border: "1px solid lightgrey", borderRadius: 1 }}>
        <ReactSignatureCanvas
          ref={signature}
          penColor="black"
          canvasProps={{ width: 500, height: 150 }}
          onEnd={() => {
            field.onChange(signature.current?.getTrimmedCanvas().toDataURL());
          }}
        />
        <Button onClick={clearSignature}>{t("delete")}</Button>
      </Box>
    </>
  );
}

export default InputSignature;
