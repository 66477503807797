import { ChangeEvent, MouseEvent, useCallback, useMemo, useState } from "react";

interface usePaginationOptionProps {
  perPageDefault: number;
}

const usePagination = <T,>(data: T[], options?: usePaginationOptionProps) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState<number>(options?.perPageDefault || 10);
  const maxPage = useMemo(() => {
    return Math.ceil(data.length / perPage) - 1;
  }, [data, perPage]);

  function next() {
    setCurrentPage((currentPage) => Math.min(currentPage + 1, maxPage));
  }

  function prev() {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 0));
  }

  const jump = useCallback(
    (page: number) => {
      const pageNumber = Math.max(0, page);
      setCurrentPage(Math.min(pageNumber, maxPage));
    },
    [maxPage]
  );

  const handlePageChange = useCallback(
    (e: MouseEvent<HTMLButtonElement> | null, page: number) => {
      jump(page);
    },
    [jump]
  );

  const handleLimitChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newLimit = parseInt(event.target.value, 10);
      setPerPage(newLimit);
      setCurrentPage(0);
    },
    []
  );

  const paginationProps = useMemo(
    () => ({
      count: data.length,
      onPageChange: handlePageChange,
      onRowsPerPageChange: handleLimitChange,
      page: currentPage,
      rowsPerPage: perPage,
      component: "div",
      labelRowsPerPage: "Einträge pro Seite",
      rowsPerPageOptions: [5, 10, 15, 20, 25, { value: -1, label: "Alle" }],
      labelDisplayedRows: ({
        from,
        to,
        count,
      }: {
        from: number;
        to: number;
        count: number;
      }) => {
        return `${from}–${to} von ${count !== -1 ? count : `mehr als ${to}`}`;
      },
    }),
    [data, currentPage, perPage, handlePageChange, handleLimitChange]
  );

  const currentData = useMemo<typeof data>(() => {
    const begin = currentPage * perPage;
    const end = begin + perPage;
    return data.slice(begin, end);
  }, [data, currentPage, perPage]);

  return {
    next,
    prev,
    jump,
    currentData,
    currentPage,
    maxPage,
    paginationProps,
  };
};

export default usePagination;
