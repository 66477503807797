import { Box, Grid, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import FooterBackgroundImage from "../../assets/images/flash_torn3_karo-footer2.png";
import LogoFooter from "../../assets/images/logo flash6_rgb_Graustufen_klein.png";

const Img = styled("img")({
  display: "inline-block",
  maxWidth: "203px",
  maxHeight: "65px",
  textAlign: "left",
  marginLeft: 20,
});

const year = new Date().getFullYear();
const version = process.env.REACT_APP_VERSION_INFO;

const Footer = () => {
  const { t } = useTranslation();
  return (
    <Box
      alignItems="center"
      justifyContent="center"
      alignContent="space-evenly"
      textAlign="right"
      marginRight={0}
      component="footer"
      sx={{
        height: "180px",
        width: "100%",
        backgroundImage: `url(${FooterBackgroundImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",

        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            paddingTop: { xs: 5, sm: 12 },
            paddingLeft: { sm: 4 },
            textAlign: { xs: "center", sm: "left" },
          }}
        >
          <Img alt="pflash-logo-footer" src={LogoFooter} />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          textAlign="center"
          sx={{ paddingTop: { xs: 0, sm: 14 } }}
        >
          <Typography fontWeight={400} fontSize={14} color="#c8cbcf">
            Forum Pflegegesellschaft e.V., {year}
          </Typography>
          {version && (
            <Typography color="#c8cbcf">Version {version}</Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={4} sx={{ paddingTop: { xs: 0, sm: 14 } }}>
          <Typography
            component={RouterLink}
            to="/impressum-datenschutz"
            sx={{
              paddingRight: { xs: 10, sm: 6 },
              "&:hover": { color: "primary.main" },
              textDecoration: "none",
              textAlign: { xs: "center", sm: "right" },
            }}
            fontWeight={400}
            fontSize={14}
            color="#c8cbcf"
          >
            {t("impress")}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
