import { useCallback, useEffect, useState } from "react";

const useFilter = <T,>(
  data: T[] | undefined,
  searchFields: string[] | null
) => {
  const [searchValue, setSearchValue] = useState<string | undefined>();
  const [filtered, setFiltered] = useState<T[]>([]);
  const filterData = useCallback(() => {
    if (searchValue && searchFields && data) {
      setFiltered(
        data?.filter((obj: any) => {
          let found = false;
          searchFields.forEach((propertyName) => {
            let property = obj;
            let nesting = propertyName.split(".");
            nesting.forEach((prop) => {
              if (property[prop]) property = property[prop];
            });
            if (
              typeof property === "string" &&
              property.toLowerCase().includes(searchValue.toLowerCase())
            ) {
              found = true;
            } else if (
              typeof property === "number" &&
              property
                .toString()
                .toLowerCase()
                .includes(searchValue.toLowerCase())
            ) {
              found = true;
            }
          });
          return found;
        }) || []
      );
    } else if (data) {
      setFiltered(data);
    }
  }, [data, searchFields, searchValue]);

  useEffect(() => {
    filterData();
  }, [searchValue, filterData]);

  return {
    data: filtered,
    setSearchValue: setSearchValue,
  };
};

export default useFilter;
