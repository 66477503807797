import { TextField } from "@mui/material";
import React from "react";
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form";

type InputTextInnerProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = {
  match?: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  multiline?: boolean;
  rows?: string;
  maxRows?: string;
  minRows?: string;
  readOnly?: boolean;
  tooltipText?: string;
} & UseControllerProps<TFieldValues, TName>;

function InputTextInner<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  {
    label,
    match,
    required,
    autoFocus,
    multiline,
    rows,
    maxRows,
    minRows,
    readOnly,
    tooltipText,
    disabled,
    ...props
  }: InputTextInnerProps<TFieldValues, TName>,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  const rules = {
    required: required ? `${label} ist ein Pflichtfeld.` : undefined,
    minLength: {
      value: 8,
      message: "Das Passwort muss mindestens 8 Zeichen enthalten.",
    },
    ...props.rules,
  };

  const {
    field,
    fieldState: { error },
  } = useController({ ...props, rules });

  return (
    <TextField
      {...field}
      InputProps={{
        readOnly: readOnly ? true : false,
        // disableUnderline: readOnly ? true : false,
      }}
      label={label}
      fullWidth
      multiline={multiline}
      rows={rows}
      maxRows={maxRows}
      minRows={minRows}
      required={required}
      error={!!error}
      helperText={error ? error.message : ""}
      autoFocus={autoFocus}
      disabled={disabled}
      inputRef={ref}
      type="password"
    />
  );
}

const InputPasswordHidden = React.forwardRef(InputTextInner) as <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  props: InputTextInnerProps<TFieldValues, TName> & {
    ref?: React.ForwardedRef<HTMLInputElement>;
  }
) => ReturnType<typeof InputTextInner>;

export default InputPasswordHidden;
