import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useTranslation } from "react-i18next";

interface ConfirmDialogProps {
  title: string;
  open: boolean;
  message: string;
  handleConfirm: () => void;
  handleCancel: () => void;
  confirmBtnText: string;
  confirmButtonColor?: string;
  confirmButtonHover?: string;
  color?: "instructor" | "organization" | "student" | "teacher" | "error";
}

const ConfirmDialog = ({
  color,
  title,
  open,
  message,
  handleConfirm,
  handleCancel,
  confirmBtnText,
  confirmButtonColor,
  confirmButtonHover,
}: ConfirmDialogProps) => {
  const { t } = useTranslation();

  const buttonColor = color || "error";

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleCancel}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
    >
      <DialogTitle id="confirm-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="confirm-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color={buttonColor} onClick={handleCancel}>
          {t("cancel")}
        </Button>
        <Button
          onClick={handleConfirm}
          variant="contained"
          autoFocus
          color={buttonColor}
        >
          {confirmBtnText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
