import gql from "graphql-tag";
import React from "react";
import { useTranslation } from "react-i18next";
import FormModal from "../../../common/form/FormModal";
import {
  AddStudentToClass,
  AddStudentToClassVariables,
} from "../../../schema/AddStudentToClass";
import { AddStudentToClassInput } from "../../../schema/globalTypes";
import StudentDropdown from "../../student/StudentDropdown";

interface AddStudentToClassModalProps {
  data?: AddStudentToClassInput | null;
  classId: string;
  open: boolean;
  handleClose: () => void;
}

export default function AddStudentToClassModal({
  data,
  classId,
  open,
  handleClose,
}: AddStudentToClassModalProps) {
  const { t } = useTranslation();

  const ADD_STUDENT_TO_CLASS = gql`
    mutation AddStudentToClass($classData: AddStudentToClassInput!) {
      addStudentToClass(command: $classData) {
        classId
        students {
          id
          user {
            id
            firstName
            lastName
          }
        }
      }
    }
  `;

  return (
    <FormModal<
      AddStudentToClassInput,
      AddStudentToClass,
      AddStudentToClassVariables
    >
      title={`${t("add-student")}`}
      description={t("add-student-to-class")}
      defaultValues={data || undefined}
      makeVars={(classData) => ({
        classData: { ...classData, classId },
      })}
      mutation={ADD_STUDENT_TO_CLASS}
      open={open}
      handleClose={handleClose}
    >
      {(control) => (
        <StudentDropdown
          exceptClassId={classId}
          name="userId"
          control={control}
          label={`${t("add-student")}`}
        />
      )}
    </FormModal>
  );
}
