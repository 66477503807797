import { Box, CircularProgress } from "@mui/material";

const LoadingState = () => {
  return (
    <Box
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <CircularProgress />
    </Box>
  );
};
export default LoadingState;
