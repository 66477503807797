import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import Page from "../../common/layout/Page";
import StudentList from "../../components/student/StudentList";
import { GetStudents } from "../../schema/GetStudents";

export const GET_STUDENTS = gql`
  query GetStudents {
    students {
      id
      class {
        classId
        number
      }
      birthdate
      user {
        id
        firstName
        lastName
        email
        isApproved
      }
    }
  }
`;

export default function SchoolStudentsPage() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { loading, data, error } = useQuery<GetStudents>(GET_STUDENTS, {
    fetchPolicy: "cache-and-network",
  });

  if (!loading && error) {
    console.log(error);
    enqueueSnackbar(t(error.message), {
      variant: "error",
    });
  }

  return (
    <Page
      title={t("student")}
      pageHeading={t("manage-students")}
      subHeading={t("here-you-find-an-overview-of-all-schools-students")}
    >
      <StudentList loading={loading} students={data?.students} canModify />
    </Page>
  );
}
