import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import DeleteButton from "../../../common/buttons/DeleteButton";
import ConfirmDialog from "../../../common/form/ConfirmDialog";
import { GET_INSTRUCTORS } from "../../../pages/school/Instructors";
import {
  DeleteInstructor,
  DeleteInstructorVariables,
} from "../../../schema/DeleteInstructor";
import { GetInstructors } from "../../../schema/GetInstructors";

interface DeleteInstructorButtonProps {
  userId: string;
}

const DELETE_INSTRUCTOR = gql`
  mutation DeleteInstructor($instructorId: ID!) {
    deleteInstructor(instructorId: $instructorId)
  }
`;

const DeleteInstructorButton = ({
  userId: instructorId,
}: DeleteInstructorButtonProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [deleteInstructor] = useMutation<
    DeleteInstructor,
    DeleteInstructorVariables
  >(DELETE_INSTRUCTOR, {
    update(cache, { data }) {
      const getinstructorsData = cache.readQuery<GetInstructors>({
        query: GET_INSTRUCTORS,
      });

      const instructors = (getinstructorsData?.instructors || []).filter(
        (x) => x.userId !== instructorId
      );

      cache.writeQuery<GetInstructors>({
        query: GET_INSTRUCTORS,
        data: { instructors },
      });
    },
  });

  const [showConfirmation, setShowConfirmation] = useState(false);

  const deleteBtnHandler = () => {
    setShowConfirmation(true);
  };

  const { t } = useTranslation();

  const handleCancelHandler = () => {
    setShowConfirmation(false);
  };

  const handleConfirmHandler = async () => {
    try {
      await deleteInstructor({
        variables: {
          instructorId,
        },
      });
      enqueueSnackbar(`${t("instructor-successfully-deleted")}`, {
        variant: "success",
      });
    } catch (e) {
      const error = e as Error;
      enqueueSnackbar(error.message, { variant: "error" });
    }
    setShowConfirmation(false);
  };

  return (
    <>
      <DeleteButton onClick={deleteBtnHandler} />
      <ConfirmDialog
        title={t("delete-instructor")}
        open={showConfirmation}
        message={t("really-delete-instructor")}
        handleConfirm={handleConfirmHandler}
        handleCancel={handleCancelHandler}
        confirmBtnText={t("delete")}
      />
    </>
  );
};

export default DeleteInstructorButton;
