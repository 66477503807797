import { Route, Switch } from "react-router-dom";
import Guarded from "../common/auth/Guarded";
import NotFound from "../pages/NotFound";
import Class from "../pages/teacher/Class";
import Classes from "../pages/teacher/Classes";
import PracticalPage from "../pages/teacher/PracticalPage";
import PracticalVisit from "../pages/teacher/PracticalVisit";
import PracticalVisits from "../pages/teacher/PracticalVisits";
import Student from "../pages/teacher/Student";
import TeacherStudentsPage from "../pages/teacher/TeacherStudentsPage";
import { Role } from "../schema/globalTypes";

const TeacherRoutes = () => {
  return (
    <Guarded requiredRole={Role.TEACHER}>
      <Switch>
        <Route path="/ausbildung/kurse" exact>
          <Classes />
        </Route>
        <Route
          path="/ausbildung/kurse/:id"
          render={(props) => <Class classId={props.match.params.id} />}
        />
        <Route
          path="/ausbildung/auszubildende/:studentId/praxiseinsaetze/:einsatzId"
          exact
          render={(props) => (
            <PracticalPage
              studentId={props.match.params.studentId}
              practicalId={props.match.params.einsatzId}
            />
          )}
        />
        <Route path="/ausbildung/auszubildende" exact>
          <TeacherStudentsPage />
        </Route>
        <Route
          path="/ausbildung/auszubildende/:id"
          render={(props) => <Student studentId={props.match.params.id} />}
        />
        <Route path="/ausbildung/praxisbegleitungen" exact>
          <PracticalVisits />
        </Route>
        <Route
          path="/ausbildung/praxisbegleitungen/:einsatzId"
          exact
          render={(props) => (
            <PracticalVisit practicalId={props.match.params.einsatzId} />
          )}
        ></Route>
        <Route path="">
          <NotFound />
        </Route>
      </Switch>
    </Guarded>
  );
};

export default TeacherRoutes;
