import { gql, useMutation } from "@apollo/client";
import { STUDENT_PRACTICAL } from "../../fragments/student";
import { Role } from "../../schema/globalTypes";
import {
  RejectInterviewProtocolTeacher,
  RejectInterviewProtocolTeacherVariables,
} from "../../schema/RejectInterviewProtocolTeacher";

import ApproveButton from "./ApproveButton";

interface RejectButtonInterviewProtocolTeacherProps {
  canApprove: boolean;
  role: Role;
  protocolId: string;
}

const REJECT_INTERVIEW_PROTOCOL_TEACHER = gql`
  ${STUDENT_PRACTICAL}
  mutation RejectInterviewProtocolTeacher($interviewProtocolId: ID!) {
    rejectInterviewProtocolTeacher(
      interviewProtocolTeacherId: $interviewProtocolId
    ) {
      ...Practical
    }
  }
`;

const RejectButtonInterviewProtocolTeacher = ({
  canApprove,
  role,
  protocolId,
}: RejectButtonInterviewProtocolTeacherProps) => {
  const [mutate] = useMutation<
    RejectInterviewProtocolTeacher,
    RejectInterviewProtocolTeacherVariables
  >(REJECT_INTERVIEW_PROTOCOL_TEACHER);

  const handleApprove = async () => {
    return mutate({ variables: { interviewProtocolId: protocolId } }).then(
      () => {
        return;
      }
    );
  };

  return (
    <ApproveButton
      canApprove={canApprove}
      handleApprove={handleApprove}
      role={role}
      cancel
    />
  );
};

export default RejectButtonInterviewProtocolTeacher;
