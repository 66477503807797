import { useQuery } from "@apollo/client";
import { Grid, Skeleton, Typography } from "@mui/material";
import gql from "graphql-tag";
import { useTranslation } from "react-i18next";
import EditButton from "../../common/buttons/EditButton";
import DisplayDate from "../../common/display/DisplayDate";
import TextBlock from "../../common/display/TextBlock";
import TextLine from "../../common/display/TextLine";
import useModal from "../../hooks/useModal";
import {
  GetCurrentClass,
  GetCurrentClassVariables,
} from "../../schema/GetCurrentClass";
import FormSection from "../FormSection";
import EditClassDetailsModal from "./modals/EditClassDetailsModal";

interface ClassProfileProps {
  classId: string;
}

export const GET_CURRENT_CLASS = gql`
  query GetCurrentClass($classId: ID!) {
    class(id: $classId) {
      classId
      number
      startDate
      endDate
      students {
        id
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

const ClassProfile = ({ classId }: ClassProfileProps) => {
  const { data } = useQuery<GetCurrentClass, GetCurrentClassVariables>(
    GET_CURRENT_CLASS,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      variables: { classId },
    }
  );
  const { open, handleOpen, handleClose } = useModal();
  const { t } = useTranslation();

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} xl={6} alignItems="flex-start">
        {data ? (
          <>
            <FormSection
              sectionTitle={t("class-details")}
              sectionAction={<EditButton handleOpen={handleOpen} />}
              headerBackgroundColor="#ededed"
            >
              {data ? (
                <>
                  <TextBlock label={t("class-number")}>
                    <TextLine value={data ? `${data.class.number}` : ""} />
                  </TextBlock>
                  <TextBlock label={t("start-date")}>
                    <DisplayDate>
                      {data ? data.class.startDate : ""}
                    </DisplayDate>
                  </TextBlock>
                  <TextBlock label={t("end-date")}>
                    <DisplayDate>{data ? data.class.endDate : ""}</DisplayDate>
                  </TextBlock>
                </>
              ) : (
                <Typography
                  color="#c8cbcf"
                  textAlign="center"
                  justifyItems="center"
                >
                  {t("no-data")}
                </Typography>
              )}
            </FormSection>
            <EditClassDetailsModal
              data={{
                startDate: data.class.startDate,
                endDate: data.class.endDate,
                classId: data.class.classId,
                classNumber: data.class.number,
              }}
              classId={data.class.classId}
              open={open}
              handleClose={handleClose}
            />
          </>
        ) : (
          <Skeleton variant="rectangular" height={200} />
        )}
      </Grid>
    </Grid>
  );
};

export default ClassProfile;
