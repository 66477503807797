import { Stack } from "@mui/material";
import gql from "graphql-tag";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import FormModal from "../../../common/form/FormModal";
import InputPassword from "../../../common/input/InputPassword";
import InputPasswordHidden from "../../../common/input/InputPasswordHidden";
import InputText from "../../../common/input/InputText";
import useAuthentication from "../../../hooks/useAuthentication";
import { RegisterOrganizationInput } from "../../../schema/globalTypes";
import {
  RegisterOrganization,
  RegisterOrganizationVariables,
} from "../../../schema/RegisterOrganization";
import SchoolDropdown from "../../school/SchoolDropdown";

interface RegisterOrganizationModalProps {
  open: boolean;
  handleClose: () => void;
}

const REGISTER_ORGANIZATION = gql`
  mutation RegisterOrganization($command: RegisterOrganizationInput!) {
    registerOrganization(command: $command) {
      accessToken
      refreshToken
    }
  }
`;

function RegisterOrganizationModal({
  open,
  handleClose,
}: RegisterOrganizationModalProps) {
  const { t } = useTranslation();

  const { setToken, login } = useAuthentication();
  const history = useHistory();

  const onClose = (success: boolean, data?: RegisterOrganization | null) => {
    if (data && success) {
      console.log("setting token", data);
      setToken(
        data.registerOrganization.accessToken,
        data.registerOrganization.refreshToken
      ).then(() => {
        handleClose();
        history.push("/");
      });
    } else {
      handleClose();
    }
  };

  const form = useForm<
    RegisterOrganizationInput & {
      confirmation: boolean;
      password_repeat: string;
    }
  >();

  const password = form.watch("password");

  return (
    <FormProvider {...form}>
      <FormModal<
        RegisterOrganizationInput & {
          confirmation: boolean;
          password_repeat: string;
        },
        RegisterOrganization,
        RegisterOrganizationVariables
      >
        title={t("register-organization")}
        description={t("register-organization-description")}
        maxWidth="sm"
        defaultValues={undefined}
        makeVars={(command) => {
          const { confirmation, password_repeat, ...rest } = command;
          return {
            command: rest,
          };
        }}
        mutation={REGISTER_ORGANIZATION}
        open={open}
        handleClose={onClose}
        useContext
      >
        {(control) => (
          <Stack spacing={2}>
            <InputText
              control={control}
              name="organizationName"
              label={t("organization")}
              required
            />
            <InputText
              control={control}
              name="street"
              label={t("street")}
              required
            />
            <InputText
              control={control}
              name="streetNumber"
              label={t("house-number")}
              required
            />
            <InputText
              control={control}
              name="postCode"
              label={t("postcode")}
              required
            />
            <InputText
              control={control}
              name="city"
              label={t("city")}
              required
            />
            <InputText
              control={control}
              name="email"
              label={t("email")}
              required
            />
            <InputPasswordHidden
              control={control}
              name="password"
              label={t("password")}
              required
            />
            <InputPasswordHidden
              control={control}
              name="password_repeat"
              label="Passwort wiederholen"
              required
              rules={{
                validate: (value) =>
                  value === password || "Die Passwörter stimmen nicht überein.",
              }}
            />
          </Stack>
        )}
      </FormModal>
    </FormProvider>
  );
}

export default RegisterOrganizationModal;
