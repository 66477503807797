import { useQuery } from "@apollo/client";
import { Grid } from "@mui/material";
import gql from "graphql-tag";
import { useTranslation } from "react-i18next";
import Page from "../../common/layout/Page";
import FormSectionSchoolData from "../../components/school/sections/FormSectionSchoolData";
import { GetSchool, GetSchoolVariables } from "../../schema/GetSchool";

const GET_SCHOOL = gql`
  query GetSchool($id: ID!) {
    school(id: $id) {
      id
      name
      street
      postcode
      city
    }
  }
`;

function School() {
  const { t } = useTranslation();

  const { loading, data } = useQuery<GetSchool, GetSchoolVariables>(
    GET_SCHOOL,
    { variables: { id: "1" }, fetchPolicy: "cache-and-network" }
  );

  return (
    <Page
      title={t("school")}
      pageHeading={t("manage-school")}
      subHeading={t("edit-school-information")}
    >
      <Grid container>
        <Grid item xs={12} md={8}>
          <FormSectionSchoolData data={data} />
        </Grid>
      </Grid>
    </Page>
  );
}
export default School;
