import { Typography } from "@mui/material";

interface LabelProps {
  label: string;
  marginTop?: number;
  color?: string;
}

const Label = ({ label, marginTop, color }: LabelProps) => {
  return (
    <Typography
      marginTop={marginTop}
      sx={{
        fontWeight: 300,
        fontSize: 13,
        color: color || "#666e78",
        // textTransform: "uppercase",
        // lineHeight: 2,
      }}
    >
      {label}
    </Typography>
  );
};

export default Label;
