import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { UseControllerProps } from "react-hook-form";
import InputDropdown from "../../common/input/InputDropdown";
import { GetOrganizationsList } from "../../schema/GetOrganizationsList";

type InputDropdownProps<T> = {
  onSelectOption?: (id?: string) => void;
  filterByInstructorId?: boolean;
  instructorId?: string;
  label?: string;
  required?: boolean;
  autoFocus?: boolean;
  exceptClassId?: string;
} & UseControllerProps<T>;

function OrganizationDropdown<T>({
  onSelectOption,
  filterByInstructorId,
  instructorId,
  exceptClassId,
  ...props
}: InputDropdownProps<T>) {
  const GET_ORGANIZATIONS_LIST = gql`
    query GetOrganizationsList {
      organizations {
        userId
        user {
          id
          fullName
        }
      }
    }
  `;

  const { data } = useQuery<GetOrganizationsList>(GET_ORGANIZATIONS_LIST, {
    fetchPolicy: "cache-and-network",
  });

  if (!data) {
    return <></>;
  }

  const options = data.organizations.map((o) => ({
    id: o.user.id,
    label: o.user.fullName,
  }));

  return (
    <InputDropdown
      onSelectOption={onSelectOption}
      options={options}
      {...props}
    />
  );
}

export default OrganizationDropdown;
