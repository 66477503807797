import { Container, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import React from "react";
import { Helmet } from "react-helmet-async";
import Breadcrumb, { BreadcrumbEntry } from "./Breadcrumb";
import FlashImage from "../../assets/images/flash.png";

interface PageProps {
  title: string;
  children: React.ReactNode;
  pageHeading?: string;
  subHeading?: string;
  breadcrumb?: BreadcrumbEntry[];
}

const Page: React.FC<PageProps> = ({
  children,
  title,
  pageHeading,
  subHeading,
  breadcrumb,
}) => {
  const theme = useTheme();

  return (
    <>
      <Helmet>
        <title>{title} | Digitales Ausbildungsheft</title>
      </Helmet>
      <Container>
        <Breadcrumb entries={breadcrumb || []} />
        <Box
          marginLeft={-2}
          marginBottom={4}
          display="flex"
          sx={{
            backgroundImage: 'url("/header.png")',
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            display: "flex",
            alignItems: "center",
            // display: "flex",
            // alignItems: "center",
            // justifyContent: "space-between",
            maxWidth: "928px",
            // minHeight: "138px",
          }}
        >
          <Box
            display="flex"
            flex={1}
            alignItems="center"
            padding={5}
            sx={{
              // backgroundImage: 'url("/header.png")',
              // backgroundSize: "cover",
              // backgroundRepeat: "no-repeat",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              position: "relative",
              // marginY: 5,
              // maxWidth: "928px",
              // minHeight: "138px",
            }}
          >
            <Typography
              sx={{
                fontSize: "3rem",
                margin: 0,
                fontFamily: "'Fredericka the Great'",
                color: "#5A8497",
                fontWeight: "400",
              }}
              variant="h3"
              component="h1"
              gutterBottom
            >
              {pageHeading}
            </Typography>
            {/* <img
              style={{ position: "absolute", top: "-25px", right: "-25px" }}
              src={FlashImage}
            ></img> */}
          </Box>
          <Box
            sx={{
              backgroundColor: "transparent",
              position: "relative",
              height: 0,
              // marginLeft: "-60px",
            }}
          >
            <img
              style={{ position: "absolute", top: "-94px", right: "-27px" }}
              src={FlashImage}
            ></img>
          </Box>
        </Box>
        <Typography variant="subtitle1" gutterBottom>
          {subHeading}
        </Typography>
        <Box
          sx={{
            my: {
              xs: theme.spacing(3),
              md: theme.spacing(5),
              xl: theme.spacing(7),
            },
          }}
          justifyContent="center"
        >
          {children}
        </Box>
      </Container>
    </>
  );
};

export default Page;
