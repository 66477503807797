import { useController, UseControllerProps } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./InputRichText.css";

type InputTextProps<T> = {
  label: string;
  required?: boolean;
  autoFocus?: boolean;
  multiline?: boolean;
  readOnly?: boolean;
} & UseControllerProps<T>;

function InputRichText<T>({
  label,
  required,
  autoFocus,
  multiline,
  readOnly,
  ...props
}: InputTextProps<T>) {
  const rules = {
    required: required ? `${label} ist ein Pflichtfeld.` : undefined,
    ...props.rules,
  };

  const { field } = useController({ ...props, rules });

  return <ReactQuill theme="snow" {...field} value={`${field.value}`} />;
}

export default InputRichText;
