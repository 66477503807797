import { Box, Stack, TablePagination } from "@mui/material";
import { ChangeEvent, MouseEvent } from "react";
import LoadingState from "./layout/LoadingState";
import SearchInput from "./SearchInput";

interface PaginationProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (e: MouseEvent<HTMLButtonElement> | null, page: number) => void;
  onRowsPerPageChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

interface PaginatedSeachBarProps {
  loading: boolean;
  setSearchValue: (value: string) => void;
  paginationProps: PaginationProps;
}

const labelDisplayedRows = ({
  from,
  to,
  count,
}: {
  from: number;
  to: number;
  count: number;
}) => {
  return `${from}–${to} von ${count !== -1 ? count : `mehr als ${to}`}`;
};

const PaginatedSeachBar = ({
  loading,
  paginationProps,
  setSearchValue,
}: PaginatedSeachBarProps) => (
  <Box display="flex" alignItems="center" justifyContent="space-between">
    <Box marginBottom={2} marginLeft={1}>
      <SearchInput onChange={setSearchValue} />
    </Box>
    <Stack direction="row">
      {loading && <LoadingState />}
      <TablePagination
        labelRowsPerPage="Einträge pro Seite"
        rowsPerPageOptions={[5, 10, 15, 20, 25, { value: -1, label: "Alle" }]}
        labelDisplayedRows={labelDisplayedRows}
        component="div"
        {...paginationProps}
      />
    </Stack>
  </Box>
);

export default PaginatedSeachBar;
