import { Grid, Stack, Typography } from "@mui/material";
import gql from "graphql-tag";
import { useEffect, useRef } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FormModal from "../../../common/form/FormModal";
import InputRadio from "../../../common/input/InputRadio";
import InputText from "../../../common/input/InputText";
import InputToggleButton from "../../../common/input/InputToggleButton";
import { STUDENT_PRACTICAL } from "../../../fragments/student";
import {
  PracticalLocationInput,
  PracticalLocationType,
} from "../../../schema/globalTypes";
import {
  UpdatePracticalLocation,
  UpdatePracticalLocationVariables,
} from "../../../schema/UpdatePracticalLocation";

interface EditPracticalLocationModalProps {
  data?: PracticalLocationInput | null;
  id: string;
  open: boolean;
  handleClose: () => void;
}

export default function EditPracticalLocationModal({
  data,
  id,
  open,
  handleClose,
}: EditPracticalLocationModalProps) {
  const UPDATE_PRACTICAL_LOCATION = gql`
    ${STUDENT_PRACTICAL}
    mutation UpdatePracticalLocation(
      $practicalLocation: PracticalLocationInput!
    ) {
      updatePracticalLocation(command: $practicalLocation) {
        ...Practical
      }
    }
  `;

  const form = useForm<PracticalLocationInput>();
  const { reset, watch } = form;
  const { t } = useTranslation();
  const toggleOptions = [
    { label: `${t("yes")}`, value: true },
    { label: `${t("no")}`, value: false },
  ];
  const radioOptions = [
    { label: `${t("living-area")}`, value: PracticalLocationType.LIVING_AREA },
    { label: `${t("ward")}`, value: PracticalLocationType.WARD },
  ];

  const disableOtherInstitution = watch("isProvider");
  const name = watch("name");
  const street = watch("street");
  const housenumber = watch("housenumber");
  const postcode = watch("postcode");
  const city = watch("city");

  const inputRefOtherInstitutionName = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const initialData = data || {
      id,
      isProvider: true,
      name: "",
      street: "",
      housenumber: "",
      postcode: "",
      city: "",
      locationType: undefined,
      locationTypeDescription: "",
      specialization: "",
    };
    reset(initialData);
  }, [data, id, reset, open]);

  useEffect(() => {
    if (disableOtherInstitution === true && name !== "") {
      form.setValue("name", "");
      form.setValue("street", "");
      form.setValue("housenumber", "");
      form.setValue("postcode", "");
      form.setValue("city", "");
    }
  }, [
    disableOtherInstitution,
    name,
    street,
    housenumber,
    postcode,
    city,
    form,
  ]);

  useEffect(() => {
    if (!disableOtherInstitution) {
      inputRefOtherInstitutionName.current?.focus();
    }
  }, [disableOtherInstitution]);

  return (
    <FormProvider {...form}>
      <FormModal<
        PracticalLocationInput,
        UpdatePracticalLocation,
        UpdatePracticalLocationVariables
      >
        title={t("edit-institution")}
        description={t("change-the-data-of-the-institution")}
        maxWidth="sm"
        defaultValues={data || undefined}
        mutation={UPDATE_PRACTICAL_LOCATION}
        open={open}
        handleClose={handleClose}
        makeVars={(practicalLocation) => ({
          practicalLocation: { ...practicalLocation, id },
        })}
        useContext
      >
        {(control) => (
          <Grid container spacing={2} paddingTop={4}>
            <Grid item xs={12} sm={6}>
              <Typography color="primary" variant="subtitle2">
                {t("provider-of-practical-training")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputToggleButton
                exclusive
                color="primary"
                options={toggleOptions}
                name="isProvider"
                control={control}
                defaultValue={data?.isProvider || false}
              />
            </Grid>
            <Grid item xs={12}>
              <InputText
                control={control}
                name="name"
                label={t("institution-name")}
                disabled={disableOtherInstitution}
                ref={inputRefOtherInstitutionName}
                defaultValue={data?.name}
              />
              <InputText
                control={control}
                name="street"
                label={t("street")}
                disabled={disableOtherInstitution}
                defaultValue={data?.street}
              />
              <InputText
                control={control}
                name="housenumber"
                label={t("house-number")}
                disabled={disableOtherInstitution}
                defaultValue={data?.housenumber}
              />
              <InputText
                control={control}
                name="postcode"
                label={t("postcode")}
                disabled={disableOtherInstitution}
                defaultValue={data?.postcode}
              />
              <InputText
                control={control}
                name="city"
                label={t("city")}
                disabled={disableOtherInstitution}
                defaultValue={data?.city}
              />
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" alignItems="center">
                <InputRadio
                  control={control}
                  name="locationType"
                  options={radioOptions}
                  defaultValue={
                    data?.locationType || PracticalLocationType.LIVING_AREA
                  }
                />
                <InputText
                  control={control}
                  name="locationTypeDescription"
                  label={t("living-area-or-ward")}
                  defaultValue={data?.locationTypeDescription}
                />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <InputText
                control={control}
                name="specialization"
                label={t("specialization")}
                defaultValue={data?.specialization}
              />
            </Grid>
          </Grid>
        )}
      </FormModal>
    </FormProvider>
  );
}
