import { CircularProgress, Grid } from "@mui/material";

const SplashScreen = () => (
  <Grid
    container
    alignItems="center"
    justifyContent="center"
    sx={{ height: "100vh" }}
  >
    <Grid item>
      <CircularProgress />
    </Grid>
  </Grid>
);

export default SplashScreen;
