import { UseControllerProps } from "react-hook-form";
import { useTranslation } from "react-i18next";
import InputDropdown from "../../common/input/InputDropdown";
import { PracticalType } from "../../schema/globalTypes";

type InputDropdownProps<T> = {
  label?: string;
  required?: boolean;
  autoFocus?: boolean;
  options?: Options[];
} & UseControllerProps<T>;

interface Options {
  label: string;
  id: string;
}

function PracticalTypeDropdown<T>({
  options,
  ...props
}: InputDropdownProps<T>) {
  const { t } = useTranslation();
  const radioOptionsPractical = [
    {
      label: `${t("practicalType.ORIENTATION")}`,
      id: PracticalType.ORIENTATION,
    },
    {
      label: `${t("practicalType.OBLIGATORY1")}`,
      id: PracticalType.OBLIGATORY1,
    },
    {
      label: `${t("practicalType.OBLIGATORY2")}`,
      id: PracticalType.OBLIGATORY2,
    },
    {
      label: `${t("practicalType.OBLIGATORY3")}`,
      id: PracticalType.OBLIGATORY3,
    },
    {
      label: `${t("practicalType.OBLIGATORY4")}`,
      id: PracticalType.OBLIGATORY4,
    },
    {
      label: `${t("practicalType.OBLIGATORY5")}`,
      id: PracticalType.OBLIGATORY5,
    },
    {
      label: `${t("practicalType.ADDITIONAL1")}`,
      id: PracticalType.ADDITIONAL1,
    },
    {
      label: `${t("practicalType.ADDITIONAL2")}`,
      id: PracticalType.ADDITIONAL2,
    },
    {
      label: `${t("practicalType.ADDITIONAL3")}`,
      id: PracticalType.ADDITIONAL3,
    },
  ];

  return <InputDropdown options={radioOptionsPractical} {...props} />;
}

export default PracticalTypeDropdown;
