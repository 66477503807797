import { gql } from "@apollo/client";

export const META = gql`
  fragment Meta on Meta {
    createdAt
    createdBy {
      id
      email
      firstName
      lastName
    }
  }
`;
