import { Typography } from "@mui/material";

interface TextLineProps {
  value?: string | null;

  variant?:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "subtitle1"
    | "subtitle2"
    | "body1"
    | "body2"
    | "caption"
    | "button"
    | "overline"
    | "inherit"
    | "sectionFooter"
    | undefined;
}

const TextLine = ({ value, variant }: TextLineProps) =>
  (value && <Typography variant={variant}>{value}</Typography>) || <></>;

export default TextLine;
