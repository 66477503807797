import { Route, Switch } from "react-router-dom";
import Guarded from "../common/auth/Guarded";
import NotFound from "../pages/NotFound";
import PracticalVisits from "../pages/organization/PracticalVisits";
import { Role } from "../schema/globalTypes";
import PracticalVisit from "../pages/organization/PracticalVisit";

const OrganizationRoutes = () => {
  return (
    <Guarded requiredRole={Role.ORGANIZATION}>
      <Switch>
        <Route path="/betriebe/praxisnachweise" exact>
          <PracticalVisits />
        </Route>
        <Route
          path="/betriebe/praxisnachweise/:einsatzId"
          exact
          render={(props) => (
            <PracticalVisit practicalId={props.match.params.einsatzId} />
          )}
        ></Route>

        <Route path="">
          <NotFound />
        </Route>
      </Switch>
    </Guarded>
  );
};

export default OrganizationRoutes;
