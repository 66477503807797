import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import DeleteButton from "../../../common/buttons/DeleteButton";
import ConfirmDialog from "../../../common/form/ConfirmDialog";
import {
  DeletePractical,
  DeletePracticalVariables,
} from "../../../schema/DeletePractical";

interface DeletePracticalButtonProps {
  practicalId: string;
}

const DELETE_PRACTICAL = gql`
  mutation DeletePractical($practicalId: ID!) {
    deletePractical(practicalId: $practicalId) {
      id
      practicals {
        practicalType
      }
    }
  }
`;

const DeletePracticalButton = ({ practicalId }: DeletePracticalButtonProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [deletePractical] = useMutation<
    DeletePractical,
    DeletePracticalVariables
  >(DELETE_PRACTICAL);

  const [showConfirmation, setShowConfirmation] = useState(false);

  const deleteBtnHandler = () => {
    setShowConfirmation(true);
  };

  const handleConfirmHandler = async () => {
    try {
      await deletePractical({
        variables: {
          practicalId: practicalId,
        },
      });
      enqueueSnackbar(`${t("practical-successfully-deleted")}`, {
        variant: "success",
      });
    } catch (e) {
      const error = e as Error;
      enqueueSnackbar(error.message, { variant: "error" });
    }
    setShowConfirmation(false);
  };

  const handleCancelHandler = () => {
    setShowConfirmation(false);
  };

  const { t } = useTranslation();

  return (
    <>
      <DeleteButton onClick={deleteBtnHandler} />
      <ConfirmDialog
        title={t("delete-practical")}
        open={showConfirmation}
        message={t("really-delete-practical")}
        handleConfirm={handleConfirmHandler}
        handleCancel={handleCancelHandler}
        confirmBtnText={t("delete")}
      />
    </>
  );
};

export default DeletePracticalButton;
