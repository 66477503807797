import { gql, useMutation } from "@apollo/client";
import { STUDENT_PRACTICAL } from "../../fragments/student";
import { Role } from "../../schema/globalTypes";
import {
  SignInterviewProtocolTeacher,
  SignInterviewProtocolTeacherVariables,
} from "../../schema/signInterviewProtocolTeacher";

import ApproveButton from "./ApproveButton";

interface ApproveButtonInterviewProtocolTeacherProps {
  canApprove: boolean;
  role: Role;
  protocolId: string;
}

const SIGN_INTERVIEW_PROTOCOL_TEACHER = gql`
  ${STUDENT_PRACTICAL}
  mutation SignInterviewProtocolTeacher($interviewProtocolId: ID!) {
    signInterviewProtocolTeacher(
      interviewProtocolTeacherId: $interviewProtocolId
    ) {
      ...Practical
    }
  }
`;

const ApproveButtonInterviewProtocolTeacher = ({
  canApprove,
  role,
  protocolId,
}: ApproveButtonInterviewProtocolTeacherProps) => {
  const [mutate] = useMutation<
    SignInterviewProtocolTeacher,
    SignInterviewProtocolTeacherVariables
  >(SIGN_INTERVIEW_PROTOCOL_TEACHER);

  const handleApprove = async () => {
    return mutate({ variables: { interviewProtocolId: protocolId } }).then(
      () => {
        return;
      }
    );
  };

  return (
    <ApproveButton
      canApprove={canApprove}
      handleApprove={handleApprove}
      role={role}
    />
  );
};

export default ApproveButtonInterviewProtocolTeacher;
