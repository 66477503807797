import { ApolloError } from "@apollo/client";
import ApprovalIcon from "@mui/icons-material/Approval";
import { Button } from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ConfirmDialog from "../../common/form/ConfirmDialog";
import useCurrentUser from "../../hooks/useCurrentUser";
import { Role } from "../../schema/globalTypes";

interface ApproveButtonProps {
  approvalRequest?: boolean | null;
  canApprove: boolean;
  cancel?: boolean;
  role: Role;
  handleApprove: () => Promise<void>;
}

const ApproveButton = ({
  approvalRequest,
  canApprove,
  cancel,
  role,
  handleApprove,
}: ApproveButtonProps) => {
  const { t } = useTranslation();
  const { user } = useCurrentUser();
  const disabled = !canApprove || !user?.roles.includes(role);
  let roleString: "instructor" | "organization" | "teacher" | "student" | null =
    null;
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  switch (role) {
    case Role.INSTRUCTOR:
      roleString = "instructor";
      break;
    case Role.ORGANIZATION:
      roleString = "organization";
      break;
    case Role.STUDENT:
      roleString = "student";
      break;
    case Role.TEACHER:
      roleString = "teacher";
      break;
  }

  if (!roleString) {
    return <></>;
  }

  const handleApproveSafe = () => {
    try {
      handleApprove()
        .then(() => {
          setShowConfirmation(false);
          enqueueSnackbar("Erfolgreich gespeichert.", { variant: "success" });
        })
        .catch((ex) => {
          console.error(ex);
          const message =
            ex instanceof ApolloError
              ? ex.message
              : "Ein unbekannter Fehler ist aufgetreten.";
          enqueueSnackbar(message, {
            variant: "error",
          });
        });
    } catch (ex) {
      console.error(ex);
      enqueueSnackbar("Ein unbekannter Fehler ist aufgetreten", {
        variant: "error",
      });
    }
  };

  return (
    <>
      <ConfirmDialog
        open={showConfirmation}
        confirmBtnText={
          approvalRequest
            ? t("request-approve-now")
            : cancel
            ? t("reject-now")
            : t("approve-now")
        }
        handleCancel={() => setShowConfirmation(false)}
        handleConfirm={handleApproveSafe}
        message={
          approvalRequest
            ? t("request-approve-confirmation-message")
            : cancel
            ? t("reject-approve-confirmation-message")
            : t("approve-confirmation-message")
        }
        title={
          approvalRequest
            ? t("request-approve-confirmation-title")
            : cancel
            ? t("reject-approve-confirmation-title")
            : t("approve-confirmation-title")
        }
        color={cancel ? "error" : roleString}
      />
      <Button
        startIcon={<ApprovalIcon />}
        color={cancel ? "error" : roleString}
        variant="outlined"
        disabled={disabled}
        onClick={() => setShowConfirmation(true)}
      >
        {approvalRequest
          ? t("approval-request")
          : cancel
          ? t("reject-approval")
          : t("approval-" + roleString)}
      </Button>
    </>
  );
};

export default ApproveButton;
