import { Alert, Box, Grid } from "@mui/material";
import gql from "graphql-tag";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FormModal from "../../../common/form/FormModal";
import InputDate from "../../../common/input/InputDate";
import InputRadio from "../../../common/input/InputRadio";
import InputSwitch from "../../../common/input/InputSwitch";
import { STUDENT_SPECIALIZATION } from "../../../fragments/student";
import { SpecializationType } from "../../../schema/globalTypes";
import { Specialization } from "../../../schema/Specialization";
import {
  UpdateSpecialization,
  UpdateSpecializationVariables,
} from "../../../schema/UpdateSpecialization";

interface EditSpecializationChoiceModalProps {
  data?: Specialization | null;
  studentId: string;
  open: boolean;
  handleClose: () => void;
}

const UPDATE_SPECIALIZATION = gql`
  ${STUDENT_SPECIALIZATION}
  mutation UpdateSpecialization(
    $specialization: SpecializationInput!
    $studentId: ID!
  ) {
    updateSpecialization(command: $specialization, studentId: $studentId) {
      id
      specialization {
        ...Specialization
      }
    }
  }
`;

interface FormProps {
  useSpecialization: boolean;
  specialization?: SpecializationType;
  choiceMade?: string;
  contractUpdated?: string;
}

const EditSpecializationChoiceModal = ({
  data,
  studentId,
  open,
  handleClose,
}: EditSpecializationChoiceModalProps) => {
  const { t } = useTranslation();
  const radioOptions = [
    {
      label: `${t("specializationType.CHILDREN")}`,
      value: SpecializationType.CHILDREN,
    },
    {
      label: `${t("specializationType.ELDERLY")}`,
      value: SpecializationType.ELDERLY,
    },
  ];

  const formToBackend: (
    formData: FormProps
  ) => UpdateSpecializationVariables = (formData) => {
    return {
      specialization: {
        choiceMade: formData.useSpecialization ? formData.choiceMade : null,
        contractUpdated: formData.useSpecialization
          ? formData.contractUpdated
          : null,
        specialization: formData.useSpecialization
          ? formData.specialization || SpecializationType.NONE
          : SpecializationType.NONE,
      },
      studentId,
    };
  };

  const backendToForm: (specialization: Specialization) => FormProps = (
    specialization
  ) => ({
    useSpecialization:
      specialization?.response?.specialization?.specialization !==
      SpecializationType.NONE
        ? true
        : false,
    specialization:
      specialization?.response?.specialization?.specialization ?? undefined,
    choiceMade:
      specialization?.response?.specialization?.choiceMade ?? undefined,
    contractUpdated:
      specialization?.response?.specialization?.contractUpdated ?? undefined,
  });

  const form = useForm<FormProps>();

  const toggleWatch = form.watch("useSpecialization");

  useEffect(() => {
    const defaultValues = data ? backendToForm(data) : undefined;
    form.reset(defaultValues);
  }, [data, open, form]);

  useEffect(() => {
    if (!toggleWatch) {
      form.setValue("specialization", undefined);
      form.setValue("choiceMade", "");
      form.setValue("contractUpdated", "");
    }
  }, [toggleWatch, form]);

  return (
    <FormProvider {...form}>
      <FormModal<FormProps, UpdateSpecialization, UpdateSpecializationVariables>
        title={t("take-suffrage")}
        description={t("suffrage-info-text")}
        makeVars={formToBackend}
        mutation={UPDATE_SPECIALIZATION}
        open={open}
        handleClose={handleClose}
        useContext
      >
        {(control) => (
          <>
            <Box sx={{ mt: 5 }}>
              <InputSwitch
                control={control}
                name="useSpecialization"
                label={t("suffrage-specialization")}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <InputRadio
                control={control}
                name="specialization"
                options={radioOptions}
                disabled={!toggleWatch}
              />
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <InputDate
                    control={control}
                    name="choiceMade"
                    label={t("suffrage-taken-on")}
                    disabled={!toggleWatch}
                    required={toggleWatch}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputDate
                    control={control}
                    name="contractUpdated"
                    label={t("formation-contract-updated-on")}
                    disabled={!toggleWatch}
                    required={toggleWatch}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Alert severity="info" color="success">
                {t("suffrage-info-text-save")}
              </Alert>
            </Box>
          </>
        )}
      </FormModal>
    </FormProvider>
  );
};

export default EditSpecializationChoiceModal;
