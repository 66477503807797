interface DisplayTimespanProps {
  children?: number | null;
}

const DisplayTimespan = ({ children }: DisplayTimespanProps) => {
  if (children) {
    const hours = Math.floor(children / 60);
    const minutes = Math.floor(children % 60);
    return (
      <>
        {("0" + hours).slice(-3)}:{("0" + minutes).slice(-2)}
      </>
    );
  } else {
    return <></>;
  }
};

export default DisplayTimespan;
