import gql from "graphql-tag";
import React from "react";
import { useTranslation } from "react-i18next";
import FormModal from "../../../common/form/FormModal";
import InputRadio from "../../../common/input/InputRadio";
import {
  CreatePractical,
  CreatePracticalVariables,
} from "../../../schema/CreatePractical";
import { PracticalInput, PracticalType } from "../../../schema/globalTypes";

interface AddPracticalModalProps {
  data?: PracticalInput | null;
  studentId: string;
  open: boolean;
  handleClose: () => void;
}

const CREATE_PRACTICAL = gql`
  mutation CreatePractical($practical: PracticalInput!) {
    createPractical(command: $practical) {
      id
      practicals {
        practicalType
      }
    }
  }
`;

export default function AddPracticalModal({
  data,
  studentId,
  open,
  handleClose,
}: AddPracticalModalProps) {
  const { t } = useTranslation();
  const radioOptionsPractical = [
    {
      label: `${t("practicalType.ORIENTATION")}`,
      value: PracticalType.ORIENTATION,
    },
    {
      label: `${t("practicalType.OBLIGATORY1")}`,
      value: PracticalType.OBLIGATORY1,
    },
    {
      label: `${t("practicalType.OBLIGATORY2")}`,
      value: PracticalType.OBLIGATORY2,
    },
    {
      label: `${t("practicalType.OBLIGATORY3")}`,
      value: PracticalType.OBLIGATORY3,
    },
    {
      label: `${t("practicalType.OBLIGATORY4")}`,
      value: PracticalType.OBLIGATORY4,
    },
    {
      label: `${t("practicalType.OBLIGATORY5")}`,
      value: PracticalType.OBLIGATORY5,
    },
    {
      label: `${t("practicalType.ADDITIONAL1")}`,
      value: PracticalType.ADDITIONAL1,
    },
    {
      label: `${t("practicalType.ADDITIONAL2")}`,
      value: PracticalType.ADDITIONAL2,
    },
    {
      label: `${t("practicalType.ADDITIONAL3")}`,
      value: PracticalType.ADDITIONAL3,
    },
  ];

  return (
    <FormModal<PracticalInput, CreatePractical, CreatePracticalVariables>
      title={t("add-practical")}
      description=""
      defaultValues={data || undefined}
      makeVars={(practical) => ({ practical: { ...practical, studentId } })}
      mutation={CREATE_PRACTICAL}
      open={open}
      handleClose={handleClose}
    >
      {(control) => (
        <InputRadio
          control={control}
          name="practicalType"
          options={radioOptionsPractical}
        ></InputRadio>
      )}
    </FormModal>
  );
}
