import { Alert, Stack, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import UploadButton from "../../../common/buttons/UploadButton";
import useModal from "../../../hooks/useModal";
import { NightShiftProtocol } from "../../../schema/NightShiftProtocol";
import { UserDetails } from "../../../schema/UserDetails";
import FormSection from "../../FormSection";
import EditNightShiftsModal from "../modals/EditNightShiftsModal";
import NightShiftsList from "../NightShiftsList";

interface NightShiftsProps {
  user?: UserDetails | null;
  practicalId: string;
  studentId: string;
  locked?: boolean;
  loading?: boolean;
  headerBackgroundColor?: string;
  data?: NightShiftProtocol[] | null;
}

export const FormSectionNightShifts = ({
  user,
  data,
  practicalId,
  studentId,
  locked,
  loading,
}: NightShiftsProps) => {
  const { open, handleOpen, handleClose } = useModal();
  const { t } = useTranslation();

  return (
    <>
      <FormSection
        sectionTitle={t("night-shifts")}
        locked={locked}
        loading={loading}
        headerBackgroundColor={"student.100"}
        //createdAt={data?.nightShifts?.meta.createdAt}
        sectionAction={<UploadButton handleOpen={handleOpen} />}
      >
        <Stack spacing={2}>
          <Alert severity="info" color="success">
            Nachtdienste müssen nach § 1 Abs. 6 Pflegeberufe-Ausbildungs- und
            -Prüfungsverordnung ab der zweiten Hälfte der Ausbildungszeit im
            Umfang von 80 bis höchstens 120 Stunden unter Aufsicht einer
            ausgebildeten Pflegefachperson nachgewiesen werden. Der Nachweis
            erfolgt durch die von der beaufsichtigenden Pflegefachperson
            unterschriebene Angabe der geleisteten Stunden und des Einsatzortes.
            Da die gesetzlich vorgesehene Anzahl der Nachtdienste nicht am Stück
            und in derselben Einrichtung absolviert werden, ist jeder Block
            zusammenhängender Nachtdienste gesondert nachzuweisen. Bei Bedarf
            ist die Vorlage entsprechend oft zu kopieren. Sollte durch
            gesetzliche Bestimmungen, z. B. Jugendarbeitsschutzgesetz oder
            Mutterschutzgesetz, die Ausübung des Nachtdienstes nicht oder nur
            eingeschränkt erlaubt sein, so ist das zu dokumentieren und vom
            Träger der praktischen Ausbildung zu unterschreiben.
          </Alert>
          {data ? (
            <NightShiftsList data={data} loading={loading} />
          ) : (
            <Typography
              color="#c8cbcf"
              textAlign="center"
              justifyItems="center"
            >
              {t("no-data")}
            </Typography>
          )}
        </Stack>
      </FormSection>
      <EditNightShiftsModal
        data={{
          practicalId: practicalId || "",
          studentId: studentId || "",
          startDate: "",
          stopDate: "",
          plannedWorkingHours: 0,
          achievedHours: 0,
          signatureName: "",
          signature: "",
        }}
        practicalId={practicalId}
        open={open}
        handleClose={handleClose}
      />
    </>
  );
};

export default FormSectionNightShifts;
