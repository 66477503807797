import { VoicemailRounded } from "@mui/icons-material";
import Keycloak, { KeycloakTokenParsed } from "keycloak-js";
import { createContext, FC, useEffect, useState } from "react";

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
}

interface AuthorizationContextValue extends State {
  login: () => Promise<void>;
  logout: () => Promise<void>;
  register: () => Promise<void>;
  getToken: () => Promise<string | undefined>;
  getRefreshToken: () => KeycloakTokenParsed | undefined;
  getUsername: () => string | undefined;
  setToken: (accessToken: string, refreshToken: string) => Promise<void>;
}

const keycloak = Keycloak("/keycloak.json");

const login = () => {
  return keycloak.login();
};

const logout = () => {
  return keycloak.logout();
};

const register = () => {
  return keycloak.register();
};

const getUsername = () => (keycloak.tokenParsed as any)?.preferred_username;

const AuthenticationContext = createContext<AuthorizationContextValue>({
  isAuthenticated: false,
  isInitialized: false,
  login,
  logout,
  register,
  getToken: () => Promise.reject("not initialized"),
  getRefreshToken: () => undefined,
  getUsername,
  setToken: () => Promise.reject("not initialized"),
});

export const AuthenticationProvider: FC<{}> = ({ children }) => {
  const [isInitialized, setInitialized] = useState(false);
  const [isAuthenticated, setAuthenticated] = useState(false);

  const initialize = () => {
    keycloak.init({ onLoad: "check-sso" }).finally(() => {
      setAuthenticated(!!keycloak.authenticated);
      setInitialized(true);
    });
  };

  useEffect(() => {
    initialize();
  }, []);

  const getToken = () => {
    if (keycloak.authenticated) {
      return keycloak
        .updateToken(30)
        .then(() => {
          return keycloak.token;
        })
        .catch((ex) => {
          setAuthenticated(false);
          login();
          return undefined;
        });
    } else {
      return Promise.resolve(undefined);
    }
  };

  const getRefreshToken = () => keycloak.refreshTokenParsed;

  const setToken = (accessToken: string, refreshToken: string) => {
    return keycloak
      .init({ checkLoginIframe: false, token: accessToken, refreshToken })
      .then(() => {
        setAuthenticated(!!keycloak.authenticated);
        console.log("authenticated", keycloak.authenticated);
      });
  };

  return (
    <AuthenticationContext.Provider
      value={{
        isAuthenticated, //: !!keycloak.authenticated,
        isInitialized,
        login,
        logout,
        register,
        getToken,
        getRefreshToken,
        getUsername,
        setToken,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

export default AuthenticationContext;
