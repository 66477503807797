import { useQuery } from "@apollo/client";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Button, Grid, Skeleton, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import gql from "graphql-tag";
import React from "react";
import { useTranslation } from "react-i18next";
import TextBlock from "../../common/display/TextBlock";
import TextLine from "../../common/display/TextLine";
import { STUDENT_PRACTICAL } from "../../fragments/student";
import useCurrentUser from "../../hooks/useCurrentUser";
import { GetPractical, GetPracticalVariables } from "../../schema/GetPractical";
import { InterviewProtocolType, Role } from "../../schema/globalTypes";
import FormSection from "../FormSection";
import PageNavigation from "../PageNavigation";
import UploadFileSection from "../UploadFileSection";
import {
  ExportPracticalButton,
  ExportPracticalTemplateButton,
} from "./buttons/ExportButtons";
import FormSectionEducationProvider from "./sections/FormSectionEducationProvider";
import FormSectionInstructionProtocol from "./sections/FormSectionInstructionProtocol";
import FormSectionInterviewProtocol from "./sections/FormSectionInterviewProtocol";
import FormSectionInterviewProtocolTeacher from "./sections/FormSectionInterviewProtocolTeacher";
import FormSectionNightShifts from "./sections/FormSectionNightShifts";
import FormSectionNursingSchool from "./sections/FormSectionNursingSchool";
import FormSectionPracticalDetails from "./sections/FormSectionPracticalDetails";
import FormSectionPracticalDuration from "./sections/FormSectionPracticalDuration";
import FormSectionPracticalLocation from "./sections/FormSectionPracticalLocation";
import FormsSectionPracticalTasks from "./sections/FormSectionPracticalTasks";

interface PracticalProps {
  practicalId: string;
  studentId: string;
}

export const GET_PRACTICAL = gql`
  ${STUDENT_PRACTICAL}

  query GetPractical($practicalId: ID!) {
    practical(practicalId: $practicalId) {
      ...Practical
    }
  }
`;

const Practical = ({ practicalId, studentId }: PracticalProps) => {
  const { data, loading } = useQuery<GetPractical, GetPracticalVariables>(
    GET_PRACTICAL,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      variables: { practicalId },
    }
  );
  const { user } = useCurrentUser();
  const { t } = useTranslation();
  const handleClick = () => {
    const start = window.document.getElementById("#pageTop");
    if (start === null) {
      console.error("cannot find #pageTop");
    } else {
      start.scrollIntoView({ behavior: "smooth" });
    }
  };

  const navigationEntries = [
    {
      name: `${t("practical-proof")}`,
      id: "#einsatznachweis",
    },
    {
      name: `${t("practical-duration")}`,
      id: "#praktische-stunden",
    },
    {
      name: `${t("interview-first")}`,
      id: "#erstgespraech",
    },
    {
      name: `${t("interview-intermediate")}`,
      id: "#zwischengespraech",
    },
    {
      name: `${t("interview-last")}`,
      id: "#abschlussgespraech",
    },
    {
      name: `${t("practical-tasks")}`,
      id: "#arbeits-lernaufgaben",
    },
    {
      name: `${t("practical-visit")}`,
      id: "#praxisbegleitung",
    },
    {
      name: `${t("practical-instruction")}`,
      id: "#praxisanleitung",
    },
    {
      name: `${t("night-shifts")}`,
      id: "#nachtdienste",
    },
    {
      name: `${t("file-upload")}`,
      id: "#dateianhang",
    },
  ];

  return (
    <Box position="relative">
      <Grid container rowSpacing={{ xs: 4, sm: 8, md: 12, xl: 16 }}>
        <Grid item xs={10}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography>
                Hier finden Sie alle notwendigen Nachweisdokumente zum
                jeweiligen Praxiseinsatz. Sie können zum gewünschten Dokument
                scrollen oder über die Navigation rechts direkt dorthin
                springen.
              </Typography>
            </Grid>
            {(user?.roles || []).includes(Role.STUDENT) ? (
              <Grid item xs={12} sm={6} alignItems="flex-end">
                <Typography>
                  Bevor Sie mit dem Ausfüllen beginnen, legen Sie bitte fest,
                  wer mit Ihnen die Dokumente zu diesem Praxiseinsatz einsehen
                  und ggf. bearbeiten und freigeben kann:
                </Typography>
                <ol>
                  <li>Betrieb, in dem der Praxiseinsatz stattfindet,</li>
                  <li>
                    Verantwortliche*r Praxisanleiter*in in diesem Betrieb für
                    Sie,
                  </li>
                  <li>Praxisbegleiter*in aus der Pflegeschule</li>
                </ol>
              </Grid>
            ) : (
              <>
                <Grid item xs={12} sm={6}>
                  <FormSection
                    sectionTitle={t("student")}
                    sectionAction={<></>}
                    headerBackgroundColor="student.100"
                  >
                    {data?.practical ? (
                      <>
                        <TextBlock label={t("name")}>
                          <TextLine
                            value={data.practical.student.user.fullName}
                          />
                        </TextBlock>

                        <TextBlock label={t("email")}>
                          <TextLine value={data.practical.student.user.email} />
                        </TextBlock>

                        <TextBlock label="Träger">
                          <TextLine
                            value={
                              data.practical.student.educationProvider.response
                                ?.educationProvider.provider
                            }
                          />
                        </TextBlock>
                      </>
                    ) : (
                      <Typography
                        color="#c8cbcf"
                        textAlign="center"
                        justifyItems="center"
                      >
                        {t("no-data")}
                      </Typography>
                    )}
                  </FormSection>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormSectionEducationProvider
                    data={data?.practical.student.educationProvider}
                    loading={loading}
                    studentId={studentId}
                    locked={false}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormSectionNursingSchool
                    data={data?.practical.student.nursingSchool}
                    loading={loading}
                    studentId={studentId}
                    locked={false}
                  />
                </Grid>
              </>
            )}

            <Grid
              item
              id="praxiseinsatz"
              xs={12}
              sm={6}
              alignItems="flex-start"
            >
              {data && practicalId ? (
                <FormSectionPracticalDetails data={data.practical} />
              ) : (
                <Skeleton variant="rectangular" height={200} />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={10}
          display="flex"
          flexDirection="row"
          alignItems="flex-end"
          spacing={2}
        >
          <Box flex="1" />
          <Stack display="flex" flexDirection="column" spacing={1}>
            <Box paddingLeft="16px">
              <Typography
                sx={{
                  textTransform: "uppercase",
                  fontSize: "12px",
                  letterSpacing: "1.88px",
                  fontWeight: 500,
                }}
              >
                Nachweisdokumente als PDF
              </Typography>
            </Box>
            <Box display="flex">
              <ExportPracticalTemplateButton name={t("template")} />
              <ExportPracticalButton
                name={t("pdf")}
                practicalId={practicalId}
              />
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={10}>
          <Grid container rowSpacing={{ xs: 4, sm: 8, md: 12, xl: 16 }}>
            <Grid id="einsatznachweis" item xs={12} alignItems="flex-start">
              {data && practicalId ? (
                <>
                  <FormSectionPracticalLocation
                    data={data.practical.practicalLocation}
                    practicalId={data.practical.id}
                  />
                </>
              ) : (
                <Skeleton variant="rectangular" height={200} />
              )}
            </Grid>
            <Grid id="praktische-stunden" item xs={12} alignItems="flex-start">
              {data && practicalId ? (
                <FormSectionPracticalDuration
                  data={data.practical.practicalDuration}
                  practicalId={data.practical.id}
                />
              ) : (
                <Skeleton variant="rectangular" height={200} />
              )}
            </Grid>
            <Grid id="erstgespraech" item xs={12} alignItems="flex-start">
              {data && studentId && practicalId && Role.STUDENT ? (
                <FormSectionInterviewProtocol
                  protocolType={InterviewProtocolType.FIRST}
                  data={data.practical.interviewProtocolFirst}
                  practicalId={data.practical.id}
                  interviewProtocolTitle={t("interview-first")}
                />
              ) : (
                <Skeleton variant="rectangular" height={200} />
              )}
            </Grid>
            <Grid id="zwischengespraech" item xs={12} alignItems="flex-start">
              {data ? (
                <FormSectionInterviewProtocol
                  protocolType={InterviewProtocolType.INTERMEDIATE}
                  data={data.practical.interviewProtocolIntermediate}
                  practicalId={data.practical.id}
                  interviewProtocolTitle={t("interview-intermediate")}
                />
              ) : (
                <Skeleton variant="rectangular" height={200} />
              )}
            </Grid>
            <Grid id="abschlussgespraech" item xs={12} alignItems="flex-start">
              {data ? (
                <FormSectionInterviewProtocol
                  protocolType={InterviewProtocolType.LAST}
                  data={data.practical.interviewProtocolLast}
                  practicalId={data.practical.id}
                  interviewProtocolTitle={t("interview-last")}
                />
              ) : (
                <Skeleton variant="rectangular" height={200} />
              )}
            </Grid>
            <Grid
              id="arbeits-lernaufgaben"
              item
              xs={12}
              alignItems="flex-start"
            >
              {data ? (
                <FormsSectionPracticalTasks
                  data={data.practical.practicalTasks}
                  practicalId={practicalId}
                />
              ) : (
                <Skeleton variant="rectangular" height={200} />
              )}
            </Grid>
            <Grid id="praxisbegleitung" item xs={12} alignItems="flex-start">
              {data ? (
                <FormSectionInterviewProtocolTeacher
                  data={data?.practical.interviewProtocolTeacher}
                  practicalId={practicalId}
                />
              ) : (
                <Skeleton variant="rectangular" height={200} />
              )}
            </Grid>

            <Grid id="praxisanleitung" item xs={12} alignItems="flex-start">
              {data ? (
                <FormSectionInstructionProtocol
                  data={data?.practical.instructionProtocols}
                  practicalId={practicalId}
                />
              ) : (
                <Skeleton variant="rectangular" height={200} />
              )}
            </Grid>
            <Grid id="nachtdienste" item xs={12} alignItems="flex-start">
              {data ? (
                <FormSectionNightShifts
                  studentId={studentId}
                  practicalId={practicalId}
                  data={data?.practical.nightShiftProtocols}
                />
              ) : (
                <Skeleton variant="rectangular" height={200} />
              )}
            </Grid>
            <Grid id="dateianhang" item xs={12} alignItems="flex-start">
              {data ? (
                <>
                  <UploadFileSection
                    referenceType="practical"
                    referenceId={practicalId}
                  />
                </>
              ) : (
                <Skeleton variant="rectangular" height={200} />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={2} sx={{ paddingLeft: 4 }}>
          <Box
            justifyContent="center"
            alignItems="center"
            position="sticky"
            top={75}
            right={0}
          >
            <PageNavigation entries={navigationEntries} />
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={10} textAlign="right">
          <Button
            sx={{ marginTop: 2 }}
            color="primary"
            variant="outlined"
            onClick={handleClick}
            endIcon={<KeyboardArrowUpIcon />}
          >
            {t("up")}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Practical;
