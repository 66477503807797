import { gql, useMutation } from "@apollo/client";
import { STUDENT_PRACTICAL } from "../../fragments/student";
import { Role } from "../../schema/globalTypes";
import {
  SetInterviewProtocolTeacherReadyForApproval,
  SetInterviewProtocolTeacherReadyForApprovalVariables,
} from "../../schema/SetInterviewProtocolTeacherReadyForApproval";

import ApproveButton from "./ApproveButton";

interface RequestApproveButtonInterviewProtocolTeacherProps {
  canApprove: boolean;
  role: Role;
  protocolId: string;
}

const SET_INTERVIEW_PROTOCOL_TEACHER_READY_FOR_APPROVAL = gql`
  ${STUDENT_PRACTICAL}
  mutation SetInterviewProtocolTeacherReadyForApproval(
    $interviewProtocolId: ID!
  ) {
    setInterviewProtocolTeacherReadyForApproval(
      interviewProtocolId: $interviewProtocolId
    ) {
      ...Practical
    }
  }
`;

const RequestApproveButtonInterviewProtocolTeacher = ({
  canApprove,
  role,
  protocolId,
}: RequestApproveButtonInterviewProtocolTeacherProps) => {
  const [mutate] = useMutation<
    SetInterviewProtocolTeacherReadyForApproval,
    SetInterviewProtocolTeacherReadyForApprovalVariables
  >(SET_INTERVIEW_PROTOCOL_TEACHER_READY_FOR_APPROVAL);

  const handleApprove = async () => {
    return mutate({ variables: { interviewProtocolId: protocolId } }).then(
      () => {
        return;
      }
    );
  };

  return (
    <ApproveButton
      canApprove={canApprove}
      handleApprove={handleApprove}
      role={role}
      approvalRequest
    />
  );
};

export default RequestApproveButtonInterviewProtocolTeacher;
