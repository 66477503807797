import { Grid } from "@mui/material";
import gql from "graphql-tag";
import { useTranslation } from "react-i18next";
import FormModal from "../../../common/form/FormModal";
import InputDate from "../../../common/input/InputDate";
import InputSignature from "../../../common/input/InputSignature";
import InputText from "../../../common/input/InputText";
import { STUDENT_PRACTICAL } from "../../../fragments/student";
import { NightShiftProtocolInput } from "../../../schema/globalTypes";
import {
  UpdateNightShiftProtocol,
  UpdateNightShiftProtocolVariables,
} from "../../../schema/UpdateNightShiftProtocol";

interface EditNightShiftsModalProps {
  data?: NightShiftProtocolInput | null;
  practicalId: string;
  open: boolean;
  handleClose: () => void;
}

export default function EditNightShiftsModal({
  data,
  practicalId,
  open,
  handleClose,
  ...props
}: EditNightShiftsModalProps) {
  const { t } = useTranslation();

  const UPDATE_NIGHT_SHIFT_PROTOCOL = gql`
    ${STUDENT_PRACTICAL}
    mutation UpdateNightShiftProtocol(
      $nightShiftProtocol: NightShiftProtocolInput!
    ) {
      updateNightShiftProtocol(command: $nightShiftProtocol) {
        ...Practical
      }
    }
  `;
  const nightShiftOptions = [
    {
      label: `${t("youth-and-mother-labor-protection-law")}`,
      value: "",
    },
  ];

  return (
    <FormModal<
      NightShiftProtocolInput,
      UpdateNightShiftProtocol,
      UpdateNightShiftProtocolVariables
    >
      title={t("night-shift")}
      description={t("edit-night-shift-details")}
      maxWidth="md"
      defaultValues={data || undefined}
      mutation={UPDATE_NIGHT_SHIFT_PROTOCOL}
      open={open}
      handleClose={handleClose}
      makeVars={(nightShiftProtocol) => ({
        nightShiftProtocol: { ...nightShiftProtocol, practicalId },
      })}
    >
      {(control) => (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <InputDate
                    control={control}
                    name="startDate"
                    label={t("start-date")}
                    required
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputDate
                    control={control}
                    name="stopDate"
                    label={t("end-date")}
                    required
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <InputText
                    control={control}
                    name="plannedWorkingHours"
                    label={t("planned-working-hours")}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputText
                    control={control}
                    name="achievedHours"
                    label={t("achieved-hours")}
                    required
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <InputText
                control={control}
                name="description"
                label={t("reason-for-discrepancy")}
              />
            </Grid>
            <Grid item xs={12}>
              <InputText
                control={control}
                name="signatureName"
                label={t("signature-name")}
              />
            </Grid>
            <Grid item xs={12}>
              <InputSignature
                control={control}
                name="signature"
                label={t("signature")}
              />
            </Grid>
          </Grid>
        </>
      )}
    </FormModal>
  );
}
