import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import SplashScreen from "./common/layout/SplashScreen";
import useCurrentUser from "./hooks/useCurrentUser";
import AwaitApprovalPage from "./pages/AwaitApprovalPage";
import FinalizeRegistrationPage from "./pages/FinalizeRegistrationPage";
import Impress from "./pages/Impress";
import InstructorHomePage from "./pages/instructor/InstructorHomePage";
import NotFound from "./pages/NotFound";
import OrganizationHomePage from "./pages/organization/OrganizationHomePage";
import ProfilePage from "./pages/ProfilePage";
import StudentHomePage from "./pages/student/StudentHomePage";
import TeacherHomePage from "./pages/teacher/TeacherHomePage";
import InstructorRoutes from "./routes/InstructorRoutes";
import OrganizationRoutes from "./routes/OrganizationRoutes";
import SchoolRoutes from "./routes/SchoolRoutes";
import StudentRoutes from "./routes/StudentRoutes";
import TeacherRoutes from "./routes/TeacherRoutes";
import { Role } from "./schema/globalTypes";

const Routes = () => {
  const { pathname } = useLocation();
  const { user, loading } = useCurrentUser();

  if (loading) {
    return <SplashScreen />;
  }

  return (
    <Switch>
      {!user?.roles?.length ? (
        <Route path="/">
          <FinalizeRegistrationPage />
        </Route>
      ) : !user?.isApproved ? (
        <Route path="/">
          <AwaitApprovalPage />
        </Route>
      ) : (
        <Route path="/" exact strict>
          {user.roles.includes(Role.STUDENT) ? (
            <StudentHomePage />
          ) : user.roles.includes(Role.INSTRUCTOR) ? (
            <InstructorHomePage />
          ) : user.roles.includes(Role.ORGANIZATION) ? (
            <OrganizationHomePage />
          ) : user.roles.includes(Role.TEACHER) ? (
            <TeacherHomePage />
          ) : (
            <></>
          )}
        </Route>
      )}

      <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />

      <Route path="/profilseite">
        <ProfilePage />
      </Route>
      <Route path="/impressum-datenschutz">
        <Impress />
      </Route>

      <Route path="/verwaltung">
        <SchoolRoutes />
      </Route>
      <Route path="/ausbildung">
        <TeacherRoutes />
      </Route>

      <Route path="/praxis">
        <InstructorRoutes />
      </Route>

      <Route path="/betriebe">
        <OrganizationRoutes />
      </Route>

      <StudentRoutes />

      <Route path="">
        <NotFound />
      </Route>
    </Switch>
  );
};

export default Routes;
