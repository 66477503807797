import { Stack } from "@mui/material";
import gql from "graphql-tag";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import FormModal from "../../../common/form/FormModal";
import InputPasswordHidden from "../../../common/input/InputPasswordHidden";
import InputText from "../../../common/input/InputText";
import useAuthentication from "../../../hooks/useAuthentication";
import { RegisterTeacherInput } from "../../../schema/globalTypes";
import {
  RegisterTeacher,
  RegisterTeacherVariables,
} from "../../../schema/RegisterTeacher";
import SchoolDropdown from "../../school/SchoolDropdown";

interface RegisterTeacherModalProps {
  open: boolean;
  handleClose: () => void;
}

const REGISTER_TEACHER = gql`
  mutation RegisterTeacher($command: RegisterTeacherInput!) {
    registerTeacher(command: $command) {
      accessToken
      refreshToken
    }
  }
`;

function RegisterTeacherModal({
  open,
  handleClose,
}: RegisterTeacherModalProps) {
  const { t } = useTranslation();

  const { setToken, login } = useAuthentication();
  const history = useHistory();

  const onClose = (success: boolean, data?: RegisterTeacher | null) => {
    if (data && success) {
      console.log("setting token", data);
      setToken(
        data.registerTeacher.accessToken,
        data.registerTeacher.refreshToken
      ).then(() => {
        handleClose();
        history.push("/");
      });
    } else {
      handleClose();
    }
  };

  const form = useForm<
    RegisterTeacherInput & {
      password_repeat: string;
    }
  >();

  const password = form.watch("password");

  return (
    <FormProvider {...form}>
      <FormModal<
        RegisterTeacherInput & { password_repeat: string },
        RegisterTeacher,
        RegisterTeacherVariables
      >
        title={t("register-teacher")}
        description={t("register-teacher-description")}
        maxWidth="sm"
        defaultValues={undefined}
        makeVars={(command) => {
          const { password_repeat, ...rest } = command;
          return {
            command: rest,
          };
        }}
        mutation={REGISTER_TEACHER}
        open={open}
        handleClose={onClose}
        useContext
      >
        {(control) => (
          <Stack spacing={2}>
            <InputText
              control={control}
              name="firstName"
              label={t("name")}
              required
            />
            <InputText
              control={control}
              name="lastName"
              label={t("lastname")}
              required
            />
            <InputText
              control={control}
              name="email"
              label={t("email")}
              required
            />
            <InputPasswordHidden
              control={control}
              name="password"
              label={t("password")}
              required
            />
            <InputPasswordHidden
              control={control}
              name="password_repeat"
              label="Passwort wiederholen"
              required
              rules={{
                validate: (value) =>
                  value === password || "Die Passwörter stimmen nicht überein.",
              }}
            />
            <SchoolDropdown
              name="schoolId"
              control={control}
              label={t("choose-school")}
              required
            />
          </Stack>
        )}
      </FormModal>
    </FormProvider>
  );
}

export default RegisterTeacherModal;
