import { useController, UseControllerProps } from "react-hook-form";
import { TimePicker } from "@mui/lab";
import { TextField } from "@mui/material";

type InputTimespanProps<T> = {
  label: string;
  required?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
} & UseControllerProps<T>;

function InputTimespan<T>({
  label,
  required,
  autoFocus,
  disabled,
  ...props
}: InputTimespanProps<T>) {
  const rules = {
    required: required ? `${label} ist ein Pflichtfeld.` : undefined,
    ...props.rules,
  };

  const {
    field,
    fieldState: { error },
  } = useController({ ...props, rules });

  let value: Date | string | null = null;
  if (field.value && field.value !== "") {
    const mm = parseInt(`${field.value}`);
    value = new Date();
    value.setHours(mm / 60);
    value.setMinutes(mm % 60);
  } else {
    value = null;
  }

  return (
    <TimePicker
      onChange={(x) => {
        if (x) {
          try {
            const date = new Date(x);
            const time = date.getHours() * 60 + date.getMinutes();
            field.onChange(time);
          } catch (ex) {
            console.error(ex);
          }
        }
      }}
      value="0:00"
      disabled={disabled}
      label={label}
      ampm={false}
      ampmInClock={false}
      OpenPickerButtonProps={{ disabled: true, style: { display: "none" } }}
      renderInput={(params) => (
        <TextField
          required={required}
          error={!!error}
          helperText={error ? error.message : ""}
          fullWidth
          autoFocus={autoFocus}
          {...params}
        />
      )}
    />
  );
}

export default InputTimespan;
