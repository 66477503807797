import { Alert, Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import EditButton from "../../../common/buttons/EditButton";
import DisplayDate from "../../../common/display/DisplayDate";
import TextBlock from "../../../common/display/TextBlock";
import TextLine from "../../../common/display/TextLine";
import useModal from "../../../hooks/useModal";
import { Role } from "../../../schema/globalTypes";
import { PracticalDuration } from "../../../schema/PracticalDuration";
import ApproveButtonPracticalDuration from "../../approval/ApproveButtonPracticalDuration";
import ApprovedBy from "../../approval/ApprovedBy";
import FormSection from "../../FormSection";
import EditPracticalDurationModal from "../modals/EditPracticalDurationModal";

interface PracticalDurationProps {
  data: PracticalDuration | null;
  practicalId: string;
}

const FormSectionPracticalDuration = ({
  data,
  practicalId,
}: PracticalDurationProps) => {
  const { t } = useTranslation();
  const { open, handleOpen, handleClose } = useModal();

  return (
    <>
      <FormSection
        sectionTitle={t("practical-duration")}
        meta={data?.response?.meta}
        sectionAction={<EditButton handleOpen={handleOpen} />}
        headerBackgroundColor={"student.100"}
        canEdit={!!data?.access.canEdit}
      >
        <Stack spacing={2}>
          <Alert severity="info" color="success">
            Hier werden von der Praxiseinrichtung Angaben zum geplanten und
            nachgewiesenen Stundenumfang gemacht. Anhand dieser Angaben können
            die Fehlzeiten berechnet werden, die dann in der qualifizierten
            Leistungseinschätzung nach § 6 Abs. 2 Pflegeberufe-Ausbildungs- und
            -Prüfungsverordnung auszuweisen sind. <br />
            <br />
            In der praktischen Ausbildung dürfen insgesamt Fehlzeiten von
            maximal zehn Prozent anfallen; in jedem der Pflichteinsätze dürfen
            die Fehlzeiten einen Umfang von 25 Prozent nicht überschreiten (vgl.
            § 13 PflBG und § 1 Abs. 4 PflAPrV).
          </Alert>
          {data?.response?.practicalDuration ? (
            <Stack spacing={5}>
              <Stack spacing={1}>
                <TextBlock label={t("practical-duration-start")}>
                  <DisplayDate>
                    {data?.response?.practicalDuration?.startDate}
                  </DisplayDate>
                </TextBlock>
                <TextBlock label={t("practical-duration-end")}>
                  <DisplayDate>
                    {data?.response?.practicalDuration?.endDate}
                  </DisplayDate>
                </TextBlock>
                <TextBlock label={t("practical-duration-planned")}>
                  <TextLine
                    value={`${
                      data?.response?.practicalDuration?.plannedHours || "?"
                    }`}
                  ></TextLine>
                </TextBlock>
                <TextBlock label={t("practical-duration-recorded")}>
                  <TextLine
                    value={`${
                      data?.response?.practicalDuration.recordedHours || "?"
                    }`}
                  ></TextLine>
                </TextBlock>
              </Stack>
              <Stack spacing={2}>
                <Typography variant="sectionHeader">
                  {t("signatures")}
                </Typography>
                <Alert severity="info" color="success">
                  {t("signatures-info")}
                </Alert>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-end"
                >
                  {data?.response?.practicalDuration && (
                    <Stack spacing={2}>
                      <ApprovedBy
                        role={Role.ORGANIZATION}
                        approvedAt={data.response.signedByOrganizationAt}
                        approvedBy={
                          data.response.signedByOrganization?.fullName
                        }
                      >
                        <ApproveButtonPracticalDuration
                          canApprove={true}
                          durationId={data.response.practicalDuration.id}
                          role={Role.ORGANIZATION}
                        />
                      </ApprovedBy>
                    </Stack>
                  )}
                </Box>
              </Stack>
            </Stack>
          ) : (
            <Typography
              color="#c8cbcf"
              textAlign="center"
              justifyItems="center"
            >
              {t("no-data")}
            </Typography>
          )}
        </Stack>
      </FormSection>

      <EditPracticalDurationModal
        data={{
          id: practicalId,
          startDate: data?.response?.practicalDuration.startDate,
          endDate: data?.response?.practicalDuration.endDate,
          plannedHours: data?.response?.practicalDuration.plannedHours,
          recordedHours: data?.response?.practicalDuration.recordedHours,
        }}
        practicalId={practicalId}
        open={open}
        handleClose={handleClose}
      />
    </>
  );
};
export default FormSectionPracticalDuration;
