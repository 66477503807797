import { gql, useMutation } from "@apollo/client";
import { STUDENT_PRACTICAL } from "../../fragments/student";
import { Role } from "../../schema/globalTypes";
import {
  setInterviewProtocolReadyForApproval,
  setInterviewProtocolReadyForApprovalVariables,
} from "../../schema/setInterviewProtocolReadyForApproval";
import ApproveButton from "./ApproveButton";

interface RequestApproveButtonInterviewProtocolProps {
  canApprove: boolean;
  role: Role;
  protocolId: string;
}

const SET_INTERVIEW_PROTOCOL_READY_FOR_APPROVAL = gql`
  ${STUDENT_PRACTICAL}
  mutation setInterviewProtocolReadyForApproval($interviewProtocolId: ID!) {
    setInterviewProtocolReadyForApproval(
      interviewProtocolId: $interviewProtocolId
    ) {
      ...Practical
    }
  }
`;

const RequestApproveButtonInterviewProtocol = ({
  canApprove,
  role,
  protocolId,
}: RequestApproveButtonInterviewProtocolProps) => {
  const [mutate] = useMutation<
    setInterviewProtocolReadyForApproval,
    setInterviewProtocolReadyForApprovalVariables
  >(SET_INTERVIEW_PROTOCOL_READY_FOR_APPROVAL);

  const handleApprove = async () => {
    return mutate({ variables: { interviewProtocolId: protocolId } }).then(
      () => {
        return;
      }
    );
  };

  return (
    <ApproveButton
      canApprove={canApprove}
      handleApprove={handleApprove}
      role={role}
      approvalRequest
    />
  );
};

export default RequestApproveButtonInterviewProtocol;
