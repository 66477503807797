import { Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Page from "../common/layout/Page";
import useCurrentUser from "../hooks/useCurrentUser";

export default function AwaitApprovalPage() {
  const { t } = useTranslation();
  const { refetch } = useCurrentUser();

  useEffect(() => {
    const interval = setInterval(refetch, 60000);
    return () => clearInterval(interval);
  }, [refetch]);

  return (
    <Page title={t("finalize-registration")} pageHeading={t("await-approval")}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item>
          <Typography mb={2}> {t("await-approval-text")}</Typography>
        </Grid>
      </Grid>
    </Page>
  );
}
