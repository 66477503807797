import { ApolloError } from "@apollo/client";
import { Grid, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { StudentDetails } from "../../schema/StudentDetails";
import UploadFileSection from "../UploadFileSection";
import {
  ExportProfileButton,
  ExportProfileTemplateButton,
} from "./buttons/ExportButtons";
import FormSectionEducationDuration from "./sections/FormSectionEducationDuration";
import FormSectionEducationProvider from "./sections/FormSectionEducationProvider";
import FormSectionMasterData from "./sections/FormSectionMasterData";
import FormSectionNursingSchool from "./sections/FormSectionNursingSchool";
import FormSectionRemarks from "./sections/FormSectionRemarks";
import FormSectionSpecializationChoice from "./sections/FormSectionSpecializationChoice";

interface StudentProfileProps {
  data?: StudentDetails | null;
  loading?: boolean;
  error?: ApolloError | undefined;
  studentId: string;
}

const StudentProfile = ({
  data,
  error,
  studentId,
  loading,
}: StudentProfileProps) => {
  const locked = (path: string): boolean =>
    !!error?.graphQLErrors.find((e) => e.path?.includes(path));
  const { t } = useTranslation();
  return (
    <>
      <Grid container justifyContent="flex-end">
        <Grid item>
          <ExportProfileTemplateButton name={t("template")} />
        </Grid>
        <Grid item>
          <ExportProfileButton name={t("pdf")} studentId={data?.id || ""} />
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={12} xl={6} alignItems="flex-start">
          <Stack spacing={5}>
            <FormSectionMasterData
              data={data}
              user={data?.user}
              studentId={studentId}
              loading={loading}
              locked={locked("masterData")}
            />

            <FormSectionNursingSchool
              data={data?.nursingSchool}
              studentId={studentId}
              loading={loading}
              locked={locked("nursingSchool")}
            />
            <FormSectionRemarks
              data={data?.remarks}
              studentId={studentId}
              loading={loading}
              locked={locked("remarks")}
            />
          </Stack>
        </Grid>

        <Grid item xs={12} xl={6}>
          <Stack spacing={5}>
            <FormSectionEducationProvider
              data={data?.educationProvider}
              loading={loading}
              studentId={studentId}
              locked={locked("educationProvider")}
            />
            <FormSectionEducationDuration
              data={data?.educationDuration}
              studentId={studentId}
              loading={loading}
              locked={locked("educationDuration")}
            />
            <FormSectionSpecializationChoice
              data={data?.specialization}
              studentId={studentId}
              loading={loading}
              locked={locked("specialization")}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} xl={6}>
          {studentId !== "" && (
            <UploadFileSection
              referenceType="student"
              referenceId={studentId}
              locked={locked("fileUpload")}
              loading={loading}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default StudentProfile;
