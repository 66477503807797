import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import EditButton from "../../../common/buttons/EditButton";
import TextBlock from "../../../common/display/TextBlock";
import TextLine from "../../../common/display/TextLine";
import useModal from "../../../hooks/useModal";
import { NursingSchool } from "../../../schema/NursingSchool";
import FormSection from "../../FormSection";
import EditNursingSchoolModal from "../modals/EditNursingSchoolModal";

interface NursingSchoolProps {
  data?: NursingSchool | null;
  studentId: string;
  locked?: boolean;
  loading?: boolean;
}

const FormSectionNursingSchool = ({
  data,
  studentId,
  locked,
  loading,
}: NursingSchoolProps) => {
  const { open, handleOpen, handleClose } = useModal();
  const { t } = useTranslation();

  return (
    <>
      <FormSection
        sectionTitle={t("nursing-school")}
        meta={data?.response?.meta}
        sectionAction={<EditButton handleOpen={handleOpen} />}
        locked={locked}
        loading={loading}
        headerBackgroundColor={"student.100"}
        canEdit={!!data?.access.canEdit}
      >
        {data ? (
          <>
            <TextBlock label={t("school-name")}>
              <TextLine value={data?.response?.nursingSchool?.schoolName} />
            </TextBlock>
            <TextBlock label={t("address")}>
              <TextLine
                value={
                  data?.response?.nursingSchool
                    ? data.response?.nursingSchool.street +
                      " " +
                      data.response?.nursingSchool.houseNumber
                    : ""
                }
              />
              <TextLine
                value={
                  data?.response?.nursingSchool
                    ? data.response?.nursingSchool.postCode +
                      " " +
                      data.response?.nursingSchool.city
                    : ""
                }
              />
            </TextBlock>
            <TextBlock label={t("contact-person")}>
              <TextLine value={data?.response?.nursingSchool?.mentor} />
            </TextBlock>
          </>
        ) : (
          <Typography color="#c8cbcf" textAlign="center" justifyItems="center">
            {t("no-data")}
          </Typography>
        )}
      </FormSection>
      <EditNursingSchoolModal
        data={data?.response?.nursingSchool}
        studentId={studentId}
        open={open}
        handleClose={handleClose}
      />
    </>
  );
};

export default FormSectionNursingSchool;
