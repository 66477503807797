import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import EditButton from "../../../common/buttons/EditButton";
import TextBlock from "../../../common/display/TextBlock";
import TextLine from "../../../common/display/TextLine";
import useModal from "../../../hooks/useModal";
import { EducationProvider } from "../../../schema/EducationProvider";
import FormSection from "../../FormSection";
import EditEducationProviderModal from "../modals/EditEducationProviderModal";

interface EducationProviderProps {
  data?: EducationProvider | null;
  studentId: string;
  locked?: boolean;
  loading?: boolean;
}

const FormSectionEducationProvider = ({
  data,
  studentId,
  loading,
  locked,
}: EducationProviderProps) => {
  const { open, handleOpen, handleClose } = useModal();
  const { t } = useTranslation();

  return (
    <>
      <FormSection
        sectionTitle={t("provider-of-practical-training")}
        meta={data?.response?.meta}
        sectionAction={<EditButton handleOpen={handleOpen} />}
        loading={loading}
        locked={locked}
        headerBackgroundColor={"student.100"}
        canEdit={!!data?.access.canEdit}
      >
        {data ? (
          <>
            <TextBlock label={t("provider")}>
              <TextLine value={data?.response?.educationProvider?.provider} />
            </TextBlock>
            <TextBlock label={t("institution-name")}>
              <TextLine
                value={data?.response?.educationProvider?.institutionName}
              />
            </TextBlock>
            <TextBlock label={t("address")}>
              <TextLine
                value={
                  data?.response?.educationProvider
                    ? data.response?.educationProvider.street +
                      " " +
                      data.response?.educationProvider.houseNumber
                    : ""
                }
              />
              <TextLine
                value={
                  data.response?.educationProvider
                    ? data.response?.educationProvider.postcode +
                      " " +
                      data.response?.educationProvider.city
                    : ""
                }
              />
            </TextBlock>
            <TextBlock label={t("mentor")}>
              <TextLine value={data?.response?.educationProvider?.mentor} />
            </TextBlock>
          </>
        ) : (
          <Typography color="#c8cbcf" textAlign="center" justifyItems="center">
            {t("no-data")}
          </Typography>
        )}
      </FormSection>
      <EditEducationProviderModal
        data={data?.response?.educationProvider}
        studentId={studentId}
        open={open}
        handleClose={handleClose}
      />
    </>
  );
};

export default FormSectionEducationProvider;
