import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import DeleteButton from "../../common/buttons/DeleteButton";
import ConfirmDialog from "../../common/form/ConfirmDialog";
import { GET_TEACHERS } from "../../pages/school/Teachers";
import {
  DeleteTeacher,
  DeleteTeacherVariables,
} from "../../schema/DeleteTeacher";
import { GetTeachers } from "../../schema/GetTeachers";

interface DeleteTeacherButtonProps {
  userId: string;
}

const DELETE_TEACHER = gql`
  mutation DeleteTeacher($teacherId: ID!) {
    deleteTeacher(teacherId: $teacherId)
  }
`;

const DeleteTeacherButton = ({
  userId: teacherId,
}: DeleteTeacherButtonProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [deleteTeacher] = useMutation<DeleteTeacher, DeleteTeacherVariables>(
    DELETE_TEACHER,
    {
      update(cache, { data }) {
        const getTeachersData = cache.readQuery<GetTeachers>({
          query: GET_TEACHERS,
        });

        const teachers = (getTeachersData?.teachers || []).filter(
          (x) => x.userId !== teacherId
        );

        cache.writeQuery<GetTeachers>({
          query: GET_TEACHERS,
          data: { teachers },
        });
      },
    }
  );

  const [showConfirmation, setShowConfirmation] = useState(false);

  const deleteBtnHandler = () => {
    setShowConfirmation(true);
  };

  const { t } = useTranslation();

  const handleConfirmHandler = async () => {
    try {
      await deleteTeacher({
        variables: {
          teacherId,
        },
      });

      enqueueSnackbar(`${t("teacher-successfully-deleted")}`, {
        variant: "success",
      });
    } catch (e) {
      const error = e as Error;
      enqueueSnackbar(error.message, { variant: "error" });
    }
    setShowConfirmation(false);
  };

  const handleCancelHandler = () => {
    setShowConfirmation(false);
  };

  return (
    <>
      <DeleteButton onClick={deleteBtnHandler} />
      <ConfirmDialog
        title={t("delete-teacher")}
        open={showConfirmation}
        message={t("really-delete-teacher")}
        handleConfirm={handleConfirmHandler}
        handleCancel={handleCancelHandler}
        confirmBtnText={t("delete")}
      />
    </>
  );
};

export default DeleteTeacherButton;
