import gql from "graphql-tag";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FormModal from "../../../common/form/FormModal";
import InputDate from "../../../common/input/InputDate";
import InputText from "../../../common/input/InputText";
import InputTimespan from "../../../common/input/InputTimespan";
import {
  CreateInstructionProtocol,
  CreateInstructionProtocolVariables,
} from "../../../schema/CreateInstructionProtocol";
import { InstructionProtocolInput } from "../../../schema/globalTypes";

interface AddInstructionProtocolModalProps {
  data?: InstructionProtocolInput | null;
  practicalId: string;
  title: string;
  description: string;
  open: boolean;
  handleClose: () => void;
}

export default function AddInstructionProtocolModal({
  data,
  practicalId,
  title,
  description,
  open,
  handleClose,
}: AddInstructionProtocolModalProps) {
  const UPDATE_INSTRUCTOR_PROTOCOL = gql`
    mutation CreateInstructionProtocol(
      $instructionProtocol: InstructionProtocolInput!
    ) {
      createInstructionProtocol(command: $instructionProtocol) {
        id
        instructionProtocols {
          response {
            instructionProtocol {
              practicalId
              date
              duration
              description
              signature
            }
            meta {
              createdBy {
                fullName
                firstName
                lastName
                email
              }
              createdAt
            }
          }
          access {
            canEdit
            canView
          }
        }
      }
    }
  `;

  const { t } = useTranslation();
  const form = useForm<InstructionProtocolInput>();

  useEffect(() => {
    form.reset({ date: new Date().toISOString() });
  }, [form, open]);

  return (
    <FormProvider {...form}>
      <FormModal<
        InstructionProtocolInput,
        CreateInstructionProtocol,
        CreateInstructionProtocolVariables
      >
        title={title}
        description={description}
        maxWidth="sm"
        defaultValues={data || undefined}
        mutation={UPDATE_INSTRUCTOR_PROTOCOL}
        open={open}
        handleClose={handleClose}
        makeVars={(instructionProtocol) => ({
          instructionProtocol: { ...instructionProtocol, practicalId },
        })}
        useContext
      >
        {(control) => (
          <>
            <InputDate
              control={control}
              name="date"
              label={t("date")}
              required
            />
            <InputTimespan
              control={control}
              name="duration"
              label={t("hours")}
              required
              autoFocus
            />

            <InputText
              control={control}
              name="description"
              label={t("description")}
              required
            />
            {/* <InputSignature
              control={control}
              name="signature"
              label={t("signature")}
            /> */}
          </>
        )}
      </FormModal>
    </FormProvider>
  );
}
