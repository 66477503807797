import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import AddButton from "../common/buttons/AddButton";
import ConfirmDialog from "../common/form/ConfirmDialog";
import { ApproveUser, ApproveUserVariables } from "../schema/ApproveUser";

interface UserApprovalButtonProps {
  userId: string;
}

const APPROVE_USER = gql`
  mutation ApproveUser($userId: ID!) {
    approveUser(userId: $userId) {
      id
      firstName
      lastName
      email
      isApproved
    }
  }
`;

const UserApprovalButton = ({ userId }: UserApprovalButtonProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [approveUser] = useMutation<ApproveUser, ApproveUserVariables>(
    APPROVE_USER
  );

  const handleConfirmHandler = async () => {
    try {
      await approveUser({
        variables: {
          userId,
        },
      });

      enqueueSnackbar(`${t("user-approved")}`, {
        variant: "success",
      });
    } catch (e) {
      const error = e as Error;
      enqueueSnackbar(error.message, { variant: "error" });
    }
    setShowConfirmation(false);
  };

  const handleCancelHandler = () => {
    setShowConfirmation(false);
  };

  const deleteBtnHandler = () => {
    setShowConfirmation(true);
  };

  return (
    <>
      <AddButton
        variant="text"
        color={undefined}
        onClick={deleteBtnHandler}
        buttonLabel={t("approve")}
      />
      <ConfirmDialog
        title={t("approve-user")}
        open={showConfirmation}
        message={t("confirm-aktivation")}
        handleConfirm={handleConfirmHandler}
        handleCancel={handleCancelHandler}
        confirmBtnText={t("approve")}
      />
    </>
  );
};

export default UserApprovalButton;
