import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useTranslation } from "react-i18next";
import Page from "../../common/layout/Page";
import FormSectionInstructorOrganizations from "../../components/instructor/sections/FormSectionInstructorOrganizations";
import {
  GetInstructor,
  GetInstructorVariables,
} from "../../schema/GetInstructor";

interface InstructorDetailsPageProps {
  instructorId: string;
}

export const GET_INSTRUCTOR = gql`
  query GetInstructor($userId: ID!) {
    instructor(userId: $userId) {
      userId
      user {
        id
        firstName
        lastName
        fullName
      }
      organizations {
        userId
        organizationName
        street
        streetNumber
        postCode
        city
      }
    }
  }
`;

const InstructorDetailsPage = ({
  instructorId,
}: InstructorDetailsPageProps) => {
  const { t } = useTranslation();

  const { loading, data } = useQuery<GetInstructor, GetInstructorVariables>(
    GET_INSTRUCTOR,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      variables: {
        userId: instructorId,
      },
    }
  );

  return (
    <Page
      breadcrumb={[
        { name: `${t("instructors")}`, to: "/verwaltung/praxisanleiter" },
        {
          name: `${`${data?.instructor.user.fullName}`}`,
          to: "",
        },
      ]}
      title={data ? `${`${t("class")} `}` : `${t("loading")}`}
      pageHeading={
        data ? `${data?.instructor.user.fullName}` : `${t("loading")}`
      }
    >
      <FormSectionInstructorOrganizations instructorId={instructorId} />
    </Page>
  );
};

export default InstructorDetailsPage;
