import { Alert, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { ReactNode } from "react";

interface FormFieldProps {
  info?: string;
  children: ReactNode;
  reverseInfo?: boolean;
}

const FormField = ({ children, info, reverseInfo }: FormFieldProps) => {
  return (
    <Grid
      container
      direction={{
        xs: "column-reverse",
        md: reverseInfo ? "row" : "row-reverse",
      }}
      spacing={2}
    >
      <Grid item xs={12} md={7}>
        {children}
      </Grid>
      <Grid item xs={12} md={5}>
        <Box>
          {info && (
            <Alert severity="info" color="success">
              {info}
            </Alert>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};
export default FormField;
