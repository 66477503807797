import gql from "graphql-tag";
import { CLASS_DETAILS } from "./class";
import { META } from "./meta";

export const USER_DETAILS = gql`
  fragment UserDetails on User {
    id
    firstName
    lastName
    fullName
    isApproved
    roles
  }
`;

export const STUDENT_MASTER_DATA = gql`
  ${META}
  fragment MasterData on MasterDataResponseWrapper {
    response {
      masterData {
        supplyArea
      }
      meta {
        ...Meta
      }
    }
    access {
      canEdit
      canView
    }
  }
`;

export const STUDENT_EDUCATION_PROVIDER = gql`
  ${META}
  fragment EducationProvider on EducationProviderResponseWrapper {
    response {
      educationProvider {
        provider
        institutionName
        street
        houseNumber
        postcode
        city
        mentor
      }
      meta {
        ...Meta
      }
    }
    access {
      canEdit
      canView
    }
  }
`;

export const STUDENT_NURSING_SCHOOL = gql`
  ${META}
  fragment NursingSchool on NursingSchoolResponseWrapper {
    response {
      nursingSchool {
        schoolName
        street
        houseNumber
        postCode
        city
        mentor
      }
      meta {
        ...Meta
      }
    }
    access {
      canEdit
      canView
    }
  }
`;

export const STUDENT_EDUCATION_DURATION = gql`
  ${META}
  fragment EducationDuration on EducationDurationResponseWrapper {
    response {
      educationDuration {
        educationStart
        educationEnd
      }
      meta {
        ...Meta
      }
    }
    access {
      canEdit
      canView
    }
  }
`;

export const STUDENT_REMARKS = gql`
  ${META}
  fragment Remarks on RemarksResponseWrapper {
    response {
      remarks {
        remarks
      }
      meta {
        ...Meta
      }
    }
    access {
      canEdit
      canView
    }
  }
`;

export const STUDENT_SPECIALIZATION = gql`
  ${META}
  fragment Specialization on SpecializationResponseWrapper {
    response {
      specialization {
        specialization
        choiceMade
        contractUpdated
      }
      meta {
        ...Meta
      }
    }
    access {
      canEdit
      canView
    }
  }
`;

export const STUDENT_INTERVIEW_PROTOCOL = gql`
  ${META}
  fragment InterviewProtocol on InterviewProtocolResponseWrapper {
    response {
      interviewProtocol {
        id
        date
        attendedByStudent
        attendedByInstructor
        attendedByOther {
          name
          email
          role
        }
        descriptionSituation
        descriptionGoals
        descriptionExercises
        descriptionResults
      }
      signatures {
        canApprove
        signedByStudentAt
        signedByStudent {
          id
          fullName
        }
        signedByInstructorAt
        signedByInstructor {
          id
          fullName
        }
        readyForApproval
      }
      meta {
        ...Meta
      }
    }
    access {
      canEdit
      canView
    }
  }
`;

export const STUDENT_INTERVIEW_PROTOCOL_TEACHER = gql`
  ${META}
  fragment InterviewProtocolTeacher on InterviewProtocolTeacherResponseWrapper {
    response {
      interviewProtocolTeacher {
        id
        date
        attendedByStudent
        attendedByInstructor
        instructorName
        attendedByTeacher
        teacherName
        attendedByOther {
          name
          email
          role
        }
        interviewReason
        interviewReasonOtherDescription
        descriptionSituation
        descriptionOther
        descriptionResults
      }
      signatures {
        canApprove
        signedByStudentAt
        signedByStudent {
          id
          fullName
        }
        signedByInstructorAt
        signedByInstructor {
          id
          fullName
        }
        signedByTeacherAt
        signedByTeacher {
          id
          fullName
        }
        readyForApproval
      }
      meta {
        ...Meta
      }
    }
    access {
      canEdit
      canView
    }
  }
`;

export const STUDENT_PRACTICAL_LOCATION = gql`
  ${META}
  fragment PracticalLocation on PracticalLocationResponseWrapper {
    response {
      practicalLocation {
        id
        isProvider
        name
        street
        housenumber
        postcode
        city
        locationType
        locationTypeDescription
        specialization
      }
      meta {
        ...Meta
      }
    }
    access {
      canEdit
      canView
    }
  }
`;

export const STUDENT_INSTRUCTION_PROTOCOL = gql`
  fragment InstructionProtocol on InstructionProtocolResponseWrapper {
    response {
      instructionProtocol {
        practicalId
        date
        duration
        description
        signature
      }
      meta {
        createdBy {
          fullName
          firstName
          lastName
          email
        }
        createdAt
      }
    }
    signatures {
      signedByStudentAt
      signedByStudent {
        id
        fullName
      }
      signedByInstructorAt
      signedByInstructor {
        id
        fullName
      }
    }
    access {
      canEdit
      canView
    }
  }
`;

export const STUDENT_PRACTICAL_TASKS = gql`
  ${META}
  fragment PracticalTasks on PracticalTasksResponseWrapper {
    response {
      practicalTasks {
        tasks {
          content
          competenceTypes
        }
        competenceAppendix
      }
      meta {
        ...Meta
      }
    }
    access {
      canEdit
      canView
    }
  }
`;

export const STUDENT_PRACTICAL_DURATION = gql`
  ${META}
  fragment PracticalDuration on PracticalDurationResponseWrapper {
    response {
      meta {
        ...Meta
      }
      practicalDuration {
        id
        startDate
        endDate
        plannedHours
        recordedHours
      }
      signedByOrganizationAt
      signedByOrganization {
        id
        fullName
      }
    }
    access {
      canEdit
      canView
    }
  }
`;

export const STUDENT_NIGHT_SHIFT_PROTOCOL = gql`
  fragment NightShiftProtocol on NightShiftProtocol {
    practicalId
    studentId
    startDate
    stopDate
    plannedWorkingHours
    achievedHours
    description
    signatureName
    signature
    practical {
      practicalType
    }
  }
`;

export const STUDENT_PRACTICAL = gql`
  ${STUDENT_INTERVIEW_PROTOCOL}
  ${STUDENT_INTERVIEW_PROTOCOL_TEACHER}
  ${STUDENT_PRACTICAL_TASKS}
  ${STUDENT_PRACTICAL_LOCATION}
  ${STUDENT_INSTRUCTION_PROTOCOL}
  ${STUDENT_PRACTICAL_DURATION}
  ${STUDENT_NIGHT_SHIFT_PROTOCOL}
  ${STUDENT_NURSING_SCHOOL}
  fragment Practical on Practical {
    id
    practicalType
    isApprovedByStudent
    isApprovedByInstructor
    isApprovedByOrganization
    isApprovedByTeacher
    isWaitingForStudent
    isWaitingForInstructor
    isWaitingForOrganization
    isWaitingForTeacher
    assignedTeacher {
      id
      fullName
    }
    assignedInstructor {
      id
      fullName
    }
    assignedOrganization {
      id
      fullName
    }
    access {
      canEdit
      canView
    }
    interviewProtocolFirst {
      ...InterviewProtocol
    }
    interviewProtocolIntermediate {
      ...InterviewProtocol
    }
    interviewProtocolLast {
      ...InterviewProtocol
    }
    interviewProtocolTeacher {
      ...InterviewProtocolTeacher
    }
    practicalTasks {
      ...PracticalTasks
    }
    practicalLocation {
      ...PracticalLocation
    }
    canDelete
    instructionProtocols {
      ...InstructionProtocol
    }
    practicalDuration {
      ...PracticalDuration
    }
    nightShiftProtocols {
      ...NightShiftProtocol
    }
    student {
      user {
        fullName
        email
      }
      nursingSchool {
        ...NursingSchool
      }
      educationProvider {
        response {
          educationProvider {
            provider
            institutionName
            street
            houseNumber
            postcode
            city
            mentor
          }
          meta {
            createdAt
            createdBy {
              id
              email
              firstName
              lastName
            }
          }
        }
        access {
          canEdit
          canView
        }
      }
    }
  }
`;

export const STUDENT_DETAILS = gql`
  ${META}
  ${STUDENT_MASTER_DATA}
  ${STUDENT_EDUCATION_PROVIDER}
  ${STUDENT_NURSING_SCHOOL}
  ${STUDENT_EDUCATION_DURATION}
  ${STUDENT_REMARKS}
  ${STUDENT_SPECIALIZATION}
  ${STUDENT_PRACTICAL}
  ${USER_DETAILS}
  ${CLASS_DETAILS}
  ${STUDENT_NIGHT_SHIFT_PROTOCOL}

  fragment StudentDetails on Student {
    id
    user {
      ...UserDetails
    }
    class {
      ...ClassDetails
    }
    masterData {
      ...MasterData
    }
    educationProvider {
      ...EducationProvider
    }
    nursingSchool {
      ...NursingSchool
    }
    educationDuration {
      ...EducationDuration
    }
    remarks {
      ...Remarks
    }
    specialization {
      ...Specialization
    }
    practicals {
      ...Practical
    }
    nightShiftProtocols {
      ...NightShiftProtocol
    }
  }
`;

export const STUDENT_USER = gql`
  fragment StudentUser on Student {
    id
    user {
      id
      firstName
      lastName
    }
  }
`;
