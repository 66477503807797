import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";

type AddButtonProps = {
  onClick: () => void;
  buttonLabel?: string;
  variant: "text" | "contained" | "outlined" | undefined;
  color:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning"
    | undefined;
};

const AddButton = ({
  onClick,
  buttonLabel,
  variant,
  color,
}: AddButtonProps) => {
  return (
    <Button
      onClick={onClick}
      variant={variant}
      color={color}
      startIcon={<AddIcon />}
    >
      {buttonLabel}
    </Button>
  );
};
export default AddButton;
