import { useQuery } from "@apollo/client";
import { createContext, FC } from "react";
import { GET_CURRENT_USER } from "../hooks/useCurrentUser";
import { CurrentUser } from "../schema/CurrentUser";
import { GetCurrentUser } from "../schema/GetCurrentUser";

interface UserContextValue {
  loading: boolean;
  user?: CurrentUser;
  refetch: () => void;
}

const UserContext = createContext<UserContextValue>({
  loading: true,
  user: undefined,
  refetch: () => {},
});

export const UserProvider: FC<{}> = ({ children }) => {
  const { data, loading, refetch } = useQuery<GetCurrentUser>(
    GET_CURRENT_USER,
    {
      fetchPolicy: "cache-and-network",
    }
  );
  return (
    <UserContext.Provider
      value={{
        loading,
        user: data?.currentUser,
        refetch: () => {
          refetch();
        },
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
