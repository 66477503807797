import {
  ApolloError,
  DocumentNode,
  MutationHookOptions,
  useMutation,
} from "@apollo/client";
import {
  Breakpoint,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSnackbar } from "notistack";
import React, { ReactNode, useEffect } from "react";
import {
  Control,
  DeepPartial,
  SubmitHandler,
  UnpackNestedValue,
  useForm,
  useFormContext,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import SubmitButton from "../buttons/SubmitButton";

type FormModalProps<TInput, TData, TVariables> = {
  title: string;
  defaultValues?: UnpackNestedValue<DeepPartial<TInput>>;
  description: string;
  maxWidth?: Breakpoint;
  mutation: DocumentNode;
  mutationOptions?: MutationHookOptions<TData, TVariables>;
  makeVars: (input: UnpackNestedValue<TInput>) => TVariables;
  children: (control: Control<TInput, object>) => ReactNode;
  useContext?: boolean;
  open: boolean;
  handleClose: (success: boolean, data?: TData | null) => void;
  actions?: ReactNode;
  hasUpload?: boolean;
};

function FormModal<TInput, TData, TVariables>({
  actions,
  title,
  description,
  maxWidth,
  children,
  defaultValues,
  makeVars,
  mutation,
  mutationOptions,
  useContext,
  open,
  handleClose,
  hasUpload,
}: FormModalProps<TInput, TData, TVariables>) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [mutate, { loading, error }] = useMutation<TData, TVariables>(
    mutation,
    mutationOptions
  );

  const formContext = useFormContext<TInput>();

  const form = useForm<TInput>({
    defaultValues: defaultValues,
  });

  const { control, handleSubmit, reset } = useContext ? formContext : form;

  useEffect(() => {
    if (!useContext) {
      reset(defaultValues);
    }
  }, [defaultValues, reset, useContext, open]);

  const onSubmit: SubmitHandler<TInput> = (input) => {
    try {
      mutate({
        variables: makeVars(input),
        context: { hasUpload: !!hasUpload },
      })
        .then(({ data }) => {
          handleClose(true, data);
          enqueueSnackbar("Erfolgreich gespeichert.", { variant: "success" });
        })
        .catch((ex) => {
          console.error(error);
          console.error(ex);
        });
    } catch (ex) {
      enqueueSnackbar("Ein Fehler ist aufgetreten: " + error, {
        variant: "error",
      });
    }
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth={maxWidth}
        fullScreen={fullScreen}
        open={open}
        disableEscapeKeyDown
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{description}</DialogContentText>
          {children(control)}
        </DialogContent>
        <DialogActions>
          {actions ? (
            actions
          ) : (
            <>
              <Button
                onClick={() => {
                  handleClose(false);
                }}
              >
                {t("cancel")}
              </Button>
              <SubmitButton
                loading={loading}
                onSubmit={handleSubmit(onSubmit)}
              />
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

export default FormModal;
