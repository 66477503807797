import AccountCircle from "@mui/icons-material/AccountCircle";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import Logout from "@mui/icons-material/Logout";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import useAuthentication from "../hooks/useAuthentication";
import { CurrentUser } from "../schema/CurrentUser";
import { Role } from "../schema/globalTypes";

interface LogoutMenuProps {
  user: CurrentUser;
}

export default function LogoutMenu({ user }: LogoutMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const history = useHistory();
  const { logout } = useAuthentication();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    history.push("/");
    logout();
    handleClose();
  };

  const { t } = useTranslation();

  return (
    <Box sx={{ display: "flex" }}>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <AccountCircle />
        <Typography fontWeight="200" pl={1} sx={{ textAlign: "center" }}>
          {user.fullName}
        </Typography>
      </IconButton>

      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={menuOpen}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem
          component={Link}
          to="/profilseite"
          sx={{ textDecoration: "none" }}
        >
          <ListItemIcon>
            <AccountCircleRoundedIcon fontSize="medium" />
          </ListItemIcon>
          <div>
            <Typography fontWeight="bold">{user.fullName}</Typography>

            {user.roles.includes(Role.TEACHER) && (
              <Typography sx={{ color: "teacher.600" }} gutterBottom>
                {t("teacher")}
              </Typography>
            )}
            {user.roles.includes(Role.STUDENT) && (
              <Typography sx={{ color: "student.900" }} gutterBottom>
                {t("student")}
              </Typography>
            )}
            {user.roles.includes(Role.ADMIN) && (
              <Typography sx={{ color: "#737475" }} gutterBottom>
                {t("admin")}
              </Typography>
            )}
            {user.roles.includes(Role.ORGANIZATION) && (
              <Typography sx={{ color: "#737475" }} gutterBottom>
                {t("organization")}
              </Typography>
            )}
            {user.roles.includes(Role.INSTRUCTOR) && (
              <Typography sx={{ color: "instructor.800" }} gutterBottom>
                {t("mentor")}
              </Typography>
            )}
          </div>
        </MenuItem>

        <Divider />
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          {t("logout")}
        </MenuItem>
      </Menu>
    </Box>
  );
}
