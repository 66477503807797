import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { UseControllerProps } from "react-hook-form";
import InputDropdown from "../../common/input/InputDropdown";
import { GetTeachersList } from "../../schema/GetTeachersList";

type InputDropdownProps<T> = {
  label?: string;
  required?: boolean;
  autoFocus?: boolean;
  options?: Options[];
  exceptClassId?: string;
} & UseControllerProps<T>;

interface Options {
  label: string;
  id: string;
}

function TeacherDropdown<T>({
  exceptClassId,
  options,
  ...props
}: InputDropdownProps<T>) {
  const GET_TEACHERS_LIST = gql`
    query GetTeachersList {
      teachers {
        userId
        classes {
          classId
          number
        }
        user {
          id
          firstName
          lastName
          email
        }
      }
    }
  `;

  const { data: teachersList } = useQuery<GetTeachersList>(GET_TEACHERS_LIST, {
    fetchPolicy: "cache-and-network",
  });
  const dropdownTeachers = teachersList?.teachers
    .filter(
      (teacher) => !teacher.classes.find((c) => c.classId === exceptClassId)
    )
    .map((teacher) => {
      const userId = teacher.userId;
      const label = teacher.user.firstName + " " + teacher.user.lastName;
      return {
        id: userId,
        label,
      };
    });

  return <InputDropdown options={dropdownTeachers} {...props} />;
}

export default TeacherDropdown;
