import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import EditButton from "../../../common/buttons/EditButton";
import TextBlock from "../../../common/display/TextBlock";
import TextLine from "../../../common/display/TextLine";
import useModal from "../../../hooks/useModal";
import { StudentDetails } from "../../../schema/StudentDetails";
import { UserDetails } from "../../../schema/UserDetails";
import FormSection from "../../FormSection";
import EditMasterDataModal from "../modals/EditMasterDataModal";

interface MasterDataProps {
  data?: StudentDetails | null;
  user?: UserDetails | null;
  studentId: string;
  locked?: boolean;
  loading?: boolean;
}

const FormSectionMasterData = ({
  data,
  user,
  studentId,
  locked,
  loading,
}: MasterDataProps) => {
  const { open, handleOpen, handleClose } = useModal();
  const { t } = useTranslation();
  return (
    <>
      <FormSection
        sectionTitle={t("student")}
        locked={locked}
        loading={loading}
        headerBackgroundColor={"student.100"}
        meta={data?.masterData?.response?.meta}
        sectionAction={<EditButton handleOpen={handleOpen} />}
        canEdit={!!data?.masterData.access.canEdit}
      >
        {data ? (
          <>
            <TextBlock label={t("fullname")}>
              <TextLine value={user ? user.fullName : ""} />
            </TextBlock>
            <TextBlock label={t("class-number")}>
              <TextLine value={data ? data?.class?.number : ""} />
            </TextBlock>
            <TextBlock label={t("supply-area-of-practical")}>
              <TextLine
                value={
                  data ? data?.masterData?.response?.masterData.supplyArea : ""
                }
              />
            </TextBlock>
          </>
        ) : (
          <Typography color="#c8cbcf" textAlign="center" justifyItems="center">
            {t("no-data")}
          </Typography>
        )}
      </FormSection>
      <EditMasterDataModal
        data={{
          firstName: user?.firstName || "",
          lastName: user?.lastName || "",
          classId: data?.class?.classId,
          supplyArea: data?.masterData?.response?.masterData.supplyArea || "",
        }}
        studentId={studentId}
        open={open}
        handleClose={handleClose}
      />
    </>
  );
};

export default FormSectionMasterData;
