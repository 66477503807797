import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import DeleteButton from "../../common/buttons/DeleteButton";
import ConfirmDialog from "../../common/form/ConfirmDialog";
import { GET_STUDENTS } from "../../pages/school/SchoolStudentsPage";
import {
  DeleteStudentMutation,
  DeleteStudentMutationVariables,
} from "../../schema/DeleteStudentMutation";
import { GetStudents } from "../../schema/GetStudents";

interface DeleteStudentButtonProps {
  userId: string;
}

const DELETE_STUDENT = gql`
  mutation DeleteStudentMutation($command: DeleteStudent!) {
    deleteStudent(command: $command)
  }
`;

const DeleteStudentButton = ({ userId }: DeleteStudentButtonProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [deleteStudent] = useMutation<
    DeleteStudentMutation,
    DeleteStudentMutationVariables
  >(DELETE_STUDENT, {
    update(cache, { data }) {
      const getStudentsData = cache.readQuery<GetStudents>({
        query: GET_STUDENTS,
      });

      const students = (getStudentsData?.students || []).filter(
        (x) => x.id !== userId
      );

      cache.writeQuery<GetStudents>({
        query: GET_STUDENTS,
        data: { students },
      });
    },
  });

  const [showConfirmation, setShowConfirmation] = useState(false);
  const { t } = useTranslation();

  const deleteBtnHandler = () => {
    setShowConfirmation(true);
  };

  const handleConfirmHandler = async () => {
    try {
      await deleteStudent({
        variables: {
          command: {
            userId,
          },
        },
      });

      enqueueSnackbar(`${t("student-successfully-deleted")}`, {
        variant: "success",
      });
    } catch (e) {
      const error = e as Error;
      enqueueSnackbar(error.message, { variant: "error" });
    }
    setShowConfirmation(false);
  };

  const handleCancelHandler = () => {
    setShowConfirmation(false);
  };

  return (
    <>
      <DeleteButton onClick={deleteBtnHandler} />
      <ConfirmDialog
        title={t("delete-student")}
        open={showConfirmation}
        message={t("really-delete-student")}
        handleConfirm={handleConfirmHandler}
        handleCancel={handleCancelHandler}
        confirmBtnText={t("delete")}
      />
    </>
  );
};

export default DeleteStudentButton;
