import { format } from "date-fns";
import TextLine from "./TextLine";

interface DisplayDateProps {
  children?: string | null;
  variant?:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "subtitle1"
    | "subtitle2"
    | "body1"
    | "body2"
    | "caption"
    | "button"
    | "overline"
    | "inherit"
    | "sectionFooter"
    | undefined;
}

const DisplayDate = ({ children, variant }: DisplayDateProps) => {
  if (children) {
    return <>{format(new Date(children), "dd.MM.yyyy")}</>;
  }

  return <></>;
};

export default DisplayDate;
