import { Alert, Button } from "@mui/material";
import gql from "graphql-tag";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FormModal from "../../../common/form/FormModal";
import InputPassword from "../../../common/input/InputPassword";
import InputText from "../../../common/input/InputText";
import { generatePassword } from "../../../helpers/helpers";
import { GET_INSTRUCTORS } from "../../../pages/school/Instructors";
import {
  CreateInstructor,
  CreateInstructorVariables,
} from "../../../schema/CreateInstructor";
import { GetInstructors } from "../../../schema/GetInstructors";
import { CreateInstructorInput } from "../../../schema/globalTypes";
import OrganizationDropdown from "../../organization/OrganizationDropdown";

interface AddInstructorModalProps {
  data?: CreateInstructorInput | null;
  open: boolean;
  handleClose: () => void;
}

const CREATE_INSTRUCTOR = gql`
  mutation CreateInstructor($instructorData: CreateInstructorInput!) {
    createInstructor(command: $instructorData) {
      userId
      user {
        id
        email
        firstName
        lastName
        isApproved
      }
    }
  }
`;

export default function AddInstructorModal({
  open,
  handleClose,
}: AddInstructorModalProps) {
  const [isModalInReadOnly, setIsModalInReadOnly] = useState(false);
  const form = useForm<CreateInstructorInput>();
  const { getValues } = form;
  const { t } = useTranslation();

  useEffect(() => {
    if (open) {
      form.reset({
        password: generatePassword(),
      });
      setIsModalInReadOnly(false);
    }
  }, [open, form]);

  const setReadOnly = (success: boolean) => {
    if (success) {
      setIsModalInReadOnly(true);
    } else {
      handleClose();
    }
  };

  return (
    <FormProvider {...form}>
      <FormModal<
        CreateInstructorInput,
        CreateInstructor,
        CreateInstructorVariables
      >
        title={
          isModalInReadOnly
            ? `${t("new-instructor-added")}`
            : `${t("add-instructor")}`
        }
        description={
          isModalInReadOnly
            ? `${t("new-instructor-created-successfully")}`
            : `${t("add-an-instructor")}`
        }
        maxWidth="md"
        makeVars={(instructorData) => ({
          instructorData: { ...instructorData },
        })}
        mutation={CREATE_INSTRUCTOR}
        mutationOptions={{
          update(cache, { data }) {
            const getInstructorsData = cache.readQuery<GetInstructors>({
              query: GET_INSTRUCTORS,
            });

            let instructors = getInstructorsData?.instructors || [];
            if (data?.createInstructor) {
              instructors = [data.createInstructor, ...instructors];
            }

            cache.writeQuery<GetInstructors>({
              query: GET_INSTRUCTORS,
              data: { instructors },
            });
          },
        }}
        actions={
          isModalInReadOnly ? (
            <>
              <Button onClick={handleClose}>
                {isModalInReadOnly ? `${t("close")}` : `${t("cancel")}`}
              </Button>
              <Button variant="contained">
                <a
                  style={{ color: "white", textDecoration: "none" }}
                  href={
                    `mailto:${getValues().email}` +
                    "?" +
                    "subject=" +
                    `${t("invite-mail-subject")}` +
                    "&" +
                    "body=" +
                    `${t("invite-mail-greeting")} ${getValues().firstName} ${
                      getValues().lastName
                    },%0D%0A%0D%0A` +
                    `${t("we-would-like-to-invite-you")} ${
                      getValues().email
                    } ${t("and-password")} ${getValues().password} ${t(
                      "change-password-on-first-login"
                    )}%0D%0A%0D%0A` +
                    `${t("greetings")}%0D%0A${t("your-nursing-school")}`
                  }
                >
                  {t("send-mail")}
                </a>
              </Button>
            </>
          ) : undefined
        }
        open={open}
        handleClose={setReadOnly}
        useContext
      >
        {(control) => (
          <>
            <InputText
              control={control}
              name="firstName"
              label={t("name")}
              required
              autoFocus
              readOnly={isModalInReadOnly}
            />
            <InputText
              control={control}
              name="lastName"
              label={t("lastname")}
              required
              readOnly={isModalInReadOnly}
            />
            <InputText
              control={control}
              name="email"
              label={t("email")}
              required
              readOnly={isModalInReadOnly}
            />
            <OrganizationDropdown
              control={control}
              name="organizationID"
              label={t("choose-organization")}
            />
            <InputPassword
              control={control}
              name="password"
              label={t("password")}
              required
              readOnly={isModalInReadOnly}
            />
            {isModalInReadOnly && (
              <Alert severity="success" sx={{ mt: 1 }}>
                {t("send-password-by-mail-instructor")}
              </Alert>
            )}
          </>
        )}
      </FormModal>
    </FormProvider>
  );
}
