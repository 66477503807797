import { Box } from "@mui/material";
import { FC } from "react";

const TextBlockValue: FC<{}> = ({ children }) => (
  <Box bgcolor="info.main" p={1} sx={{ minHeight: "40px" }}>
    {children}
  </Box>
);

export default TextBlockValue;
