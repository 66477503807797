import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { NewLifecycle, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { relativeDate } from "../../common/utils/date";
import useCurrentUser from "../../hooks/useCurrentUser";
import { Role } from "../../schema/globalTypes";

interface ApprovedByProps {
  approvedAt?: string | null;
  approvedBy?: string | null;
  approvalRequest?: boolean | null;
  approvalRequested?: boolean | null;
  children: ReactNode;
  role: Role;
}

const ApprovedBy = ({
  approvalRequest,
  approvalRequested,
  approvedAt,
  approvedBy,
  children,
  role,
}: ApprovedByProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { user } = useCurrentUser();
  const isRole = user?.roles.includes(role);

  let color = theme.palette.primary.main;
  switch (role) {
    case Role.INSTRUCTOR:
      color = theme.palette.instructor.main;
      break;
    case Role.ORGANIZATION:
      color = theme.palette.organization.main;
      break;
    case Role.TEACHER:
      color = theme.palette.teacher.main;
      break;
    case Role.STUDENT:
      color = theme.palette.student.main;
      break;
  }

  if (approvedAt || (approvalRequest && approvalRequested)) {
    const label = approvalRequest
      ? t(`approval-requested`)
      : t(`approval-${role.toLocaleLowerCase()}`) + " " + t(`approval-given`);
    const approvedRelative = approvedAt ? relativeDate(approvedAt) : "";

    return (
      <div>
        <Typography
          sx={{
            fontSize: "14px",
            textTransform: "uppercase",
            color: color,
          }}
        >
          {label}
        </Typography>
        <Typography
          sx={{
            fontSize: "12px",
            textAlign: "right",
            color: "#00000099",
          }}
        >
          {approvedRelative && <>{approvedRelative}</>}
          {approvedRelative && approvedBy && <>, </>}
          {!!approvedBy && !approvalRequest && <>{approvedBy}</>}
        </Typography>
      </div>
    );
  } else if (isRole) {
    return <>{children}</>;
  } else {
    return (
      <div>
        <Typography
          sx={{
            fontSize: "14px",
            textTransform: "uppercase",
            color: "#c8cbcf",
          }}
        >
          {t(`awaiting-approval-${role.toLocaleLowerCase()}`)}
        </Typography>
      </div>
    );
  }
};

export default ApprovedBy;
