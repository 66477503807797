import { gql, useQuery } from "@apollo/client";
import { USER_DETAILS } from "../fragments/student";
import {
  GetStudentName,
  GetStudentNameVariables,
} from "../schema/GetStudentName";

const GET_STUDENT = gql`
  ${USER_DETAILS}

  query GetStudentName($userId: ID!) {
    student(userId: $userId) {
      id
      user {
        ...UserDetails
      }
    }
  }
`;

const useUsername = (userId: string) => {
  const { data } = useQuery<GetStudentName, GetStudentNameVariables>(
    GET_STUDENT,
    {
      variables: { userId },
      fetchPolicy: "cache-and-network",
    }
  );

  return data?.student.user.fullName || "";
};

export default useUsername;
