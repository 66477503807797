import gql from "graphql-tag";
import React from "react";
import { useTranslation } from "react-i18next";
import FormModal from "../../../common/form/FormModal";
import InputText from "../../../common/input/InputText";
import { STUDENT_MASTER_DATA } from "../../../fragments/student";
import { MasterDataInput } from "../../../schema/globalTypes";
import {
  UpdateMasterData,
  UpdateMasterDataVariables,
} from "../../../schema/UpdateMasterData";
import ClassDropdown from "../../class/ClassDropdown";

interface EditMasterDataModalProps {
  data?: MasterDataInput | null;
  studentId: string;
  open: boolean;
  handleClose: () => void;
}

export default function EditMasterDataModal({
  data,
  studentId,
  open,
  handleClose,
}: EditMasterDataModalProps) {
  const { t } = useTranslation();
  const UPDATE_MASTERDATA = gql`
    ${STUDENT_MASTER_DATA}
    mutation UpdateMasterData($masterData: MasterDataInput!, $studentId: ID!) {
      updateMasterData(command: $masterData, studentId: $studentId) {
        id
        masterData {
          ...MasterData
        }
        user {
          firstName
          lastName
        }
        class {
          classId
          number
        }
      }
    }
  `;

  return (
    <FormModal<MasterDataInput, UpdateMasterData, UpdateMasterDataVariables>
      title={t("edit-student-data")}
      description={t("change-your-details-here")}
      defaultValues={data || undefined}
      makeVars={(masterData) => ({ masterData: { ...masterData }, studentId })}
      mutation={UPDATE_MASTERDATA}
      open={open}
      handleClose={handleClose}
    >
      {(control) => (
        <>
          <InputText
            control={control}
            name="firstName"
            label={t("name")}
            required
          />
          <InputText
            control={control}
            name="lastName"
            label={t("lastname")}
            required
          />
          <ClassDropdown
            name="classId"
            control={control}
            label={t("choose-class")}
          />
          <InputText
            control={control}
            name="supplyArea"
            label={t("supply-area")}
          />
        </>
      )}
    </FormModal>
  );
}
