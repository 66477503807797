import { Box } from "@mui/material";
import DOMPurify from "dompurify";

interface DisplayRichTextProps {
  text?: string | null;
  background?: string;
  padding?: string;
}

const DisplayRichText = ({
  text,
  padding,
  background,
}: DisplayRichTextProps) => {
  var clean = DOMPurify.sanitize;
  return (
    <Box
      my="-1em"
      dangerouslySetInnerHTML={{ __html: clean(text ? text : "") }}
    />
  );
};

export default DisplayRichText;
