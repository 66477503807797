import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    sectionFooter: React.CSSProperties;
    sectionHeader: React.CSSProperties;
    navbarHeader: React.CSSProperties;
    navbarSubHeader: React.CSSProperties;
    subHeader: React.CSSProperties;
    subHeaderDescription: React.CSSProperties;
  }
  interface Palette {
    teacher: Palette["primary"];
    student: Palette["primary"];
    studentTabs: Palette["primary"];
    instructor: Palette["primary"];
    organization: Palette["primary"];
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    sectionFooter?: React.CSSProperties;
    sectionHeader?: React.CSSProperties;
    navbarHeader?: React.CSSProperties;
    navbarSubHeader?: React.CSSProperties;
    subHeader?: React.CSSProperties;
    subHeaderDescription?: React.CSSProperties;
  }

  interface PaletteOptions {
    teacher: PaletteOptions["primary"];
    student: PaletteOptions["primary"];
    studentTabs: PaletteOptions["primary"];
    instructor: PaletteOptions["primary"];
    organization: PaletteOptions["primary"];
  }
  interface PaletteColor {
    darker?: string;
  }
}
declare module "@mui/material/Button" {
  interface TabPropsOverrides {
    standard: true;
  }
  interface ButtonPropsColorOverrides {
    student: true;
    teacher: true;
    instructor: true;
    organization: true;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    sectionFooter: true;
    sectionHeader: true;
    navbarHeader: true;
    navbarSubHeader: true;
    subHeader: true;
    subHeaderDescription: true;
  }
}
/* const snackbarStyles = {
  success: { backgroundColor: "purple" },
  error: { backgroundColor: "blue" },
  warning: { backgroundColor: "green" },
  info: { backgroundColor: "yellow" },
}; */

const theme = createTheme({
  palette: {
    primary: {
      main: "#3F616F",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#B6DE2C",
      light: "#ECFBC6",
      dark: "#A7C720",
    },
    info: {
      main: "#f3f3f3",
      contrastText: "#707070",
    },
    success: {
      main: "#96C11E",
      contrastText: "#00483D",
    },
    warning: {
      main: "#00A19A",
    },
    error: {
      main: "#B00020",
      contrastText: "#FFFFFF",
    },
    teacher: {
      main: "#3F616F",
      900: "#213842",
      800: "#314D5A",
      600: "#4E7585",
      500: "#5A8497",
      400: "#7297A7",
      300: "#8AAAB9",
      200: "#AAC3CF",
      100: "#C7DCE5",
      50: "#E5F1F9",
      dark: "#123744",
      light: "#6C8E9D",
      contrastText: "#ffffff",
    },
    student: {
      main: "#B6DE2C",
      contrastText: "white",
      light: "#d4f778",
      dark: "#a7c720",
      900: "#828800",
      800: "#99b013",
      600: "#b6de2c",
      500: "#c1f134",
      400: "#caf457",
      300: "#d4f778",
      200: "#e0faa0",
      100: "#ecfbc6",
      50: "#f7fee8",
    },
    instructor: {
      main: "#00a19a",
      contrastText: "white",
      dark: "#00645a",
      light: "#41b2ab",
      900: "#00483d",
      800: "#00645a",
      700: "#007469",
      600: "#008479",
      500: "#009086",
      300: "#41b2ab",
      200: "#7ac8c4",
      100: "#afdddb",
      50: "#dff1f1",
    },
    organization: {
      main: "#a11086",
      light: "#bf5ea8",
      dark: "#750073",
      900: "#5a0065",
      800: "#750073",
      700: "#84017b",
      600: "#950a82",
      400: "#b13a96",
      300: "#bf5ea8",
      200: "#d38cc1",
      100: "#e4bad9",
      50: "#f4e3ef",
      contrastText: "#ffffff",
    },
    studentTabs: {
      //foreground: "#ECFBC6",
      main: "#A7C720",
    },
  },

  components: {
    MuiContainer: {
      styleOverrides: {
        root: ({ theme }) => ({
          [theme.breakpoints.up("lg")]: {
            maxWidth: "1100px",
          },
        }),
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          '&[class*="variantInfo"]': {
            backgroundColor: "red",
          },
          // .. etc for other variants
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
        margin: "normal",
      },
    },
  },

  typography: {
    navbarHeader: {
      fontFamily: "'Saira Stencil One'",
      fontSize: "1.1rem",
      fontWeight: "400",
      letterSpacing: "0.9px",
      textTransform: "uppercase",
    },
    navbarSubHeader: {
      fontFamily: "'Times New Roman'",
      fontSize: "1.1rem",
      fontWeight: "400",
      letterSpacing: "0.9px",
      textTransform: "uppercase",
    },
    sectionFooter: {
      color: "#a2a4a6",
      fontSize: "0.8rem",
    },
    sectionHeader: {
      fontSize: "1.2rem",
      fontWeight: 300,
    },
    subHeader: {
      fontWeight: 600,
      display: "block",
      marginBottom: "0.7rem",
    },
    subHeaderDescription: {
      fontWeight: 500,
      display: "block",
      marginBottom: "1.5rem",
    },
  },
});

export default theme;
