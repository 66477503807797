import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import React from "react";
import { useTranslation } from "react-i18next";
import Page from "../../common/layout/Page";
import Practical from "../../components/student/Practical";
import { STUDENT_PRACTICAL } from "../../fragments/student";
import {
  GetCurrentPracticalVisit,
  GetCurrentPracticalVisitVariables,
} from "../../schema/GetCurrentPracticalVisit";

interface PracticalVisitProps {
  practicalId: string;
}

export const GET_CURRENT_PRACTICAL_VISIT = gql`
  ${STUDENT_PRACTICAL}

  query GetCurrentPracticalVisit($practicalId: ID!) {
    practical(practicalId: $practicalId) {
      ...Practical
      student {
        id
        user {
          firstName
          lastName
          fullName
        }
      }
    }
  }
`;

const PracticalVisit = ({ practicalId }: PracticalVisitProps) => {
  const { data } = useQuery<
    GetCurrentPracticalVisit,
    GetCurrentPracticalVisitVariables
  >(GET_CURRENT_PRACTICAL_VISIT, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    variables: { practicalId },
  });
  const { t } = useTranslation();

  return (
    <Page
      breadcrumb={[
        {
          name: `${t("my-practical-visits")}`,
          to: "/ausbildung/praxisbegleitungen",
        },
        {
          name: `${
            `${t("practical-visit")} ` + data?.practical.student.user.fullName
          }`,
          to: "/praxisbegleitungen/:einsatzId",
        },
      ]}
      title={
        data
          ? `${
              `${t("practical-visit")} ` + data?.practical.student.user.fullName
            }`
          : `${t("loading")}`
      }
      pageHeading={
        data
          ? `${
              `${t("practical-visit-of")} ` +
              data.practical.student.user.fullName
            }`
          : `${t("loading")}`
      }
      subHeading={
        data
          ? `${t("practicalType." + data.practical.practicalType)}`
          : `${t("loading")}`
      }
    >
      {data && (
        <Practical
          practicalId={practicalId}
          studentId={data.practical.student.id}
        />
      )}
    </Page>
  );
};
export default PracticalVisit;
