import { gql, useMutation } from "@apollo/client";
import { STUDENT_PRACTICAL } from "../../fragments/student";
import { Role } from "../../schema/globalTypes";
import {
  SignInstructionProtocols,
  SignInstructionProtocolsVariables,
} from "../../schema/SignInstructionProtocols";
import ApproveButton from "./ApproveButton";

interface ApproveButtonInstructionProtocolsProps {
  canApprove: boolean;
  practicalId: string;
  role: Role;
}

const SIGN_INSTRUCTION_PROTOCOL = gql`
  ${STUDENT_PRACTICAL}
  mutation SignInstructionProtocols($practicalId: ID!) {
    signInstructionProtocols(practicalId: $practicalId) {
      ...Practical
    }
  }
`;

const ApproveButtonInstructionProtocols = ({
  canApprove,
  practicalId,
  role,
}: ApproveButtonInstructionProtocolsProps) => {
  const [mutate] = useMutation<
    SignInstructionProtocols,
    SignInstructionProtocolsVariables
  >(SIGN_INSTRUCTION_PROTOCOL);

  const handleApprove = async () => {
    return mutate({ variables: { practicalId } }).then(() => {
      return;
    });
  };

  return (
    <ApproveButton
      canApprove={canApprove}
      handleApprove={handleApprove}
      role={role}
    />
  );
};

export default ApproveButtonInstructionProtocols;
