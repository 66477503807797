import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";
import React, { FC } from "react";
import useAuthentication from "../../hooks/useAuthentication";
import { onError } from "@apollo/client/link/error";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

const AuthenticatedApolloProvider: FC<{}> = ({ children }) => {
  const { getToken, logout } = useAuthentication();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const httpLink = createHttpLink({
    uri: "/query",
  }) as unknown as ApolloLink;

  const uploadLink = createUploadLink({
    uri: "/query",
  }) as unknown as ApolloLink;

  const authLink = setContext((op, { headers }) =>
    getToken().then((token) => ({
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    }))
  );

  const cleanTypeName = new ApolloLink((operation, forward) => {
    if (operation.variables) {
      const omitTypename = (key: any, value: any) =>
        key === "__typename" ? undefined : value;
      operation.variables = JSON.parse(
        JSON.stringify(operation.variables),
        omitTypename
      );
    }
    return forward(operation).map((data) => {
      return data;
    });
  });

  // Log any GraphQL errors or network error that occurred
  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    // raw "response" set by apollo-link-http
    const { response } = operation.getContext();
    if (response.status === 401) {
      logout();
    } else if (!!graphQLErrors) {
      graphQLErrors.forEach((e) => {
        enqueueSnackbar(t(e.message), {
          variant: "error",
        });
      });
    } else if (!!networkError) {
      console.error(networkError);
      enqueueSnackbar(t("network-error"), {
        variant: "error",
      });
    }
  });

  const link = ApolloLink.split(
    (operation) => operation.getContext().hasUpload,
    ApolloLink.from([authLink, errorLink, uploadLink]),
    ApolloLink.from([authLink, errorLink, cleanTypeName, httpLink])
  );

  const cache = new InMemoryCache({
    typePolicies: {
      Class: {
        keyFields: ["classId"],
      },
      Teacher: {
        keyFields: ["userId"],
      },
      Organization: {
        keyFields: ["userId"],
      },
    },
  });

  const client = new ApolloClient({
    link,
    cache,
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default AuthenticatedApolloProvider;
