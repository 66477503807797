import { Grid, Typography } from "@mui/material";
import { FC } from "react";
import TextBlockValue from "./TextBlockValue";

interface TextBlockProps {
  label?: string | null;
}

const TextBlock: FC<TextBlockProps> = ({ label, children }) => (
  <Grid container direction={{ xs: "column", md: "row" }}>
    <Grid item xs={12} md={4} py={1}>
      {label && (
        <Typography
          sx={{
            fontWeight: 300,
            fontSize: 13,
          }}
        >
          {label}
        </Typography>
      )}
    </Grid>
    <Grid item xs={12} md={8}>
      {children && <TextBlockValue>{children}</TextBlockValue>}
    </Grid>
  </Grid>
);

export default TextBlock;
