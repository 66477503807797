import { useMutation } from "@apollo/client";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import gql from "graphql-tag";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ConfirmDialog from "../../../common/form/ConfirmDialog";
import useModal from "../../../hooks/useModal";
import { Role } from "../../../schema/globalTypes";
import { SelectRole, SelectRoleVariables } from "../../../schema/SelectRole";
import SchoolDropdown from "../../school/SchoolDropdown";
import SelectRoleModal from "../modals/SelectRoleModal";

interface SelectRoleButtonProps {
  backgroundColor?: string;
  role: Role;
  confirmButtonColor?: string;
}

const SelectRoleButton = ({
  backgroundColor,
  role,
  confirmButtonColor,
}: SelectRoleButtonProps) => {
  const { t } = useTranslation();
  const { open, handleOpen, handleClose } = useModal();

  return (
    <>
      <Button
        variant="contained"
        sx={{
          backgroundColor: { backgroundColor },
          marginTop: "40px",
          width: "125px",
        }}
        onClick={handleOpen}
      >
        {t("choose-now")}
      </Button>

      <SelectRoleModal role={role} handleClose={handleClose} open={open} />
    </>
  );
};

export default SelectRoleButton;
