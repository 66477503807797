import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

interface PageNavigationProps {
  entries: PageNavigationEntry[];
}

export interface PageNavigationEntry {
  name: string;
  id: string;
}

const PageNavigation: React.FC<PageNavigationProps> = ({
  entries,
}: PageNavigationProps) => {
  const { t } = useTranslation();

  return (
    <>
      <nav aria-label="seitennavigation">
        <Typography
          sx={{
            textTransform: "uppercase",
            fontSize: "12px",
            letterSpacing: "1.88px",
            fontWeight: 500,
          }}
        >
          {t("page-content")}
        </Typography>
        <List disablePadding>
          {entries.map((entry) => {
            return (
              <ListItem
                sx={{
                  padding: 0,
                  margin: 0,
                  borderLeft: "1px solid #fff",
                  "&:hover": {
                    "& .navButton": {
                      color: "primary.main",
                      background: "white",
                      borderLeft: "1px solid #3f616f",
                    },
                  },
                }}
                key={entry.id}
              >
                <ListItemButton
                  className="navButton"
                  sx={{
                    padding: 0,
                  }}
                  component="a"
                  href={entry.id}
                >
                  <ListItemText
                    sx={{
                      py: 0.5,
                    }}
                    primary={
                      <Typography sx={{ fontSize: "0.8rem" }}>
                        {entry.name}
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </nav>
    </>
  );
};
export default PageNavigation;
