import { Paper } from "@mui/material";
import { useTranslation } from "react-i18next";
import Page from "../../common/layout/Page";

function TrainingCertificate() {
  const { t } = useTranslation();

  return (
    <Page
      breadcrumb={[
        {
          name: `${t("proof-of-training")}`,
          to: "/ausbildungsnachweis",
        },
      ]}
      title={t("proof-of-training")}
      pageHeading="Dein Ausbildungsnachweis"
      subHeading="Hier findest Du alle Informationen zu Deinem Ausbildungsnachweis."
    >
      <Paper></Paper>
    </Page>
  );
}

export default TrainingCertificate;
