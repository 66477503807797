import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Divider, Grid, IconButton, Stack } from "@mui/material";
import gql from "graphql-tag";
import { useEffect, useRef } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import AddButton from "../../../common/buttons/AddButton";
import FormField from "../../../common/form/FormField";
import FormModal from "../../../common/form/FormModal";
import Label from "../../../common/form/Label";
import InputCheckbox from "../../../common/input/InputCheckbox";
import InputDate from "../../../common/input/InputDate";
import InputRichText from "../../../common/input/InputRichText";
import InputText from "../../../common/input/InputText";
import { STUDENT_PRACTICAL } from "../../../fragments/student";
import {
  InterviewProtocolTeacherInput,
  InterviewReason,
} from "../../../schema/globalTypes";
import {
  UpdateInterviewProtocolTeacher,
  UpdateInterviewProtocolTeacherVariables,
} from "../../../schema/UpdateInterviewProtocolTeacher";
import InputInterviewReason from "../input/InputInterviewReason";

interface EditInterviewProtocolTeacherModalProps {
  data?: InterviewProtocolTeacherInput | null;
  practicalId: string;
  open: boolean;
  handleClose: () => void;
}

export default function EditInterviewProtocolTeacherModal({
  data,
  practicalId,
  open,
  handleClose,
}: EditInterviewProtocolTeacherModalProps) {
  const UPDATE_INTERVIEW_PROTOCOL_TEACHER = gql`
    ${STUDENT_PRACTICAL}
    mutation UpdateInterviewProtocolTeacher(
      $interviewProtocol: InterviewProtocolTeacherInput!
      $practicalId: ID!
    ) {
      updateInterviewProtocolTeacher(
        command: $interviewProtocol
        practicalId: $practicalId
      ) {
        ...Practical
      }
    }
  `;

  const form = useForm<InterviewProtocolTeacherInput>();
  const { control, reset, watch } = form;
  const interviewReason = watch("interviewReason", []);
  const showOther = interviewReason.includes(InterviewReason.OTHER);
  const interviewReasonOtherDescription = watch(
    "interviewReasonOtherDescription"
  );
  const { fields, append, remove } = useFieldArray({
    control,
    name: "attendedByOther",
  });

  const { t } = useTranslation();

  const inputRefOtherReasonText = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const initialData: InterviewProtocolTeacherInput = data || {
      date: new Date().toISOString(),
      attendedByStudent: false,
      attendedByInstructor: false,
      attendedByTeacher: false,
      attendedByOther: [],
      interviewReason: [],
      interviewReasonOtherDescription: "",
      descriptionSituation: "",
      descriptionOther: "",
      descriptionResults: "",
    };
    reset(initialData);
  }, [data, practicalId, reset, open]);

  useEffect(() => {
    if (showOther === false && interviewReasonOtherDescription !== "") {
      form.setValue("interviewReasonOtherDescription", "");
    }
  }, [showOther, interviewReasonOtherDescription, form]);

  useEffect(() => {
    if (showOther) {
      inputRefOtherReasonText.current?.focus();
    }
  }, [showOther]);

  return (
    <FormProvider {...form}>
      <FormModal<
        InterviewProtocolTeacherInput,
        UpdateInterviewProtocolTeacher,
        UpdateInterviewProtocolTeacherVariables
      >
        title={t("edit-practical-assistance")}
        description={t("edit-practical-assistance-here")}
        maxWidth="lg"
        defaultValues={data || undefined}
        mutation={UPDATE_INTERVIEW_PROTOCOL_TEACHER}
        open={open}
        handleClose={handleClose}
        makeVars={(interviewProtocolTeacher) => {
          return {
            interviewProtocol: interviewProtocolTeacher,
            practicalId,
          };
        }}
        useContext
      >
        {(control) => (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Label
                  label={t("date-of-practical-assistance")}
                  color="#95C11F"
                />
                <Box width="14em">
                  <InputDate
                    name="date"
                    control={control}
                    label={t("date")}
                    required
                    autoFocus
                  />
                </Box>
              </Grid>
              <Grid item xs={12} alignSelf="center">
                <Grid item xs={12}>
                  <Label label={t("attendees")} color="#95C11F" />
                </Grid>
                <Stack direction={"column"}>
                  <Grid item xs={12}>
                    <InputCheckbox
                      name="attendedByStudent"
                      label={t("student")}
                      control={control}
                      defaultValue={data?.attendedByStudent}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputCheckbox
                      name="attendedByInstructor"
                      label={t("mentor")}
                      control={control}
                      defaultValue={data?.attendedByInstructor}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      width="100%"
                      justifyContent="space-between"
                    >
                      <InputCheckbox
                        name="attendedByTeacher"
                        label={t("teacher-nursing-school")}
                        control={control}
                        defaultValue={data?.attendedByTeacher}
                        required
                      />
                      <AddButton
                        onClick={() =>
                          append({
                            name: "",
                            email: "",
                            role: "",
                          })
                        }
                        buttonLabel={t("additional-person")}
                        variant="outlined"
                        color="success"
                      />
                    </Box>
                  </Grid>
                </Stack>
              </Grid>
              {fields.length > 0 && (
                <Grid item xs={12}>
                  <Label
                    label={t("additional-persons-label")}
                    color="#95C11F"
                  />
                  {fields.map((field, index) => {
                    return (
                      <Grid
                        container
                        key={field.id}
                        direction="row"
                        rowSpacing={{ md: 1 }}
                        columnSpacing={{ xs: 1, md: 2 }}
                      >
                        <Grid item xs={12} md={3}>
                          <InputText<InterviewProtocolTeacherInput>
                            name={`attendedByOther.${index}.name` as const}
                            key={field.id}
                            control={control}
                            label={t("fullname")}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <InputText
                            key={field.id}
                            name={`attendedByOther.${index}.email` as const}
                            control={control}
                            label={t("email")}
                          />
                        </Grid>
                        <Grid item xs={10} md={3}>
                          <InputText
                            key={field.id}
                            name={`attendedByOther.${index}.role` as const}
                            control={control}
                            label={t("role")}
                          />
                        </Grid>

                        <Grid item xs={2} md={3} alignSelf="center">
                          <IconButton
                            size="large"
                            onClick={() => remove(index)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              )}
              <Grid item xs={12} alignSelf="center">
                <Grid item xs={12}>
                  <Label label={t("interview-reason")} color="#95C11F" />
                </Grid>
                <Stack direction="column">
                  <Grid item xs={12}>
                    <InputInterviewReason
                      name="interviewReason"
                      label={t("interview-reason")}
                      control={control}
                    />
                  </Grid>
                </Stack>
                <Grid item xs={12}>
                  <InputText
                    name="interviewReasonOtherDescription"
                    label={t("other-reason")}
                    control={control}
                    disabled={!showOther}
                    ref={inputRefOtherReasonText}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} mt={4}>
                <Divider sx={{ display: { md: "none" } }} />
                <Label label={t("reflection-on-education")} color="#95C11F" />
                <FormField
                  info={t("interviewProtocol.TEACHER.descriptionSituation")}
                >
                  <InputRichText
                    name="descriptionSituation"
                    control={control}
                    label={t("reflection-on-education")}
                    multiline
                  />
                </FormField>
              </Grid>
              <Grid item xs={12} mt={4}>
                <Divider sx={{ display: { md: "none" } }} />
                <Label label={t("other-topics-and-protocol")} color="#95C11F" />
                <FormField
                  info={t("interviewProtocol.TEACHER.descriptionOther")}
                >
                  <InputRichText
                    name="descriptionOther"
                    control={control}
                    label={t("other-topics-and-protocol")}
                    multiline
                  />
                </FormField>
              </Grid>
              <Grid item xs={12} mt={4}>
                <Divider sx={{ display: { md: "none" } }} />
                <Label
                  label={t("result-and-further-agreements")}
                  color="#95C11F"
                />
                <FormField>
                  <InputRichText
                    name="descriptionResults"
                    control={control}
                    label={t("result-and-further-agreements")}
                    multiline
                  />
                </FormField>
              </Grid>
            </Grid>
          </>
        )}
      </FormModal>
    </FormProvider>
  );
}
