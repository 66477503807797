import { Alert, Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import EditButton from "../../../common/buttons/EditButton";
import DisplayDate from "../../../common/display/DisplayDate";
import DisplayRichText from "../../../common/display/DisplayRichText";
import TextBlock from "../../../common/display/TextBlock";
import TextLine from "../../../common/display/TextLine";
import useModal from "../../../hooks/useModal";
import { InterviewProtocolType, Role } from "../../../schema/globalTypes";
import { InterviewProtocol } from "../../../schema/InterviewProtocol";
import ApproveButtonInterviewProtocol from "../../approval/ApproveButtonInterviewProtocol";
import ApprovedBy from "../../approval/ApprovedBy";
import RejectButtonInterviewProtocol from "../../approval/RejectButtonInterviewProtocol";
import RequestApproveButtonInterviewProtocol from "../../approval/RequestApproveButtonInterviewProtocol";
import FormSection from "../../FormSection";
import EditInterviewProtocolModal from "../../student/modals/EditInterviewProtocolModal";

interface InterviewProtocolProps {
  data: InterviewProtocol | null;
  protocolType: InterviewProtocolType;
  practicalId: string;
  interviewProtocolTitle: string;
}

const FormSectionInterviewProtocol = ({
  data,
  protocolType,
  practicalId,
  interviewProtocolTitle,
}: InterviewProtocolProps) => {
  const { open, handleOpen, handleClose } = useModal();
  const { t } = useTranslation();

  let protocol = undefined;
  if (data?.response?.interviewProtocol) {
    if (data?.response?.interviewProtocol) {
      const { id, ...rest } = data?.response?.interviewProtocol;
      protocol = rest;
    }
  }

  return (
    <>
      <FormSection
        sectionTitle={interviewProtocolTitle}
        meta={data?.response?.meta}
        sectionAction={<EditButton handleOpen={handleOpen} />}
        canEdit={!!data?.access.canEdit}
        headerBackgroundColor={"student.100"}
      >
        <Stack spacing={2}>
          <Alert severity="info" color="success">
            Die Gesprächssequenzen, die i. d. R. am Beginn, in der Mitte und am
            Ende des Einsatzes geführt werden, werden als Erst-, Zwischen- und
            Abschlussgespräch dokumentiert. Diese Gespräche werden zwischen
            Ihnen und den Praxisanleitenden geführt. Es wird empfohlen, dass Sie
            die Gesprächsinhalte selbst dokumentieren. Im Vorfeld wird Ihnen
            ausreichend Gelegenheit gegeben, sich auf die Gespräche
            vorzubereiten.
            <br />
            <br />
            {protocolType == InterviewProtocolType.FIRST ? (
              <>
                Beim Erstgespräch wird gemeinsam der Ausbildungsstand
                reflektiert, hiervon ausgehend werden die Ziele des
                Praxiseinsatzes – anzubahnende Kompetenzen und individuellen
                Ziele der Auszubildenden – in Abhängigkeit vom Ausbildungsplan,
                dem Lernangebot der Einrichtung und den Erwartungen der
                Auszubildenden festgelegt. Die individuellen Ziele der
                Auszubildenden sind umfassend zu verstehen. Sie können neben den
                fachlich und methodisch orientierten Lernzielen auch solche der
                Persönlichkeitsentwicklung, der beruflichen Identität oder der
                Einbindung ins Team bedeuten. Hieraus leiten die
                Gesprächspartner Arbeits- und Lernaufgaben9 ab, die separat im
                Dokument Arbeits- und Lernaufgaben festgehalten werden. Um die
                Verzahnung mit den Inhalten der theoretischen Ausbildung zu
                gewährleisten, wird im entsprechenden Feld des
                Gesprächsprotokolls auch dokumentiert, welche Lern- und
                Arbeitsaufgaben10, abgeleitet aus dem schuleigenen Curriculum,
                von Seiten der Pflegeschule für diesen Einsatz vorgesehen sind.
              </>
            ) : protocolType == InterviewProtocolType.INTERMEDIATE ? (
              <>
                Es wird empfohlen, ein Zwischengespräch nur dann zu führen, wenn
                der Einsatz mehr als vier Wochen dauert oder wenn ein besonderer
                Anlass besteht. Im Zwischengespräch, wird auf der Basis der
                vereinbarten Ziele des Praxiseinsatzes eine Zwischenbilanz
                gezogen. Davon ausgehend können für die restliche Zeit des
                Einsatzes die Ziele angepasst und weitere Vereinbarungen
                getroffen und dokumentiert werden.
              </>
            ) : (
              <>
                Im Abschlussgespräch wird der Lernfortschritt im Vergleich mit
                dem Beginn des Einsatzes und den gesetzten Zielen gemeinsam
                reflektiert. Den Auszubildenden wird hier die Möglichkeit
                gegeben, ihre persönliche Kompetenzentwicklung auf der Grundlage
                der im Erstgespräch formulierten anzubahnenden Kompetenzen und
                individuellen Ziele festzustellen. Die Auszubildenden erhalten
                eine qualifizierte Leistungseinschätzung.11 Diese wird
                erläutert, und es werden gemeinsam Empfehlungen für den weiteren
                Verlauf der Ausbildung dokumentiert.
              </>
            )}
          </Alert>
          {data?.response ? (
            <Stack spacing={5}>
              <Stack spacing={1}>
                <TextBlock label={t("interview-held-on")}>
                  <DisplayDate>
                    {data?.response?.interviewProtocol?.date}
                  </DisplayDate>
                </TextBlock>

                <TextBlock label={t("attendees")}>
                  <TextLine
                    value={
                      data?.response?.interviewProtocol?.attendedByStudent
                        ? `${t("student")}`
                        : ""
                    }
                  />
                  <TextLine
                    value={
                      data?.response?.interviewProtocol?.attendedByInstructor
                        ? `${t("mentor")}`
                        : ""
                    }
                  />
                  {data?.response?.interviewProtocol?.attendedByOther.map(
                    (person, index) => (
                      <TextLine key={index} value={person.name} />
                    )
                  )}
                </TextBlock>
                <TextBlock label={t("reflection-on-education")}>
                  <DisplayRichText
                    text={
                      data.response?.interviewProtocol?.descriptionSituation
                    }
                  />
                </TextBlock>
                <TextBlock label={t("practical-goal")}>
                  <DisplayRichText
                    text={data.response?.interviewProtocol?.descriptionGoals}
                  />
                </TextBlock>
                <TextBlock label={t("practical-tasks-nursing-school")}>
                  <DisplayRichText
                    text={
                      data.response?.interviewProtocol?.descriptionExercises
                    }
                  />
                </TextBlock>
                <TextBlock label={t("result-and-agreement")}>
                  <DisplayRichText
                    text={data.response?.interviewProtocol?.descriptionResults}
                  />
                </TextBlock>
              </Stack>
              <Stack spacing={2}>
                <Typography variant="sectionHeader">
                  {t("signatures")}
                </Typography>
                <Alert severity="info" color="success">
                  {t("signatures-info")}
                </Alert>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-end"
                >
                  {data?.response?.interviewProtocol && (
                    <Stack spacing={2}>
                      <ApprovedBy
                        role={Role.STUDENT}
                        approvedBy={
                          data.response.signatures?.signedByStudent?.fullName
                        }
                        approvalRequest
                        approvalRequested={
                          data.response.signatures?.readyForApproval
                        }
                      >
                        <RequestApproveButtonInterviewProtocol
                          canApprove={!!data.response.signatures?.canApprove}
                          protocolId={data.response.interviewProtocol.id}
                          role={Role.STUDENT}
                        />
                      </ApprovedBy>
                      <ApprovedBy
                        role={Role.INSTRUCTOR}
                        approvedAt={
                          data.response.signatures?.signedByInstructorAt
                        }
                        approvedBy={
                          data.response.signatures?.signedByInstructor?.fullName
                        }
                      >
                        <ApproveButtonInterviewProtocol
                          protocolId={data.response.interviewProtocol.id}
                          role={Role.INSTRUCTOR}
                          canApprove={
                            !!data.response.signatures?.readyForApproval
                          }
                        />
                        <RejectButtonInterviewProtocol
                          protocolId={data.response.interviewProtocol.id}
                          role={Role.INSTRUCTOR}
                          canApprove={
                            !!data.response.signatures?.readyForApproval
                          }
                        />
                      </ApprovedBy>
                      <ApprovedBy
                        role={Role.STUDENT}
                        approvedAt={data.response.signatures?.signedByStudentAt}
                        approvedBy={
                          data.response.signatures?.signedByStudent?.fullName
                        }
                      >
                        <ApproveButtonInterviewProtocol
                          canApprove={
                            !!data.response.signatures?.signedByInstructorAt
                          }
                          protocolId={data.response.interviewProtocol.id}
                          role={Role.STUDENT}
                        />
                      </ApprovedBy>
                    </Stack>
                  )}
                </Box>
              </Stack>
            </Stack>
          ) : (
            <Typography
              color="#c8cbcf"
              textAlign="center"
              justifyItems="center"
            >
              {t("no-data")}
            </Typography>
          )}
        </Stack>
      </FormSection>
      <EditInterviewProtocolModal
        data={protocol}
        practicalId={practicalId}
        protocolType={protocolType}
        open={open}
        handleClose={handleClose}
      />
    </>
  );
};

export default FormSectionInterviewProtocol;
