import gql from "graphql-tag";
import { useTranslation } from "react-i18next";
import FormModal from "../../../common/form/FormModal";
import Label from "../../../common/form/Label";
import InputCheckbox from "../../../common/input/InputCheckbox";
import InputText from "../../../common/input/InputText";
import { UpdateTeacherInput } from "../../../schema/globalTypes";
import {
  UpdateTeacher,
  UpdateTeacherVariables,
} from "../../../schema/UpdateTeacher";

interface EditTeacherModalProps {
  data?: UpdateTeacherInput | null;
  open: boolean;
  userId: string;
  handleClose: () => void;
}

const UPDATE_TEACHER = gql`
  mutation UpdateTeacher($teacherData: UpdateTeacherInput!) {
    updateTeacher(command: $teacherData) {
      userId
      user {
        id
        email
        firstName
        lastName
      }
      isSchoolAdmin
    }
  }
`;

export default function EditTeacherModal({
  data,
  open,
  handleClose,
}: EditTeacherModalProps) {
  const { t } = useTranslation();

  return (
    <FormModal<UpdateTeacherInput, UpdateTeacher, UpdateTeacherVariables>
      title={t("edit-teacher-data")}
      description={t("change-teacher-details")}
      maxWidth="md"
      defaultValues={data || undefined}
      makeVars={(teacherData) => ({
        teacherData: { ...teacherData },
      })}
      mutation={UPDATE_TEACHER}
      open={open}
      handleClose={handleClose}
    >
      {(control) => (
        <>
          <InputText
            control={control}
            name="firstName"
            label={t("name")}
            required
          />
          <InputText
            control={control}
            name="lastName"
            label={t("lastname")}
            required
          />
          <InputText
            control={control}
            name="email"
            label={t("email")}
            required
          />
          <Label label={t("is-teacher-school-admin")} marginTop={2} />
          <InputCheckbox
            name="isSchoolAdmin"
            label={t("school-admin")}
            control={control}
            defaultValue={data?.isSchoolAdmin}
          />
        </>
      )}
    </FormModal>
  );
}
