import { gql, useMutation } from "@apollo/client";
import { STUDENT_PRACTICAL } from "../../fragments/student";
import { Role } from "../../schema/globalTypes";
import {
  SignInterviewProtocol,
  SignInterviewProtocolVariables,
} from "../../schema/SignInterviewProtocol";
import ApproveButton from "./ApproveButton";

interface ApproveButtonInterviewProtocolProps {
  canApprove: boolean;
  role: Role;
  protocolId: string;
}

const SIGN_INTERVIEW_PROTOCOL = gql`
  ${STUDENT_PRACTICAL}
  mutation signInterviewProtocolTeacher($interviewProtocolId: ID!) {
    signInterviewProtocol(interviewProtocolId: $interviewProtocolId) {
      ...Practical
    }
  }
`;

const ApproveButtonInterviewProtocol = ({
  canApprove,
  role,
  protocolId,
}: ApproveButtonInterviewProtocolProps) => {
  const [mutate] = useMutation<
    SignInterviewProtocol,
    SignInterviewProtocolVariables
  >(SIGN_INTERVIEW_PROTOCOL);

  const handleApprove = async () => {
    return mutate({ variables: { interviewProtocolId: protocolId } }).then(
      () => {
        return;
      }
    );
  };

  return (
    <ApproveButton
      canApprove={canApprove}
      handleApprove={handleApprove}
      role={role}
    />
  );
};

export default ApproveButtonInterviewProtocol;
