import { Input } from "@mui/material";
import gql from "graphql-tag";
import React, { useEffect, useRef } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FormModal from "../common/form/FormModal";
import InputText from "../common/input/InputText";
import { CreateFile, CreateFileVariables } from "../schema/CreateFile";
import { GetFiles, GetFilesVariables } from "../schema/GetFiles";
import { CreateFileInput } from "../schema/globalTypes";
import { GET_FILES } from "./UploadFileSection";

interface UploadFileModalProps {
  data?: CreateFileInput | null;
  title: string;
  description: string;
  open: boolean;
  referenceType: string;
  referenceId: string;
  handleClose: () => void;
}

interface UploadFileModalData {
  name: string;
  files: FileList;
}

export default function UploadFileModal({
  data,
  title,
  description,
  open,
  handleClose,
  referenceType,
  referenceId,
}: UploadFileModalProps) {
  const UPLOAD_FILE = gql`
    mutation CreateFile(
      $fileData: CreateFileInput!
      $referenceData: ReferenceInput!
    ) {
      createFile(command: $fileData, reference: $referenceData) {
        file {
          name
          id
        }
        meta {
          createdAt
          createdBy {
            fullName
          }
        }
        access {
          canEdit
          canView
        }
      }
    }
  `;

  const { t } = useTranslation();
  const form = useForm<UploadFileModalData>();
  const { reset, watch } = form;
  const fileName = watch("files");

  useEffect(() => {
    if (fileName && fileName[0]) {
      form.setValue("name", fileName[0].name);
      inputRefUpload.current?.focus();
    }
  });
  useEffect(() => {
    if (open) {
      form.reset({
        name: "",
      });
    }
  }, [open, form]);

  const inputRefUpload = useRef<HTMLInputElement>(null);

  return (
    <FormProvider {...form}>
      <FormModal<UploadFileModalData, CreateFile, CreateFileVariables>
        title={title}
        description={description}
        maxWidth="sm"
        defaultValues={data || undefined}
        mutation={UPLOAD_FILE}
        open={open}
        handleClose={handleClose}
        makeVars={(data) => ({
          fileData: {
            name: data.name,
            upload: data.files[0],
          },
          referenceData: { id: referenceId, type: referenceType },
        })}
        hasUpload
        mutationOptions={{
          update(cache, { data }) {
            const getFilesData = cache.readQuery<GetFiles, GetFilesVariables>({
              query: GET_FILES,
              variables: {
                reference: {
                  id: referenceId,
                  type: referenceType,
                },
              },
            });

            let files = getFilesData?.files || [];
            if (data?.createFile) {
              files = [data.createFile, ...files];
            }

            cache.writeQuery<GetFiles, GetFilesVariables>({
              query: GET_FILES,
              variables: {
                reference: {
                  id: referenceId,
                  type: referenceType,
                },
              },
              data: { files },
            });
          },
        }}
        useContext
      >
        {(control) => (
          <>
            <Input {...control.register("files")} type="file" />
            <InputText
              control={control}
              name="name"
              label={t("filename")}
              ref={inputRefUpload}
              required
            />
          </>
        )}
      </FormModal>
    </FormProvider>
  );
}
