import gql from "graphql-tag";
import { useTranslation } from "react-i18next";
import FormModal from "../../../common/form/FormModal";
import InputText from "../../../common/input/InputText";
import { UpdateOrganizationInput } from "../../../schema/globalTypes";
import {
  UpdateOrganization,
  UpdateOrganizationVariables,
} from "../../../schema/UpdateOrganization";

interface EditOrganizationModalProps {
  data?: UpdateOrganizationInput | null;
  open: boolean;
  userId: string;
  handleClose: () => void;
}

const UPDATE_ORGANIZATION = gql`
  mutation UpdateOrganization($organizationData: UpdateOrganizationInput!) {
    updateOrganization(command: $organizationData) {
      userId
      user {
        id
        email
        firstName
        lastName
      }
      organizationName
      street
      streetNumber
      postCode
      city
    }
  }
`;

export default function EditOrganizationModal({
  data,
  userId,
  open,
  handleClose,
}: EditOrganizationModalProps) {
  const { t } = useTranslation();

  return (
    <FormModal<
      UpdateOrganizationInput,
      UpdateOrganization,
      UpdateOrganizationVariables
    >
      title={t("edit-organization-data")}
      description={t("change-organization-details")}
      maxWidth="md"
      defaultValues={data || undefined}
      makeVars={(organizationData) => ({
        organizationData: { ...organizationData, userId },
      })}
      mutation={UPDATE_ORGANIZATION}
      open={open}
      handleClose={handleClose}
    >
      {(control) => (
        <>
          <InputText
            control={control}
            name="organizationName"
            label={t("organization-name")}
            required
          />
          <InputText control={control} name="street" label={t("street")} />
          <InputText
            control={control}
            name="streetNumber"
            label={t("house-number")}
          />
          <InputText control={control} name="postCode" label={t("postcode")} />

          <InputText control={control} name="city" label={t("city")} required />
          <InputText
            control={control}
            name="email"
            label={t("email")}
            required
          />
        </>
      )}
    </FormModal>
  );
}
