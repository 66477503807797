import gql from "graphql-tag";
import React from "react";
import { useTranslation } from "react-i18next";
import FormModal from "../../../common/form/FormModal";
import InputText from "../../../common/input/InputText";
import { STUDENT_NURSING_SCHOOL } from "../../../fragments/student";
import { NursingSchoolInput } from "../../../schema/globalTypes";
import {
  UpdateNursingSchool,
  UpdateNursingSchoolVariables,
} from "../../../schema/UpdateNursingSchool";

interface EditNuringSchoolModalProps {
  data?: NursingSchoolInput | null;
  studentId: string;
  open: boolean;
  handleClose: () => void;
}

export default function EditNursingSchoolModal({
  data,
  studentId,
  open,
  handleClose,
}: EditNuringSchoolModalProps) {
  const { t } = useTranslation();

  const UPDATE_NURSINGSCHOOL = gql`
    ${STUDENT_NURSING_SCHOOL}
    mutation UpdateNursingSchool(
      $nursingSchool: NursingSchoolInput!
      $studentId: ID!
    ) {
      updateNursingSchool(command: $nursingSchool, studentId: $studentId) {
        id
        nursingSchool {
          ...NursingSchool
        }
      }
    }
  `;

  return (
    <FormModal<
      NursingSchoolInput,
      UpdateNursingSchool,
      UpdateNursingSchoolVariables
    >
      title={t("edit-data-on-the-nursing-school")}
      description={t("change-the-dates-of-the-nursing-school-here")}
      defaultValues={data || undefined}
      makeVars={(nursingSchool) => ({
        nursingSchool: { ...nursingSchool },
        studentId,
      })}
      mutation={UPDATE_NURSINGSCHOOL}
      open={open}
      handleClose={handleClose}
    >
      {(control) => (
        <>
          <InputText
            control={control}
            name="schoolName"
            label={t("school-name")}
            required
            autoFocus
          />
          <InputText
            control={control}
            name="street"
            label={t("street")}
            required
          />
          <InputText
            control={control}
            name="houseNumber"
            label={t("house-number")}
            required
          />
          <InputText
            control={control}
            name="postCode"
            label={t("postcode")}
            required
          />
          <InputText control={control} name="city" label={t("city")} required />
          <InputText
            control={control}
            name="mentor"
            label={t("contact-person")}
            required
          />
        </>
      )}
    </FormModal>
  );
}
