import { UseControllerProps } from "react-hook-form";
import InputCheckboxGroup from "../../../common/input/InputCheckboxGroup";
import { CompetenceType } from "../../../schema/globalTypes";

type InputCompetenceAreaProps<T> = {
  label: string;
  disabled?: boolean;
  required?: boolean;
} & UseControllerProps<T>;

function InputCompetenceArea<T>(props: InputCompetenceAreaProps<T>) {
  return (
    <InputCheckboxGroup
      {...props}
      options={[
        {
          label: "I",
          value: CompetenceType.I,
        },
        {
          label: "II",
          value: CompetenceType.II,
        },
        {
          label: "III",
          value: CompetenceType.III,
        },
        {
          label: "IV",
          value: CompetenceType.IV,
        },
        {
          label: "V",
          value: CompetenceType.V,
        },
      ]}
    />
  );
}

export default InputCompetenceArea;
