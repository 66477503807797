import { Stack } from "@mui/material";
import gql from "graphql-tag";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import FormModal from "../../../common/form/FormModal";
import { UpdatePracticalInput } from "../../../schema/globalTypes";
import {
  UpdatePractical,
  UpdatePracticalVariables,
} from "../../../schema/UpdatePractical";
import InstructorDropdown from "../../instructor/InstructorDropdown";
import OrganizationDropdown from "../../organization/OrganizationDropdown";
import TeacherDropdown from "../../teacher/TeacherDropdown";
import PracticalTypeDropdown from "../PracticalTypeDropdown";

interface EditPracticalDetailsModalProps {
  data?: UpdatePracticalInput | null;
  practicalId: string;
  open: boolean;
  handleClose: () => void;
}

export default function EditPracticalDetailsModal({
  data,
  practicalId,
  open,
  handleClose,
}: EditPracticalDetailsModalProps) {
  const { t } = useTranslation();

  const [organizationId, setOrganizationId] = useState(
    data?.assignedOrganizationId ?? "0"
  );

  const UPDATE_PRACTICAL = gql`
    mutation UpdatePractical($practical: UpdatePracticalInput!) {
      updatePractical(command: $practical) {
        id
        practicalType
        assignedTeacher {
          id
        }
        assignedInstructor {
          id
        }
        assignedOrganization {
          id
        }
      }
    }
  `;

  const onSelectOptionHandler = (id?: string) => {
    if (id) setOrganizationId(id);
  };

  return (
    <FormModal<UpdatePracticalInput, UpdatePractical, UpdatePracticalVariables>
      title={t("practical")}
      description={t("edit-practical-details")}
      maxWidth="sm"
      defaultValues={data || undefined}
      mutation={UPDATE_PRACTICAL}
      open={open}
      handleClose={handleClose}
      makeVars={(practical) => ({
        practical: { ...practical, practicalId },
      })}
    >
      {(control) => (
        <>
          <Stack spacing={2}>
            <PracticalTypeDropdown
              control={control}
              name="practicalType"
              label={t("choose-practical")}
            />
            <TeacherDropdown
              control={control}
              name="assignedTeacherId"
              label={t("assign-mentor")}
            />
            <OrganizationDropdown
              onSelectOption={onSelectOptionHandler}
              control={control}
              name="assignedOrganizationId"
              label={t("choose-organization")}
            />
            <InstructorDropdown
              filterByOrganizationId={true}
              organizationId={organizationId}
              control={control}
              name="assignedInstructorId"
              label={t("assign-instructor")}
            />
          </Stack>
        </>
      )}
    </FormModal>
  );
}
