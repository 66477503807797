import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { UseControllerProps } from "react-hook-form";
import InputDropdown from "../../common/input/InputDropdown";
import { GetClassList } from "../../schema/GetClassList";

type InputDropdownProps<T> = {
  label?: string;
  required?: boolean;
  autoFocus?: boolean;
  options?: Options[];
} & UseControllerProps<T>;

interface Options {
  label: string;
  id: string;
}

function ClassDropdown<T>({ options, ...props }: InputDropdownProps<T>) {
  const GET_CLASS_LIST = gql`
    query GetClassList {
      classes {
        classId
        number
      }
    }
  `;

  const { data: classList } = useQuery<GetClassList>(GET_CLASS_LIST, {
    fetchPolicy: "cache-and-network",
  });
  const dropdownClasses = classList?.classes.map((classData) => {
    const classId = classData.classId;
    const label = classData.number;

    return {
      id: classId,
      label,
    };
  });
  return <InputDropdown options={dropdownClasses} {...props} />;
}

export default ClassDropdown;
