import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import DeleteButton from "../../../common/buttons/DeleteButton";
import ConfirmDialog from "../../../common/form/ConfirmDialog";
import { GET_ORGANIZATIONS } from "../../../pages/school/Organizations";
import {
  DeleteOrganization,
  DeleteOrganizationVariables,
} from "../../../schema/DeleteOrganization";
import { GetOrganizations } from "../../../schema/GetOrganizations";

interface DeleteOrganizationButtonProps {
  userId: string;
}

const DELETE_ORGANIZATION = gql`
  mutation DeleteOrganization($organizationId: ID!) {
    deleteOrganization(organizationId: $organizationId)
  }
`;

const DeleteOrganizationButton = ({
  userId: organizationId,
}: DeleteOrganizationButtonProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [deleteOrganization] = useMutation<
    DeleteOrganization,
    DeleteOrganizationVariables
  >(DELETE_ORGANIZATION, {
    update(cache, { data }) {
      const getOrganizationsData = cache.readQuery<GetOrganizations>({
        query: GET_ORGANIZATIONS,
      });

      const organizations = (getOrganizationsData?.organizations || []).filter(
        (x) => x.userId !== organizationId
      );

      cache.writeQuery<GetOrganizations>({
        query: GET_ORGANIZATIONS,
        data: { organizations },
      });
    },
  });

  const [showConfirmation, setShowConfirmation] = useState(false);

  const deleteBtnHandler = () => {
    setShowConfirmation(true);
  };

  const { t } = useTranslation();

  const handleCancelHandler = () => {
    setShowConfirmation(false);
  };

  const handleConfirmHandler = async () => {
    try {
      await deleteOrganization({
        variables: {
          organizationId,
        },
      });
      enqueueSnackbar(`${t("organization-successfully-deleted")}`, {
        variant: "success",
      });
    } catch (e) {
      const error = e as Error;
      enqueueSnackbar(error.message, { variant: "error" });
    }
    setShowConfirmation(false);
  };

  return (
    <>
      <DeleteButton onClick={deleteBtnHandler} />
      <ConfirmDialog
        title={t("delete-organization")}
        open={showConfirmation}
        message={t("really-delete-organization")}
        handleConfirm={handleConfirmHandler}
        handleCancel={handleCancelHandler}
        confirmBtnText={t("delete")}
      />
    </>
  );
};

export default DeleteOrganizationButton;
