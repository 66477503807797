import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useController, UseControllerProps } from "react-hook-form";

type InputRadioProps<T> = {
  label?: string;
  value?: string;
  required?: boolean;
  autoFocus?: boolean;
  multiline?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  options?: Options[];
} & UseControllerProps<T>;

interface Options {
  label: string;
  value: string;
}

function InputRadio<T>({
  label,
  value,
  required,
  autoFocus,
  multiline,
  readOnly,
  disabled,
  options,
  ...props
}: InputRadioProps<T>) {
  const rules = {
    required: required ? `${label} ist ein Pflichtfeld.` : undefined,
    ...props.rules,
  };

  const { field } = useController({ ...props, rules });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    field.onChange(event.target.value);
  };

  const fieldValue = field.value || "";

  return (
    <RadioGroup
      aria-label="radio-group"
      name="radio-buttons"
      value={fieldValue}
      onChange={handleChange}
    >
      {options &&
        options.map(({ label, value }) => (
          <FormControlLabel
            key={value}
            label={label}
            value={value}
            control={<Radio color="success" />}
            disabled={disabled}
          />
        ))}
    </RadioGroup>
  );
}

export default InputRadio;
