import { useQuery } from "@apollo/client";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import UploadButton from "../../../common/buttons/UploadButton";
import useModal from "../../../hooks/useModal";
import { GET_INSTRUCTOR } from "../../../pages/school/InstructorDetailsPage";
import { GetInstructor } from "../../../schema/GetInstructor";
import FormSection from "../..//FormSection";
import DeleteOrganizationAssociatedWithStudentButton from "../buttons/DeleteOrganizationAssociatedWithInstructorButton";
import AddInstructorToOrganizationModal from "../modals/AddInstructorToOrganizationModal";
interface UploadFileProps {
  instructorId: string;
}

const FormSectionInstructorOrganizations = ({
  instructorId,
}: UploadFileProps) => {
  const { open, handleOpen, handleClose } = useModal();
  const { t } = useTranslation();

  const { loading, data } = useQuery<GetInstructor>(GET_INSTRUCTOR, {
    variables: {
      userId: instructorId,
    },
    fetchPolicy: "cache-and-network",
  });

  return (
    <>
      <FormSection
        sectionTitle={t("organizations")}
        headerBackgroundColor={"instructor.50"}
        sectionAction={<UploadButton handleOpen={handleOpen} />}
      >
        <>
          <TableContainer>
            <Table>
              <TableHead sx={{ backgroundColor: "#ededed" }}>
                <TableRow>
                  <TableCell component="th" scope="row">
                    {t("organization-name")}
                  </TableCell>
                  <TableCell align="right" component="th" scope="row">
                    {t("city")}
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              {data && data.instructor.organizations.length >= 1 ? (
                <TableBody>
                  {data.instructor.organizations.map((organization, index) => (
                    <TableRow key={index}>
                      <TableCell>{organization.organizationName}</TableCell>
                      <TableCell align="right">{organization.city}</TableCell>
                      <TableCell align="right">
                        <DeleteOrganizationAssociatedWithStudentButton
                          instructorId={instructorId}
                          organizationId={organization.userId}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Typography
                        color="#c8cbcf"
                        textAlign="center"
                        justifyItems="center"
                      >
                        {t("no-data")}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </>
      </FormSection>

      <AddInstructorToOrganizationModal
        instructorId={instructorId}
        open={open}
        handleClose={handleClose}
      />
    </>
  );
};
export default FormSectionInstructorOrganizations;
