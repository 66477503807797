import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import DisplayDate from "../../common/display/DisplayDate";
import { NightShiftProtocol } from "../../schema/NightShiftProtocol";

interface NightShiftsListProps {
  data?: NightShiftProtocol[] | null;
  loading?: boolean;
  primary?: boolean;
}

const NightShiftsList = ({ data, loading, primary }: NightShiftsListProps) => {
  const { t } = useTranslation();

  return (
    <TableContainer>
      <Table>
        <TableHead
          sx={{ backgroundColor: primary ? "student.100" : "#ededed" }}
        >
          <TableRow>
            {primary && (
              <TableCell component="th" scope="row">
                {t("practical-number")}
              </TableCell>
            )}
            <TableCell component="th" scope="row">
              {t("night-shifts-from-to")}
            </TableCell>
            <TableCell component="th" scope="row">
              {t("hours-practical-duration")}
            </TableCell>

            <TableCell component="th" scope="row">
              {t("reason-for-discrepancy")}
            </TableCell>
            <TableCell component="th" scope="row">
              {t("signature-name")}
            </TableCell>
            <TableCell component="th" scope="row">
              {t("signature")}
            </TableCell>
            <TableCell component="th" scope="row" align="right"></TableCell>
          </TableRow>
        </TableHead>
        {data && data.length >= 1 ? (
          <TableBody>
            {data.map((nightShift, index) => (
              <TableRow key={index}>
                {primary && (
                  <TableCell>{`${t(
                    "practicalType." + nightShift.practical.practicalType
                  )}`}</TableCell>
                )}
                <TableCell>
                  <DisplayDate>{nightShift.startDate}</DisplayDate>
                  {" - "}
                  <DisplayDate>{nightShift.stopDate}</DisplayDate>
                </TableCell>
                <TableCell component="td">
                  {nightShift.plannedWorkingHours +
                    " / " +
                    nightShift.achievedHours}
                </TableCell>
                <TableCell>{nightShift.description}</TableCell>
                <TableCell>{nightShift.signatureName}</TableCell>
                <TableCell>
                  {nightShift.signature !== "" && (
                    <img
                      width={100}
                      height="auto"
                      src={nightShift.signature}
                      alt={t("signature")}
                    />
                  )}
                </TableCell>
                <TableCell
                  align="right"
                  onClick={(e) => e.stopPropagation()}
                ></TableCell>
              </TableRow>
            ))}
          </TableBody>
        ) : (
          <TableBody>
            <TableRow>
              <TableCell colSpan={7}>
                <Typography
                  color="#c8cbcf"
                  textAlign="center"
                  justifyItems="center"
                >
                  {t("no-data")}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default NightShiftsList;
