import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useTranslation } from "react-i18next";
import Page from "../../common/layout/Page";
import StudentList from "../../components/student/StudentList";
import { GetCurrentStudents } from "../../schema/GetCurrentStudents";

export const GET_CURRENT_STUDENTS = gql`
  query GetCurrentStudents {
    currentStudents {
      id
      class {
        classId
        number
      }
      birthdate
      user {
        id
        firstName
        lastName
        email
        isApproved
      }
    }
  }
`;

const TeacherStudentsPage = () => {
  const { t } = useTranslation();
  const { loading, data } = useQuery<GetCurrentStudents>(GET_CURRENT_STUDENTS, {
    fetchPolicy: "cache-and-network",
  });

  return (
    <Page
      title={t("my-students")}
      pageHeading={t("my-students")}
      subHeading={t("my-students-description")}
    >
      <StudentList loading={loading} students={data?.currentStudents} canOpen />
    </Page>
  );
};

export default TeacherStudentsPage;
