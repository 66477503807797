import { useQuery } from "@apollo/client";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import MoreInfoButton from "../../common/buttons/MoreInfoButton";
import EmptyStateSubtle from "../../common/layout/EmptyStateSubtle";
import Page from "../../common/layout/Page";
import PaginatedSeachBar from "../../common/PaginatedSearchBar";
import useFilter from "../../hooks/useFilter";
import usePagination from "../../hooks/usePagination";
import { CurrentPracticals } from "../../schema/CurrentPracticals";
import { CURRENT_PRACTICALS } from "../teacher/PracticalVisits";

const searchFields = [
  "practical.student.user.fullName",
  "practical.student.user.email",
];

const PracticalVisits = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { url } = useRouteMatch();
  const { loading, data } = useQuery<CurrentPracticals>(CURRENT_PRACTICALS, {
    fetchPolicy: "cache-and-network",
  });

  const handleClick = (practicalId: string) => {
    if (data) {
      history.push(`${url}/${practicalId}`);
    }
  };

  const filter = useFilter(data?.currentPracticals || [], searchFields);
  const pagination = usePagination(filter.data);

  return (
    <Page
      title={t("my-practical-instructions")}
      pageHeading={t("my-practical-instructions")}
      subHeading={t("my-practical-instructions-description")}
    >
      <PaginatedSeachBar
        loading={loading}
        setSearchValue={filter.setSearchValue}
        paginationProps={pagination.paginationProps}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead sx={{ backgroundColor: "instructor.100" }}>
            <TableRow>
              <TableCell component="th" scope="col">
                {t("practical")}
              </TableCell>
              <TableCell component="th" scope="col">
                {t("student")}
              </TableCell>
              <TableCell component="th" scope="col">
                {t("nursing-school")}
              </TableCell>
              <TableCell component="th" scope="col">
                Aktion erforderlich
              </TableCell>
              <TableCell component="th" scope="col">
                {t("approved")}
              </TableCell>
              <TableCell component="th" scope="col"></TableCell>
            </TableRow>
          </TableHead>
          {pagination.currentData && pagination.currentData.length >= 1 ? (
            <TableBody>
              {pagination.currentData.map((practical) => {
                return (
                  <TableRow
                    key={practical.id}
                    hover
                    onClick={() => handleClick(practical.id)}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell
                      component="td"
                      sx={{
                        fontWeight: practical.isWaitingForInstructor
                          ? "bold"
                          : undefined,
                      }}
                    >
                      {t("practicalType." + practical.practicalType)}
                    </TableCell>
                    <TableCell
                      component="td"
                      sx={{
                        fontWeight: practical.isWaitingForInstructor
                          ? "bold"
                          : undefined,
                      }}
                    >
                      {practical.student.user.fullName}
                    </TableCell>
                    <TableCell
                      component="td"
                      sx={{
                        fontWeight: practical.isWaitingForInstructor
                          ? "bold"
                          : undefined,
                      }}
                    ></TableCell>
                    <TableCell
                      component="td"
                      sx={{
                        fontWeight: practical.isWaitingForInstructor
                          ? "bold"
                          : undefined,
                      }}
                    >
                      {practical.isWaitingForInstructor ? "ja" : "nein"}
                    </TableCell>
                    <TableCell
                      component="td"
                      sx={{
                        fontWeight: practical.isWaitingForInstructor
                          ? "bold"
                          : undefined,
                      }}
                    >
                      {practical.isApprovedByInstructor ? "ja" : "nein"}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: practical.isWaitingForInstructor
                          ? "bold"
                          : undefined,
                      }}
                    >
                      <MoreInfoButton
                        onClick={() => handleClick(practical.id)}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={5}>
                  <EmptyStateSubtle
                    title={t("no-data")}
                    description={t("no-practical-instructions-yet")}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Page>
  );
};

export default PracticalVisits;
