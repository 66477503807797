import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import EditButton from "../common/buttons/EditButton";
import TextBlock from "../common/display/TextBlock";
import TextLine from "../common/display/TextLine";
import useCurrentUser from "../hooks/useCurrentUser";
import useModal from "../hooks/useModal";
import EditUserModal from "./EditUserModal";
import FormSection from "./FormSection";

const FormSectionUserProfile = () => {
  const { open, handleOpen, handleClose } = useModal();
  const { t } = useTranslation();
  const { user } = useCurrentUser();
  return (
    <>
      <FormSection
        sectionTitle={t("profile")}
        sectionAction={<EditButton handleOpen={handleOpen} />}
        headerBackgroundColor="#ededed"
      >
        {user ? (
          <>
            <TextBlock label={t("name")}>
              <TextLine value={user.firstName} />
            </TextBlock>
            <TextBlock label={t("lastname")}>
              <TextLine value={user.lastName} />
            </TextBlock>
            <TextBlock label={t("email")}>
              <TextLine value={user.email} />
            </TextBlock>
          </>
        ) : (
          <Typography color="#c8cbcf" textAlign="center" justifyItems="center">
            {t("no-data")}
          </Typography>
        )}
      </FormSection>

      <EditUserModal
        data={{
          userId: user?.id || "",
          firstName: user?.firstName || "",
          lastName: user?.lastName || "",
          email: user?.email || "",
        }}
        userId={user ? user.id : ""}
        title={t("edit-profile")}
        description={t("edit-your-profile")}
        open={open}
        handleClose={handleClose}
      />
    </>
  );
};
export default FormSectionUserProfile;
