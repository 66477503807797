import gql from "graphql-tag";
import React from "react";
import { useTranslation } from "react-i18next";
import FormModal from "../../../common/form/FormModal";
import {
  AddInstructorToOrganization,
  AddInstructorToOrganizationVariables,
} from "../../../schema/AddInstructorToOrganization";
import OrganizationDropdown from "../../organization/OrganizationDropdown";
import { GET_INSTRUCTOR } from "../../../pages/school/InstructorDetailsPage";
interface AddInstructorToOrganizationModalProps {
  instructorId: string;
  open: boolean;
  handleClose: () => void;
}

interface AddInstructorToOrganizationModalData {
  instructorId: string;
  organizationId: string;
}

const ADD_INSTRUCTOR_TO_ORGANIZATION = gql`
  mutation AddInstructorToOrganization(
    $instructorId: ID!
    $organizationId: ID!
  ) {
    addInstructorToOrganization(
      instructorId: $instructorId
      organizationId: $organizationId
    ) {
      userId
      user {
        id
        firstName
        lastName
        fullName
      }
      organizations {
        userId
        organizationName
        street
        streetNumber
        postCode
        city
      }
    }
  }
`;

export default function AddInstructorToOrganizationModal({
  instructorId,
  open,
  handleClose,
}: AddInstructorToOrganizationModalProps) {
  const { t } = useTranslation();
  return (
    <FormModal<
      AddInstructorToOrganizationModalData,
      AddInstructorToOrganization,
      AddInstructorToOrganizationVariables
    >
      title={t("add-organization")}
      description=""
      defaultValues={undefined}
      makeVars={(data) => ({
        instructorId: instructorId,
        organizationId: data.organizationId,
      })}
      mutation={ADD_INSTRUCTOR_TO_ORGANIZATION}
      mutationOptions={{ refetchQueries: [GET_INSTRUCTOR, "GetInstructor"] }}
      open={open}
      handleClose={handleClose}
    >
      {(control) => (
        <>
          <OrganizationDropdown
            filterByInstructorId={true}
            instructorId={instructorId}
            control={control}
            name="organizationId"
            label={t("choose-organization")}
          ></OrganizationDropdown>
        </>
      )}
    </FormModal>
  );
}
