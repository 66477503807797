import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import React from "react";
import { useController, UseControllerProps } from "react-hook-form";

type InputToggleButtonProps<T> = {
  label?: string;
  value?: string;
  color?:
    | "standard"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning"
    | undefined;
  exclusive?: boolean;
  required?: boolean;
  options?: Options[];
} & UseControllerProps<T>;

interface Options {
  label: string;
  value: boolean;
}

function InputToggleButton<T>({
  label,
  color,
  exclusive,
  required,
  options,
  ...props
}: InputToggleButtonProps<T>) {
  const rules = {
    required: required ? `${label} ist ein Pflichtfeld` : undefined,
    ...props.rules,
  };

  const { field } = useController({ ...props, rules });
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string
  ) => {
    console.log(newValue);
    if (newValue !== undefined && newValue !== null) {
      field.onChange(newValue);
    }
  };

  return (
    <ToggleButtonGroup
      color={color}
      exclusive={exclusive}
      {...field}
      onChange={handleChange}
    >
      {options &&
        options.map(({ label, value }) => (
          <ToggleButton key={label} value={value}>
            {label}
          </ToggleButton>
        ))}
    </ToggleButtonGroup>
  );
}
export default InputToggleButton;
