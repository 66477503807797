import gql from "graphql-tag";
import { useContext } from "react";
import UserContext from "../context/UserContext";

export const GET_CURRENT_USER = gql`
  fragment CurrentUser on User {
    id
    email
    firstName
    lastName
    fullName
    roles
    isApproved
  }

  query GetCurrentUser {
    currentUser {
      ...CurrentUser
    }
  }
`;

const useCurrentUser = () => useContext(UserContext);

export default useCurrentUser;
