import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { UseControllerProps } from "react-hook-form";
import InputDropdown from "../../common/input/InputDropdown";
import { GetSchoolsList } from "../../schema/GetSchoolsList";

type InputDropdownProps<T> = {
  label?: string;
  required?: boolean;
  autoFocus?: boolean;
  options?: Options[];
  exceptClassId?: string;
} & UseControllerProps<T>;

interface Options {
  label: string;
  id: string;
}

function SchoolDropdown<T>({ options, ...props }: InputDropdownProps<T>) {
  const GET_SCHOOLS_LIST = gql`
    query GetSchoolsList {
      schools {
        id
        name
      }
    }
  `;

  const { data: schoolsList } = useQuery<GetSchoolsList>(GET_SCHOOLS_LIST, {
    fetchPolicy: "cache-and-network",
  });
  const dropdownSchools = schoolsList?.schools.map((school) => {
    const userId = school.id;
    const label = school.name;
    return {
      id: userId,
      label,
    };
  });

  return <InputDropdown options={dropdownSchools} {...props} />;
}

export default SchoolDropdown;
