import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { IconButton } from "@mui/material";

interface UploadButtonProps {
  handleOpen: () => void;
}

const UploadButton = ({ handleOpen }: UploadButtonProps) => (
  <IconButton
    aria-label="hochladen"
    onClick={handleOpen}
    sx={{ "&:hover": { "& .uploadIcon": { color: "primary.main" } } }}
  >
    <AddCircleOutlineIcon className="uploadIcon" />
  </IconButton>
);

export default UploadButton;
