import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import EditButton from "../../../common/buttons/EditButton";
import DisplayDate from "../../../common/display/DisplayDate";
import TextBlock from "../../../common/display/TextBlock";
import TextLine from "../../../common/display/TextLine";
import useModal from "../../../hooks/useModal";
import { Specialization } from "../../../schema/Specialization";
import FormSection from "../../FormSection";
import EditSpecializationChoiceModal from "../modals/EditSpecializationChoiceModal";

interface SpecializationChoiceProps {
  data?: Specialization | null;
  studentId: string;
  locked?: boolean;
  loading?: boolean;
}

const FormSectionSpecializationChoice = ({
  data,
  studentId,
  locked,
  loading,
}: SpecializationChoiceProps) => {
  const { open, handleOpen, handleClose } = useModal();
  const { t } = useTranslation();
  return (
    <>
      <FormSection
        sectionTitle={t("diploma")}
        meta={data?.response?.meta}
        sectionAction={<EditButton handleOpen={handleOpen} />}
        loading={loading}
        locked={locked}
        headerBackgroundColor={"student.100"}
        canEdit={!!data?.access.canEdit}
      >
        {data?.response?.specialization?.specialization === undefined ||
        data?.response?.specialization?.specialization === "NONE" ? (
          <>
            <TextBlock label={t("profession-title-without-specialization")}>
              <TextLine value={t("care-specialist")} />
            </TextBlock>
            <Typography>{t("suffrage-not-taken")}</Typography>
          </>
        ) : (
          <>
            <TextBlock label={t("profession-title-with-specialization")}>
              {data?.response?.specialization?.specialization === "ELDERLY" && (
                <TextLine value={t("geriatric-nurse")} />
              )}
              {data?.response?.specialization?.specialization ===
                "CHILDREN" && <TextLine value={t("pediatric-nurse")} />}
            </TextBlock>

            <TextBlock label={t("suffrage-taken-on")}>
              <DisplayDate>
                {data?.response?.specialization?.choiceMade}
              </DisplayDate>
            </TextBlock>
            <TextBlock label={t("formation-contract-updated-on")}>
              <DisplayDate>
                {data?.response?.specialization?.contractUpdated}
              </DisplayDate>
            </TextBlock>
          </>
        )}
      </FormSection>
      <EditSpecializationChoiceModal
        data={data}
        studentId={studentId}
        open={open}
        handleClose={handleClose}
      />
    </>
  );
};

export default FormSectionSpecializationChoice;
