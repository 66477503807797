import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Breadcrumbs, Link } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

interface BreadcrumbProps {
  entries: BreadcrumbEntry[];
}

export interface BreadcrumbEntry {
  name: string;
  to: string;
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({
  entries,
}: BreadcrumbProps) => {
  return (
    <Box role="presentation" marginBottom={2}>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<ChevronRightIcon fontSize="small" />}
      >
        {entries?.map((entry, index) => {
          if (index === entries.length - 1) {
            return (
              <Box
                component="span"
                key={entry.name}
                sx={{ display: "flex", alignItems: "center" }}
                color="studentTabs.main"
              >
                {entry.name}
              </Box>
            );
          } else {
            return (
              <Link
                key={entry.name}
                component={RouterLink}
                underline="hover"
                sx={{ display: "flex", alignItems: "center" }}
                color="inherit"
                to={entry.to}
              >
                {entry.name}
              </Link>
            );
          }
        })}
      </Breadcrumbs>
    </Box>
  );
};

export default Breadcrumb;
