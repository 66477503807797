import { Typography } from "@mui/material";
import gql from "graphql-tag";
import { useTranslation } from "react-i18next";
import FormModal from "../../../common/form/FormModal";
import { Role } from "../../../schema/globalTypes";
import { SelectRole, SelectRoleVariables } from "../../../schema/SelectRole";
import SchoolDropdown from "../../school/SchoolDropdown";

interface SelectRoleModalProps {
  data?: SelectRoleInput | null;
  open: boolean;
  role: Role;
  handleClose: () => void;
}

type SelectRoleInput = {
  //role: Role;
  schoolId?: string;
};

const SELECT_ROLE = gql`
  mutation SelectRole($role: Role!, $schoolId: ID) {
    selectRole(role: $role, schoolId: $schoolId) {
      id
      roles
    }
  }
`;

function SelectRoleModal({
  data,
  open,
  role,
  handleClose,
}: SelectRoleModalProps) {
  const { t } = useTranslation();

  return (
    <>
      <FormModal<SelectRoleInput, SelectRole, SelectRoleVariables>
        title={t("choose-role")}
        description={t("really-choose-role")}
        maxWidth="sm"
        defaultValues={data || undefined}
        makeVars={(selectRole) => ({
          role,
          ...selectRole,
        })}
        mutation={SELECT_ROLE}
        open={open}
        handleClose={handleClose}
      >
        {(control) =>
          role === Role.STUDENT || role === Role.TEACHER ? (
            <>
              <Typography mt={4}>{t("choose-a-school")}</Typography>
              <SchoolDropdown
                name="schoolId"
                control={control}
                label={t("choose-school")}
              />
            </>
          ) : (
            <></>
          )
        }
      </FormModal>
    </>
  );
}

export default SelectRoleModal;
