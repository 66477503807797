import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useTranslation } from "react-i18next";
import Page from "../../common/layout/Page";
import ClassList from "../../components/class/ClassList";
import { GetCurrentClasses } from "../../schema/GetCurrentClasses";

export const GET_CURRENT_CLASSES = gql`
  query GetCurrentClasses {
    currentClasses {
      classId
      number
      startDate
      endDate
      students {
        id
        user {
          email
          firstName
          lastName
        }
      }
    }
  }
`;

function Classes() {
  const { t } = useTranslation();

  const { loading, data } = useQuery<GetCurrentClasses>(GET_CURRENT_CLASSES, {
    fetchPolicy: "cache-and-network",
  });

  return (
    <Page
      title={t("my-classes")}
      pageHeading={t("my-classes")}
      subHeading={t("here-you-find-an-overview-of-your-classes")}
    >
      <ClassList loading={loading} classes={data?.currentClasses} />
    </Page>
  );
}

export default Classes;
