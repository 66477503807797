import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import deLocale from "date-fns/locale/de";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
import { useController, UseControllerProps } from "react-hook-form";

type InputDateProps<T> = {
  label: string;
  required?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
} & UseControllerProps<T>;

function InputDate<T>({
  label,
  required,
  autoFocus,
  disabled,
  ...props
}: InputDateProps<T>) {
  const rules = {
    required: required ? `${label} ist ein Pflichtfeld.` : undefined,
    ...props.rules,
  };

  const {
    field,
    fieldState: { error },
  } = useController({ ...props, rules });

  let value: Date | string | null = null;
  try {
    if (field.value && field.value !== "") {
      value = new Date(`${field.value}`);
    } else {
      value = null;
    }
  } catch {
    value = null;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
      <DatePicker
        value={value}
        onChange={(date) => {
          if (date instanceof Date && date.getTime()) {
            const offset = -date.getTimezoneOffset();
            date.setHours(Math.trunc(offset / 60), offset % 60);
          }

          field.onChange(
            date && typeof date == "object" && date.getTime()
              ? date.toISOString()
              : date
          );
        }}
        disabled={disabled}
        label={label}
        inputFormat="dd.MM.yyyy"
        mask="__.__.____"
        renderInput={(params) => (
          <TextField
            required={required}
            helperText={error ? error.message : ""}
            fullWidth
            autoFocus={autoFocus}
            {...params}
            error={!!error}
          />
        )}
      />
    </LocalizationProvider>
  );
}

export default InputDate;
