import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import DeleteButton from "../../common/buttons/DeleteButton";
import ConfirmDialog from "../../common/form/ConfirmDialog";
import { GET_CLASSES } from "../../pages/school/Classes";
import { DeleteClass, DeleteClassVariables } from "../../schema/DeleteClass";
import { GetClasses } from "../../schema/GetClasses";

interface DeleteClassButtonProps {
  classId: string;
}

const DELETE_CLASS = gql`
  mutation DeleteClass($command: DeleteClassInput!) {
    deleteClass(command: $command)
  }
`;

const DeleteClassButton = ({ classId }: DeleteClassButtonProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const [deleteClass] = useMutation<DeleteClass, DeleteClassVariables>(
    DELETE_CLASS,
    {
      update(cache, { data }) {
        const getClassesData = cache.readQuery<GetClasses>({
          query: GET_CLASSES,
        });

        const classes = (getClassesData?.classes || []).filter(
          (x) => x.classId !== classId
        );

        cache.writeQuery<GetClasses>({
          query: GET_CLASSES,
          data: { classes },
        });
      },
    }
  );

  const [showConfirmation, setShowConfirmation] = useState(false);

  const deleteBtnHandler = () => {
    setShowConfirmation(true);
  };

  const handleConfirmHandler = async () => {
    try {
      await deleteClass({
        variables: {
          command: {
            classId: classId,
          },
        },
      });

      enqueueSnackbar(`${t("class-successfully-deleted")}`, {
        variant: "success",
      });
    } catch (e) {
      const error = e as Error;
      enqueueSnackbar(error.message, { variant: "error" });
    }
    setShowConfirmation(false);
  };

  const handleCancelHandler = () => {
    setShowConfirmation(false);
  };

  const { t } = useTranslation();

  return (
    <>
      <DeleteButton onClick={deleteBtnHandler} />
      <ConfirmDialog
        title={t("delete-class")}
        open={showConfirmation}
        message={t("really-delete-class")}
        handleConfirm={handleConfirmHandler}
        handleCancel={handleCancelHandler}
        confirmBtnText={t("delete")}
      />
    </>
  );
};

export default DeleteClassButton;
