import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { useController, UseControllerProps } from "react-hook-form";

type InputDropdownProps<T> = {
  onSelectOption?: (id?: string) => void;
  label?: string;
  required?: boolean;
  autoFocus?: boolean;
  options?: Options[];
} & UseControllerProps<T>;

interface Options {
  label: string;
  id: string;
}

function InputDropdown<T>({
  onSelectOption,
  label,
  required,
  autoFocus,
  options,
  ...props
}: InputDropdownProps<T>) {
  const rules = {
    required: required ? `${label} ist ein Pflichtfeld` : undefined,
    ...props.rules,
  };
  const {
    field: { value, onChange, ...fieldProps },
    fieldState: { error },
  } = useController({ ...props, rules });

  console.log(error);

  const option = value ? options?.find((option) => option.id === value) : null;

  return (
    <>
      {options && (
        <Autocomplete
          options={options ? options : []}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              required={required}
              error={!!error}
              helperText={error?.message}
            />
          )}
          value={option}
          onChange={(_, data) => {
            onChange(data?.id);
            if (onSelectOption) onSelectOption(data?.id);
          }}
          {...fieldProps}
        />
      )}
    </>
  );
}

export default InputDropdown;
