import AccountBoxIcon from "@mui/icons-material/AccountCircleOutlined";
import ClassIcon from "@mui/icons-material/Class";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooksOutlined";
import HomeWorkIcon from "@mui/icons-material/HomeWorkOutlined";
import SchoolIcon from "@mui/icons-material/School";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import { Role } from "./schema/globalTypes";
import ApartmentIcon from "@mui/icons-material/Apartment";
import HomeIcon from "@mui/icons-material/Home";

export const ApplicationMenu: Menu = [
  {
    title: "Meine Ausbildung",
    role: Role.STUDENT,
    children: [
      {
        title: "Willkommen",
        path: "/",
        exact: true,
        icon: <HomeIcon />,
        backgroundColor: "#ecfbc6",
      },
      {
        title: "Ausbildungsprofil",
        path: "/ausbildungsprofil",
        icon: <AccountBoxIcon />,
        backgroundColor: "#ecfbc6",
      },
      {
        title: "Praxiseinsätze",
        path: "/praxiseinsaetze",
        icon: <LibraryBooksIcon />,
        backgroundColor: "#ecfbc6",
      },
    ],
  },
  {
    title: "Verwaltung",
    role: Role.SCHOOL_ADMIN,
    children: [
      {
        title: "Auszubildende",
        path: "/verwaltung/auszubildende",
        icon: <GroupOutlinedIcon />,
        backgroundColor: "#ededed",
      },
      {
        title: "Kurse",
        path: "/verwaltung/kurse",
        icon: <ClassIcon />,
        backgroundColor: "#ededed",
      },
      {
        title: "Lehrer*innen",
        path: "/verwaltung/lehrer",
        icon: <SchoolIcon />,
        backgroundColor: "#ededed",
      },
      {
        title: "Praxisanleiter*innen",
        path: "/verwaltung/praxisanleiter",
        icon: <SchoolOutlinedIcon />,
        backgroundColor: "#ededed",
      },
      {
        title: "Betriebe",
        path: "/verwaltung/betriebe",
        icon: <HomeWorkIcon />,
        backgroundColor: "#ededed",
      },
      {
        title: "Schule",
        path: "/verwaltung/schule",
        icon: <ApartmentIcon />,
        backgroundColor: "#ededed",
      },
    ],
  },
  {
    title: "Ausbildung",
    role: Role.TEACHER,
    children: [
      {
        title: "Willkommen",
        path: "/",
        exact: true,
        icon: <HomeIcon />,
        backgroundColor: "#dff1f1",
      },
      {
        title: "Meine Auszubildenden",
        path: "/ausbildung/auszubildende",
        icon: <GroupOutlinedIcon />,
        backgroundColor: "#E5F1F9",
      },
      {
        title: "Meine Kurse",
        path: "/ausbildung/kurse",
        icon: <ClassIcon />,
        backgroundColor: "#E5F1F9",
      },
      {
        title: "Meine Praxisbegleitungen",
        path: "/ausbildung/praxisbegleitungen",
        icon: <SchoolIcon />,
        backgroundColor: "#E5F1F9",
      },
    ],
  },
  {
    title: "Praxis",
    role: Role.INSTRUCTOR,
    children: [
      {
        title: "Willkommen",
        path: "/",
        exact: true,
        icon: <HomeIcon />,
        backgroundColor: "#dff1f1",
      },
      {
        title: "Meine Praxisanleitungen",
        path: "/praxis/praxisanleitungen",
        icon: <SchoolIcon />,
        backgroundColor: "#dff1f1",
      },
    ],
  },
  {
    title: "Betrieb",
    role: Role.ORGANIZATION,
    children: [
      {
        title: "Willkommen",
        path: "/",
        exact: true,
        icon: <HomeIcon />,
        backgroundColor: "#f4e3ef",
      },
      {
        title: "Meine Praxisnachweise",
        path: "/betriebe/praxisnachweise",
        icon: <SchoolOutlinedIcon />,
        backgroundColor: "#f4e3ef",
      },
    ],
  },

  /* {
    title: "Feedback",
    role: Role.STUDENT,
    children: [
      {
        title: "Teilen Sie uns gerne Ihr Feedback an: feedback@pflash.de mit",
        icon: <FeedbackIcon />,
      },
    ],
  },
  {
    title: "Feedback",
    role: Role.TEACHER,
    children: [
      {
        title: "Teilen Sie uns gerne Ihr Feedback mit an: feedback@pflash.de",
        icon: <FeedbackIcon />,
      },
    ],
  }, */
];
