import EditButton from "../../../common/buttons/EditButton";
import useModal from "../../../hooks/useModal";
import { UpdateOrganizationInput } from "../../../schema/globalTypes";
import EditOrganizationModal from "../modals/EditOrganizationModal";

interface EditOrganizationButtonProps {
  userId: string;
  data?: UpdateOrganizationInput | null;
}

const EditOrganizationButton = ({
  userId,
  data,
}: EditOrganizationButtonProps) => {
  const { open, handleOpen, handleClose } = useModal();

  return (
    <>
      <EditButton handleOpen={handleOpen} />

      <EditOrganizationModal
        data={data}
        userId={userId}
        handleClose={handleClose}
        open={open}
      />
    </>
  );
};

export default EditOrganizationButton;
