import { useQuery } from "@apollo/client";
import CheckIcon from "@mui/icons-material/Check";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Box } from "@mui/system";
import gql from "graphql-tag";
import { useTranslation } from "react-i18next";
import AddButton from "../../common/buttons/AddButton";
import EmptyStateSubtle from "../../common/layout/EmptyStateSubtle";
import Page from "../../common/layout/Page";
import PaginatedSeachBar from "../../common/PaginatedSearchBar";
import EditTeacherButton from "../../components/teacher/buttons/EditTeacherButton";
import DeleteTeacherButton from "../../components/teacher/DeleteTeacherButton";
import AddTeacherModal from "../../components/teacher/modals/AddTeacherModal";
import CheckOrActivate from "../../components/user/CheckOrActivate";
import useFilter from "../../hooks/useFilter";
import useModal from "../../hooks/useModal";
import usePagination from "../../hooks/usePagination";
import { GetTeachers } from "../../schema/GetTeachers";

export const GET_TEACHERS = gql`
  query GetTeachers {
    teachers {
      userId
      user {
        id
        firstName
        lastName
        email
        isApproved
      }
      isSchoolAdmin
      classes {
        classId
        number
      }
    }
  }
`;

const searchFields = ["user.firstName", "user.lastName", "user.email"];

export default function Teachers() {
  const { t } = useTranslation();
  const { loading, data } = useQuery<GetTeachers>(GET_TEACHERS, {
    fetchPolicy: "cache-and-network",
  });
  const { open, handleOpen, handleClose } = useModal();

  const filter = useFilter(data?.teachers, searchFields);
  const pagination = usePagination(filter.data);

  return (
    <Page
      title={t("teachers")}
      pageHeading={t("manage-teachers")}
      subHeading={t("here-you-find-an-overview-of-all-teachers")}
    >
      <Box
        sx={{
          visibility: { xs: "visible", sm: "hidden" },
          marginBottom: { xs: "1rem", sm: "-1rem" },
        }}
      >
        <AddButton
          variant="contained"
          color="success"
          buttonLabel={t("add")}
          onClick={handleOpen}
        />
      </Box>
      <PaginatedSeachBar
        loading={loading}
        setSearchValue={filter.setSearchValue}
        paginationProps={pagination.paginationProps}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead sx={{ backgroundColor: "#EDEDED" }}>
            <TableRow>
              <TableCell component="th" scope="col">
                {t("lastname")}
              </TableCell>
              <TableCell component="th" scope="col">
                {t("name")}
              </TableCell>
              <TableCell component="th" scope="col">
                {t("email")}
              </TableCell>
              <TableCell component="th" scope="col">
                {t("admin")}
              </TableCell>
              <TableCell
                component="th"
                scope="col"
                style={{ textAlign: "center" }}
              >
                {t("is-approved")}
              </TableCell>
              <TableCell
                component="th"
                scope="col"
                align="right"
                sx={{
                  visibility: { xs: "hidden", sm: "visible" },
                  marginBottom: { xs: "1rem", sm: "-1rem" },
                }}
              >
                <AddButton
                  variant="contained"
                  color="warning"
                  buttonLabel={t("add")}
                  onClick={handleOpen}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pagination && pagination.currentData.length >= 1 ? (
              <>
                {pagination.currentData.map((teacher) => (
                  <TableRow key={teacher.userId}>
                    <TableCell component="td">
                      {teacher.user.lastName}
                    </TableCell>
                    <TableCell component="td">
                      {teacher.user.firstName}
                    </TableCell>
                    <TableCell component="td">{teacher.user.email}</TableCell>
                    <TableCell component="td">
                      {teacher.isSchoolAdmin ? <CheckIcon /> : ""}
                    </TableCell>
                    <TableCell component="td" style={{ textAlign: "center" }}>
                      <CheckOrActivate
                        isApproved={teacher.user.isApproved}
                        userId={teacher.userId}
                      />
                    </TableCell>
                    <TableCell
                      align="right"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <EditTeacherButton
                        data={{
                          userId: teacher.userId,
                          firstName: teacher.user.firstName,
                          lastName: teacher.user.lastName,
                          email: teacher.user.email,
                          isSchoolAdmin: teacher.isSchoolAdmin,
                        }}
                        userId={teacher.userId}
                      />
                      <DeleteTeacherButton userId={teacher.userId} />
                    </TableCell>
                  </TableRow>
                ))}
              </>
            ) : (
              <TableRow>
                <TableCell colSpan={6}>
                  <EmptyStateSubtle
                    title={t("no-data")}
                    description={t("add-teachers")}
                    buttonLabel={t("add-teacher")}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <AddTeacherModal open={open} handleClose={handleClose} />
    </Page>
  );
}
