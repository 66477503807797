import { Grid, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import Page from "../common/layout/Page";
import SelectRoleButton from "../components/no-role/buttons/SelectRoleButton";
import { Role } from "../schema/globalTypes";

export default function FinalizeRegistrationPage() {
  const { t } = useTranslation();

  return (
    <Page title={t("finalize-registration")}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item>
          <Typography
            sx={{ paddingTop: "36px", fontSize: { xs: "1.25rem", md: "3rem" } }}
            variant="h3"
            component="h1"
            gutterBottom
          >
            {t("finalize-registration")}
          </Typography>
          <Typography mb={2}>
            {" "}
            {t("finalize-registration-info-text")}
          </Typography>
          <Typography mb={6} variant="subtitle2">
            {t("please-choose-a-role")}
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={3}>
              <Box
                width="206px"
                height="206px"
                component={Paper}
                elevation={5}
                textAlign="center"
              >
                <Typography
                  padding={2}
                  sx={{ backgroundColor: "student.100", height: "50px" }}
                >
                  {t("student")}
                </Typography>

                <SelectRoleButton
                  role={Role.STUDENT}
                  backgroundColor="#B6DE2C"
                  confirmButtonColor="student.800"
                  //confirmButtonHover="student.600"
                />
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box
                width="206px"
                height="206px"
                component={Paper}
                elevation={5}
                textAlign="center"
              >
                <Typography
                  padding={2}
                  sx={{ backgroundColor: "instructor.50", height: "50px" }}
                >
                  {t("mentor")}
                </Typography>
                <SelectRoleButton
                  role={Role.INSTRUCTOR}
                  backgroundColor="#00A19A"
                  confirmButtonColor="instructor.main"
                />
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box
                width="206px"
                height="206px"
                component={Paper}
                elevation={5}
                textAlign="center"
              >
                <Typography
                  padding={2}
                  sx={{ backgroundColor: "teacher.50", height: "50px" }}
                >
                  {t("teacher")}
                </Typography>
                <SelectRoleButton
                  role={Role.TEACHER}
                  backgroundColor="#3F616F"
                  confirmButtonColor="teacher.main"
                />
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box
                width="206px"
                height="206px"
                component={Paper}
                elevation={5}
                textAlign="center"
              >
                <Typography
                  padding={2}
                  sx={{ backgroundColor: "organization.50", height: "50px" }}
                >
                  {t("organization")}
                </Typography>
                <SelectRoleButton
                  role={Role.ORGANIZATION}
                  backgroundColor="#a11086"
                  confirmButtonColor="organization.main"
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
}
