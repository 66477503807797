import { FormControlLabel, Switch } from "@mui/material";
import { useController, UseControllerProps } from "react-hook-form";

type InputSwitchProps<T> = {
  label?: string;
  required?: boolean;
  autoFocus?: boolean;
  multiline?: boolean;
  readOnly?: boolean;
} & UseControllerProps<T>;

function InputSwitch<T>({
  label,
  required,
  autoFocus,
  multiline,
  readOnly,
  ...props
}: InputSwitchProps<T>) {
  const rules = {
    required: required ? `${label} ist ein Pflichtfeld.` : undefined,
    ...props.rules,
  };

  const { field } = useController({ ...props, rules });

  return (
    <FormControlLabel
      control={
        <Switch
          {...field}
          defaultChecked={field.value === true}
          required={required}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            field.onChange(event.target.checked === true ? true : false);
          }}
          inputProps={{ "aria-label": "controlled" }}
        />
      }
      label={label ?? ""}
      labelPlacement="start"
    />
  );
}

export default InputSwitch;
