import { Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ReactNode } from "react";
import { Role } from "../schema/globalTypes";

interface NavigationSectionProps {
  children: ReactNode;
  title: string;
  role: Role;
}

const NavigationSection = ({
  children,
  role,
  title,
}: NavigationSectionProps) => {
  var backgroundColor = "white";
  switch (role) {
    case Role.STUDENT:
      backgroundColor = "student.100";
      break;
    case Role.INSTRUCTOR:
      backgroundColor = "instructor.100";
      break;
    case Role.TEACHER:
      backgroundColor = "teacher.100";
      break;
    case Role.ORGANIZATION:
      backgroundColor = "organization.100";
      break;
  }

  return (
    <Box component={Paper} width="100%" elevation={3} textAlign="center">
      <Typography
        padding={2}
        sx={{
          backgroundColor,
          letterSpacing: "0.2px",
          fontSize: "1.3rem",
          fontWeight: 300,
        }}
      >
        {title}
      </Typography>
      <Box padding={4}>{children}</Box>
    </Box>
  );
};

export default NavigationSection;
