import gql from "graphql-tag";
import React from "react";
import { useTranslation } from "react-i18next";
import FormModal from "../../../common/form/FormModal";
import InputDate from "../../../common/input/InputDate";
import InputText from "../../../common/input/InputText";
import { UpdateClassInput } from "../../../schema/globalTypes";
import { UpdateClass, UpdateClassVariables } from "../../../schema/UpdateClass";

interface EditClassDetailsModalProps {
  data?: UpdateClassInput | null;
  classId: string;
  open: boolean;
  handleClose: () => void;
}

export default function EditClassDetailsModal({
  data,
  classId,
  open,
  handleClose,
}: EditClassDetailsModalProps) {
  const { t } = useTranslation();

  const UPDATE_CLASS = gql`
    mutation UpdateClass($classData: UpdateClassInput!) {
      updateClass(command: $classData) {
        classId
        number
        startDate
        endDate
      }
    }
  `;

  return (
    <FormModal<UpdateClassInput, UpdateClass, UpdateClassVariables>
      title={t("edit-class")}
      description={t("change-the-class-details-here")}
      defaultValues={data || undefined}
      makeVars={(classData) => ({ classData: { ...classData, classId } })}
      mutation={UPDATE_CLASS}
      open={open}
      handleClose={handleClose}
    >
      {(control) => (
        <>
          <InputText
            control={control}
            name="classNumber"
            label={t("class-number")}
            required
            autoFocus
          />
          <InputDate
            control={control}
            name="startDate"
            label={t("start-date")}
            required
          />
          <InputDate
            control={control}
            name="endDate"
            label={t("end-date")}
            required
          />
        </>
      )}
    </FormModal>
  );
}
