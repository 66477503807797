import { AppBar, Stack, styled, Toolbar, Typography } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { Box } from "@mui/system";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import {
  BrowserRouter as Router,
  Link as RouterLink,
  Route,
  Switch,
} from "react-router-dom";
import NavImage from "../src/assets/images/logo flash6_rgb_4C_neg_klein@2x.png";
import SessionTimeoutNotification from "./common/auth/SessionTimeoutNotification";
import Footer from "./common/layout/Footer";
import LayoutAuthorized from "./common/layout/LayoutAuthorized";
import SplashScreen from "./common/layout/SplashScreen";
import FeedbackModal from "./components/FeedbackModal";
import { UserProvider } from "./context/UserContext";
import useAuthentication from "./hooks/useAuthentication";
import Impress from "./pages/Impress";
import RegistrationPage from "./pages/RegistrationPage";
import StartPage from "./pages/StartPage";
import Routes from "./Routes";

const RedirectLogin = () => {
  const { login } = useAuthentication();
  login();
  return null;
};

const Img = styled("img")({
  margin: "auto",
  display: "inline-block",
  maxWidth: "202px",
  maxHeight: "90px",
});

const App = () => {
  const { isInitialized, isAuthenticated } = useAuthentication();
  const { t } = useTranslation();

  if (!isInitialized) {
    return <SplashScreen />;
  }

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Digitales Ausbildungsheft für die Pflegeausbildung in Schleswig-Holstein"
        />
      </Helmet>
      <CssBaseline />
      <Router>
        <Box
          sx={{
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box>
            {isAuthenticated ? (
              <UserProvider>
                <LayoutAuthorized>
                  <SessionTimeoutNotification minRemainingSeconds={60 * 10} />
                  <Box textAlign="right">
                    <FeedbackModal />
                  </Box>
                  <Routes />
                </LayoutAuthorized>
              </UserProvider>
            ) : (
              <>
                <AppBar
                  position="fixed"
                  sx={
                    {
                      // width: { sm: `calc(100% - ${drawerWidth}px)` },
                      // ml: { sm: `${drawerWidth}px` },
                    }
                  }
                >
                  <Toolbar>
                    <Stack
                      direction="row"
                      spacing={2}
                      sx={{ alignItems: "center" }}
                    >
                      <RouterLink to="/" style={{ display: "flex" }}>
                        <Img alt="pflash-logo-nav" src={NavImage} />
                      </RouterLink>
                      <Typography
                        variant="navbarHeader"
                        sx={{ color: "inherit", textDecoration: "inherit" }}
                        component={RouterLink}
                        to="/"
                      >
                        {t("digital-training-documentation")}
                      </Typography>
                      <Typography
                        variant="navbarSubHeader"
                        sx={{
                          color: "inherit",
                          textDecoration: "inherit",
                          fontWeight: 200,
                        }}
                        component={RouterLink}
                        to="/"
                      >
                        {t("care-training-sh")}
                      </Typography>
                    </Stack>
                  </Toolbar>
                </AppBar>
                <Toolbar />
                <Toolbar />
                <Switch>
                  <Route path="/" exact strict>
                    <StartPage />
                  </Route>
                  <Route path="/impressum-datenschutz">
                    <Impress />
                  </Route>
                  <Route path="/registrierung" exact strict>
                    <RegistrationPage />
                  </Route>
                  <Route path="">
                    <RedirectLogin />
                  </Route>
                </Switch>
              </>
            )}
          </Box>
          <Footer />
        </Box>
      </Router>
    </>
  );
};

export default App;
