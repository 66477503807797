import gql from "graphql-tag";
import { useTranslation } from "react-i18next";
import FormModal from "../common/form/FormModal";
import InputText from "../common/input/InputText";
import { UpdateUserInput } from "../schema/globalTypes";
import { UpdateUser, UpdateUserVariables } from "../schema/UpdateUser";

interface EditUserModalProps {
  data?: UpdateUserInput | null;
  userId: string;
  title: string;
  description: string;
  open: boolean;
  handleClose: () => void;
}

export default function EditUserModal({
  data,
  userId,
  title,
  description,
  open,
  handleClose,
}: EditUserModalProps) {
  const UPDATE_USER = gql`
    mutation UpdateUser($userData: UpdateUserInput!) {
      updateUser(command: $userData) {
        id
        firstName
        lastName
        email
      }
    }
  `;

  const { t } = useTranslation();

  return (
    <FormModal<UpdateUserInput, UpdateUser, UpdateUserVariables>
      title={title}
      description={description}
      maxWidth="sm"
      defaultValues={data || undefined}
      mutation={UPDATE_USER}
      open={open}
      handleClose={handleClose}
      makeVars={(userData) => ({ userData: { ...userData, userId } })}
    >
      {(control) => (
        <>
          <InputText
            control={control}
            name="firstName"
            label={t("name")}
            required
          />
          <InputText
            control={control}
            name="lastName"
            label={t("lastname")}
            required
          />
          <InputText
            control={control}
            name="email"
            label={t("email")}
            required
          />
        </>
      )}
    </FormModal>
  );
}
