import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import AddButton from "../../common/buttons/AddButton";
import MoreInfoButton from "../../common/buttons/MoreInfoButton";
import DisplayDate from "../../common/display/DisplayDate";
import EmptyStateSubtle from "../../common/layout/EmptyStateSubtle";
import useCurrentUser from "../../hooks/useCurrentUser";
import useModal from "../../hooks/useModal";
import { StudentDetails } from "../../schema/StudentDetails";
import AddPracticalModal from "../student/modals/AddPracticalModal";
import DeletePracticalButton from "./buttons/DeletePracticalButton";

interface PracticalsProps {
  data?: StudentDetails | null;
  loading?: boolean;
}

const PracticalList = ({ data, loading }: PracticalsProps) => {
  const { user } = useCurrentUser();
  const { t } = useTranslation();
  const history = useHistory();
  const { url } = useRouteMatch();
  const { open, handleOpen, handleClose } = useModal();

  const handleClick = (practicalId: string) => {
    if (user && data) {
      history.push(`${url}/${practicalId}`);
    }
  };

  return (
    <>
      <Box
        sx={{
          visibility: { xs: "visible", sm: "hidden" },
        }}
        marginBottom={2}
      >
        <AddButton
          variant="contained"
          color="success"
          buttonLabel={t("add")}
          onClick={handleOpen}
        />
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead sx={{ backgroundColor: "student.100" }}>
            <TableRow>
              <TableCell component="th" scope="row">
                {t("practical")}
              </TableCell>
              <TableCell component="th" scope="row">
                {t("institution")}
              </TableCell>
              <TableCell component="th" scope="row">
                {t("practical-period")}
              </TableCell>
              <TableCell component="th" scope="row">
                {t("hours-practical-duration")}
              </TableCell>
              <TableCell component="th" scope="row">
                {t("absences")}
              </TableCell>
              <TableCell component="th" scope="col">
                Aktion erforderlich
              </TableCell>
              <TableCell component="th" scope="col">
                {t("approved")}
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                align="right"
                sx={{
                  visibility: { xs: "hidden", sm: "visible" },
                }}
              >
                <AddButton
                  variant="contained"
                  color="primary"
                  buttonLabel={t("add")}
                  onClick={handleOpen}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          {data?.practicals && data.practicals.length >= 1 ? (
            <TableBody>
              {data?.practicals.map((practical) => (
                <TableRow
                  hover
                  onClick={() => handleClick(practical.id)}
                  sx={{ cursor: "pointer" }}
                  key={practical.id}
                >
                  <TableCell
                    component="td"
                    sx={{
                      fontWeight: practical.isWaitingForStudent
                        ? "bold"
                        : undefined,
                    }}
                  >
                    {t("practicalType." + practical.practicalType)}
                  </TableCell>
                  <TableCell
                    component="td"
                    sx={{
                      fontWeight: practical.isWaitingForStudent
                        ? "bold"
                        : undefined,
                    }}
                  >
                    {
                      practical.practicalLocation?.response?.practicalLocation
                        .name
                    }
                  </TableCell>
                  <TableCell
                    component="td"
                    sx={{
                      fontWeight: practical.isWaitingForStudent
                        ? "bold"
                        : undefined,
                    }}
                  >
                    <DisplayDate>
                      {
                        practical.practicalDuration?.response?.practicalDuration
                          .startDate
                      }
                    </DisplayDate>
                    <br />
                    <DisplayDate>
                      {
                        practical.practicalDuration?.response?.practicalDuration
                          .endDate
                      }
                    </DisplayDate>
                  </TableCell>
                  <TableCell
                    component="td"
                    sx={{
                      fontWeight: practical.isWaitingForStudent
                        ? "bold"
                        : undefined,
                    }}
                  >
                    {practical.practicalDuration?.response?.practicalDuration
                      ? practical.practicalDuration?.response?.practicalDuration
                          .plannedHours +
                        "/" +
                        practical.practicalDuration?.response?.practicalDuration
                          .recordedHours
                      : ""}
                  </TableCell>

                  <TableCell
                    component="td"
                    sx={{
                      fontWeight: practical.isWaitingForStudent
                        ? "bold"
                        : undefined,
                    }}
                  >
                    {(practical.practicalDuration?.response?.practicalDuration
                      .plannedHours || 0) -
                      (practical.practicalDuration?.response?.practicalDuration
                        .recordedHours || 0) || ""}
                  </TableCell>
                  <TableCell
                    component="td"
                    sx={{
                      fontWeight: practical.isWaitingForStudent
                        ? "bold"
                        : undefined,
                    }}
                  >
                    {practical.isWaitingForStudent ? "ja" : "nein"}
                  </TableCell>
                  <TableCell
                    component="td"
                    sx={{
                      fontWeight: practical.isWaitingForStudent
                        ? "bold"
                        : undefined,
                    }}
                  >
                    {practical.isApprovedByStudent ? "ja" : "nein"}
                  </TableCell>

                  <TableCell align="right" onClick={(e) => e.stopPropagation()}>
                    {practical.canDelete && (
                      <DeletePracticalButton practicalId={practical.id} />
                    )}
                    <MoreInfoButton onClick={() => handleClick(practical.id)} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={8}>
                  <EmptyStateSubtle
                    title={t("no-practicals")}
                    description={t("add-practicals")}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {data && (
        <AddPracticalModal
          studentId={data.id}
          open={open}
          handleClose={handleClose}
        />
      )}
    </>
  );
};

export default PracticalList;
