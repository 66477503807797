import MenuIcon from "@mui/icons-material/Menu";
import { Stack, Toolbar, styled } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import useCurrentUser from "../hooks/useCurrentUser";
import LogoutMenu from "./LogoutMenu";
import NavImage from "../assets/images/logo flash6_rgb_4C_neg_klein@2x.png";

interface MenuTopProps {
  handleDrawerOpen: () => void;
}

export default function MenuTop({ handleDrawerOpen }: MenuTopProps) {
  const { user } = useCurrentUser();
  const { t } = useTranslation();

  const Img = styled("img")({
    margin: "auto",
    display: "inline",
    maxWidth: "202px",
    maxHeight: "60px",
  });

  return (
    <Toolbar sx={{ justifyContent: "space-between" }}>
      <Stack direction="row" sx={{ alignItems: "center" }}>
        <IconButton
          color="inherit"
          aria-label="anker-d"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{ mr: 2, display: { md: "none" } }}
        >
          <MenuIcon />
        </IconButton>
        <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
          <RouterLink to="/" style={{ display: "flex" }}>
            <Img alt="pflash-logo-nav" src={NavImage} />
          </RouterLink>
          <Typography
            variant="navbarHeader"
            component={RouterLink}
            to="/"
            sx={{ color: "inherit", textDecoration: "inherit" }}
          >
            {t("digital-training-documentation")}
          </Typography>
          <Typography
            variant="navbarSubHeader"
            component={RouterLink}
            to="/"
            sx={{
              color: "inherit",
              textDecoration: "inherit",
              fontWeight: 200,
            }}
          >
            {t("care-training-sh")}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row" sx={{ alignItems: "center" }} spacing={3}>
        {user && <LogoutMenu user={user} />}
      </Stack>
    </Toolbar>
  );
}
