import gql from "graphql-tag";
import React from "react";
import { useTranslation } from "react-i18next";
import FormModal from "../../../common/form/FormModal";
import InputRichText from "../../../common/input/InputRichText";
import { STUDENT_REMARKS } from "../../../fragments/student";
import { RemarksInput } from "../../../schema/globalTypes";
import {
  UpdateRemarks,
  UpdateRemarksVariables,
} from "../../../schema/UpdateRemarks";

interface EditRemarksModalProps {
  data?: RemarksInput | null;
  studentId: string;
  open: boolean;
  handleClose: () => void;
}

export default function EditRemarksModal({
  data,
  studentId,
  open,
  handleClose,
}: EditRemarksModalProps) {
  const { t } = useTranslation();

  const UPDATE_REMARKS = gql`
    ${STUDENT_REMARKS}
    mutation UpdateRemarks($remarks: RemarksInput!, $studentId: ID!) {
      updateRemarks(command: $remarks, studentId: $studentId) {
        id
        remarks {
          ...Remarks
        }
      }
    }
  `;

  return (
    <FormModal<RemarksInput, UpdateRemarks, UpdateRemarksVariables>
      title={t("edit-remarks")}
      description={t("change-the-remarks-here")}
      defaultValues={data || { remarks: "" }}
      makeVars={(remarks) => ({ remarks: { ...remarks }, studentId })}
      mutation={UPDATE_REMARKS}
      open={open}
      handleClose={handleClose}
    >
      {(control) => (
        <InputRichText
          control={control}
          name="remarks"
          label={t("remarks")}
          multiline
          required
          autoFocus
        />
      )}
    </FormModal>
  );
}
