import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";

type DeleteButtonProps = {
  onClick: () => void;
};

const DeleteButton = ({ onClick }: DeleteButtonProps) => {
  return (
    <IconButton
      onClick={onClick}
      sx={{ "&:hover": { "& .deleteIcon": { color: "error.main" } } }}
    >
      <DeleteIcon className="deleteIcon" fontSize="small" />
    </IconButton>
  );
};
export default DeleteButton;
