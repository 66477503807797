import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { LoadingButton } from "@mui/lab";
import { Grid } from "@mui/material";
import { saveAs } from "file-saver";
import { useState } from "react";
import useAuthentication from "../../../hooks/useAuthentication";

interface ExportButtonProps {
  url: string;
  filename: string;
  name: string;
}

const ExportButton = ({ filename, url, name }: ExportButtonProps) => {
  const [loading, setLoading] = useState(false);
  const { getToken } = useAuthentication();

  return (
    <Grid justifyContent="flex-end" container marginBottom={2}>
      <Grid item>
        <LoadingButton
          sx={{ marginLeft: 2 }}
          variant="outlined"
          color="warning"
          loading={loading}
          onClick={() => {
            setLoading(true);
            getToken()
              .then((token) =>
                fetch(url, {
                  method: "POST",
                  headers: {
                    authorization: token ? `Bearer ${token}` : "",
                  },
                })
              )
              .then((res) => {
                const header = res.headers.get("Content-Disposition");
                const parts = header?.split(";");
                if (parts && parts.length > 1) {
                  filename = parts[1].split("=")[1];
                }
                return res.blob();
              })
              .then((blob) => {
                saveAs(blob, filename);
              })
              .finally(() => {
                setLoading(false);
              });
          }}
          startIcon={<FileDownloadIcon />}
        >
          {name}
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

interface ExportTemplateButtonProps {
  url: string;
  filename: string;
  name: string;
}

const ExportTemplateButton = ({
  filename,
  url,
  name,
}: ExportTemplateButtonProps) => {
  const [loading, setLoading] = useState(false);
  return (
    <Grid justifyContent="flex-end" container marginBottom={2}>
      <Grid item>
        <LoadingButton
          sx={{ marginLeft: 2 }}
          variant="outlined"
          color="warning"
          loading={loading}
          onClick={() => {
            setLoading(true);
            fetch(url, { method: "POST" })
              .then(() => {
                const link = document.createElement("a");
                link.download = filename;
                link.href = url;
                link.dispatchEvent(
                  new MouseEvent("click", {
                    bubbles: true,
                    cancelable: true,
                    view: window,
                  })
                );
              })
              .finally(() => {
                setLoading(false);
              });
          }}
          startIcon={<FileDownloadIcon />}
        >
          {name}
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

interface ExportPracticalButtonProps {
  practicalId: string;
  name: string;
}

export const ExportPracticalButton = ({
  practicalId,
  name,
}: ExportPracticalButtonProps) => {
  const filename = `Praxiseinsatz-${practicalId}.pdf`;
  const url = `/export/practical/${practicalId}`;

  return <ExportButton name={name} filename={filename} url={url} />;
};

interface ExportPracticalTemplateButtonProps {
  name: string;
}

export const ExportPracticalTemplateButton = ({
  name,
}: ExportPracticalTemplateButtonProps) => {
  const filename = `Praxiseinsatz-Vorlage.pdf`;
  const url = `/praxiseinsatz-vorlage.pdf`;
  return <ExportTemplateButton name={name} filename={filename} url={url} />;
};

interface ExportProfileButtonProps {
  studentId: string;
  name: string;
}

export const ExportProfileButton = ({
  studentId,
  name,
}: ExportProfileButtonProps) => {
  const filename = `Ausbildungsprofil.pdf`;
  const url = `/export/profile/${studentId}`;

  return <ExportButton name={name} filename={filename} url={url} />;
};

interface ExportProfileTemplateButtonProps {
  studentId?: string;
  name: string;
}

export const ExportProfileTemplateButton = ({
  name,
}: ExportProfileTemplateButtonProps) => {
  const filename = `Deckblatt.pdf`;
  const url = `/deckblatt.pdf`;

  return <ExportTemplateButton name={name} filename={filename} url={url} />;
};
