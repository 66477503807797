import {
  Alert,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import EditButton from "../../../common/buttons/EditButton";
import DisplayRichText from "../../../common/display/DisplayRichText";
import useModal from "../../../hooks/useModal";
import { CompetenceAppendix } from "../../../schema/globalTypes";
import { PracticalTasks } from "../../../schema/PracticalTasks";
import FormSection from "../../FormSection";
import EditPracticalTasksModal from "../modals/EditPracticalTasksModal";

interface PracticalTasksProps {
  data: PracticalTasks | null;
  practicalId: string;
}

const FormsSectionPracticalTasks = ({
  data,
  practicalId,
}: PracticalTasksProps) => {
  const { t } = useTranslation();
  const { open, handleOpen, handleClose } = useModal();

  return (
    <>
      <FormSection
        sectionTitle={t("practical-tasks")}
        meta={data?.response?.meta}
        sectionAction={<EditButton handleOpen={handleOpen} />}
        headerBackgroundColor={"student.100"}
        canEdit={!!data?.access.canEdit}
      >
        <Stack spacing={2}>
          <Alert severity="info" color="success">
            Arbeits- und Lernaufgaben werden beim Erstgespräch gemeinsam mit den
            Praxisanleitenden und den Auszubildenden erstellt und können ggf. im
            Rahmen des Zwischengespräches ergänzt werden. Die Arbeits- und
            Lernaufgaben orientieren sich am Ausbildungsplan, am
            Ausbildungsstand der Auszubildenden, und sie richten sich nach dem
            jeweiligen Lernort sowie dessen Lernangebot. Um im Praxiseinsatz
            alle Kompetenzbereiche ausreichend zu berücksichtigen, sollten
            möglichst die den Arbeits- und Lernaufgaben zugehörigen
            Kompetenzschwerpunkte angegeben werden. Die Kompetenzschwerpunkte
            sind – je nach Ausbildungsjahr und Ausrichtung im letzten Drittel –
            den Anlagen 1 bis 4 der Pflegeberufe- Ausbildungs- und
            -Prüfungsverordnung zu entnehmen.12 Die Arbeits- und Lernaufgaben
            sind als Angebote zur Kompetenzentwicklung zu verstehen. Das heißt,
            sie werden im Verlauf der Ausbildung anspruchsvoller und bauen
            aufeinander auf. Dies drückt sich aus über eine Zunahme der
            Komplexität von zu bewältigenden Pflegesituationen, einen zunehmend
            höheren Anspruch an die pflegerischen Aufgaben sowie an die
            Selbstständigkeit und Verantwortungsübernahme durch die
            Auszubildenden. Entsprechend sollten für jeden Praxiseinsatz die
            Aufgabenstellungen bzw. Pflegesituationen und die zu ihrer
            Bearbeitung erforderlichen Handlungsmuster festgelegt werden. Bei
            sehr komplexen Pflegesituationen muss darauf geachtet werden, dass
            die Anforderungen und Aufgabenstellungen dennoch im Rahmen einer
            Ausbildung zur Pflegefachfrau/zum Pflegefachmann bleiben.
          </Alert>
          {data?.response?.practicalTasks &&
          data.response?.practicalTasks.tasks.length >= 1 ? (
            <TableContainer>
              <Table>
                <TableHead
                  sx={{
                    backgroundColor: "#ededed",
                    // borderBottom: "1px solid #f3f3f3",
                  }}
                >
                  <TableRow>
                    <TableCell
                    // sx={{
                    //   borderBottom: "1px solid #f3f3f3",
                    //   borderRight: "1px solid #f3f3f3",
                    // }}
                    >
                      {t("tasks-agreed-on-in-first-or-intermediate-interview")}
                    </TableCell>
                    {data.response?.practicalTasks.competenceAppendix && (
                      <TableCell>
                        {t(data.response?.practicalTasks.competenceAppendix)}
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.response?.practicalTasks.tasks.map((task, index) => (
                    <TableRow key={index}>
                      <TableCell
                        component="td"
                        scope="row"
                        // sx={{
                        //   borderBottom: "1px solid #f3f3f3",
                        //   borderRight: "1px solid #f3f3f3",
                        // }}
                      >
                        <Typography variant="subHeaderDescription">
                          {t("task")} {index + 1}
                        </Typography>
                        <DisplayRichText text={task.content} />
                      </TableCell>
                      <TableCell
                        component="td"
                        scope="row"
                        // sx={{ borderBottom: "1px solid #f3f3f3" }}
                      >
                        {`${task.competenceTypes.join(", ")}`}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography
              color="#c8cbcf"
              textAlign="center"
              justifyItems="center"
            >
              {t("no-data")}
            </Typography>
          )}
        </Stack>
      </FormSection>
      <EditPracticalTasksModal
        data={data?.response?.practicalTasks}
        practicalId={practicalId}
        open={open}
        handleClose={handleClose}
      />
    </>
  );
};
export default FormsSectionPracticalTasks;
