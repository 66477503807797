import { useQuery } from "@apollo/client";
import { Box, Tab, Tabs } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  Link as RouterLink,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from "react-router-dom";
import { GET_CLASS } from "../../pages/school/Class";
import { GetClass, GetClassVariables } from "../../schema/GetClass";
import ClassInstructor from "./ClassInstructor";
import ClassProfile from "./ClassProfile";
import ClassStudents from "./ClassStudents";

interface ClassDetailsProps {
  canEdit?: boolean;
  canOpen?: boolean;
  classId: string;
}

const ClassDetails = ({ canEdit, canOpen, classId }: ClassDetailsProps) => {
  useQuery<GetClass, GetClassVariables>(GET_CLASS, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    variables: { id: classId },
  });

  const { path, url, params } = useRouteMatch<{ id: string }>();
  const match = useRouteMatch(`${path}/:tabname`);
  const { t } = useTranslation();

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider", marginBottom: 2 }}>
          <Tabs
            value={match?.url}
            textColor="primary"
            indicatorColor="primary"
            aria-label="class tabs"
          >
            <Tab
              label={t("class-data")}
              value={`${url}/kursdaten`}
              component={RouterLink}
              to={`${url}/kursdaten`}
            />
            <Tab
              label={t("class-instructors")}
              value={`${url}/kursleiter`}
              component={RouterLink}
              to={`${url}/kursleiter`}
            />
            <Tab
              label={t("students")}
              value={`${url}/auszubildende`}
              component={RouterLink}
              to={`${url}/auszubildende`}
            />
          </Tabs>
        </Box>
      </Box>

      <Switch>
        <Route
          path={path}
          render={(props) => <Redirect to={`${url}/kursdaten`} />}
          exact
        />
        <Route
          path={`${path}/kursdaten`}
          render={(props) => <ClassProfile classId={params.id} />}
          exact
        />
        <Route
          path={`${path}/kursleiter`}
          render={(props) => <ClassInstructor classId={params.id} />}
          exact
        />
        <Route
          path={`${path}/auszubildende`}
          render={(props) => (
            <ClassStudents
              //canEdit={canEdit}
              canOpen={canOpen}
              classId={params.id}
            />
          )}
          exact
        />
      </Switch>
    </>
  );
};

export default ClassDetails;
