import AutorenewIcon from "@mui/icons-material/Autorenew";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { useController, UseControllerProps } from "react-hook-form";
import { generatePassword } from "../../helpers/helpers";

type InputPasswordProps<T> = {
  label: string;
  required?: boolean;
  autoFocus?: boolean;
  multiline?: boolean;
  length?: number;
  readOnly?: boolean;
} & UseControllerProps<T>;

function InputPassword<T>({
  label,
  required,
  autoFocus,
  multiline,
  length,
  readOnly,
  ...props
}: InputPasswordProps<T>) {
  const rules = {
    required: required ? `${label} ist ein Pflichtfeld.` : undefined,
    ...props.rules,
  };

  const {
    field,
    fieldState: { error },
  } = useController({ ...props, rules });

  return (
    <TextField
      {...field}
      label={label}
      fullWidth
      multiline={multiline}
      required={required}
      error={!!error}
      helperText={error ? error.message : ""}
      autoFocus={autoFocus}
      InputLabelProps={{
        shrink: field.value ? true : false,
      }}
      InputProps={{
        readOnly: readOnly ? true : false,
        // disableUnderline: readOnly ? true : false,
        endAdornment: !readOnly && (
          <InputAdornment position="end">
            <IconButton
              size="small"
              onClick={() => {
                field.onChange(generatePassword(length));
              }}
            >
              <AutorenewIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

export default InputPassword;
