import { Redirect, Route, Switch } from "react-router-dom";
import Guarded from "../common/auth/Guarded";
import NotFound from "../pages/NotFound";
import Class from "../pages/school/Class";
import Classes from "../pages/school/Classes";
import Intstructors from "../pages/school/Instructors";
import InstructorDetailsPage from "../pages/school/InstructorDetailsPage";
import Organizations from "../pages/school/Organizations";
import School from "../pages/school/School";
import SchoolStudentsPage from "../pages/school/SchoolStudentsPage";
import Teachers from "../pages/school/Teachers";
import { Role } from "../schema/globalTypes";

const SchoolRoutes = () => (
  <Guarded requiredRole={Role.SCHOOL_ADMIN}>
    <Switch>
      <Route path="/verwaltung/kurse/" exact strict>
        <Redirect to="/verwaltung/kurse" />
      </Route>
      <Route path="/verwaltung/kurse" exact>
        <Classes />
      </Route>
      <Route
        path="/verwaltung/kurse/:id"
        render={(props) => <Class classId={props.match.params.id} />}
      ></Route>
      <Route path="/verwaltung/auszubildende" exact>
        <SchoolStudentsPage />
      </Route>
      <Route exact path="/">
        <Redirect to="/verwaltung/auszubildende" />
      </Route>
      <Route exact path="/verwaltung">
        <Redirect to="/verwaltung/auszubildende" />
      </Route>
      <Route path="/verwaltung/lehrer" exact>
        <Teachers />
      </Route>
      <Route path="/verwaltung/praxisanleiter" exact>
        <Intstructors />
      </Route>
      <Route
        path="/verwaltung/praxisanleiter/:id"
        render={(props) => (
          <InstructorDetailsPage instructorId={props.match.params.id} />
        )}
      ></Route>
      <Route path="/verwaltung/schule" exact>
        <School />
      </Route>
      <Route path="/verwaltung/betriebe" exact>
        <Organizations />
      </Route>
      <Route path="">
        <NotFound />
      </Route>
      {/*  <Route path="/impressum-datenschutz">
        <Impressum />
      </Route> */}
    </Switch>
  </Guarded>
);

export default SchoolRoutes;
