import SearchIcon from "@mui/icons-material/Search";
import { IconButton } from "@mui/material";

interface MoreInfoButtonProps {
  onClick: () => void;
  buttonLabel?: string;
}

const MoreInfoButton = ({ onClick, buttonLabel }: MoreInfoButtonProps) => {
  return (
    <IconButton onClick={onClick}>
      <SearchIcon color="primary" />
    </IconButton>
  );
};

export default MoreInfoButton;
