import gql from "graphql-tag";
import React from "react";
import { useTranslation } from "react-i18next";
import FormModal from "../../../common/form/FormModal";
import {
  AddTeacherToClass,
  AddTeacherToClassVariables,
} from "../../../schema/AddTeacherToClass";
import { ClassUserAssignmentInput } from "../../../schema/globalTypes";
import TeacherDropdown from "../../teacher/TeacherDropdown";

interface AddTeacherToClassModalProps {
  data?: ClassUserAssignmentInput | null;
  classId: string;
  open: boolean;
  handleClose: () => void;
}

export default function AddTeacherToClassModal({
  data,
  classId,
  open,
  handleClose,
}: AddTeacherToClassModalProps) {
  const { t } = useTranslation();

  const ADD_TEACHER_TO_CLASS = gql`
    mutation AddTeacherToClass($classData: ClassUserAssignmentInput!) {
      addTeacherToClass(command: $classData) {
        classId
        teachers {
          userId
          user {
            id
            firstName
            lastName
          }
        }
      }
    }
  `;

  return (
    <FormModal<
      ClassUserAssignmentInput,
      AddTeacherToClass,
      AddTeacherToClassVariables
    >
      title={t("add-teacher")}
      description={t("add-teacher-to-class")}
      defaultValues={data || undefined}
      makeVars={(classData) => ({
        classData: { ...classData, classId },
      })}
      mutation={ADD_TEACHER_TO_CLASS}
      open={open}
      handleClose={handleClose}
    >
      {(control) => (
        <TeacherDropdown
          exceptClassId={classId}
          name="userId"
          control={control}
          label={t("add-teacher")}
        />
      )}
    </FormModal>
  );
}
