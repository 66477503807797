import { Alert, Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import EditButton from "../../../common/buttons/EditButton";
import DisplayDate from "../../../common/display/DisplayDate";
import DisplayRichText from "../../../common/display/DisplayRichText";
import TextBlock from "../../../common/display/TextBlock";
import TextLine from "../../../common/display/TextLine";
import useModal from "../../../hooks/useModal";
import { Role } from "../../../schema/globalTypes";
import { InterviewProtocolTeacher } from "../../../schema/InterviewProtocolTeacher";
import ApproveButtonInterviewProtocolTeacher from "../../approval/ApproveButtonInterviewProtocolTeacher";
import ApprovedBy from "../../approval/ApprovedBy";
import RejectButtonInterviewProtocolTeacher from "../../approval/RejectButtonInterviewProtocolTeacher";
import RequestApproveButtonInterviewProtocolTeacher from "../../approval/RequestApproveButtonInterviewProtocolTeacher";
import FormSection from "../../FormSection";
import EditInterviewProtocolTeacherModal from "../modals/EditInterviewProtocolTeacherModal";

interface InterviewProtocolTeacherProps {
  data: InterviewProtocolTeacher | null;
  practicalId: string;
}

const FormSectionInterviewProtocolTeacher = ({
  data,
  practicalId,
}: InterviewProtocolTeacherProps) => {
  const { t } = useTranslation();
  const { open, handleOpen, handleClose } = useModal();

  let protocol = undefined;
  if (data?.response?.interviewProtocolTeacher) {
    if (data?.response?.interviewProtocolTeacher) {
      const { id, ...rest } = data?.response?.interviewProtocolTeacher;
      protocol = rest;
    }
  }

  return (
    <>
      <FormSection
        sectionTitle={t("practical-assistance")}
        meta={data?.response?.meta}
        sectionAction={<EditButton handleOpen={handleOpen} />}
        headerBackgroundColor={"student.100"}
        canEdit={!!data?.access.canEdit}
      >
        <Stack spacing={2}>
          <Alert severity="info" color="success">
            Die Praxisbegleitung nach § 5 Pflegeberufe-Ausbildungs- und
            -Prüfungsverordnung durch die persönliche Anwesenheit Lehrender der
            Pflegeschule ist mindestens je einmal pro Orientierungseinsatz,
            Pflichteinsatz und Vertiefungseinsatz vorgesehen.14 Die
            Praxisbegleitung dient der Verzahnung der theoretischen und
            praktischen Ausbildung und insbesondere der fachlichen Betreuung und
            Beurteilung der Auszubildenden sowie ggf. der Unterstützung der
            Praxisanleitenden. Das Dokumentationsblatt ist für den Fall eines
            Beratungsgesprächs mit den Auszubildenden vorgesehen und nur als
            Vorschlag zu verstehen. Sollte ein anderes Format der
            Praxisbegleitung vorliegen, kann die Dokumentation auch auf andere
            Art erfolgen und dem Ausbildungsnachweis beigefügt werden. Die
            Auszubildenden werden dazu angehalten, die Gesprächsinhalte selbst
            zu dokumentieren.
          </Alert>
          {data?.response?.interviewProtocolTeacher ? (
            <Stack spacing={5}>
              <Stack spacing={1}>
                <TextBlock label={t("date-of-practical-assistance")}>
                  <DisplayDate>
                    {data?.response?.interviewProtocolTeacher?.date}
                  </DisplayDate>
                </TextBlock>
                <TextBlock label={t("attendees")}>
                  <TextLine
                    value={
                      data?.response?.interviewProtocolTeacher
                        ?.attendedByStudent
                        ? `${t("student")}`
                        : ""
                    }
                  />
                  <TextLine
                    value={
                      data?.response?.interviewProtocolTeacher
                        ?.attendedByInstructor
                        ? `${t("mentor")}`
                        : ""
                    }
                  />
                  <TextLine
                    value={
                      data?.response?.interviewProtocolTeacher
                        ?.attendedByTeacher
                        ? `${t("teacher-nursing-school")}`
                        : ""
                    }
                  />
                  {data?.response?.interviewProtocolTeacher?.attendedByOther.map(
                    (person, index) => (
                      <TextLine key={index} value={person.name} />
                    )
                  )}
                </TextBlock>
                <TextBlock label={t("interview-reason")}>
                  {data?.response?.interviewProtocolTeacher?.interviewReason.map(
                    (reason) => (
                      <TextLine value={t("interviewReason." + reason)} />
                    )
                  )}
                </TextBlock>
                <TextBlock label={t("interview-reason-other")}>
                  <TextLine
                    value={
                      data?.response?.interviewProtocolTeacher
                        .interviewReasonOtherDescription
                    }
                  />
                </TextBlock>

                <Typography
                  pt={6}
                  pb={2}
                  sx={{ fontWeight: 300 }}
                  color="#666e78"
                >
                  {t("documentation-practical-assistance")}
                </Typography>

                <TextBlock label={t("reflection-on-education")}>
                  <DisplayRichText
                    text={
                      data?.response?.interviewProtocolTeacher
                        .descriptionSituation
                    }
                  />
                </TextBlock>
                <TextBlock label={t("other-topics-and-protocol")}>
                  <DisplayRichText
                    text={
                      data?.response?.interviewProtocolTeacher.descriptionOther
                    }
                  />
                </TextBlock>
                <TextBlock label={t("result-and-further-agreements")}>
                  <DisplayRichText
                    text={
                      data?.response?.interviewProtocolTeacher
                        .descriptionResults
                    }
                  />
                </TextBlock>
              </Stack>
              <Stack spacing={2}>
                <Typography variant="sectionHeader">
                  {t("signatures")}
                </Typography>
                <Alert severity="info" color="success">
                  {t("signatures-info")}
                </Alert>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-end"
                >
                  {data?.response?.interviewProtocolTeacher && (
                    <Stack spacing={2}>
                      <ApprovedBy
                        role={Role.STUDENT}
                        approvedBy={
                          data.response.signatures?.signedByStudent?.fullName
                        }
                        approvalRequest
                        approvalRequested={
                          data.response.signatures?.readyForApproval
                        }
                      >
                        <RequestApproveButtonInterviewProtocolTeacher
                          canApprove={!!data.response.signatures?.canApprove}
                          protocolId={data.response.interviewProtocolTeacher.id}
                          role={Role.STUDENT}
                        />
                      </ApprovedBy>
                      <ApprovedBy
                        role={Role.TEACHER}
                        approvedAt={data.response.signatures?.signedByTeacherAt}
                        approvedBy={
                          data.response.signatures?.signedByTeacher?.fullName
                        }
                      >
                        <ApproveButtonInterviewProtocolTeacher
                          canApprove={
                            !!data.response.signatures.readyForApproval
                          }
                          protocolId={data.response.interviewProtocolTeacher.id}
                          role={Role.TEACHER}
                        />
                        <RejectButtonInterviewProtocolTeacher
                          protocolId={data.response.interviewProtocolTeacher.id}
                          role={Role.TEACHER}
                          canApprove={
                            !!data.response.signatures?.readyForApproval
                          }
                        />
                      </ApprovedBy>
                      <ApprovedBy
                        role={Role.INSTRUCTOR}
                        approvedAt={
                          data.response.signatures?.signedByInstructorAt
                        }
                        approvedBy={
                          data.response.signatures?.signedByInstructor?.fullName
                        }
                      >
                        <ApproveButtonInterviewProtocolTeacher
                          canApprove={
                            !!data.response.signatures.signedByTeacherAt
                          }
                          protocolId={data.response.interviewProtocolTeacher.id}
                          role={Role.INSTRUCTOR}
                        />
                      </ApprovedBy>
                      <ApprovedBy
                        role={Role.STUDENT}
                        approvedAt={data.response.signatures?.signedByStudentAt}
                        approvedBy={
                          data.response.signatures?.signedByStudent?.fullName
                        }
                      >
                        <ApproveButtonInterviewProtocolTeacher
                          protocolId={data.response.interviewProtocolTeacher.id}
                          role={Role.STUDENT}
                          canApprove={
                            !!data.response.signatures.signedByInstructorAt
                          }
                        />
                      </ApprovedBy>
                    </Stack>
                  )}
                </Box>
              </Stack>
            </Stack>
          ) : (
            <Typography
              color="#c8cbcf"
              textAlign="center"
              justifyItems="center"
            >
              {t("no-data")}
            </Typography>
          )}
        </Stack>
      </FormSection>
      <EditInterviewProtocolTeacherModal
        data={protocol}
        practicalId={practicalId}
        open={open}
        handleClose={handleClose}
      />
    </>
  );
};

export default FormSectionInterviewProtocolTeacher;
