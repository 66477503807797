import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { useTranslation } from "react-i18next";

interface SubmitButtonProps {
  loading: boolean;
  onSubmit: () => Promise<void>;
}

const SubmitButton = ({ loading, onSubmit }: SubmitButtonProps) => {
  const { t } = useTranslation();

  return (
    <LoadingButton
      loading={loading}
      loadingPosition="start"
      startIcon={<SaveIcon />}
      variant="contained"
      onClick={onSubmit}
    >
      {t("save")}
    </LoadingButton>
  );
};

export default SubmitButton;
