import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import DeleteButton from "../../../common/buttons/DeleteButton";
import ConfirmDialog from "../../../common/form/ConfirmDialog";
import { GET_INSTRUCTOR } from "../../../pages/school/InstructorDetailsPage";

import { GetInstructor } from "../../../schema/GetInstructor";
import {
  RemoveInstructorFromOrganization,
  RemoveInstructorFromOrganizationVariables,
} from "../../../schema/RemoveInstructorFromOrganization";

interface DeleteOrganizationButtonProps {
  instructorId: string;
  organizationId: string;
}

const REMOVE_INSTRUCTOR_FROM_ORGANIZATION = gql`
  mutation RemoveInstructorFromOrganization(
    $instructorId: ID!
    $organizationId: ID!
  ) {
    removeInstructorFromOrganization(
      instructorId: $instructorId
      organizationId: $organizationId
    ) {
      userId
      user {
        id
        firstName
        lastName
        fullName
      }
      organizations {
        userId
        organizationName
        street
        streetNumber
        postCode
        city
      }
    }
  }
`;

const DeleteOrganizationAssociatedWithInstructorButton = ({
  instructorId,
  organizationId,
}: DeleteOrganizationButtonProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [removeOrganization] = useMutation<
    RemoveInstructorFromOrganization,
    RemoveInstructorFromOrganizationVariables
  >(REMOVE_INSTRUCTOR_FROM_ORGANIZATION, {
    update(cache, { data }) {
      if (!data) {
        return;
      }

      cache.writeQuery<GetInstructor>({
        query: GET_INSTRUCTOR,
        data: { instructor: data?.removeInstructorFromOrganization },
        variables: {
          userId: instructorId,
        },
      });
    },
  });

  const [showConfirmation, setShowConfirmation] = useState(false);

  const deleteBtnHandler = () => {
    setShowConfirmation(true);
  };

  const { t } = useTranslation();

  const handleCancelHandler = () => {
    setShowConfirmation(false);
  };

  const handleConfirmHandler = async () => {
    try {
      await removeOrganization({
        variables: {
          instructorId,
          organizationId,
        },
      });
      enqueueSnackbar(`${t("organization-successfully-deleted")}`, {
        variant: "success",
      });
    } catch (e) {
      const error = e as Error;
      enqueueSnackbar(error.message, { variant: "error" });
    }
    setShowConfirmation(false);
  };

  return (
    <>
      <DeleteButton onClick={deleteBtnHandler} />
      <ConfirmDialog
        title={t("delete-organization")}
        open={showConfirmation}
        message={t("really-delete-organization")}
        handleConfirm={handleConfirmHandler}
        handleCancel={handleCancelHandler}
        confirmBtnText={t("delete")}
      />
    </>
  );
};

export default DeleteOrganizationAssociatedWithInstructorButton;
