import { Button, Grid, Paper, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import Page from "../common/layout/Page";
import NavigationSection from "../components/NavigationSection";
import RegisterInstructorButton from "../components/no-role/buttons/RegisterInstructorButton";
import RegisterOrganizationButton from "../components/no-role/buttons/RegisterOrganizationButton";
import RegisterStudentButton from "../components/no-role/buttons/RegisterStudentButton";
import RegisterTeacherButton from "../components/no-role/buttons/RegisterTeacherButton";
import SelectRoleButton from "../components/no-role/buttons/SelectRoleButton";
import useAuthentication from "../hooks/useAuthentication";
import { Role } from "../schema/globalTypes";

export default function RegistrationPage() {
  const { t } = useTranslation();
  const { login } = useAuthentication();

  return (
    <Page title={t("registration")} pageHeading={t("registration")}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item>
          <Typography mb={2}>{t("finalize-registration-info-text")}</Typography>
          <Typography mb={6}>{t("please-choose-a-role")}</Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Typography variant="subHeader">
                Registrierung als Auszubildende*r
              </Typography>
              <Stack spacing={2}>
                <Typography>
                  Wenn Sie Auszubildende*r und bereits bei einer Pflegeschule
                  angemeldet sind, registrieren Sie sich bitte hier.
                </Typography>
                <NavigationSection role={Role.STUDENT} title={t("student")}>
                  <RegisterStudentButton />
                </NavigationSection>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subHeader">
                Registrierung als Praxisanleiter*in
              </Typography>
              <Stack spacing={2}>
                <Typography>
                  Wenn Sie Praxisanleiter*in sind und über einen entsprechenden
                  Qualifikationsnachweis verfügen, registrieren Sie sich bitte
                  hier.
                </Typography>
                <NavigationSection role={Role.INSTRUCTOR} title={t("mentor")}>
                  <RegisterInstructorButton />
                </NavigationSection>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subHeader">
                Registrierung als Lehrer*in
              </Typography>
              <Stack spacing={2}>
                <Typography>
                  Wenn Sie Lehrer*in an einer Pflegeschule sind, registrieren
                  Sie sich bitte hier.
                </Typography>
                <NavigationSection role={Role.TEACHER} title={t("teacher")}>
                  <RegisterTeacherButton />
                </NavigationSection>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="subHeader">
                Registrierung als Betrieb
              </Typography>
              <Stack spacing={2}>
                <Typography>
                  Wenn Sie als Betrieb den Nachweis der geleisteten Stunden für
                  Auszubildende eintragen möchten, registrieren Sie sich bitte
                  hier.
                </Typography>
                <NavigationSection
                  role={Role.ORGANIZATION}
                  title={t("organization")}
                >
                  <RegisterOrganizationButton />
                </NavigationSection>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="subHeader">Bereits Registriert?</Typography>
              <Stack spacing={2}>
                <Typography>Hier geht es zur Anmeldung:</Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={login}
                  sx={{ width: "200px" }}
                >
                  Zum Login
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
}
